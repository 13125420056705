<template>
  <div id="centreRight2">
    <div class="bg-color-black">
<!--      <div class="d-flex pt-2 pl-2">-->
<!--        <span style="color:#5cd9e8">-->
<!--          <icon name="align-left"></icon>-->
<!--        </span>-->
<!--        <span class="fs-xl text mx-2">密码算法使用分析</span>-->
<!--      </div>-->
      <div class="d-flex jc-center body-box flow-rank" style=" margin-top: 0;">
<!--        <dv-capsule-chart :config="config" style="width: 100%;height:2rem" />-->
<!--        &lt;!&ndash; &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;-->
<!--        <centreRight2Chart1 />-->
        <div class="down">
          <div class="ranking bg-color-black">

            <div class="percent">
              <div class="item bg-color-black label-text-font">
                <span>加密流量比率</span>
                <CenterChart ref="flowPer1"  :tips="rate[0].tips" :colorObj="rate[0].colorData" />
              </div>
              <div class="item bg-color-black label-text-font">
                <span>国密算法使用率</span>
                <CenterChart ref="flowPer2" :tips="rate[1].tips" :colorObj="rate[1].colorData" />
              </div>
                <div>
                    <span style="color:#5cd9e8">
                  <icon name="align-left"></icon>
                </span>
                    <span class="fs-xl text mx-2 mb-1 label-text-font" style="margin-top: .12rem">最近3小时流量排行</span>
                </div>
              <dv-scroll-ranking-board ref="flowTop" :config="ranking" style="height:3.26rem; margin-top: .08rem" />
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import centreRight2Chart1 from "@/components/echart/centerRight/centerRight2Chart";
import CenterChart from "@/components/echart/center/centerChartRate";

export default {
  data() {
    return {
      ranking: {
        data: [],
        carousel: "single",
        unit: "MB",
          rowNum:6
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [{
          tips:0
      },{
          tips:0
      }],
        timer:null,
        timer2:null,
        first: true
    };
  },
  components: { CenterChart },
  mounted() {
      this.findTopCryptoFlow();
      this.findCryptoFlowPer();
  },
    destroyed() {
        if(this.timer){
            clearInterval(this.timer);
        }
        if(this.timer2){
            clearInterval(this.timer2);
        }
    },
  methods: {
      findCryptoFlowPer(){
          let self = this;
          self.doStaticsData();
          self.changeStaticsTiming();
      },
      doStaticsData(){
          let self = this;
          // self.rate = [{
          //     tips:0
          // },{
          //     tips:0
          // }]
          let param = {
              type: VIEW_TYPE
          }
          self.$http.post({
              url: self.$api.bigScreenApi.findCryptoFlowPer,
              opt: {showSuccess: false, isLoading: false},
              params: VIEW_TYPE,
              callback: {
                  success: data => {
                      self.rate = [
                          {
                              id: "centerRate1",
                              tips: data.result.tips1,
                              colorData: {
                                  textStyle: "#3fc0fb",
                                  series: {
                                      color: ["#00bcd44a", "transparent"],
                                      dataColor: {
                                          normal: "#03a9f4",
                                          shadowColor: "#97e2f5"
                                      }
                                  }
                              }
                          },
                          {
                              id: "centerRate2",
                              tips: data.result.tips2,
                              colorData: {
                                  textStyle: "#67e0e3",
                                  series: {
                                      color: ["#faf3a378", "transparent"],
                                      dataColor: {
                                          normal: "#ff9800",
                                          shadowColor: "#fcebad"
                                      }
                                  }
                              }
                          }
                      ]
                      self.$refs.flowPer1.draw();
                      self.$refs.flowPer2.draw();
                  }
              }
          })
      },
      // makeStaticsData(){
      //     let self = this;
      //     let datas = self.staticsData;
      //     self.titleItem = []
      //     for(var i = 0; i < datas.length; i++){
      //         self.titleItem.push(
      //             {
      //                 title: datas[i].name,
      //                 number: {
      //                     number: [datas[i].value],
      //                     toFixed: 0,
      //                     content: "{nt}"
      //                 }
      //             }
      //         );
      //     }
      //     console.log(self.titleItem)
      // },
      findTopCryptoFlow(){
          let self = this;
          self.doFindData();
          self.changeTiming();
      },
      doFindData(){
          let self = this;
          self.$http.post({
              url: self.$api.bigScreenApi.findTopCryptoFlow,
              opt: {showSuccess: false, isLoading: false},
              params: VIEW_TYPE,
              callback: {
                  success: data => {
                      self.ranking.data = data.result;
                      if(self.first){
                          self.first = false;
                          self.$refs.flowTop.calcData();
                      }
                  }
              }
          })
      },
      // makeData(){
      //     let self = this;
      //     let datas = self.cryptoData;
      //     self.flowData = []
      //     let date = []
//     let data = []
      //     for(var i = 0; i < datas.length; i++){
      //         date.push(
      //             datas[i].date
      //         );
      //         data.push(datas[i].data);
      //     }
      //     self.flowData =  {
      //         date: date,
      //         data: data
      //     }
      //     self.$refs.dataCenterDisplay.draw();
      // },
      changeTiming() {
          let self = this;
          self.timer = setInterval(() => {
              self.doFindData();
          }, 60000);
      },
      changeStaticsTiming() {
          let self = this;
          self.timer2 = setInterval(() => {
              self.doStaticsData();
          }, 10000);
      },
  }
};
</script>

<style lang="scss">
#centreRight2 {
  padding: 0.0625rem;
  height: 5.45rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
      padding: 0.0625rem 0.0625rem 0.015rem;
    /*height: 5.0625rem;*/
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
.flow-rank {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      height: 0.875rem;
    }
  }
  .down {
    padding: 0.07rem 0.05rem 0;
    width: 100%;
    display: flex;
    /*height: 3.1875rem;*/
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 100%;
    }
    .percent {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 45%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>