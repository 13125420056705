<template>
    <el-main>
        <el-form label-position="top" class="search-form" label-width="100px">
            <el-row :gutter="35">
                <el-col :span="4">
                    <el-form-item label="操作类型">
                        <el-select v-model="action" placeholder="请选择" clearable>
                            <el-option
                                v-for="item in actionType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="3">
                    <el-form-item label="." class="search-button-space">
                        <el-button
                            class="search-button"
                            @click="select"
                            :size="buttonSize"
                            type="primary"
                            plain>
                            <span class="icon iconfont iconsearch"></span>
                            查询
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row class="paging-row width-ninety">
            <el-col :span="24" class="text-right">
                <!-- <el-button type="primary" :size="buttonSize" @click="add">
                    <span class="icon iconfont iconfileplus-fill"></span>
                    添加类型
                </el-button> -->
                <!-- <el-button type="primary" :size="buttonSize" @click="edit">
                    <span class="icon iconfont iconedit"></span>
                    编辑
                </el-button> -->
                <!-- <el-button type="success" :size="buttonSize" @click="doEnabled">
                    <span class="icon iconfont iconcheck-circle"></span>
                    <span>启用</span>
                </el-button> -->
                <!-- <el-button
                    type="warning"
                    :size="buttonSize"
                    @click="doDisabled">
                    <span class="icon iconfont iconban"></span>
                    <span>禁用</span>
                </el-button> -->
                <!-- <el-button type="danger" :size="buttonSize" @click="del">
                    <span class="icon iconfont icondelete-fill"></span>
                    <span>删除</span>
                </el-button> -->
                <!--                    <el-button type="primary" :size="buttonSize" @click="registerUser"><span class="icon iconfont iconuser-group-fill  "></span> 点击注册</el-button>-->
            </el-col>
        </el-row>
        <el-table
            tooltip-effect="light"
            :data="tableData"
            @selection-change="handleSelectionChange"
            height="300px"
            v-loading="loading"
            stripe
            class="table-top">
            <el-table-column
                fixed="left"
                type="selection"
                width="55"></el-table-column>
            <el-table-column
                prop="action"
                min-width="120"
                label="操作类型"
                show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ actionType[scope.row.action].label }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160">
                <template slot-scope="scope">
                    {{ scope.row.createTime | formatDate }}
                </template>
            </el-table-column>
            <el-table-column
                prop="ip"
                width="140"
                label="操作 IP"></el-table-column>
            <el-table-column
                prop="changeUserType"
                width="100"
                label="操作人类型">
                <template slot-scope="scope">
                    {{ scope.row.changeUserType == 1 ? '管理员' : '委外用户' }}
                </template>
            </el-table-column>
            <el-table-column
                prop="changeUserName"
                min-width="120"
                label="操作人账号"></el-table-column>
            <el-table-column
                prop="changeUserRealName"
                min-width="120"
                label="操作人真实姓名"></el-table-column>
            <el-table-column prop="isSuccess" width="100" label="操作状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.isSuccess ? 'success' : 'danger'">
                        {{ scope.row.isSuccess ? '成功' : '失败' }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="log"
                min-width="200"
                label="描述"></el-table-column>
        </el-table>

        <el-row class="paging-row width-ninety">
            <el-col :span="12" class="float-right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 15, 20, 30, 40, 50]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-size="pageSize"
                    :current-page="pageNo"
                    :total="total"></el-pagination>
            </el-col>
        </el-row>

        <el-dialog
            :title="isAdd ? '添加应用类型' : '编辑应用类型'"
            :modal-append-to-body="false"
            :append-to-body="true"
            @close="closeDialog"
            :close-on-click-modal="false"
            :visible.sync="dialogEditModelFormVisible"
            width="20%"
            custom-class="small-dialog-min-width">
            <el-form :model="modelForm" ref="modelForm">
                <el-form-item
                    label="类型名称"
                    prop="name"
                    :label-width="formLabelWidth"
                    :rules="{
                        required: true,
                        message: '类型名称不能为空',
                        trigger: 'blur'
                    }">
                    <el-input
                        class="in-line-box-input"
                        v-model="modelForm.name"
                        auto-complete="off"
                        placeholder="不大于50个字"
                        :size="inputSize"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button
                    @click="cancel"
                    :size="buttonSize"
                    class="black-button">
                    取 消
                </el-button>
                <el-button
                    type="primary"
                    @click="saveModel('modelForm', 0)"
                    :size="buttonSize">
                    保存
                </el-button>
                <el-button
                    type="success"
                    v-if="isAdd"
                    @click="saveModel('modelForm', 1)"
                    :size="buttonSize">
                    保存并继续
                </el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
import { formatDate } from '../../../../assets/js/date'

export default {
    name: 'DeviceTypeDialog',
    props: {
        id: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            buttonSize: BUTTONSIZE,
            inputSize: INPUTSIZE,
            modelDialogSaveButton: '保存',
            action: null,
            pageNo: 1,
            pageSize: 10,
            tableData: [],
            total: 0,
            formLabelWidth: '150px',
            currentModelId: '',
            dialogEditModelFormVisible: false,
            isClose: null,
            modelForm: {
                name: ''
            },
            ids: [],
            isAdd: false,
            loading: false,
            selectModels: [],
            actionType: {
                0: { label: '未知', value: 0 },
                1: { label: '提交审核', value: 1 },
                2: { label: '审核通过', value: 2 },
                3: { label: '审核不通过', value: 3 },
                4: { label: '审核回滚', value: 4 },
                5: { label: '应用数据新增操作', value: 5 },
                6: { label: '应用数据修改操作', value: 6 },
                7: { label: '应用数据删除操作', value: 7 }
            }
        }
    },
    created() {
        this.findAll()
    },
    filters: {
        formatDate(time) {
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
            //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
        },
        formatDateToDate(time) {
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd')
        }
        // checkStatus([status, options]) {
        //     for (var i = 0; i < options.length; i++) {
        //         if (status == options[i].value) return options[i].label;
        //     }
        // },
    },
    methods: {
        del() {
            let self = this
            if (self.ids.length <= 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                self.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'black-button',
                    type: 'warning'
                })
                    .then(() => {
                        self.$http.post({
                            url: self.$api.applicationApi
                                .delApplicationsTypeByIds,
                            params: { ids: self.ids },
                            callback: {
                                success: (data) => {
                                    self.findAll()
                                }
                            }
                        })
                    })
                    .catch(() => {})
            }
        },
        doDisabled() {
            let self = this
            if (self.ids.length <= 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                self.$confirm('此操作将禁用该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'black-button',
                    type: 'warning'
                })
                    .then(() => {
                        self.$http.post({
                            url: self.$api.deviceApi.updateDeviceTypeToDisabled,
                            params: { ids: self.ids },
                            callback: {
                                success: (data) => {
                                    self.findAll()
                                }
                            }
                        })
                    })
                    .catch(() => {})
            }
        },
        doEnabled() {
            let self = this
            if (self.ids.length <= 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                self.$http.post({
                    url: self.$api.deviceApi.updateDeviceTypeToEnabled,
                    params: { ids: self.ids },
                    callback: {
                        success: (data) => {
                            self.findAll()
                        }
                    }
                })
            }
        },
        edit() {
            if (this.selectModels.length != 1) {
                this.$alert('需要且只能选择一条数据进行此操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                let row = this.selectModels[0]
                this.modelForm = row
                this.isAdd = false
                this.dialogEditModelFormVisible = true
            }
        },
        closeDialog() {
            this.$refs['modelForm'].resetFields()
            this.findAll()
        },
        add() {
            this.modelForm = {}
            this.isAdd = true
            this.dialogEditModelFormVisible = true
        },
        saveModel(formName, val) {
            let self = this
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.doSaveModel(val)
                } else {
                    return false
                }
            })
        },
        clearForm() {
            this.modelForm = { name: '' }
            this.$refs['modelForm'].resetFields()
        },
        doSaveModel(val) {
            let self = this
            self.$http.post({
                url: self.$api.applicationApi.saveApplicationsType,
                params: self.modelForm,
                callback: {
                    success: (data) => {
                        if (val == 0) {
                            self.findAll()
                            self.dialogEditModelFormVisible = false
                        } else {
                            self.clearForm()
                        }
                    }
                }
            })
        },
        cancel() {
            this.dialogEditModelFormVisible = false
            this.findAll()
        },
        handleSelectionChange(val) {
            let self = this
            self.selectModels = val
            self.ids = []
            for (let i = 0; i < val.length; i++) {
                self.ids.push(val[i].id)
            }
        },
        handleSizeChange(val) {
            this.pageNo = 1
            this.pageSize = val
            this.findAll()
        },
        handleCurrentChange(val) {
            this.pageNo = val
            this.findAll()
        },
        select() {
            this.pageNo = 1
            this.findAll()
        },

        findAll() {
            let self = this
            self.loading = true
            let data = {
                pageNo: self.pageNo - 1,
                pageSize: self.pageSize,
                action: self.action,
                applicationId: self.id
            }
            if (self.action || self.action === 0) {
                data = {
                    pageNo: self.pageNo - 1,
                    pageSize: self.pageSize,
                    action: self.action,
                    applicationId: self.id
                }
            } else {
                data = {
                    pageNo: self.pageNo - 1,
                    pageSize: self.pageSize,
                    applicationId: self.id
                }
            }
            self.$http.post({
                url: self.$api.applicationApi.findApplicationsLogByPageAndId,
                params: data,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.loading = false
                        self.tableData = data.result.content
                        self.total = data.result.totalElements
                    },
                    error: (data) => {
                        self.loading = false
                    },
                    fail: (res) => {
                        self.loading = false
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.table-top {
    margin-top: 30px;
}

.search-bt {
    width: 120px;
    margin-top: 51px;
}
</style>
