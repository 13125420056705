<template>
    <el-main style="min-width:1200px">
        <el-row class="con statistic-table" :gutter="20">
            <el-col :span="8">
                <el-card class="center">

                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 设备调用统计</span>
                        </div>

                        <el-radio-group v-model="staticsDay1" @change="(label) => {changeStaticsDay(label, 1)}">
                            <el-radio-button label="WEEK">周</el-radio-button>
                            <el-radio-button label="MONTH">月</el-radio-button>
                            <el-radio-button label="YEAR">年</el-radio-button>
                        </el-radio-group>
                    </div>

                    <div class="graph-panel tls-version-box">
                        <div class="empty-data" v-if="sessCount.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-if="!isLoading">暂时没有数据</div>
                        </div>

                        <default-pie :data="sessCount" radius='45%' />

                    </div>

                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 源 IP 数据统计</span>
                        </div>

                        <el-radio-group v-model="staticsDay2" @change="(label) => {changeStaticsDay(label, 2)}">
                            <el-radio-button label="WEEK">周</el-radio-button>
                            <el-radio-button label="MONTH">月</el-radio-button>
                            <el-radio-button label="YEAR">年</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="graph-panel tls-version-box">
                        <div class="empty-data" v-if="srcIpCount.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-if="!isLoading">暂时没有数据</div>
                        </div>

                        <default-bar :data="srcIpCount" :xAxis="srcIpXAxis" />

                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 设备流量统计</span>
                        </div>

                        <el-radio-group v-model="staticsDay3" @change="(label) => {changeStaticsDay(label, 3)}">
                            <el-radio-button label="WEEK">周</el-radio-button>
                            <el-radio-button label="MONTH">月</el-radio-button>
                            <el-radio-button label="YEAR">年</el-radio-button>
                        </el-radio-group>
                    </div>

                    <div class="graph-panel tls-version-box">
                        <div class="empty-data" v-if="databytesCount.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-if="!isLoading">暂时没有数据</div>
                        </div>

                        <default-bar :data="databytesCount" :xAxis="databytesXAxis" yAxisName="流量大小/字节" />

                    </div>

                </el-card>

                <!-- <div class="graph-panel tls-version-box">
                        <el-table tooltip-effect="light" height="350" v-loading="isLoading" class="table-top width-ninety" stripe :data="databytesCount">
                            <el-table-column prop="name" show-overflow-tooltip label="套件名称">
                            </el-table-column>
                            <el-table-column prop="count" width="120" label="数量（单位：个）" align="right">
                            </el-table-column>
                        </el-table>
                    </div> -->
            </el-col>
        </el-row>
        <el-row class="con statistic-table" :gutter="20">
            <el-col :span="8">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 设备连接数量</span>
                        </div>

                        <el-radio-group v-model="staticsDay4" @change="(label) => {changeStaticsDay(label, 4)}">
                            <el-radio-button label="WEEK">周</el-radio-button>
                            <el-radio-button label="MONTH">月</el-radio-button>
                            <el-radio-button label="YEAR">年</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="graph-panel dst-ip-bar-box">
                        <div class="empty-data" v-if="sessDailyCount.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-if="!isLoading">暂时没有数据</div>
                        </div>

                        <default-line :data="sessDailyCount" :xAxis="dailyXAxis" />

                    </div>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card class="center">
                    <!-- <div slot="header" class="clearfix">
                        <span class="title-text"><i class="icon iconfont icondata"></i> 数据统计</span>
                    </div> -->
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 设备通信列表</span>
                        </div>
                        <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDateRange" :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                    <div class="graph-panel tls-version-box">

                        <el-table tooltip-effect="light" height="350" class="table-top width-ninety" stripe :data="dataList"  v-loading="loading">
                            <el-table-column prop="timestamp" show-overflow-tooltip label="时间">
                                <template slot-scope="scope">
                                    {{scope.row.timestamp | formatDateFilter}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="deviceName" show-overflow-tooltip label="设备名称">
                            </el-table-column>
                            <el-table-column prop="srcIp" show-overflow-tooltip label="源IP">
                            </el-table-column>
                            <el-table-column prop="dstIp" show-overflow-tooltip label="目的IP">
                                <template slot-scope="scope">
                                    {{scope.row.dstIp}}:{{scope.row.dstPort}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="protocol" show-overflow-tooltip label="协议">
                                <template slot-scope="scope">
                                    <span v-for="(item, index) in scope.row.protocol" :key="index">{{index===0 ? '' : ','}} {{item}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="totPackets" show-overflow-tooltip label="包数量">
                            </el-table-column>
                            <el-table-column prop="totDataBytes" show-overflow-tooltip label="数据大小">
                                <template slot-scope="scope">
                                    {{scope.row.totDataBytes | fileSizeFilter}}
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-row class="paging-row width-ninety">
                            <el-col :span="24">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 15, 20, 30, 40, 50]" layout="total, sizes, prev, pager, next, jumper" :page-size="pageSize" :current-page="pageNo" :total="total">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-main>
</template>
<script>
export default {
    data() {
        return {
            isLoading: false,
            isInit: true,
            dataInfo: {
                sess_daily_count: [],
                sess_count: [],
                srcip_count: [],
                Databytes_count: [],
            },
            staticsDay: 'MONTH',
            staticsDay1: 'MONTH',
            staticsDay2: 'MONTH',
            staticsDay3: 'MONTH',
            staticsDay4: 'MONTH',
            staticsDay5: 'MONTH',
            staticsDayType: 1,
            sessDailyCount: [],
            dailyXAxis: [],
            sessCount: [],
            srcIpCount: [],
            srcIpXAxis: [],
            databytesCount: [],
            databytesXAxis: [],

            pageNo: 1,
            pageSize: 15,
            total: 0,
            searchForm: {
                startTime: '',
                endTime: '',
            },
            dataList: [],
            dateRange: '',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            loading: false

        }
    },
    mounted() {
        this.deviceStatistics()
        this.getConnectInfoList()
    },
    methods: {
        changeStaticsDay(val, type) {
            this.staticsDay = this['staticsDay' + type]
            this.staticsDayType = type
            this.deviceStatistics()
        },
        formatDailyXAxis(day) {
            let dates = []
            let today = new Date().getTime()
            for (let i = day; i >= 0; i--) {
                let beforeToday = today - 1000 * 60 * 60 * 24 * i
                dates.push(this._.formatDate(beforeToday))
            }
            return dates
        },
        changeDateRange(val) {
            if(val) {
                this.searchForm.startTime = val[0]
                this.searchForm.endTime = val[1]
            } else {
                this.searchForm.startTime = ''
                this.searchForm.endTime = ''
            }
            this.getConnectInfoList()
        },
        handleSizeChange(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.getConnectInfoList();
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.getConnectInfoList();
        },

        deviceStatistics() {
            let params = {
                staticsDay: this.staticsDay
            }
            if (this.isInit) {
                this.isLoading = true;
            }
            this.$http.post({
                url: this.$api.deviceApi.deviceStatistics,
                params: params,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {

                        this.dataInfo = data.result || {};

                        if (this.staticsDayType === 1 || this.isInit) {
                            this.sessCount = []
                            this.dataInfo.sess_count.map(i => {
                                if (i.deviceName) {
                                    this.sessCount.push({ value: i.count, name: i.deviceName, deviceMac: i.deviceMac })
                                }
                            })

                        }

                        if (this.staticsDayType === 2 || this.isInit) {
                            this.srcIpCount = []
                            this.srcIpXAxis = []
                            this.dataInfo.srcip_count.map(i => {
                                if (i.name) {
                                    this.srcIpCount.push(i.count)
                                    this.srcIpXAxis.push(i.name)
                                }
                            })
                        }

                        if (this.staticsDayType === 3 || this.isInit) {
                            this.databytesCount = []
                            this.databytesXAxis = []
                            this.dataInfo.Databytes_count.map(i => {
                                if (i.deviceName) {
                                    this.databytesCount.push(i.count)
                                    this.databytesXAxis.push(i.deviceName)
                                }
                            })
                        }

                        if (this.staticsDayType === 4 || this.isInit) {
                            if (this.staticsDay === 'WEEK') {
                                this.dailyXAxis = this.formatDailyXAxis(6)
                            }
                            if (this.staticsDay === 'MONTH') {
                                this.dailyXAxis = this.formatDailyXAxis(29)
                            }
                            if (this.staticsDay === 'YEAR') {
                                this.dailyXAxis = this.formatDailyXAxis(364)
                            }
                            this.sessDailyCount = this.dataInfo.sess_daily_count
                        }

                        this.isInit = false
                        this.isLoading = false

                    }
                }
            })

        },
        getConnectInfoList() {
            let params = {
                pageNo: this.pageNo - 1,
                pageSize: this.pageSize,
                model: 'DeviceData',
                ...this.searchForm
            }
            this.loading = true
            this.$http.post({
                url: this.$api.deviceApi.getConnectInfoList,
                params: params,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        this.dataList = data.result.result || [];
                        this.total = data.result.total
                        this.loading = false
                    }
                }
            })

        }
    }
}
</script>
<style lang="scss" scoped="scoped">
.con {
    display: flex;
}
.hous {
    display: flex;
    flex-direction: column;
    /*text-align: center;margin: auto;border: 1px solid red;*/
    justify-content: space-around;
}
.long-bt {
    width: 120px;
}
.mar-top {
    margin-top: 30px;
}
.label-text {
    color: #606266;
    line-height: 40px;
    display: inline-block;
    padding: 0 0 10px;
    font-size: 14px;
}
.statistic-table {
    margin-top: 20px;
}
.graph-panel {
    position: relative;
}
.statics-day {
    position: absolute;
    top: -30px;
    right: 0;
}
.network-encrypt-daily-box {
    height: 300px;
    position: relative;
}

.tls-version-box {
    height: 350px;
}
.dst-ip-bar-box {
    height: 350px;
}
.header-title {
    display: flex;
    justify-content: space-between;
}
</style>