<template>
  <el-container class="login-body">
    <el-main class="login-main">
      <el-row class="title-logo">
        <el-col>
            <img src="../../assets/images/login-logo-hd.png" v-if="!isOem"><span class="app-title" v-if="isOem">{{env.VUE_APP_TITLE}}</span>
        </el-col>
      </el-row>
      <el-row class="title-desc">
        <el-col>
          <span>密码设备与密码应用管理、网络流量与应用日志监控、密码算法与协议统计分析、密码漏洞与异常监测告警</span>
        </el-col>
      </el-row>
<!--      <el-card class="box-card center margin-top-small-box">-->
       <!-- <el-row>
          <el-col :span="24" class="logo-box"><img src="./../../assets/logo-index.png" width="260"></img></el-col>
        </el-row>-->
        <!--<div slot="header" class="clearfix">-->
          <!--<span class="web-title">xx公司经销商订货系统</span>-->
        <!--</div>-->
<!--        <el-header class="login-header">-->
<!--          <span class="web-title">{{companyName}}</span>-->
<!--          <div @click="toBigScreen">大屏</div>-->
<!--        </el-header>-->


<!--      </el-card>-->
      <el-row class="login-row box-card">
        <el-col :span="10" :offset="12">

          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="login-form center">

<!--            <div class="qr-login-button" @click="useWXLogin">-->
<!--              <el-tooltip class="item" effect="dark" content="微信扫码登录" :value="true" placement="left" :manual="true" popper-class="login-tips" >-->
<!--                <i class="iconfont iconerweima login-bt-icon"></i>-->
<!--              </el-tooltip>-->
<!--              <i class="iconfont icondiannao login-bt-icon-no" v-if="!isUserLogin"></i>-->
<!--            </div>-->

            <div v-if="isUserLogin">
            <h3>操作员登录</h3>
<!--            <el-radio-group v-model="loginType" class="bright-radio-group" >-->
<!--                <el-radio :label="1" v-if="authType == 1 || authType == 3">账号密码登录</el-radio>-->
<!--                <el-radio :label="2" v-if="authType == 2 || authType == 3">SIM盾登录</el-radio>-->
<!--            </el-radio-group>-->
            <el-form-item prop="username" v-if="loginType != 2">
              <el-input type="text" v-model="ruleForm.username" size="big" auto-complete="off" placeholder="请输入用户名">
                <template slot="prepend"><i class="iconfont iconyonghuming1"/> </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" v-if="loginType != 2">
              <el-input type="password" v-model="ruleForm.password" size="big" auto-complete="off" @keyup.enter.native="actionLogin('ruleForm')" :placeholder="useUkey?'输入UKEY PIN码':'请输入密码'">
                <template slot="prepend"><i class="iconfont iconmima1"/></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="simPhone" v-if="loginType == 2">
                <el-input
                    type="text"
                    v-model="ruleForm.simPhone"
                    size="big"
                    placeholder="请输入手机号码"
                    @keyup.enter.native="actionLogin('ruleForm')">
                    <template slot="prepend">
                        <i class="iconfont iconphone-fill" />
                    </template>
                </el-input>
            </el-form-item>
            <el-alert v-if="errorShow"
                      :title="errorText"
                      type="error"
                      :closable="false">
            </el-alert>
            <el-form-item label-width="0" >
                <el-row :gutter="20">
                    <el-col :span="24">
<!--                        <el-button size="medium" v-if="!useUkey" type="primary" :loading="isLoading" autofocus @click="submitForm('ruleForm')" class="login-bt">登录</el-button>-->
                        <el-button size="medium" type="primary" :loading="isLoading" autofocus @click="actionLogin('ruleForm')" class="login-bt">登 录</el-button>
<!--                        <el-button size="medium" v-if="useUkey" type="primary" :loading="isLoading" autofocus @click="submitFormWithUKey('ruleForm')" class="login-bt">登录</el-button>-->
                    </el-col>
                                            <el-col :span="12">
<!--                        <el-button size="medium" type="primary" :loading="isLoading" autofocus @click="submitFormWithUKey('ruleForm')" class="login-bt">UKEY登录</el-button>-->
                    </el-col>
                </el-row>
            </el-form-item>
            </div>

            <!--<div v-if="!isUserLogin">-->
            <!---->
            <!--</div>-->
          </el-form>
        </el-col>
      </el-row>
    </el-main>
    <el-footer class="login-footer">
      <div class="text-left">{{env.VUE_APP_TITLE}} {{env.VUE_APP_VERSION}}{{env.VUE_APP_INTERNAL_VERSION}} {{env.VUE_APP_COPYRIGHT}}</div>
      <div class="text-right">
          <span class="is-href"><router-link tag="a" target="_blank" class="color-light" :to="{path:'/dview'}">地图展示</router-link></span> |
<!--          <span class="is-href"><router-link tag="a" target="_blank" class="color-light" :to="{path:'/cview'}">地图展示</router-link></span> |-->
<!--          <span class="is-href"><router-link tag="a" target="_blank" class="color-light"  :to="{path:'/dview'}">大屏展示</router-link></span> |-->
          <span>{{env.VUE_APP_COMPANY}}</span></div>
    </el-footer>

  </el-container>

</template>

<script>
    import ElRow from "element-ui/packages/row/src/row";
    import ElCol from "element-ui/packages/col/src/col";
    import {mapActions, mapState,mapGetters} from "vuex";

    export default {
        components: {
            ElCol,
            ElRow
        },
        name: 'AdminLogin',
        data() {
            var validateUsername = (rule, value, callback) => {
                this.ruleForm.username = this.ruleForm.username.trim();
                //console.log(this.ruleForm.username.trim())
                if (value.trim() === '') {
                    callback(new Error('请输入用户名'));
                } else {
                    callback();
                }
            };
            var validatePassword = (rule, value, callback) => {
                //console.log(rule,);//用户名
                //console.log(value );//密码
                //console.log(callback );//密码
                if (value === '') {
                    let s = '请输入密码'
                    if(USE_UKEY){
                        s = '请输入UKEY PIN码'
                    }
                    callback(new Error(s));
                } else {
                    callback();
                }
            };

            return {
                isOem: IS_OEM,
                isLoading: false,
                ruleForm: {
                    username: '',
                    password: '',
                    simPhone: ''
                },
                rules: {
                    username: [
                        {validator: validateUsername, trigger: 'blur'}
                    ],
                    password: [
                        {validator: validatePassword, trigger: 'blur'}
                    ]
                },
                errorShow: false,
                errorText: "",
                isUserLogin: true,
                // isUseCaptcha:1,
                // captchaAppId:"",
                // isCaptchaReady:false,
                // TxCaptcha:null,
                env: process.env,
                sessionStr: null,
                loginParams:{},
                useUkey:USE_UKEY,
                initStatus:0,//系统初始化请求是否成功
                loginType: 1,
                authType: 0,
            };
        },
        computed: {
            ...mapState("localModules", {
                localUseName: state => state.useName
            }),
            ...mapState("sessionModules", {
                sessionUseName: state => state.useName
            })
        },
        created() {
            // if(this.useUkey){
            //     this.getSessionStr();
            // }
            this.getSessionStr();
            // console.log(this.localUseName);
            // console.log(this.sessionUseName);
            // this.test();
        },
        methods: {
            actionLogin(formName){
                if(this.initStatus === 0){
                    this.$alert('系统还未就绪，请刷新再试', '信息', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                }else {
                    this.$cookies.set("USE_UKEY", this.useUkey);
                    if(this.loginType == 2) {
                        // 选择 SIM 盾登录
                        this.simDunLogin()
                    } else {
                        if(this.useUkey){
                            this.submitFormWithUKey(formName);
                        }else {
                            this.submitForm(formName);
                        }
                    }
                    
                }

            },
            test(){
                let p = {
                    ukeyId: "ukeyname",
                    jsStr: "jsStr",
                    sessionStr: "self.sessionStr",
                    username: "self.ruleForm.username",
                    // pin: self.ruleForm.password,

                }
                let s = this.$sm2SignHelper.buildSignParamAndSort(p);
                let sm3Hex = this.$sm2SignHelper.sm3Hex(s);
            },
            toMapScreen() {    //地图展示
                this.goUrl("/mview")
            },
            toBigScreen() {    //大图展示
                this.goUrl("/bview")
            },
            getSystemCaptchaParam() {
                let self = this;
                self.$http.post({
                    url: self.$api.actionApi.systemCaptchaParam,
                    opt: {showSuccess: false, isLoading: false},
                    callback: {
                        success: (data => {
                            if (data.error === 0) {
                                self.isUseCaptcha = data.result.isUseCaptcha;
                                self.captchaAppId = data.result.appId;
                                self.isCaptchaReady = true;
                                if (self.isUseCaptcha === 1) {
                                    self.TxCaptcha = new TencentCaptcha(self.captchaAppId, self.captchaCallBack, null);
                                }
                            } else {
                                this.$alert('验证码初始化失败：' + data.msg, '信息', {
                                    confirmButtonText: '确定',
                                    showClose: false,
                                    type: "warning",
                                    callback: action => {
                                        self.isCaptchaReady = false;
                                    }
                                });
                            }
                        })
                    }
                })
            },
            captchaCallBack(res) {
                if (res.ret === 0) {
                    //alert(res.ticket)   // 票据
                    this.doLogin(res.ticket, res.randstr)
                } else {
                    this.errorShow = true;
                    this.errorText = "验证码验证失败";
                }
            },
            submitForm(formName) {
                let self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        // console.log(valid);
                        // if(self.isUseCaptcha == 1){
                        //   self.TxCaptcha.show();
                        // }else{
                        //   self.doLogin();
                        // }
                        self.doLogin();
                    } else {
                        return false;
                    }
                });
            },
            submitFormWithUKey(formName) {
                let self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        // console.log(valid);
                        // if(self.isUseCaptcha == 1){
                        //   self.TxCaptcha.show();
                        // }else{
                        //   self.doLogin();
                        // }
                        self.isLoading = true;
                        self.getUkeyInfoAndDoLogin();
                    } else {
                        return false;
                    }
                });
            },
            getUkeyInfoAndDoLogin(){
                let self = this;

                self.$http.uKeyPost({
                    url: self.$apiUrl.uKeyApi.action,
                    params: {
                        OP: 1
                    },
                    opt: {showError: true, isLoading:false, showSuccess: false},
                    callback: {
                        success: data => {
                            self.doLoginWithUkey(data.result.NAME);
                        },
                        error: data => {
                            // self.$alert("暂时不能登录，UKEY服务未就绪："+data.msg, '错误', {
                            //     confirmButtonText: '确定',
                            //     showClose: false,
                            //     type: "error"
                            // });
                            self.isLoading = false;
                            self.errorShow = true;
                            self.errorText = "登录失败："+data.msg + " [CODE:"+data.error+"]";
                        },
                        fail: res =>{
                            // self.$alert("暂时不能登录，UKEY服务未就绪："+res.message, '错误', {
                            //     confirmButtonText: '确定',
                            //     showClose: false,
                            //     type: "error"
                            // });
                            self.isLoading = false;
                            self.errorShow = true;
                            self.errorText = "登录失败："+res.message;
                        }
                    }
                })
            },
            doLoginWithUkey(ukeyName) {
                let self = this;
                let sm3Hex = self.buildUSm3Hex(ukeyName);
                self.$http.uKeyPost({
                    url: self.$apiUrl.uKeyApi.action,
                    params: {
                        OP: 2,
                        PIN: self.ruleForm.password,
                        CHAP: sm3Hex
                    },
                    opt: {showError: true, isLoading:false, showSuccess:false},
                    callback: {
                        success: data => {
                            let sign = data.result.SIGN;
                            let token = data.result.TOKEN;
                            self.doUkeyLoginForJava(sign, token);
                        },
                        error: data => {
                            self.isLoading = false;
                            self.errorShow = true;
                            self.errorText = "登录失败："+data.msg ;
                        },
                        fail: res =>{
                            self.isLoading = false;
                            self.errorShow = true;
                            self.errorText = "登录失败："+res.message;
                        }
                    }
                })
            },
            doUkeyLoginForJava(sign, token){
                let self = this;
                let data = self.loginParams;
                data.signData = sign;
                self.$http.post({
                    url: self.$apiUrl.uKeyActionApi.ukeylogin,
                    params: data,
                    opt: {showError: true, isLoading:false, showSuccess:false},
                    callback: {
                        success: data => {
                            self.errorShow = false;
                            self.errorText = '';
                            self.$cookies.remove(TOKENNAME);
                            self.$cookies.remove(UKEY_TOKENNAME);
                            self.$cookies.set(TOKENNAME, data.result.token, 0);
                            self.$cookies.set(UKEY_TOKENNAME, token, 0);
                            self.isLoading = true;
                            if (self.$route.query.redirect) {
                                this.goUrl(this.$route.query.redirect);
                            } else {
                                self.goUrl("/admin");
                            }
                        },
                        error: data => {
                            self.getSessionStr();
                            self.isLoading = false;
                            self.errorShow = true;
                            self.errorText = "登录失败："+data.msg;
                        },
                        fail: res =>{
                            self.getSessionStr();
                            self.isLoading = false;
                            self.errorShow = true;
                            self.errorText = "登录失败："+res.message;
                        }
                    }
                })
            },
            doLogin(ticket, randstr) {

                let self = this;
                //console.log(self)
                // var data = {
                //   username:self.ruleForm.username,
                //   password:self.ruleForm.password,
                //   ticket:ticket,
                //   randstr:randstr
                // };
                self.isLoading = true;
                var data = {
                    username: self.ruleForm.username,
                    password: self.ruleForm.password
                };
                // self.$cookie.setCookie(TOKENNAME,"this is a constant token",1);
                // self.goUrl("/admin");
                self.$http.post({
                    url: self.$api.actionApi.userLogin,
                    params: data,
                    opt: {isLoading: false, showSuccess: false},
                    callback: {
                        success: data => {
                            self.isLoading = false;
                            if (data.error !== 0) {
                                self.errorShow = true;
                                self.errorText = data.msg;
                            } else {
                                self.errorShow = false;
                                self.errorText = '';
                                self.$cookies.remove(TOKENNAME);
                                self.$cookies.set(TOKENNAME, data.result.token, -1);
                                if (self.$route.query.redirect) {
                                    this.goUrl(this.$route.query.redirect);
                                } else {
                                    self.goUrl("/admin");
                                }
                            }
                        },
                        error: data => {
                            self.isLoading = false;
                            self.errorShow = true;
                            self.errorText = data.msg;
                            // this.$alert(data.msg, '提示', {
                            //     confirmButtonText: '确定',
                            //     showClose: false,
                            //     type: "warning"
                            // });
                        },
                        fail: res => {
                            self.isLoading = false;
                            // this.$alert('网络连接失败，请稍候再试！', '信息', {
                            //     confirmButtonText: '确定',
                            //     showClose: false,
                            //     type: "warning"
                            // });
                        }
                    }
                })
            },
            noAction() {
                this.$alert('暂时没有开放这个功能！', '信息', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: "warning"
                });
            },
            goUrl(url) {
                this.$router.push(url);
            },
            getSessionStr() {
                let self = this;
                self.$http.post({
                    url: self.$api.uKeyActionApi.ukeyparam,
                    opt: {isLoading: false, showSuccess: false, showError: false},
                    callback: {
                        success: data => {
                            self.sessionStr = data.result.sessionStr;
                            self.useUkey = data.result.useUkey;
                            self.initStatus = 1;
                            self.authType = data.result.authType
                            if(data.result.authType == 1 || data.result.authType == 3) {
                                self.loginType = 1
                            }
                            if(data.result.authType == 2) {
                                self.loginType = 2
                            }
                        },
                        error: data => {
                            self.$message({
                                duration: 0,
                                showClose: true,
                                type: 'error',
                                // message: "系统错误，暂时无法使用，请刷新后再试：" + data.message
                                message: data.message
                            })
                        },
                        fail: res => {
                            self.$message({
                                duration: 0,
                                showClose: true,
                                type: 'error',
                                // message: "系统错误，暂时无法使用，请刷新后再试：" + res.message
                                message: res.message
                            })
                        }
                    }
                })
            },
            buildUSm3Hex(ukeyname){
                let self = this;
                let jsStr = this.$sm2SignHelper.buildJsStr(10);
                self.loginParams = {
                    jsStr: jsStr,
                    sessionStr: self.sessionStr,
                    username: self.ruleForm.username,
                    // pin: self.ruleForm.password,
                    ukeyId: ukeyname
                }
                //返回排序后的值
                let sortParams = this.$sm2SignHelper.buildSignParamAndSort(self.loginParams);
                return this.$sm2SignHelper.sm3Hex(sortParams);
            },
            //SIM 盾登录 satr
            simDunLogin() {
                this.$http.post({
                    url: this.$apiUrl.actionApi.simDunLogin,
                    params: { phone: this.ruleForm.simPhone },
                    opt: { showSuccess: false, isLoading: true, showError: true },
                    callback: {
                        success: (data) => {
                            this.simShieldRes = data.result
                            this.checkSimDunLogin()
                        },
                        error: (data) => {
                            this.isLoading = false
                        },
                        fail: (res) => {
                            this.isLoading = false
                        }
                    }
                })
            },
            checkSimDunLogin() {
                this.$http.post({
                    url: this.$apiUrl.actionApi.checkSimDunLogin,
                    params: {
                        phone: this.simShieldRes.phone,
                        uuId: this.simShieldRes.uuId
                    },
                    opt: { showSuccess: false, isLoading: false, showError: false },
                    callback: {
                        success: (data) => {
                            if (data.error != 0) {
                                this.errorShow = true
                                this.errorText = data.msg
                            } else {
                                this.errorShow = false
                                this.errorText = ''
                                this.$cookies.remove(TOKENNAME)
                                this.$cookies.remove(ADMINTYPE)
                                this.$cookies.set(TOKENNAME, data.result.token, -1)
                                this.$cookies.set(ADMINTYPE, data.result.type, -1)
                                global.ISLOGIN = '1'
                                if (data.result.isChanged != 1) {
                                    // 未修改密码
                                    this.$confirm('第一次登录，请修改密码！', '提示', {
                                        confirmButtonText: '确定',
                                        type: 'warning',
                                        showClose: false,
                                        showCancelButton: false,
                                        closeOnClickModal: false,
                                        closeOnPressEscape: false,
                                        callback: (action) => {
                                            this.dialogUpdatePasswordFormVisible = true
                                        }
                                    })
                                } else {
                                    if (this.$route.query.redirect) {
                                        this.goUrl(this.$route.query.redirect)
                                    } else {
                                        this.goUrl('/admin')
                                    }
                                }
                            }
                            this.isLoading = false
                        },
                        error: (data) => {
                            if (data.error == 300) {
                                this.isShowSpin = true
                                this.simShieldCheckTimer && clearTimeout(this.simShieldCheckTimer)
                                this.simShieldCheckTimer = setTimeout(() => {
                                    this.checkSimDunLogin()
                                }, 1000)
                            } else {
                                this.$message.error(data.msg || '操作失败')
                                this.isLoading = false
                                this.isShowSpin = false
                                this.isShowVcode = false
                            }
                        },
                        fail: (data) => {
                            this.$message.error(data.msg || '操作失败')
                            this.isLoading = false
                            this.isShowSpin = false
                            this.isShowVcode = false
                        }
                    }
                })
            },
            // SIM 盾登录 end
        },

    }
</script>
<style scoped>
  .title-desc{
    padding:15px 0;
    color: #fff;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
  .login-footer{
    background: transparent !important;
    border-top: 1px solid #1c8af4;
    color: rgba(255,255,255,.7);
    min-width: 1280px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .app-title{
      display: inline-block;
      height: 100px;
      background-image: url(../../assets/images/oem_logo_write.png);
      background-size: 80px 80px;
      background-repeat: no-repeat;
      font-size: 36px;
      line-height: 80px;
      color: #ffffff;
      padding-left: 90px;
  }
  .title-logo{
    height: 80px;
    text-align: center;
    width: 100%;
  }
  .title-logo img{
    height: 80px;
  }
  .login-body{
    position: relative;
    height: 100%;
    min-height: 500px;
    background: url(../../assets/images/r-bgL.jpg) no-repeat;
    background-size: 100% 100%;
    min-width: 1280px;
    font-size:14px;
  }
  .login-row{
    background-image: url("../../assets/images/r-n-login.png");
    background-repeat: no-repeat;
    background-size: 430px;
    background-position: 40% 30%;
    /*background-position: left bottom;*/
    /*background-color: #4fc3ff;*/
    height:430px;
    min-width: 1280px;
  }
  .logo-box{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logo-img{
    height:100px;
    width: 100px;
  }
  .width-100{
    width:100%;
  }
  @media (min-width: 0){
    .box-card{
      margin-top: 2%;
    }
  }
  @media (min-width: 1280px){
    .box-card{
      margin-top: 4%;
    }
  }
  @media (min-width: 1920px){
    .box-card{
      margin-top: 6%;
    }
  }
  .box-card{
    width: 100%;
  }

  .login-form{
    width: 280px;
    /* min-height: 240px; */
    padding: 20px 20px;
    margin-top: 90px;
    background: #fff;
    position: relative;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
  .qr-login-button{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    padding:5px;
    color: #008000;
  }
  .qr-login-button:hover{
    color: #005d00;
  }
  .login-bt-icon{
    font-size: 42px;
    cursor: pointer;
  }
  .login-bt-icon-no{
    font-size: 32px;
    cursor: pointer;
  }
  .login-bt{
    width: 100%;
  }

</style>

<style>
  .margin-top-small-box .el-card__body{
    padding:0 !important;
  }
  .el-container{
    height: 100%;
  }
    .color-light{
        color:rgba(255,255,255,.7);
    }
    .bright-radio-group {
        margin-bottom: 18px;
    }
    .bright-radio-group .el-radio {
        color: #6582a0;
    }
    .bright-radio-group .el-radio__input.is-checked+.el-radio__label {
        color: #003cb4 !important;
    }
    /* #034c96 */
</style>
