<template>
    <div>
        <!--        <el-form label-position="top" class="search-form" label-width="100px">-->
        <!--            <el-row :gutter="35">-->
        <!--                <el-col :span="7">-->
        <!--                    <el-form-item label="CNNVD编码">-->
        <!--                        <el-input v-model="cveId" class="input-width" :size="inputSize" clearable></el-input>-->
        <!--                    </el-form-item>-->
        <!--                </el-col>-->

        <!--                <el-col :span="7">-->
        <!--                    <el-form-item label="描述">-->
        <!--                        <el-input v-model="description" class="input-width" :size="inputSize" clearable></el-input>-->
        <!--                    </el-form-item>-->
        <!--                </el-col>-->

        <!--                <el-col :span="7">-->
        <!--                    <el-form-item label="." class="search-button-space">-->
        <!--                        <el-button class="search-button" @click="select" :size="buttonSize" type="primary" plain><span class="icon iconfont iconsearch"></span> 查询</el-button>-->
        <!--                    </el-form-item>-->
        <!--                </el-col>-->
        <!--            </el-row>-->
        <!--        </el-form>-->
        <el-row class="operation-button">
            <el-col :span="24" class="text-right">
                <!--                <el-button type="primary" @click="addUser">添加</el-button>-->
                <!--                <el-button type="success" :size="buttonSize" @click="changeToEnabled">启用</el-button>-->
                <!--                <el-button type="warning" :size="buttonSize" @click="changeToDisabled">禁用</el-button>-->
                <!--                <el-button type="danger" :size="buttonSize" @click="del">删除</el-button>-->
                <el-radio-group v-model="isHandle" size="mini" @change="handleRadioChange">
                    <el-radio-button :label="0">未处理</el-radio-button>
                    <el-radio-button :label="1">全部</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-table
                tooltip-effect="light"
                :height="tableHeight"
                v-loading="loading"
                stripe
                class="table-top width-ninety body-table"
                :data="tableData">
            <el-table-column
                    label="告警时间"
                    width="160">
                <template slot-scope="scope">
                    {{scope.row.createTime|formatDate}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="level"
                    label="告警级别"
                    width="100">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.level == 1?'info':scope.row.level == 2?'warning':'danger'">{{scope.row.level == 1?'一级':scope.row.level == 2?'二级':scope.row.level == 3?'三级':'未知'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="applicationName"
                    width="200"
                    label="应用名称">
            </el-table-column>
            <el-table-column
                    prop="applicationIp"
                    width="120"
                    label="应用IP">
            </el-table-column>
            <el-table-column
                    prop="log"
                    show-overflow-tooltip
                    label="告警内容">
            </el-table-column>

<!--            <el-table-column-->
<!--                    prop="appid"-->
<!--                    width="150"-->
<!--                    show-overflow-tooltip-->
<!--                    label="APPID">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                    show-overflow-tooltip-->
<!--                    prop="moduleid"-->
<!--                    width="180"-->
<!--                    label="MODULEID">-->
<!--            </el-table-column>-->
            <el-table-column
                    prop="isHandle"
                    label="处理状态"
                    width="80">
                <template  slot-scope="scope">
                    <el-tag :type="scope.row.isHandle == 1?'success':'danger'">{{scope.row.isHandle == 1?'已处理':'未处理'}}</el-tag>
                </template>
            </el-table-column>
<!--            <el-table-column-->
<!--                    prop="handlerName"-->
<!--                    width="80"-->
<!--                    label="处理人">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                    prop="handleTime"-->
<!--                    label="处理时间"-->
<!--                    width="180">-->
<!--                <template slot-scope="scope">-->
<!--                    {{scope.row.handleTime|formatDate}}-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="edit(scope.row)"><span class="icon iconfont iconeye"></span> 查看</el-button>
                    <el-button type="info" :disabled="scope.row.isHandle === 1" size="mini" @click="saveWarn(scope.row.id,1)"><span class="icon iconfont iconcheck"></span> 处理</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row class="paging-row width-ninety">
            <el-col :span="24">
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 15, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        :current-page="pageNo"
                        :total="total">
                </el-pagination>
            </el-col>
        </el-row>

        <!--        用户编辑-->
        <el-dialog :title="warnDialogTitle" @close="closeDialog" :close-on-click-modal="false" :visible.sync="dialogWarnFormVisible" width="40%" custom-class="mid-dialog-min-width">
            <el-form :model="warnForm" ref="warnForm">
                <el-form-item label="告警时间" prop="createTime"
                              :label-width="formLabelWidth">
                    {{warnForm.createTime|formatDate}}
                </el-form-item>
                <el-form-item label="告警应用" prop="applicationName"
                              :label-width="formLabelWidth">
                    {{warnForm.applicationName}}
                </el-form-item>
                <el-form-item label="告警级别" prop="level"
                              :label-width="formLabelWidth">
                    <el-tag :type="warnForm.level == 1?'info':warnForm.level == 2?'warning':'danger'">{{warnForm.level == 1?'一级':warnForm.level == 2?'二级':warnForm.level == 3?'三级':'未知'}}</el-tag>
                </el-form-item>
                <el-form-item label="应用IP" prop="applicationIp"
                              :label-width="formLabelWidth">
                    {{warnForm.applicationIp}}
                </el-form-item>
                <el-form-item label="APPID" prop="appid"
                              :label-width="formLabelWidth">
                    {{warnForm.appid}}
                </el-form-item>
                <el-form-item label="MODULEID" prop="moduleid"
                              :label-width="formLabelWidth">
                    {{warnForm.moduleid}}
                </el-form-item>
                <el-form-item label="告警内容" prop="log"
                              :label-width="formLabelWidth">
                    {{warnForm.log}}
                </el-form-item>
                <el-form-item v-if="warnForm.isHandle == 1" label="处理人"
                              :label-width="formLabelWidth">
                    {{warnForm.handlerName}}
                </el-form-item>
                <el-form-item  v-if="warnForm.isHandle == 1" label="处理时间"
                               :label-width="formLabelWidth">
                    {{warnForm.handleTime|formatDate}}
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel" class="black-button" :size="buttonSize">取 消</el-button>
                <el-button type="primary" :disabled="warnForm.isHandle == 1" @click="saveWarn(warnForm.id,0)" :size="buttonSize">{{warnDialogSaveButton}}</el-button>
                <!--                <el-button type="success" v-if="isAdd" @click="saveModelAndContinue('userForm')" :size="buttonSize">保存并继续</el-button>-->
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {formatDate} from '../../../../assets/js/date'
    export default {
        name:"AppCryptoVdWarnPage",
        data(){
            return{
                tableHeight: window.innerHeight  - 305,
                inputSize:INPUTSIZE, //输入框大小
                buttonSize:BUTTONSIZE, //按钮大小
                formLabelWidth: '120px',
                pageNo:1,   //1页
                pageSize : 15, //10条数据
                tableData: [],
                total:0,
                isHandle:0,
                dialogWarnFormVisible:false,
                warnDialogTitle:"查看告警信息详情",
                warnDialogSaveButton:"处理",
                warnForm:{},
                loading:false
            }
        },
        mounted(){
            this.findByPage();
        },
        filters:{
            formatDate(time){
                if(!time)return"";
                let date = new Date(time);
                return formatDate(date,'yyyy-MM-dd hh:mm:ss');
                //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
            },
        },
        methods: {
            saveWarn(id,val){
                let self = this;
                self.$confirm('确认该告警已经被处理了吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass:"black-button",
                    type: 'warning'
                }).then(() => {
                    self.$http.post({
                        url: self.$api.adminApi.handleAppWarnLog,
                        params: {id: id},
                        callback: {
                            success: data => {
                                if(val == 0){
                                    self.dialogWarnFormVisible = false;
                                }
                                self.findByPage();
                            }

                        }
                    })
                }).catch(() => {
                });
            },
            handleSizeChange(val) {
                this.pageNo = 1;
                this.pageSize = val;
                this.findByPage();
            },
            handleCurrentChange(val) {
                this.pageNo = val;
                this.findByPage();
            },

            select(){
                this.pageNo = 1;
                this.findByPage();
            },
            handleRadioChange(){
                this.pageNo = 1;
                this.findByPage();
            },
            findByPage(){
                var self = this;
                self.loading = true;
                var data = {
                    pageNo : self.pageNo -1,
                    pageSize :  self.pageSize,
                    isHandle : parseInt(self.isHandle)
                };

                self.$http.post({
                    url: self.$api.adminApi.findAppVulWarnByPage,
                    params:data,
                    opt:{isLoading:false, showSuccess:false},
                    callback: {
                        success: data => {
                            self.loading = false;
                            self.tableData = data.result.content;
                            self.total = data.result.totalElements;
                        },
                        error: data =>{
                            self.loading = false;
                        },
                        fail: res =>{
                            self.loading = false;
                        }

                    }
                })
            },

            edit(row){
                this.warnForm = row;
                this.isAdd = false;
                if(row.group != null) {
                    this.groupId = row.group.id;
                }
                this.dialogWarnFormVisible = true;
            },
            cancel(){
                this.dialogWarnFormVisible = false;
                this.findByPage();
            },

            closeDialog(){
                this.groupId = null;
                this.$refs['warnForm'].resetFields();
                this.findByPage();
            }
        }

    }
</script>

<style scoped>
    .input-width{
        width: 300px;
    }

    .mar-right{
        margin-right: 35px;
    }

    .long-bt {
        width: 120px;
    }

    .mar-top{
        margin-top: 30px;
    }

    .label-text{
        color: #606266;
        line-height: 40px;
        display: inline-block;
        padding: 0 0 10px;
        font-size: 14px;
    }

    .label-text{
        color: #606266;
        line-height: 40px;
        display: inline-block;
        padding: 0 0 10px;
        font-size: 14px;
    }


</style>
