<template>
    <el-dialog
        :title="title"
        :close-on-click-modal="false"
        :visible="dialogVisible"
        @close="closeDialog"
        width="30%"
        custom-class="small-dialog-min-width">
        <el-form label-width="140px" :model="dataForm" :rules="formRules" ref="dataForm" v-if="dialogVisible">
            <el-form-item label="用户名：">
                {{ dataInfo.username }}
            </el-form-item>
            <el-form-item label="手机号码：" prop="phone">
                <el-input v-model="dataForm.phone" class="dialog-input-width"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="black-button" @click="handleCancle">取 消</el-button>
            <el-button class="black-button" @click="handleSubmit" type="primary" v-if="!isUpdateSim">开启</el-button>
            <el-button class="black-button" @click="handleSubmit" type="primary" v-if="isUpdateSim">确定</el-button>
        </div>
        <div class="sim-loading-mask" v-if="isShowSpin">
            <div class="sim-loading">
                <i class="icon el-icon-loading"></i>
                等待手机验证中
            </div>
        </div>
    </el-dialog>
</template>
<script>

export default {
    name: 'EditSim',
    props: {
        value: Boolean,
        title: {
            type: String,
            default: ''
        },
        dataInfo: {
            type: Object,
            default: null
        },
        isCreate: {
            type: Boolean,
            default: false
        },
        isUpdateSim: false
    },
    data() {
        return {
            buttonSize: BUTTONSIZE,
            inputSize: INPUTSIZE,
            dialogVisible: this.value,
            dataForm: {
                phone: ''
            },
            simShieldRes: {},
            simShieldCheckTimer: null,
            isShowSpin: false
        }
    },
    created() {},
    mounted() {},
    computed: {
        formRules() {
            return {
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { pattern: /^[1][3-9][0-9]{9}$/, message: '手机号码格式有误', trigger: 'blur' }
                ]
            }
        }
    },
    watch: {
        value(val) {
            this.dialogVisible = val
            if (val) {
                this.$nextTick(() => {
                    if (!this.isCreate) {
                        this.dataForm = this.dataInfo
                    }
                })
            }
        }
    },
    methods: {
        closeDialog(e) {
            this.resetForm && this.resetForm()
            this.dataForm && this.dataForm.id && delete this.dataForm.id
            this.dialogVisible = false
            this.$emit('input', false)
            this.$emit('on-close', e)
        },
        handleCancle() {
            this.dialogVisible = false
            this.resetForm()
        },
        handleSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    let url = this.isUpdateSim
                        ? this.$apiUrl.adminApi.updateSimDunPhone
                        : this.$apiUrl.adminApi.openSimDun
                    let params = {
                        id: this.dataInfo.id,
                        phone: this.dataForm.phone
                    }
                    this.$http.post({
                        url: url,
                        params: params,
                        opt: { showSuccess: false, isLoading: true, showError: true },
                        callback: {
                            success: (data) => {
                                this.simShieldRes = data.result
                                this.checkSimShield()
                            }
                        }
                    })
                } else {
                    return false
                }
            })
        },
        checkSimShield() {
            let url = this.isUpdateSim
                ? this.$apiUrl.adminApi.checkUpdateSimPhone
                : this.$apiUrl.adminApi.checkOpenSimDun
            this.$http.post({
                url: url,
                params: {
                    transactionid: this.simShieldRes.transactionid,
                    uuId: this.simShieldRes.uuId
                },
                opt: { showSuccess: false, isLoading: false, showError: false },
                callback: {
                    success: (data) => {
                        this.isShowSpin = false
                        this.$message.success(data.msg || '操作成功')
                        this.dialogVisible = false
                    },
                    error: (data) => {
                        if (data.error == 300) {
                            this.isShowSpin = true
                            this.simShieldCheckTimer && clearTimeout(this.simShieldCheckTimer)
                            this.simShieldCheckTimer = setTimeout(() => {
                                this.checkSimShield()
                            }, 1000)
                        } else {
                            this.$message.error(data.msg || '操作失败')
                            this.isShowSpin = false
                        }
                    },
                    fail: (data) => {
                        this.$message.error(data.msg || '操作失败')
                        this.isShowSpin = false
                    }
                }
            })
        },
        resetForm() {
            this.$refs.dataForm && this.$refs.dataForm.resetFields()
            this.$refs.dataForm && this.$refs.dataForm.clearValidate()
        }
    }
}
</script>
<style lang="scss" scoped>
.sim-loading-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.sim-loading {
    width: 300px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
        font-size: 30px;
        margin-right: 8px;
        color: #1890ff;
    }
}
</style>
