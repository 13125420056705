import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import localModules from "./localModules";
import sessionModules from "./sessionModules";

Vue.use(Vuex)

const createSessionPersisted = createPersistedState({
  storage: window.sessionStorage,
  reducer: (vuexState) =>{
    return vuexState.sessionModules;
  }
})

const createLocalPersisted = createPersistedState({
  storage: window.localStorage,
  reducer: (vuexState) =>{
    return vuexState.localModules;
  }
})

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    sessionModules: sessionModules,
    localModules: localModules
  },
  plugins: [
    createSessionPersisted, createLocalPersisted
  ]
})
