// 数字大小转换
//保留两位小数
function compute(val1, val2){
    return Math.floor((val1 * 100) / val2) / 100;
}
export const numberFilter = val => {
    if(val < 10000){
        return val;
    }else if(val >= 10000 && val < 10000000){
        return compute(val , 10000) + "万";
    }else if(val >= 10000000 && val < 100000000){
        return compute(val , 10000000) + "K万";
    }else if(val >= 100000000 && val < 100000000000){
        return compute(val , 100000000) + "亿";
    }else{
        return compute(val , 100000000000) + "K亿";
    }
    // return new Intl.NumberFormat('zh-CN', { notation: 'compact' }).format(val)
};

// 文件大小转换
export const fileSizeFilter = (val, num = 2) => {
    if (val < 1024) {
        return val + ' B';
    }
    if (val < 1024 * 1024) {
        return (val / 1024).toFixed(num) + ' KB';
    }
    if (val < 1024 * 1024 * 1024) {
        return (val / 1024 / 1024).toFixed(num) + ' MB';
    }
    if (val < 1024 * 1024 * 1024 * 1024) {
        return (val / 1024 / 1024 / 1024).toFixed(num) + ' GB';
    }
    if (val >= 1024 * 1024 * 1024 * 1024)  {
        return (val / 1024 / 1024 / 1024 / 1024).toFixed(num) + ' TB';
    }
    return '-'
};

// 日期
export const formatDateFilter = (date, fmt='yyyy-MM-dd hh:mm:ss') => {
    if(!date) {
        return '--'
    }
    date = new Date(date);
    
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
        }
    }
    return fmt
}
function padLeftZero (str) {
    return ('00' + str).substr(str.length)
}
