<template>
    <el-main style="min-width: 1200px">
        <el-card class="center admin-style-list">
            <div slot="header" class="clearfix">
                <span class="title-text">用户管理</span>
            </div>
            <el-form label-position="top" class="search-form" label-width="100px">
                <el-row :gutter="15">
                    <el-col :span="3">
                        <el-form-item label="用户名">
                            <el-input v-model="username" :size="inputSize" clearable></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="3">
                        <el-form-item label="真实姓名">
                            <el-input v-model="realname" :size="inputSize" clearable></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="4">
                        <el-form-item label="电话号码">
                            <el-input v-model="tel" :size="inputSize" clearable></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="5">
                        <el-form-item label="邮箱">
                            <el-input v-model="email" :size="inputSize" clearable></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="3">
                        <el-form-item label="角色">
                            <el-select v-model="groupId" clearable placeholder="请选择">
                                <el-option
                                    v-for="item in groups"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="3">
                        <el-form-item label="." class="search-button-space">
                            <el-button class="search-button" @click="select" :size="buttonSize" type="primary" plain>
                                <span class="icon iconfont iconsearch"></span>
                                查询
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row class="operation-button">
                <el-col :span="24" class="text-right">
                    <el-button type="primary" @click="handleSetPassword(1)">
                        <span class="icon iconfont iconcog"></span>
                        设置口令
                    </el-button>
                    <el-button type="warning" @click="handleSetPassword(0)">
                        <span class="icon iconfont iconredo"></span>
                        重置口令
                    </el-button>
                    <el-button type="primary" @click="handleSetPassword(2)">
                        <span class="icon iconfont iconedit"></span>
                        修改口令
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                tooltip-effect="light"
                v-loading="loading"
                class="table-top width-ninety"
                :height="tableHeight"
                stripe
                @selection-change="handleSelectionChange"
                :data="tableData">
                <el-table-column
                    fixed="left"
                    type="selection"
                    :selectable="handleSelectable"
                    width="55"></el-table-column>
                <el-table-column prop="username" label="用户名" min-width="80"></el-table-column>
                <el-table-column prop="realName" min-width="120" label="真实姓名"></el-table-column>
                <el-table-column prop="type" min-width="80" label="用户角色">
                    <template slot-scope="scope">
                        {{ scope.row.groupName }}
                    </template>
                </el-table-column>
                <el-table-column prop="tel" min-width="80" label="电话号码"></el-table-column>
                <el-table-column prop="email" min-width="100" show-overflow-tooltip label="邮箱"></el-table-column>
                <el-table-column label="状态" width="80">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isEnabled == 1 ? 'success' : 'danger'">
                            {{ scope.row.isEnabled == 1 ? '启用' : '禁用' }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="上次登录" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.lastLoginTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="SIM盾状态" prop="isOpenSimDun" width="110">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isOpenSimDun === 1 ? 'primary' : 'info'">
                            {{ scope.row.isOpenSimDun ? '开启' : '关闭' }}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column width="360" label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            @click="handleEnableSim(scope.row)"
                            v-if="scope.row.isOpenSimDun === 0">
                            <span class="icon iconfont iconcheck-circle"></span>
                            开启SIM盾
                        </el-button>
                        <el-button
                            type="primary"
                            @click="handleDisabledSim(scope.row)"
                            v-if="scope.row.isOpenSimDun === 1">
                            <span class="icon iconfont icontimes-circle"></span>
                            关闭SIM盾
                        </el-button>
                        <el-button
                            type="primary"
                            @click="handleUpdateSim(scope.row)"
                            :disabled="scope.row.isOpenSimDun === 0">
                            <span class="icon iconfont iconedit"></span>
                            修改SIM盾
                        </el-button>
                        <el-button type="warning" @click="handleResetSim(scope.row)">
                            <span class="icon iconfont iconredo"></span>
                            重置SIM盾
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-row class="paging-row width-ninety">
                <el-col :span="24">
                    <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 15, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        :current-page="pageNo"
                        :total="total"></el-pagination>
                </el-col>
            </el-row>
        </el-card>

        <EditSim
            @on-close="findByPage"
            v-model="simDialogVisible"
            :dataInfo="dataInfo"
            :title="simDialogTitle"
            :isUpdateSim="isUpdateSim"
            :isCreate="isCreate" />

        <div class="sim-loading-mask" v-if="isShowSpin">
            <div class="sim-loading">
                <i class="icon el-icon-loading"></i>
                等待手机验证中
            </div>
        </div>
    </el-main>
</template>

<script>
import { formatDate } from '../../../assets/js/date'
import EditSim from './components/editSim.vue'

export default {
    name: 'Sim',
    components: { EditSim },
    data() {
        return {
            tableHeight: window.innerHeight - 410,
            inputSize: INPUTSIZE, //输入框大小
            buttonSize: BUTTONSIZE, //按钮大小
            formLabelWidth: '120px',
            pageNo: 1, //1页
            pageSize: 10, //10条数据
            tableData: [],
            total: 0,
            username: '',
            code: '',
            realname: '',
            tel: '',
            email: '',
            type: null,
            groupId: '',
            ids: [],
            loading: false,
            groups: [],
            buttonSize: BUTTONSIZE,
            inputSize: INPUTSIZE,
            pageNo: 1,
            pageSize: 15,
            total: 0,
            tableData: [],
            loading: false,
            currentSelect: [],
            singleBtnDisabled: true,
            multipleBtnDisabled: true,
            dialogVisible: false,
            dialogTitle: '',
            dataInfo: {},
            isCreate: false,
            currentSelectIds: [],
            currentSelect: [],
            simDialogVisible: false,
            simDialogTitle: '',
            simShieldRes: {},
            simShieldCheckTimer: null,
            isShowSpin: false,
            simShieldSetRes: {},
            isUpdateSim: false
        }
    },
    created() {
        this.findByPage()
        this.findAllGroups()
    },

    filters: {
        formatDate(time) {
            return formatDate(time, 'yyyy-MM-dd hh:mm:ss')
            //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
        },
        verifyFilter: function (value) {
            if (value == 0) {
                return '管理员'
            } else if (value == 1) {
                return '操作员'
            } else {
                return '审计员'
            }
        }
    },
    methods: {
        findAllGroups() {
            var self = this
            //self.loading = true;
            self.$http.post({
                url: self.$apiUrl.groupApi.findAllGroups,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.groups = data.result
                    }
                }
            })
        },

        handleSelectable(row, index) {
            if (row.flag === 0) return false
            return true
        },
        handleSelectionChange(val) {
            this.currentSelectIds = []
            this.currentSelect = val
            for (let i = 0; i < val.length; i++) {
                this.currentSelectIds.push(val[i].id)
            }
            if (!val || val.length === 0) {
                this.singleBtnDisabled = true
                this.multipleBtnDisabled = true
            } else if (val.length === 1) {
                this.singleBtnDisabled = false
                this.multipleBtnDisabled = false
            } else if (val.length > 1) {
                this.singleBtnDisabled = true
                this.multipleBtnDisabled = false
            }
        },
        handleSizeChange(val) {
            this.pageNo = 1
            this.pageSize = val
            this.findByPage()
        },
        handleCurrentChange(val) {
            this.pageNo = val
            this.findByPage()
        },
        select() {
            this.pageNo = 1
            this.findByPage()
        },
        //得到后台用户
        findByPage() {
            var self = this
            var data = {
                pageNo: self.pageNo - 1,
                pageSize: self.pageSize,
                username: self.username,
                code: self.code,
                realname: self.realname,
                tel: self.tel,
                groupid: self.groupId,
                email: self.email
            }
            self.loading = true
            self.$http.post({
                url: self.$apiUrl.adminApi.findAdminListByPage,
                params: data,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.loading = false
                        self.tableData = data.result.content
                        self.total = data.result.totalElements
                    },
                    error: (data) => {
                        self.loading = false
                    },
                    fail: (res) => {
                        self.loading = false
                    }
                }
            })
        },
        handleEnableSim(row) {
            this.simDialogVisible = true
            this.simDialogTitle = '开启 SIM 盾登录'
            this.isCreate = false
            this.isUpdateSim = false
            this.dataInfo = {
                username: row.username,
                phone: row.tel,
                id: row.id
            }
        },
        handleUpdateSim(row) {
            this.simDialogVisible = true
            this.simDialogTitle = '修改 SIM 盾登录'
            this.isCreate = false
            this.isUpdateSim = true
            this.dataInfo = {
                username: row.username,
                phone: row.tel,
                id: row.id
            }
        },
        handleDisabledSim(row) {
            this.$confirm('确定关闭该用户的 SIM 盾登录功能？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    this.$http.post({
                        url: this.$apiUrl.adminApi.closeSimDun,
                        params: { id: row.id },
                        opt: { showSuccess: false, isLoading: true, showError: true },
                        callback: {
                            success: (data) => {
                                this.simShieldRes = data.result
                                this.checkSimShieldClose()
                            }
                        }
                    })
                })
                .catch(() => {})
        },
        checkSimShieldClose() {
            this.$http.post({
                url: this.$apiUrl.adminApi.checkCloseSimDun,
                params: {
                    transactionid: this.simShieldRes.transactionid,
                    uuId: this.simShieldRes.uuId
                },
                opt: { showSuccess: false, isLoading: false, showError: false },
                callback: {
                    success: (data) => {
                        this.isShowSpin = false
                        this.$message.success(data.msg || '操作成功')
                        this.findByPage()
                    },
                    error: (data) => {
                        if (data.error == 300) {
                            this.isShowSpin = true
                            this.simShieldCheckTimer && clearTimeout(this.simShieldCheckTimer)
                            this.simShieldCheckTimer = setTimeout(() => {
                                this.checkSimShieldClose()
                            }, 1000)
                        } else {
                            this.$message.error(data.msg || '操作失败')
                            this.isShowSpin = false
                        }
                    },
                    fail: (data) => {
                        this.$message.error(data.msg || '操作失败')
                        this.isShowSpin = false
                    }
                }
            })
        },
        handleResetSim(row) {
            this.$confirm('确定重置该用户的 SIM 盾登录功能？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    this.$http.post({
                        url: this.$apiUrl.adminApi.resetSimDun,
                        params: { id: row.id },
                        opt: { showSuccess: false, isLoading: true, showError: true },
                        callback: {
                            success: (data) => {
                                this.simShieldRes = data.result
                                this.checkSimShieldClose()
                            }
                        }
                    })
                })
                .catch(() => {})
        },
        handleSetPassword(type) {
            if (this.currentSelectIds.length == 0) {
                this.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }

            let msg = ''
            let url = ''
            let params = {}
            if (type == 0) {
                msg = '确定重置该用户的 SIM 盾口令？'
                url = this.$apiUrl.adminApi.resetSimSignPwd
                params = {
                    id: this.currentSelectIds[0],
                    type: 0
                }
            } else if (type == 1) {
                msg = '确定设置该用户的 SIM 盾口令？'
                url = this.$apiUrl.adminApi.resetSimSignPwd
                params = {
                    id: this.currentSelectIds[0],
                    type: 1
                }
            } else if (type == 2) {
                msg = '确定修改该用户的 SIM 盾口令？'
                url = this.$apiUrl.adminApi.updateSimSignPwd
                params = {
                    id: this.currentSelectIds[0]
                }
            }
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    this.$http.post({
                        url: url,
                        params: params,
                        opt: { showSuccess: false, isLoading: true, showError: true },
                        callback: {
                            success: (data) => {
                                this.simShieldSetRes = data.result
                                this.checkSimShieldSet()
                            }
                        }
                    })
                })
                .catch(() => {})
        },
        checkSimShieldSet() {
            this.$http.post({
                url: this.$apiUrl.adminApi.checkSimPwd,
                params: {
                    transactionid: this.simShieldSetRes.transactionid,
                    uuId: this.simShieldSetRes.uuId
                },
                opt: { showSuccess: false, isLoading: false, showError: false },
                callback: {
                    success: (data) => {
                        this.isShowSpin = false
                        this.$message.success(data.msg || '操作成功')
                        this.findByPage()
                    },
                    error: (data) => {
                        if (data.error == 300) {
                            this.isShowSpin = true
                            this.simShieldCheckTimer && clearTimeout(this.simShieldCheckTimer)
                            this.simShieldCheckTimer = setTimeout(() => {
                                this.checkSimShieldSet()
                            }, 1000)
                        } else {
                            this.$message.error(data.msg || '操作失败')
                            this.isShowSpin = false
                        }
                    },
                    fail: (data) => {
                        this.$message.error(data.msg || '操作失败')
                        this.isShowSpin = false
                    }
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.sim-loading-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.sim-loading {
    width: 300px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
        font-size: 30px;
        margin-right: 8px;
        color: #1890ff;
    }
}
</style>
<style lang="scss" scoped>
.sim-loading-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.sim-loading {
    width: 300px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
        font-size: 30px;
        margin-right: 8px;
        color: #1890ff;
    }
}
</style>

<style scoped>
.input-width {
    width: 300px;
}
</style>
