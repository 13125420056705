
  /**
   * 手机号码
   * @param {*} s
   */
  export function isTel (s) {
    return /^(\d{3,4}-?)?\d{7,9}$/.test(s)||/^[1][3,4,5,6,7,8][0-9]{9}$/.test(s)
  }

 


 
