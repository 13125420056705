<template>
    <el-main style="min-width: 1200px">
        <el-card class="center admin-style-list">
            <div slot="header" class="clearfix">
                <span class="title-text">委外用户</span>
            </div>
            <el-form
                label-position="top"
                class="search-form"
                label-width="100px">
                <el-row :gutter="15">
                    <el-col :span="5">
                        <el-form-item label="用户名">
                            <el-input
                                v-model="username"
                                :size="inputSize"
                                clearable></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="5">
                        <el-form-item label="真实姓名">
                            <el-input
                                v-model="realname"
                                :size="inputSize"
                                clearable></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="3">
                        <el-form-item label="." class="search-button-space">
                            <el-button
                                class="search-button"
                                @click="select"
                                :size="buttonSize"
                                type="primary"
                                plain>
                                <span class="icon iconfont iconsearch"></span>
                                查询
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row class="operation-button">
                <el-col :span="24" class="text-right">
                    <el-button
                        type="primary"
                        :size="buttonSize"
                        @click="addUser">
                        <span class="icon iconfont iconuserplus-fill"></span>
                        添加
                    </el-button>
                    <!-- <el-button type="primary" :size="buttonSize" @click="edit">
                        <span class="icon iconfont iconedit"></span>
                        编辑
                    </el-button> -->
                    <!-- <el-button
                        v-if="useUkey"
                        type="primary"
                        :size="buttonSize"
                        @click="modifyUkey">
                        <span class="icon iconfont iconedit"></span>
                        更新UKEY
                    </el-button> -->
                    <!-- <el-button
                        type="success"
                        :size="buttonSize"
                        @click="changeToEnabled">
                        <span class="icon iconfont iconcheck-circle"></span>
                        启用
                    </el-button>
                    <el-button
                        type="warning"
                        :size="buttonSize"
                        @click="changeToDisabled">
                        <span class="icon iconfont iconban"></span>
                        禁用
                    </el-button> -->
                    <el-button type="danger" :size="buttonSize" @click="del">
                        <span class="icon iconfont icondelete-fill"></span>
                        删除
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                tooltip-effect="light"
                v-loading="loading"
                class="table-top width-ninety"
                :height="tableHeight"
                stripe
                @selection-change="handleSelectionChange"
                :data="tableData">
                <el-table-column
                    fixed="left"
                    type="selection"
                    :selectable="handleSelectable"
                    width="55"></el-table-column>
                <el-table-column
                    prop="userName"
                    label="用户名"
                    min-width="80"></el-table-column>
                <el-table-column
                    prop="realName"
                    min-width="120"
                    label="真实姓名"></el-table-column>
                <el-table-column
                    prop="orgName"
                    min-width="100"
                    label="用户组织">
                </el-table-column>
                <el-table-column
                    prop="tel"
                    min-width="80"
                    label="电话号码"></el-table-column>
                <el-table-column
                    prop="email"
                    min-width="100"
                    show-overflow-tooltip
                    label="邮箱"></el-table-column>
                <!-- <el-table-column label="状态" width="80">
                    <template slot-scope="scope">
                        <el-tag
                            :type="
                                scope.row.isEnabled == 1 ? 'success' : 'danger'
                            ">
                            {{ scope.row.isEnabled == 1 ? '启用' : '禁用' }}
                        </el-tag>
                    </template>
                </el-table-column> -->
                <el-table-column label="上次登录" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.lastLoginTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    min-width="120"
                    show-overflow-tooltip
                    label="备注"></el-table-column>
            </el-table>
            <el-row class="paging-row width-ninety">
                <el-col :span="24">
                    <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 15, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        :current-page="pageNo"
                        :total="total"></el-pagination>
                </el-col>
            </el-row>
        </el-card>

        <!--用户编辑-->
        <el-dialog
            :title="isAdd ? '添加用户信息' : '编辑用户信息'"
            @close="closeDialog"
            :close-on-click-modal="false"
            :visible.sync="dialogEditUserFormVisible"
            width="40%"
            custom-class="mid-dialog-min-width">
            <el-form :model="userForm" ref="userForm" :rules="rules">
                <el-form-item
                    label="用户名"
                    prop="username"
                    :label-width="formLabelWidth">
                    <el-input
                        v-model="userForm.username"
                        auto-complete="off"
                        class="dialog-input-width"
                        placeholder="3-20位字母、数字和下划线组成"
                        :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item
                    label="密码"
                    prop="password"
                    :rules="[
                        {
                            min: 6,
                            max: 20,
                            message: '用户密码由6-20位字符组成',
                            trigger: 'blur'
                        }
                    ]"
                    :label-width="formLabelWidth">
                    <el-input
                        type="password"
                        v-model="userForm.password"
                        class="dialog-input-width"
                        placeholder="用户密码（6-20位）"
                        auto-complete="off"
                        :size="inputSize"></el-input>
                </el-form-item>

                <el-form-item
                    label="所属单位"
                    prop="orgId"
                    :label-width="formLabelWidth">
                    <el-select
                        @clear="clearOrg"
                        clearable
                        class="input-width"
                        v-model="userForm.orgId"
                        placeholder="请选择"
                        popper-class="tree-select"
                        ref="selectReport">
                        <el-option
                            :value="userForm.orgId"
                            :label="userForm.orgName">
                            <el-tree
                                :data="organization"
                                :props="defaultProps"
                                @node-click="handleNodeClick"></el-tree>
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- <el-form-item
                    label="角色组"
                    prop="groupId"
                    :label-width="formLabelWidth"
                    :rules="{
                        required: true,
                        message: '请选择用户角色组',
                        trigger: ['blur']
                    }">
                    <el-select
                        v-model="userForm.groupId"
                        placeholder="请选择"
                        class="input-width"
                        :size="inputSize">
                        <el-option
                            v-for="item in groups"
                            :label="item.name"
                            :key="item.id"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item
                    label="真实姓名"
                    prop="realName"
                    :label-width="formLabelWidth"
                    :rules="[
                        {
                            required: true,
                            message: '真实姓名不能为空',
                            trigger: 'blur'
                        }
                    ]">
                    <el-input
                        type="realName"
                        v-model="userForm.realName"
                        class="dialog-input-width"
                        placeholder="请填写真实姓名"
                        :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item
                    label="电话号码"
                    prop="tel"
                    :label-width="formLabelWidth">
                    <el-input
                        v-model="userForm.tel"
                        auto-complete="off"
                        class="dialog-input-width"
                        placeholder="请输入电话号码"
                        :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item
                    label="邮箱"
                    prop="email"
                    :label-width="formLabelWidth">
                    <el-input
                        v-model="userForm.email"
                        auto-complete="off"
                        class="dialog-input-width"
                        placeholder="请输入您的邮箱"
                        :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item
                    label="备注"
                    prop="remark"
                    :label-width="formLabelWidth">
                    <el-input
                        type="textarea"
                        :rows="2"
                        v-model="userForm.remark"
                        auto-complete="off"
                        class="dialog-input-width"
                        placeholder="请输入备注"
                        :size="inputSize"></el-input>
                </el-form-item>

                <!-- <el-form-item
                    label="状态"
                    v-if="isAdd"
                    prop="isEnabled"
                    :label-width="formLabelWidth"
                    :rules="{
                        required: true,
                        message: '请选择用户状态',
                        trigger: 'change'
                    }">
                    <el-radio v-model="userForm.isEnabled" :label="1">
                        启用
                    </el-radio>
                    <el-radio v-model="userForm.isEnabled" :label="0">
                        禁用
                    </el-radio>
                </el-form-item> -->
                <el-form-item :label-width="formLabelWidth">
                    *注：默认密码为 123456
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button
                    @click="cancel"
                    class="black-button"
                    :size="buttonSize">
                    取 消
                </el-button>
                <el-button
                    type="primary"
                    @click="saveUser('userForm')"
                    :size="buttonSize">
                    {{ userDialogSaveButton }}
                </el-button>
                <el-button
                    type="success"
                    v-if="isAdd"
                    @click="saveModelAndContinue('userForm')"
                    :size="buttonSize">
                    保存并继续
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="更换用户UKEY"
            @close="closeUserUkeyDialog"
            :close-on-click-modal="false"
            :visible.sync="dialogEditUseUKeyFormVisible"
            width="40%"
            custom-class="mid-dialog-min-width">
            <el-form :model="ukeyUser" ref="ukeyUser">
                <el-form-item
                    label="用户名"
                    prop="username"
                    :label-width="formLabelWidth">
                    {{ ukeyUser.username }}
                </el-form-item>
                <el-form-item
                    label="新UKEY PIN"
                    prop="password"
                    :rules="[
                        {
                            required: isAdd,
                            message: 'PIN码不能为空',
                            trigger: 'blur'
                        },
                        {
                            min: 6,
                            max: 20,
                            message: '用户密码由6-20位字符组成',
                            trigger: 'blur'
                        }
                    ]"
                    :label-width="formLabelWidth">
                    <el-input
                        type="password"
                        v-model="ukeyUser.password"
                        class="dialog-input-width"
                        placeholder="PIN码（6-20位）"
                        auto-complete="off"
                        :size="inputSize"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button
                    @click="ukeyUserCancel"
                    class="black-button"
                    :size="buttonSize">
                    取 消
                </el-button>
                <el-button
                    type="primary"
                    @click="changeUkey('ukeyUser')"
                    :size="buttonSize">
                    更 换
                </el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
import { formatDate } from '../../../assets/js/date'
export default {
    name: 'AdminUsers',
    data() {
        // 验证邮箱的校验规则
        let checkEmail = (rule, value, callback) => {
            // 验证邮箱的正则表达式
            const regEmail =
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
            if (!value) return callback()
            if (regEmail.test(value)) {
                // 合法的邮箱
                return callback()
            }
            // 不合法的邮箱
            callback(new Error('请输入正确的邮箱地址'))
        }
        //判断中文
        let checkRealName = (rule, value, callback) => {
            if (!value || value == null || value == '') {
                return callback(new Error('真实姓名不能为空'))
            }
            var reg = /^[\u4E00-\u9FA5a-zA-Z.·]+$/
            if (!reg.test(value)) {
                return callback(
                    new Error('只允许使用汉字、字母或者"."作为真实姓名')
                )
            }
            if (value.length < 2 || value.length > 30) {
                return callback(new Error('真实姓名长度为2-30个字符'))
            } else {
                return callback()
            }
        }
        // 验证手机号的校验规则
        let checkMobile = (rule, value, callback) => {
            // 验证手机号的正则表达式
            const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/
            if (!value) return callback()
            if (regMobile.test(value)) {
                // 合法的手机号
                return callback()
            }
            // 不合法
            callback(new Error('请输入正确的手机号码'))
        }
        return {
            checkRealName: checkRealName,
            tableHeight: window.innerHeight - 410,
            inputSize: INPUTSIZE, //输入框大小
            buttonSize: BUTTONSIZE, //按钮大小
            formLabelWidth: '120px',
            pageNo: 1, //1页
            pageSize: 10, //10条数据
            tableData: [],
            total: 0,
            username: '',
            code: '',
            realname: '',
            tel: '',
            email: '',
            type: null,
            dialogEditUserFormVisible: false,
            userDialogSaveButton: '保存',
            userForm: {
                orgName: '',
                orgId: ''
            },
            isAdd: false,
            organization: [],
            //currentAdminFlag: null,
            defaultProps: {
                label: 'label',
                children: 'children'
            },
            groupId: '',
            types: [
                {
                    value: 0,
                    name: '管理员'
                },
                {
                    value: 1,
                    name: '操作员'
                },
                {
                    value: 2,
                    name: '审计员'
                }
            ],
            ids: [],
            rules: {
                //验证规则
                username: [
                    {
                        required: true,
                        message: '用户名不能为空',
                        trigger: 'blur'
                    },
                    {
                        min: 3,
                        max: 20,
                        message:
                            '用户名必须是3-20位字符大小写字母数字下划线组成',
                        trigger: 'blur'
                    }
                ],
                // realName:[
                //     {validator:checkRealName,trigger:'blur'}
                //  ],
                // password:[{min:6,max:20,message:"用户密码由6-20位字符组成",trigger:'blur'}],
                tel: [
                    {
                        required: false,
                        message: '电话号码不能为空',
                        trigger: 'blur'
                    },
                    { validator: checkMobile, trigger: 'blur' }
                ],
                email: [
                    {
                        required: false,
                        message: '邮箱地址不能为空',
                        trigger: 'blur'
                    },
                    { validator: checkEmail, trigger: 'blur' }
                ]
            },
            loading: false,
            userForms: [],
            groups: [],
            useUkey: USE_UKEY,
            ukeyUser: {
                id: '',
                username: '',
                modifyTime: '',
                password: ''
            },
            dialogEditUseUKeyFormVisible: false,
            initStatus: 0 //系统初始化请求是否成功
        }
    },
    created() {
        // this.getSessionStr()
        this.findByPage()
        this.findAllOrganization();
        this.findAllGroups()
    },

    filters: {
        formatDate(time) {
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
            //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
        },
        verifyFilter: function (value) {
            if (value == 0) {
                return '管理员'
            } else if (value == 1) {
                return '操作员'
            } else {
                return '审计员'
            }
        }
    },
    methods: {
        getSessionStr() {
            let self = this
            self.$http.post({
                url: self.$api.uKeyActionApi.useUkeyParams,
                opt: { isLoading: false, showSuccess: false, showError: false },
                callback: {
                    success: (data) => {
                        self.useUkey = data.result.useUkey
                        self.initStatus = 1
                    },
                    error: (data) => {
                        self.$message({
                            duration: 0,
                            showClose: true,
                            type: 'error',
                            message:
                                // '系统错误，暂时无法使用，请刷新后再试：' +
                                data.message
                        })
                    },
                    fail: (res) => {
                        self.$message({
                            duration: 0,
                            showClose: true,
                            type: 'error',
                            message:
                                // '系统错误，暂时无法使用，请刷新后再试：' +
                                res.message
                        })
                    }
                }
            })
        },
        doChangeUkey() {
            var self = this
            //self.loading = true;
            self.$http.post({
                url: self.$api.uKeyActionApi.changeUkey,
                params: self.ukeyUser,
                opt: { showError: false },
                callback: {
                    success: (data) => {
                        self.dialogEditUseUKeyFormVisible = false
                    },
                    error: (data) => {
                        if (data.error === 300) {
                            self.$message.success(data.msg)
                            self.dialogEditUseUKeyFormVisible = false
                        } else {
                            self.$message.error(data.msg)
                        }
                    },
                    fail: (res) => {
                        self.$message.error(res.message)
                    }
                }
            })
        },
        changeUkey(formName) {
            var self = this
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.doChangeUkey()
                } else {
                    return false
                }
            })
        },
        closeUserUkeyDialog() {
            this.ukeyUser = {
                id: '',
                username: '',
                modifyTime: '',
                password: ''
            }
            this.$refs['ukeyUser'].resetFields()
        },
        ukeyUserCancel() {
            this.dialogEditUseUKeyFormVisible = false
        },
        modifyUkey() {
            if (this.userForms == null || this.userForms.length != 1) {
                this.$alert('需要且只能选择一条数据进行此操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            this.ukeyUser = {
                id: this.userForms[0].id,
                username: this.userForms[0].username,
                modifyTime: this.userForms[0].modifyTime
            }
            this.dialogEditUseUKeyFormVisible = true
        },
        findAllGroups() {
            var self = this
            //self.loading = true;
            self.$http.post({
                url: self.$api.groupApi.findAllGroups,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.groups = data.result
                    }
                }
            })
        },
        isChinese(temp) {
            var reg = /^[\u4E00-\u9FA5]+$/
            if (!reg.test(keywordscn)) {
                return false
            }
        },
        sendEmail(id) {
            let self = this
            self.$http.post({
                url: self.$api.adminApi.sendActiveCodeById,
                params: { id: id }
            })
        },
        handleNodeClick(data) {
            this.userForm.orgId = data.id
            this.userForm.orgName = data.label
            this.$refs.selectReport.blur()
        },
        findAllOrganization(){
            let self = this;
            self.$http.post({
                url: self.$api.adminApi.findAllOrganization,
                opt:{isLoading:false, showSuccess:false},
                callback: {
                    success: data => {
                        self.organization = data.result;
                    }
        
                }
            })
        },
        handleSelectable(row, index) {
            if (row.flag === 0 && !this.useUkey) return false
            return true
        },
        handleSelectionChange(val) {
            let self = this
            self.ids = []
            for (let i = 0; i < val.length; i++) {
                self.ids.push(val[i].id)
            }
            self.userForms = val
        },
        handleSizeChange(val) {
            this.pageNo = 1
            this.pageSize = val
            this.findByPage()
        },
        handleCurrentChange(val) {
            this.pageNo = val
            this.findByPage()
        },
        select() {
            this.pageNo = 1
            this.findByPage()
        },
        //得到后台用户
        findByPage() {
            var self = this
            var data = {
                pageNo: self.pageNo - 1,
                pageSize: self.pageSize,
                userName: self.username,
                realName: self.realname
            }
            self.loading = true
            self.$http.post({
                url: self.$api.adminApi.findExternalUserByPage,
                params: data,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.loading = false
                        self.tableData = data.result.content
                        self.total = data.result.totalElements
                    },
                    error: (data) => {
                        self.loading = false
                    },
                    fail: (res) => {
                        self.loading = false
                    }
                }
            })
        },
        //启用
        changeToEnabled() {
            let self = this
            if (self.ids == null || self.ids.length == 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            self.$confirm('此操作将启用所选择的数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    self.doChangeToEnabled()
                })
                .catch(() => {})
        },
        //启用
        doChangeToEnabled() {
            let self = this
            if (self.ids == null || self.ids.length == 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            let data = { ids: self.ids }
            self.$http.post({
                url: self.$api.adminApi.updateSystemAdminToEnabled,
                params: data,
                // opt:{isLoading:false, showSuccess:false},
                callback: {
                    success: (data) => {
                        self.findByPage()
                    }
                }
            })
        },
        changeToDisabled() {
            let self = this
            if (self.ids == null || self.ids.length == 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            self.$confirm('此操作将禁用所选择的数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    self.doChangeToDisabled()
                })
                .catch(() => {})
        },
        //禁用
        doChangeToDisabled() {
            let self = this
            if (self.ids == null || self.ids.length == 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            let data = { ids: self.ids }
            self.$http.post({
                url: self.$api.adminApi.updateSystemAdminToDisabled,
                params: data,
                // opt:{isLoading:false, showSuccess:false},
                callback: {
                    success: (data) => {
                        self.findByPage()
                    }
                }
            })
        },
        addUser() {
            this.userForm = {
                orgId: '',
                orgName: ''
            }
            this.isAdd = true
            this.dialogEditUserFormVisible = true
        },
        edit() {
            if (this.userForms == null || this.userForms.length != 1) {
                this.$alert('需要且只能选择一条数据进行此操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            this.userForm = this.userForms[0]
            this.isAdd = false
            this.dialogEditUserFormVisible = true
        },
        saveUser(formName) {
            var self = this
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.doSaveUser(0)
                } else {
                    return false
                }
            })
        },
        saveModelAndContinue(formName) {
            let self = this
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.doSaveUser(1)
                } else {
                    return false
                }
            })
        },
        doSaveUser(val) {
            let self = this
            // if (self.initStatus === 0) {
            //     this.$alert('系统还未就绪，请刷新再试', '信息', {
            //         confirmButtonText: '确定',
            //         showClose: false,
            //         type: 'warning'
            //     })
            //     return
            // }
            self.$http.post({
                url: self.$api.adminApi.saveExternalUser,
                params: self.userForm,
                // opt:{isLoading:false, showSuccess:false},
                callback: {
                    success: (data) => {
                        if (val == 0) {
                            self.findByPage()
                            self.dialogEditUserFormVisible = false
                        } else {
                            self.$refs['userForm'].resetFields()
                            self.userForm = {
                                orgId: '',
                                orgName: ''
                            }
                        }
                    }
                }
            })
        },
        cancel() {
            this.dialogEditUserFormVisible = false
            this.findByPage()
        },
        del() {
            let self = this
            if (self.ids == null || self.ids.length == 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            self.$confirm('此操作将永久删除您选择的数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    self.$http.post({
                        url: self.$api.adminApi.delExternalUserByIds,
                        params: { ids: self.ids },
                        callback: {
                            success: (data) => {
                                self.findByPage()
                            }
                        }
                    })
                })
                .catch(() => {})
        },
        closeDialog() {
            this.groupId = null
            this.$refs['userForm'].resetFields()
            this.findByPage()
        },
        clearOrg() {
            this.userForm.orgId = ''
            this.userForm.orgName = ''
        }
    }
}
</script>

<style scoped>
.input-width {
    width: 300px;
}

.mar-right {
    margin-right: 35px;
}

.long-bt {
    width: 120px;
}

.mar-top {
    margin-top: 30px;
}

.label-text {
    color: #606266;
    line-height: 40px;
    display: inline-block;
    padding: 0 0 10px;
    font-size: 14px;
}

.label-text {
    color: #606266;
    line-height: 40px;
    display: inline-block;
    padding: 0 0 10px;
    font-size: 14px;
}
</style>
