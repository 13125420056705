<template>
  <div>
    <div id="centreLeft1Chart" style="width:100%; height: 2.55rem;"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";

export default {
  props:["pieDatas", "appTotal", "color"],
  data() {
    return {
      chart: null
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.draw();

  },
  methods: {
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreLeft1Chart"));
      //  ----------------------------------------------------------------

      let width = document.body.clientWidth;
      let color = this.color || [
          "#37a2da",
          "#32c5e9",
          "#9fe6b8",
          "#ffdb5c",
          "#ff9f7f",
          "#fb7293",
          "#e7bcf3",
          "#8378ea"
        ]
      this.chart.setOption({
        color: color,
        title: {
          // text: '密码应用总计',
          subtext: '总计: ' + this.appTotal,
          subtextStyle:{
            fontSize: width > 2000 ?24 * 1.5 : 12
          }
          // left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        toolbox: {
          show: false
        },
        calculable: true,
        legend: {
          type: 'scroll',
          orient: 'vertical',
          icon: "circle",
          right: 10,
          top: 20,
          bottom: 20,
          data: this.pieDatas.legendData,
          textStyle: {
            fontSize: width > 2000 ?18 * 1.5 : 12,
            lineHeight: width > 2000 ?18 * 2 : 12,
            color: "#fff"
          }
        },
        // legend: {
        //   orient: "horizontal",
        //   icon: "circle",
        //   bottom: 0,
        //   x: "center",
        //   data: ["中国", "美国", "日本", "德国", "澳大利亚", "法国"],
        //   textStyle: {
        //     color: "#fff"
        //   }
        // },
        series: [
          {
            name: "密码应用",
            // 防止重叠
            avoidLabelOverlap: false,
            // 相当于权重
            zlevel:1,
            label: {
              normal: {
                show: false,
              }
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '28',
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              show: false,
              position: 'center'
            },
            type: "pie",
            radius: ['35%', '60%'],
            // roseType: "area",
            center: ["30%", "50%"],
            data: this.pieDatas.seriesData
          }
        ]
      });
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>