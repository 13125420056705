const state = {
    useName: "localModules"
};
const mutations = {

};
const actions = {
    changeName ({commit, rootState},anotherName) {
        if(rootState.job =="web") {
            commit("change_name", anotherName)
        }
    },
    alertName({state}) {
        alert(state.useName)
    }
};
const getters = {

};

// 不要忘记把state, mutations等暴露出去。
export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}