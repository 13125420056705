<template>
    <el-main style="min-width:1200px">
        <!--        <el-card class="center admin-style-list">-->
        <!--            <div slot="header" class="clearfix">-->
        <!--                <span class="title-text">密码漏洞库</span>-->
        <!--            </div>-->
        <el-tabs v-model="activeName" type="border-card" class="body-tab" @tab-click="handleTabClick" >
            <el-tab-pane label="设备漏洞告警信息" name="first">
                <device-crypto-vd-warn-page v-if="isDevice"></device-crypto-vd-warn-page>
            </el-tab-pane>
            <el-tab-pane label="应用漏洞告警信息" name="second">
                <app-crypto-vd-warn-page v-if="!isDevice"></app-crypto-vd-warn-page>
            </el-tab-pane>
        </el-tabs>
        <!--        </el-card>-->
    </el-main>
</template>

<script>
    import DeviceCryptoVdWarnPage from './components/devicecryptovdwarnpage'
    import AppCryptoVdWarnPage from './components/appcryptovdwarnpage'
    export default {
        name: 'WarningData',
        components: {DeviceCryptoVdWarnPage,AppCryptoVdWarnPage},
        data() {
            return {
                activeName:"first",
                isDevice:true
            }
        },
        methods:{
            handleTabClick(tab, event) {
                if(tab.name == "first"){
                    this.isDevice = true;
                }else{
                    this.isDevice = false;
                }
                //console.log(tab);
            }
        }
    }
</script>