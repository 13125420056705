<template>
    <el-main style="min-width:1200px">
        <el-card class="center admin-style-list">
            <div slot="header" class="clearfix" >
                <span class="title-text">添加组</span>
            </div>

            <el-form :model="group"  :rules="groupRules" ref="groupRuleForm"  label-position="right" label-width="100px" class="group-form">
                <el-form-item label="用户名" prop="username">
                    <el-input class="input-width" v-model="group.username" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="组状态" prop="status">
                    <el-select v-model="group.status" placeholder="请选择" class="input-width">
                        <el-option label="启用" :value="1"></el-option>
                        <el-option label="禁用" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input type="textarea" :rows="7" class="input-width" v-model="group.describe" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="save-bt" @click="save('groupRuleForm')">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </el-main>
</template>

<script>
    export default {
        name: 'AddGroup',
        data(){
            return{
                group: {
                    inputSize:INPUTSIZE,
                    username: '',
                    status: 1,
                    describe: '',
                },
                groupRules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'blur'}
                    ],
                    status: [
                        {required: true, message: '请选择状态', trigger: 'blur'}
                    ],
                }
            }
        },
        created(){
            this.$emit('message', { isMain: false });
        },
        methods: {
            save(formName){
                var self = this;
                this.$refs[formName].validate((valid) =>{
                    if(valid) {
                        var data = {
                            name: self.group.username,
                            status: self.group.status,
                            describe: self.group.describe,
                        };
                        self.$http.post(this.$api.groupApi.addGroup,data)
                            .then(Response =>{
                                if(Response.body.error != 0){
                                    if(Response.body.error != 2){
                                        self.$alert(Response.body.msg, '错误', {
                                            confirmButtonText: '确定',
                                            showClose: false,
                                            type: "error"
                                        });
                                    }
                                }else{
                                    self.$alert(Response.body.msg, '信息', {
                                        confirmButtonText: '确定',
                                        showClose: false,
                                        type: "success",
                                        callback: action => {
                                            self.resetForm('groupRuleForm'); //重置表单
                                        }
                                    });
                                }
                            },Response =>{
                                self.$alert('网络连接失败，请稍候再试！', '错误', {
                                    confirmButtonText: '确定',
                                    showClose: false,
                                    type: "warning"
                                });
                            });
                    }else{
                        return false;
                    }
                })
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.group.describe = '';
                this.group.status = 1;
            },

        }
    }
</script>

<style scoped>
    .input-width{
        width: 260px;
    }

    .group-form{
        margin-left: 150px;
    }

    .save-bt{
        width:120px;
    }

</style>



