<template>
    <el-container class="is-vertical">
        <admin-header class="data-page-view"/>
        <el-container class="main-body">
            <admin-left-side :isCollapse="isCollapse"/>
            <el-container class="is-vertical right-ie-body">
                <div class="menu-controller-button" >
                    <div class="controller-button-icon" @click="showOrHidenMenu">
                        <i v-if="isCollapse" class="controller-text iconfont iconangle-right"></i>
                        <i v-if="!isCollapse" class="controller-text iconfont iconangle-left"></i>
                    </div>
                </div>
                <admin-nav-tabs />
                <router-view class="data-page-view"></router-view>
            </el-container>
        </el-container>
    </el-container>
</template>
<script>
    import AdminHeader from '../components/header.vue'
    import ElContainer from "element-ui/packages/container/src/main.vue"
    import AdminLeftSide from '../components/leftside.vue'
    import ElMain from "element-ui/packages/main/src/main.vue"
    import AdminFooter from '../components/footer.vue'
    import AdminNavTabs from '../components/navtabs.vue'
    export default {
        components: {
            ElMain,
            ElContainer,
            AdminHeader,
            AdminLeftSide,
            AdminFooter,
            AdminNavTabs
        },
      data(){
          return{
              isCollapse:false
          }
      },
      methods:{
        showOrHidenMenu(){
            if(this.isCollapse){
                this.isCollapse = false;
                // this.buttonLeft = "left:200px";
            }else{
                this.isCollapse = true;
                // this.buttonLeft = "left:64px";
            }
            var myEvent = new Event('resize');
            window.dispatchEvent(myEvent);
        }
      }

    }
</script>
<style>
    .is-vertical{
        position: relative;
    }
    .menu-controller-button{
        position: absolute;
        top:45%;
        z-index: 999;
        left: 0;
    }
    .controller-button-icon{
        width: 10px;
        height: 60px;
        background: #023d79;
        border-radius: 0 10px 10px 0;
        cursor: pointer;
        line-height: 60px;
        color: #6cb5ff;
    }
    .controller-button-icon:hover{
        background: #024d9a;
        color: #82bbf5;
    }
    .controller-text{
        font-size: 12px !important;
    }
    .data-page-view.el-main{
        border-left:1px solid #023d79;
    }
</style>
