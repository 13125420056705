global.TOKENNAME = "CSA_ADMIN_TOKEN";
global.UKEY_TOKENNAME = "ADMIN_UKEY_TOKEN";
global.POSTAUTHNAME = "x-authorization";
global.INPUTSIZE = "mini";
global.BUTTONSIZE = "mini";
global.ROLEAUTHURLS = [];
global.MENUICONS = ["el-icon-tickets","el-icon-message","el-icon-tickets","el-icon-menu","el-icon-menu","el-icon-location","el-icon-document","el-icon-bell","el-icon-bell","el-icon-menu","el-icon-menu","el-icon-menu"];
global.ISSHOWERROR = false;
//global.APPID = "wyd9269v81123";
global.LEFTMENU = [];
global.ISUSEROLES = false;
//是否是用UKEY
global.USE_UKEY = false;
global.IS_OEM = false;