<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item text-center" v-for="item in titleItem" :key="item.title">
        <div class="ml-3 colorBlue fw-b label-text-font" >{{item.title}}</div>
        <div>
          <dv-digital-flop :config="item.number" style="width:100%;height:.625rem;" />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="down-body">
        <div class="down-body-title">
          <span style="color:#5cd9e8">
            <icon name="align-left"></icon>
          </span>
          <span class="fs-xl text mx-2 mb-1 label-text-font">最近1小时实时流量（MB）</span>
        </div>


      <center-flow-char ref="dataCenterDisplay" :flowData="flowData"></center-flow-char>
      </div>
    </div>
  </div>
</template>

<script>
// import CenterFlowChar from "@/components/echart/center/centerFlowChar";
import CenterFlowChar from "../../components/echart/center/centerFlowChar";

export default {
  data () {
    return {
      titleItem: [],
      flowData:{},
      timer:null,
      timer2:null,
      cryptoData: null,
      staticsData:null
    };
  },
  components: {
    CenterFlowChar
    // centerChart1,
    // centerChart2
  },
  destroyed() {
    if(this.timer){
      clearInterval(this.timer);
    }
    if(this.timer2){
      clearInterval(this.timer2);
    }
  },
  mounted() {
    this.findCurrentFlowData();
    this.findStaticsAllData();
  },
  methods:{
    findStaticsAllData(){
      let self = this;
      self.doStaticsData();
      self.changeStaticsTiming();
    },
    doStaticsData(){
      let self = this;
      let param = {
        type: VIEW_TYPE
      }
      self.$http.post({
        url: self.$api.bigScreenApi.findStaticsAllData,
        opt: {showSuccess: false, isLoading: false},
        params: VIEW_TYPE,
        callback: {
          success: data => {
            self.staticsData = data.result;
            self.makeStaticsData();
          }
        }
      })
    },
    makeStaticsData(){
      let self = this;
      let datas = self.staticsData;
      self.titleItem = []
      for(var i = 0; i < datas.length; i++){
        self.titleItem.push(
                {
                  title: datas[i].name,
                  number: {
                    number: [datas[i].value],
                    toFixed: 0,
                    content: "{nt}"
                  }
                }
        );
      }
    },
    findCurrentFlowData(){
      let self = this;
      self.doFindData();
      self.changeTiming();
    },
    doFindData(){
      let self = this;
      let param = {
        type: VIEW_TYPE
      }
      self.$http.post({
        url: self.$api.bigScreenApi.findCurrentFlowData,
        opt: {showSuccess: false, isLoading: false, showError: false},
        params: VIEW_TYPE,
        callback: {
          success: data => {
            self.cryptoData = data.result;
            self.makeData();
          }
        }
      })
    },
    makeData(){
      let self = this;
      let datas = self.cryptoData;
      self.flowData = []
      let date = []
      let data = []
      for(var i = 0; i < datas.length; i++){
        date.push(
                datas[i].date
        );
        data.push(datas[i].data);
      }
      self.flowData =  {
        date: date,
        data: data
      }
      self.$refs.dataCenterDisplay.draw();
    },
    changeTiming() {
      let self = this;
      self.timer = setInterval(() => {
        self.doFindData();
      }, 3000);
    },
    changeStaticsTiming() {
      let self = this;
      self.timer2 = setInterval(() => {
        self.doStaticsData();
      }, 5000);
    },
  }

};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 33%;
      height: 0.875rem;
    }
  }
  .down {

    width: 100%;
    display: flex;
    height: 3.1875rem;
    justify-content: space-between;
    .down-body{
      width: 100%;
      .down-body-title{
        box-sizing: border-box;
        padding: 0.07rem .125rem 0;
      }
    }
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 59%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
  .bg-color-black {
    background-color: rgba(19, 25, 47, 0.6);
  }

  .bg-color-blue {
    background-color: #1a5cd7;
  }

  .colorBlack {
    color: #272727 !important;

    &:hover {
      color: #272727 !important;
    }
  }

  .colorGrass {
    color: #33cea0;

    &:hover {
      color: #33cea0 !important;
    }
  }

  .colorRed {
    color: #ff5722;

    &:hover {
      color: #ff5722 !important;
    }
  }

  .colorText {
    color: #d3d6dd !important;

    &:hover {
      color: #d3d6dd !important;
    }
  }

  .colorBlue {
    color: #257dff !important;

    &:hover {
      color: #257dff !important;
    }
  }
</style>