<template>
  <div id="center">
<!--    <div class="up">-->
<!--      <div class="bg-color-black item text-center" v-for="item in titleItem" :key="item.title">-->
<!--        <div class="ml-3 colorBlue fw-b">{{item.title}}</div>-->
<!--        <div>-->
<!--          <dv-digital-flop :config="item.number" style="width:100%;height:.625rem;" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="down">-->
<!--      <div class="ranking bg-color-black">-->
<!--        <span style="color:#5cd9e8">-->
<!--          <icon name="align-left"></icon>-->
<!--        </span>-->
<!--        <span class="fs-xl text mx-2 mb-1">年度设备加密流量榜</span>-->
<!--        <dv-scroll-ranking-board :config="ranking" style="height:2.75rem" />-->
<!--      </div>-->
<!--      <div class="percent">-->
<!--        <div class="item bg-color-black">-->
<!--          <span>加密流量比率</span>-->
<!--          <CenterChart :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />-->
<!--        </div>-->
<!--        <div class="item bg-color-black">-->
<!--          <span>密码使用率</span>-->
<!--          <CenterChart :id="rate[1].id" :tips="rate[1].tips" :colorObj="rate[1].colorData" />-->
<!--        </div>-->
<!--        <div class="water">-->
<!--          <dv-water-level-pond :config="water" style="height: 1.5rem" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <dv-flyline-chart-enhanced :config="config" style="width:100%;height:100%;" />-->
      <div style="position: relative">
          <ChinaMap></ChinaMap>
          <dv-flyline-chart-enhanced :config="config" :dev="true" style="width:100%;height:12rem;position: absolute;top: 0;left: 0" />
      </div>

  </div>
</template>

<script>
//import CenterChart from "@/components/echart/center/centerChartRate";
import ChinaMap from "@/components/mapcharts/chinamap";

export default {
  data () {
    return {
        config:{
            points: [
                {
                    name: '上海',
                    coordinate: [0.76, 0.63],//0.67, 0.62
                    icon: {
                        src: '/img/flylineChart/mapCenterPoint.png',
                        width: 30,
                        height: 30
                    }
                },
                {
                    name: '江苏',
                    coordinate: [0.73, 0.58]
                },
                {
                    name: '浙江',
                    coordinate: [0.74, 0.68]
                },
                {
                    name: '安徽',
                    coordinate: [0.70, 0.63]
                },
                {
                    name: '山东',
                    coordinate: [0.71, 0.49]
                },
                {
                    name: '河南',
                    coordinate: [0.64, 0.56]
                },
                {
                    name: '湖北',
                    coordinate: [0.64, 0.64]
                },
                {
                    name: '江西',
                    coordinate: [0.67, 0.72]
                },
                {
                    name: '福建',
                    coordinate: [0.71, 0.77]
                },
                {
                    name: '天津',
                    coordinate: [0.70, 0.42]
                },
                {
                    name: '四川',
                    coordinate: [0.50, 0.64]
                },
                {
                    name: '河北',
                    coordinate: [0.66, 0.44]
                },
                {
                    name: '北京',
                    coordinate: [0.68, 0.39]
                },
                {
                    name: '山西',
                    coordinate: [0.61, 0.47]
                },
                {
                    name: '陕西',
                    coordinate: [0.57, 0.54]
                },
                {
                    name: '重庆',
                    coordinate: [0.55, 0.67]
                },
                {
                    name: '湖南',
                    coordinate: [0.61, 0.71]
                },
                {
                    name: '广东',
                    coordinate: [0.64, 0.82]
                },
                {
                    name: '甘肃',
                    coordinate: [0.49, 0.50]
                },
                {
                    name: '青海',
                    coordinate: [0.39, 0.51]
                },
                {
                    name: '贵州',
                    coordinate: [0.54, 0.75]
                },
                {
                    name: '云南',
                    coordinate: [0.46, 0.80]
                },
                {
                    name: '广西',
                    coordinate: [0.56, 0.85]
                },
                {
                    name: '海南',
                    coordinate: [0.58, 0.95]
                },
                {
                    name: '宁夏',
                    coordinate: [0.53, 0.46]
                },
                {
                    name: '内蒙古',
                    coordinate: [0.61, 0.34]
                },
                {
                    name: '辽宁',
                    coordinate: [0.79, 0.34]
                },
                {
                    name: '吉林',
                    coordinate: [0.82, 0.28]
                },
                {
                    name: '黑龙江',
                    coordinate: [0.87, 0.20]
                },
                {
                    name: '西藏',
                    coordinate: [0.27, 0.64]
                },
                {
                    name: '新疆',
                    coordinate: [0.22, 0.37]
                },
                {
                    name: '台湾',
                    coordinate: [0.76, 0.82]
                },
                {
                    name: '澳门',
                    coordinate: [0.62, 0.89]
                },
                {
                    name: '香港',
                    coordinate: [0.66, 0.87]
                }


            ],
            lines: [
                {
                    source: '江苏',
                    target: '上海'
                },
                {
                    source: '浙江',
                    target: '上海'
                },
                {
                    source: '山东',
                    target: '上海'
                },
                {
                    source: '河北',
                    target: '上海'
                },
                {
                    source: '江西',
                    target: '上海'
                },
                {
                    source: '湖北',
                    target: '上海'
                },
                {
                    source: '北京',
                    target: '上海'
                },
                {
                    source: '天津',
                    target: '上海'
                },
                {
                    source: '河南',
                    target: '上海'
                },
                {
                    source: '安徽',
                    target: '上海'
                },
                {
                    source: '重庆',
                    target: '上海'
                },
                {
                    source: '广东',
                    target: '上海'
                },
                {
                    source: '福建',
                    target: '上海'
                },
                {
                    source: '陕西',
                    target: '上海'
                },
                {
                    source: '山西',
                    target: '上海'
                },
                {
                    source: '广东',
                    target: '上海'
                },
                {
                    source: '四川',
                    target: '上海'
                },
                {
                    source: '甘肃',
                    target: '上海'
                },
                {
                    source: '青海',
                    target: '上海'
                },
                {
                    source: '湖南',
                    target: '上海'
                },
                {
                    source: '贵州',
                    target: '上海'
                },
                {
                    source: '云南',
                    target: '上海'
                },
                {
                    source: '广西',
                    target: '上海'
                },
                {
                    source: '海南',
                    target: '上海'
                },
                {
                    source: '宁夏',
                    target: '上海'
                },
                {
                    source: '内蒙古',
                    target: '上海'
                },
                {
                    source: '辽宁',
                    target: '上海'
                },
                {
                    source: '吉林',
                    target: '上海'
                },
                {
                    source: '黑龙江',
                    target: '上海'
                },
                {
                    source: '西藏',
                    target: '上海'
                },
                {
                    source: '新疆',
                    target: '上海'
                },
                {
                    source: '台湾',
                    target: '上海'
                },
                {
                    source: '澳门',
                    target: '上海'
                },
                {
                    source: '香港',
                    target: '上海'
                }
            ]
        },
      titleItem: [
        {
          title: "今年累计加密连接千次数",
          number: {
            number: [1200],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "本月累计加密连接千次数",
          number: {
            number: [180],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今日累计加密连接千次数",
          number: {
            number: [20],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今年密码设备使用次数",
          number: {
            number: [1000],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今年密码设备告警次数",
          number: {
            number: [56],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今年漏洞通知处理次数",
          number: {
            number: [30],
            toFixed: 0,
            content: "{nt}"
          }
        }
      ],
      ranking: {
        data: [
          {
            name: "VPN网关",
            value: 788
          },
          {
            name: "签名服务器",
            value: 620
          },
          {
            name: "证书验证服务器",
            value: 980
          },
          {
            name: "纽创密码机",
            value: 660
          },
          {
            name: "数据服务器",
            value: 520
          },
          {
            name: "网站服务器",
            value: 380
          },
          {
            name: "密钥管理服务器",
            value: 1080
          },
        ],
        carousel: "single",
        unit: "Gb"
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ]
    };
  },
  components: {
    // CenterChart,
    ChinaMap
    // centerChart1,
    // centerChart2
  }
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      height: 0.875rem;
    }
  }
}
</style>