<template>
    <div ref="cflow" class="flow-line"></div>
</template>

<script>
    import * as echarts from 'echarts';
    import allData from '../../../assets/json/bigview.json'
    export default {
        name: "centerFlowChar",
        props:["height", "lineColor"],
        data(){
            return{
                width: document.body.clientWidth,
                chart: null
            }
        },
        mounted() {

            this.requestTlsData();
        },
        methods:{
            requestTlsData(){
                let self = this;
                self.$http.post({
                    url: self.$api.sdBigScreenApi.findCurrentFlowData,
                    opt: {showSuccess: false, isLoading: false},
                    params: 1,
                    callback: {
                        success: data => {
                            // self.cryptoData = data.result;
                            // self.$refs.dataPieDisplay.draw();
                            self.draw(data.result)
                            setTimeout(()=>{
                                self.requestTlsData()
                            }, 5000)
                        }
                    }
                })
            },
            draw(data){
                let x = [];
                let y = [];
                for(let o of data){
                    x.push(o.date)
                    y.push(o.data)
                }
               let option = {
                   tooltip: {
                       trigger: 'axis',
                       position: function (pt) {
                           return [pt[0], '10%'];
                       }
                   },
                   title: {
                       show:false
                   },
                   toolbox: false,
                   grid:{
                       top:"20px",
                       bottom:"30px",
                       left:"100px",
                       right:"20px"
                   },
                   xAxis: {
                       type: 'category',
                       boundaryGap: false,
                       data: x,
                       axisLine:{
                           lineStyle:{
                               color:"#888888"
                           }
                       },
                       axisLabel:{
                           fontSize: this.width > 2000 ?12 * 1.5 : 12,
                       },
                       splitLine:false,
                       min:0
                   },
                   yAxis: {
                       type: 'value',
                       min: 0,
                       axisLine:{
                           show:true,
                           lineStyle:{
                               color:"#888888"
                           }
                       },
                       axisLabel:{
                           fontSize: this.width > 2000 ?12 * 1.5 : 12,
                       },
                       splitLine: {
                           lineStyle:{
                               color: "#666666"
                           }
                       }
                   },
                    series: [
                        {
                            name: 'TLS流量统计',
                            type: 'line',
                            symbol: 'none',
                            smooth: true,
                            itemStyle: {
                                color: this.lineColor || '#37a2da'
                            },
                            areaStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color:  this.lineColor || '#37a2da'
                                }, {
                                    offset: 1,
                                    color:  this.lineColor || '#37a2da'
                                }])
                            },
                            data: y
                        }
                    ]
                };
                this.chart = this.$echarts.init(this.$refs.cflow);
                this.chart.setOption(option);
               // setTimeout(()=>{
               //     console.log(this.$refs.cflow)
               //
               // },1000)

            }
        }
    }
</script>

<style scoped>
    .flow-line{
        height: 100%;
        padding: 1rem .5rem .25rem .5rem;
    }
</style>