<template>
  <div>
    <div id="bottomLeftChart" style="width:11.25rem;height:5.3rem;"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
export default {
  data() {
    return {
      chart: null,
      cryptoBarData:null,
      timer:null,
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.findData();
    this.changeTiming();
  },
  destroyed() {
    if(this.timer){
      clearInterval(this.timer);
    }
  },
  methods: {
    changeTiming() {
      let self = this;
      self.timer = setInterval(() => {
        self.findData();
      }, 60*60*24*1000);
    },
    findData(){
      let self = this;
      self.$http.post({
        url: self.$api.bigScreenApi.findCryptoBarData,
        opt: {showError: false, showSuccess: false, isLoading: false},
        params: VIEW_TYPE,
        callback: {
          success: data => {
            self.cryptoBarData = data.result;
            self.makeData();
          }
        }
      })
    },
    makeData() {
      let width = document.body.clientWidth;
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("bottomLeftChart"));
      //  ----------------------------------------------------------------
      let category = [];
      let lineData = [];
      let barData = [];
      for(var i = 0; i < this.cryptoBarData.length; i++){
        category.push(this.cryptoBarData[i].date);
        lineData.push(this.cryptoBarData[i].lineData);
        barData.push(this.cryptoBarData[i].barData);
      }
      let rateData = [];
      for (let i = 0; i < 33; i++) {
        let rate = barData[i] / lineData[i];
        rateData[i] = rate.toFixed(2);
      }

      let option = {
        title: {
          text: "",
          x: "center",
          y: 0,
          textStyle: {
            color: "#B4B4B4",
            fontSize:  width > 2000 ?24 * 1.5 : 16,
            fontWeight: "normal"
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC"
            }
          }
        },
        legend: {
          data: ["加密连接", "连接总量", "加密率"],
          textStyle: {
            color: "#B4B4B4",
            fontSize: width > 2000 ? 24 * 1.5 : 18,
          },
          top: "0%"
        },
        grid: {
          x: "8%",
          width: "88%",
          y: "4%"
        },
        xAxis: {
          data: category,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisLabel: {
            fontSize:  width > 2000 ?20 * 1.5 : 16
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },

            axisLabel: {
              fontSize:  width > 2000 ?20 * 1.5 : 16,
              formatter: "{value} "
            }
          },
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisLabel: {
              fontSize:  width > 2000 ?20 * 1.5 : 14,
              formatter: "{value} "
            }
          }
        ],
        series: [
          {
            name: "加密率",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#F02FC2"
              }
            },
            data: rateData
          },

          {
            name: "加密连接",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#956FD4" },
                  { offset: 1, color: "#3EACE5" }
                ])
              }
            },
            data: barData
          },

          {
            name: "连接总量",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(156,107,211,0.8)" },
                  { offset: 0.2, color: "rgba(156,107,211,0.5)" },
                  { offset: 1, color: "rgba(156,107,211,0.2)" }
                ])
              }
            },
            z: -12,

            data: lineData
          }
        ]
      };
      this.chart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>