<template>
    <div class="big-view-main">
        <dv-full-screen-container class="bg">
            <dv-loading v-if="loading">Loading...</dv-loading>
            <div v-else class="host-body" ref="hostBody">
                <div class="d-flex jc-center" ref="topTitle">
                    <dv-decoration-10 :color="['#406bbb', '#345281']" style="width: 33.3%; height: 0.0625rem" />
                    <div class="d-flex jc-center">
                        <dv-decoration-8 :color="['#345281', '#000000']" style="width: 2.5rem; height: 0.625rem" />
                        <div class="title">
                            <span class="title-text">{{ env.VUE_APP_TITLE }}</span>
                            <dv-decoration-6
                                class="title-bototm"
                                :reverse="true"
                                :color="['#56bce0', '#406bbb']"
                                style="width: 100%; height: 0.1rem" />
                        </div>
                        <dv-decoration-8
                            :reverse="true"
                            :color="['#345281', '#000000']"
                            style="width: 2.5rem; height: 0.625rem" />
                    </div>
                    <dv-decoration-10
                        :color="['#406bbb', '#345281']"
                        style="width: 33.3%; height: 0.0625rem; transform: rotateY(180deg)" />
                </div>

                <!-- 第二行 -->
                <div class="d-flex jc-between px-2" ref="subTitle">
                    <div class="d-flex" style="width: 40%">
                        <div
                            class="react-right ml-4"
                            style="width: 6.25rem; text-align: left; background-color: #0f1325">
                            <span class="react-before"></span>
                            <span class="text big-title-text-span">{{env.VUE_APP_VIEW_COMPANY}}</span>
                        </div>
                        <div class="react-right ml-3" style="background-color: #0f1325">
                            <span class="text" style="color: #46a3e7">CRYPTO SITUATION</span>
                        </div>
                    </div>
                    <div style="width: 40%" class="d-flex">
                        <div class="react-left mr-3" style="background-color: #0f1325">
                            <span class="text" style="color: #46a3e7">PANORAMA VIEW</span>
                        </div>
                        <div
                            class="react-left mr-4"
                            style="width: 6.25rem; background-color: #0f1325; text-align: right">
                            <span class="react-after"></span>
                            <span class="text big-title-text-span">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
                        </div>
                    </div>
                </div>

                <!--下半部分-->
                <div class="d-flex jc-between px-2 bottom-body" :style="mainBodyStyle">
                    <!--左-->
                    <div class="bottom-left">
                        <div class="col-3-lg">
                            <dv-border-box-13 :color="['#a4eaff', '#7ce8fc']">
                                <div class="box-title pt-2 pl-2">
                                    <span style="color: #3a7aac">
                                        <icon name="chart-bar"></icon>
                                    </span>
                                    <div class="box-title-text">
                                        <span class="fs-xl text label-text-font">密码应用资产统计</span>
                                        <dv-decoration-3
                                            style="
                                                width: 1.25rem;
                                                height: 0.25rem;
                                                position: relative;
                                                top: -0.0375rem;
                                                opacity: 0.6;
                                            " />

                                        <div class="bottom-data">
                                            <CentreLeft1Chart
                                                :color="chartColor"
                                                ref="dataPieDisplay"
                                                :pieDatas="pieDatas"
                                                :appTotal="appTotal" />
                                        </div>
                                    </div>
                                </div>
                            </dv-border-box-13>
                        </div>
                        <div class="col-3-lg margin-top-min">
                            <dv-border-box-13 :color="['#a4eaff', '#7ce8fc']">
                                <div class="box-title pt-2 pl-2">
                                    <span style="color: #3a7aac">
                                        <icon name="chart-bar"></icon>
                                    </span>
                                    <div class="box-title-text">
                                        <span class="fs-xl text label-text-font">密码合规告警信息列表</span>
                                        <dv-decoration-3
                                            style="
                                                width: 1.25rem;
                                                height: 0.25rem;
                                                position: relative;
                                                top: -0.0375rem;
                                                opacity: 0.6;
                                            " />
                                    </div>
                                </div>
                                <dv-scroll-board
                                    ref="heGuiWaringList"
                                    :config="heGuiWarningConfig.config"
                                    style="width: 95%; height: 75%; margin: 0 auto" />
                            </dv-border-box-13>
                        </div>
                        <div class="col-3-lg margin-top-min">
                            <dv-border-box-13 :color="['#a4eaff', '#7ce8fc']">
                                <div class="box-title pt-2 pl-2">
                                    <span style="color: #3a7aac">
                                        <icon name="chart-bar"></icon>
                                    </span>
                                    <div class="box-title-text">
                                        <span class="fs-xl text label-text-font">密码合规告警统计</span>
                                        <dv-decoration-3
                                            style="
                                                width: 1.25rem;
                                                height: 0.25rem;
                                                position: relative;
                                                top: -0.0375rem;
                                                opacity: 0.6;
                                            " />
                                    </div>
                                </div>
                                <ZsChart :data="heGuiBarData" :refreshTime="heGuiRefreshTime" />
                            </dv-border-box-13>
                        </div>
                    </div>
                    <!--中-->
                    <div class="bottom-center">
                        <div class="map-body" ref="mapBody">
<!--                             <china />-->
                            <shandong />
                            <dv-flyline-chart :config="shandongConfig" dev class="map-top-line" />
<!--                            <dv-flyline-chart :config="config" dev class="map-top-line" />-->
                        </div>

                        <div class="map-bottom" :style="mapBottomStyle">
                            <dv-border-box-11
                                title="密码流量态势"
                                :style="mapBottomStyle"
                                v-if="showBottom"
                                :color="['#a4eaff', '#0c2539']">
                                <CryptoFlow lineColor="#3a79d3" />
                            </dv-border-box-11>
                        </div>
                    </div>
                    <!--右-->
                    <div class="bottom-right">
                        <div class="col-3-lg">
                            <dv-border-box-12 :color="['#557a8b', '#5bb7e2']">
                                <div class="box-title pt-2 pl-2">
                                    <span style="color: #3a7aac">
                                        <icon name="chart-bar"></icon>
                                    </span>
                                    <div class="box-title-text">
                                        <span class="fs-xl text label-text-font">探针即时流量</span>
                                        <dv-decoration-3
                                            style="
                                                width: 1.25rem;
                                                height: 0.25rem;
                                                position: relative;
                                                top: -0.0375rem;
                                                opacity: 0.6;
                                            " />
                                    </div>
                                </div>
                                <dv-scroll-ranking-board
                                    :config="agentNetflowData"
                                    style="width: 90%; margin: 0 auto; height: 2.25rem" />
                            </dv-border-box-12>
                        </div>
                        <div class="col-3-lg margin-top-min">
                            <dv-border-box-12 :color="['#557a8b', '#5bb7e2']">
                                <div class="box-title pt-2 pl-2">
                                    <span style="color: #3a7aac">
                                        <icon name="chart-bar"></icon>
                                    </span>
                                    <div class="box-title-text">
                                        <span class="fs-xl text label-text-font">密码安全告警信息列表</span>
                                        <dv-decoration-3
                                            style="
                                                width: 1.25rem;
                                                height: 0.25rem;
                                                position: relative;
                                                top: -0.0375rem;
                                                opacity: 0.6;
                                            " />
                                    </div>
                                </div>
                                <dv-scroll-board
                                    ref="secWaringList"
                                    :config="secWarningConfig.config"
                                    style="width: 95%; height: 75%; margin: 0 auto" />
                            </dv-border-box-12>
                        </div>
                        <div class="col-3-lg margin-top-min">
                            <dv-border-box-12 :color="['#557a8b', '#5bb7e2']">
                                <div class="box-title pt-2 pl-2">
                                    <span style="color: #3a7aac">
                                        <icon name="chart-bar"></icon>
                                    </span>
                                    <div class="box-title-text">
                                        <span class="fs-xl text label-text-font">密码安全告警统计</span>
                                        <dv-decoration-3
                                            style="
                                                width: 1.25rem;
                                                height: 0.25rem;
                                                position: relative;
                                                top: -0.0375rem;
                                                opacity: 0.6;
                                            " />
                                    </div>
                                </div>
                                <ZsChart :data="secBarData" :refreshTime="secRefreshTime" />
                            </dv-border-box-12>
                        </div>
                    </div>
                </div>
            </div>
        </dv-full-screen-container>
    </div>
</template>

<script>
import bigViewData from '../../assets/json/bigview'
import { formatTime } from '../../utils'
import china from './components/china'
import shandong from './components/shandong'
import allData from '../../assets/json/bigview.json'
import BottomRightChart from '@/components/echart/bottom/sdBottomRightChart'
import BottomLeftChart from '@/components/echart/bottom/sdBottomLeftChart'
import ZsChart from '@/components/echart/bottom/unicomZsChart'
import HgChart from '@/components/echart/bottom/hgChart'
import CryptoFlow from '@/components/echart/center/cryptoFlow'
import { agentSearchField } from '../../assets/js/constants'
import CentreLeft1Chart from '@/components/echart/centerLeft/centreLeft1Chart'

export default {
    name: 'index',
    components: {
        china,
        shandong,
        BottomRightChart,
        BottomLeftChart,
        ZsChart,
        HgChart,
        CryptoFlow,
        CentreLeft1Chart
    },
    data() {
        return {
            env: process.env,
            loading: true,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            config: bigViewData.mapConfig,
            shandongConfig: bigViewData.shandongMapConfig,
            numberData: {},
            heGuiWarningConfig: bigViewData.heGuiWarningListConfig,
            secWarningConfig: bigViewData.secWarningListConfig,
            heGuiBarData: {},
            secBarData: {},
            heGuiRefreshTime: new Date(),
            secRefreshTime: new Date(),
            pieDatas: {},
            appTotal: 0,
            agentNetflowData: {},
            mainBodyStyle: '',
            mapBottomStyle: '',
            showBottom: false,
            chartColor: [
                '#73d2d3',
                '#69b6ec',
                '#5496c8',
                '#4275a1',
                '#2f547b',
                '#a85862',
                '#4d77c6',
                '#56a4dd',
                '#bead71'
            ]
        }
    },
    watch: {},
    mounted() {
        this.timeFn()
        this.cancelLoading()
        // this.makeWarningData()

        // 真实数据
        this.requestCryptoAppTotal()
        this.requestSecList()
        this.requestHeGuiList()
        this.requestHeGuiBar()
        this.requestSecBar()
        this.requestAllAgentData()
    },
    destroyed() {},
    methods: {
        setMainBodyStyle() {
            this.$nextTick(() => {
                let topHeight = this.$refs.topTitle.offsetHeight + this.$refs.subTitle.offsetHeight
                let hostBodyHeight = this.$refs.hostBody.offsetHeight
                let mainHeight = hostBodyHeight - topHeight
                this.mainBodyStyle = 'height:' + mainHeight + 'px'
                let mapHeight = this.$refs.mapBody.offsetHeight
                let mapBottomHeight = mainHeight - mapHeight
                this.mapBottomStyle = 'height:' + mapBottomHeight + 'px'
                this.showBottom = true
            })

            // this.mainBodyStyle =
        },
        requestAllAgentData() {
            let self = this
            self.$http.post({
                url: self.$api.sdBigScreenApi.findAllAgentData,
                opt: { showSuccess: false, isLoading: false },
                params: 1,
                callback: {
                    success: (data) => {
                        // self.cryptoData = data.result;
                        // self.$refs.dataPieDisplay.draw();
                        self.makeAgentBar(data.result)
                        setTimeout(() => {
                            self.requestAllAgentData()
                        }, 15000)
                    }
                }
            })
        },
        toHumanUnit(val) {
            if (val < 1024) {
                return val + 'B'
            }
            if (val >= 1024 && val < 1024 * 1024) {
                return Math.round(val / 1024) + 'KB'
            }
            if (val >= 1024 * 1024 && val < 1024 * 1024 * 1024) {
                return Math.round(val / 1024 / 1024) + 'MB'
            }
            if (val >= 1024 * 1024 * 1024 && val < 1024 * 1024 * 1024 * 1024) {
                return Math.round(val / 1024 / 1024 / 1024) + 'GB'
            }
            if (val >= 1024 * 1024 * 1024 * 1024) {
                return Math.round(val / 1024 / 1024 / 1024 / 1024) + 'TB'
            }
            return '-'
        },
        makeAgentBar(data) {
            let temp = []
            let self = this
            for (let o of data) {
                temp.push({
                    name: o.name,
                    value: o.io
                })
            }
            this.agentNetflowData = {
                data: temp,
                rowNum: 5,
                waitTime: 2000,
                valueFormatter({ value }) {
                    return self.toHumanUnit(value)
                }
            }
        },
        requestHeGuiBar() {
            let self = this
            self.$http.post({
                url: self.$api.sdBigScreenApi.findHeGuiAgentStatistics,
                opt: { showSuccess: false, isLoading: false },
                params: VIEW_TYPE,
                callback: {
                    success: (data) => {
                        // self.cryptoData = data.result;
                        // self.$refs.dataPieDisplay.draw();
                        self.makeWarningBar(data.result, 1)
                        setTimeout(() => {
                            self.requestHeGuiBar()
                        }, 10000)
                    }
                }
            })
        },
        requestSecBar() {
            let self = this
            self.$http.post({
                url: self.$api.sdBigScreenApi.findSecAgentStatistics,
                opt: { showSuccess: false, isLoading: false },
                params: VIEW_TYPE,
                callback: {
                    success: (data) => {
                        // self.cryptoData = data.result;
                        // self.$refs.dataPieDisplay.draw();
                        self.makeWarningBar(data.result, 2)
                        setTimeout(() => {
                            self.requestSecBar()
                        }, 10000)
                    }
                }
            })
        },

        makeWarningBar(datas, type) {
            let self = this
            // let datas = allData.warningListData;
            let x = []
            let y = []
            for (var i = 0; i < datas.length; i++) {
                let exist = false
                for (let a of agentSearchField.engines) {
                    if (a.value === datas[i].type) {
                        x.push(a.name)
                        exist = true
                        break
                    }
                }
                if (!exist) {
                    x.push('未知')
                }
                y.push(datas[i].value)
            }
            if (type === 1) {
                //合规
                self.heGuiBarData = { x: x, y: y }
                self.heGuiRefreshTime = new Date()
            } else {
                //安全
                self.secBarData = { x: x, y: y }
                self.secRefreshTime = new Date()
            }

            // setTimeout(()=>{
            //     self.$refs.waringList.calcData();
            //
            // },1000)
        },
        requestHeGuiList() {
            let self = this
            let param = {
                top: 30
            }
            self.$http.post({
                url: self.$api.sdBigScreenApi.findHeGuiWarn,
                opt: { showSuccess: false, isLoading: false },
                params: param,
                callback: {
                    success: (data) => {
                        // self.cryptoData = data.result;
                        // self.$refs.dataPieDisplay.draw();
                        self.makeWarningList(data.result, 1)
                        setTimeout(() => {
                            self.requestHeGuiList()
                        }, 30000)
                    }
                }
            })
        },
        requestSecList() {
            let self = this
            let param = {
                top: 30
            }
            self.$http.post({
                url: self.$api.sdBigScreenApi.findSecWarn,
                opt: { showSuccess: false, isLoading: false },
                params: param,
                callback: {
                    success: (data) => {
                        // self.cryptoData = data.result;
                        // self.$refs.dataPieDisplay.draw();
                        self.makeWarningList(data.result, 2)
                        setTimeout(() => {
                            self.requestSecList()
                        }, 30000)
                    }
                }
            })
        },

        makeWarningList(datas, type) {
            let self = this
            // let datas = allData.warningListData;
            let data = []
            for (var i = 0; i < datas.length; i++) {
                let col = []
                // let exist = false;
                // for(let a of agentSearchField.engines){
                //     if(a.value === datas[i].type){
                //         col.push(a.name)
                //         exist = true
                //         break;
                //     }
                // }
                // if(!exist){
                //     col.push("未知")
                // }
                col.push(formatTime(datas[i].timestamp, 'MM/dd HH:mm:ss'))
                col.push(datas[i].name)
                col.push(datas[i].protocol)
                col.push(datas[i].log)
                data.push(col)
            }
            if (type === 1) {
                //合规
                // bigViewData.heGuiWarningListConfig.config.data = data;
                let config = bigViewData.heGuiWarningListConfig.config
                config.data = data
                self.heGuiWarningConfig.config = config
                self.$refs.heGuiWaringList.calcData()
            } else {
                //安全
                let config = bigViewData.secWarningListConfig.config
                config.data = data
                self.secWarningConfig.config = config
                self.$refs.secWaringList.calcData()
            }

            // setTimeout(()=>{
            //     self.$refs.waringList.calcData();
            //
            // },1000)
        },

        requestCryptoAppTotal() {
            let self = this
            let param = {
                type: 1
            }
            self.$http.post({
                url: self.$api.sdBigScreenApi.findCryptoNumber,
                opt: { showSuccess: false, isLoading: false },
                params: VIEW_TYPE,
                callback: {
                    success: (data) => {
                        self.cryptoData = data.result
                        // self.$refs.dataPieDisplay.draw();
                        self.makeData()
                        self.makeWarningList(data.result, 2)
                        setTimeout(() => {
                            self.requestCryptoAppTotal()
                        }, 30000)
                    }
                }
            })
        },
        makeData() {
            let self = this
            let data = self.cryptoData
            self.numberData = []
            self.appTotal = 0
            let legendData = []
            let seriesData = []
            for (var i = 0; i < data.length; i++) {
                self.numberData.push({
                    number: {
                        number: [data[i].number],
                        toFixed: 0,
                        content: '{nt}',
                        style: { fontSize: 18 }
                    },
                    text: data[i].name + ': ' + data[i].number
                })
                self.appTotal += data[i].number
                legendData.push(data[i].name + ': ' + data[i].number)
                seriesData.push({
                    value: data[i].number,
                    name: data[i].name + ': ' + data[i].number
                })
            }
            self.pieDatas = {
                legendData: legendData,
                seriesData: seriesData
            }
            self.$refs.dataPieDisplay.draw()
            self.isLoadingLeft = false
        },

        // makeWarningData() {
        //     let self = this;
        //     let datas = allData.warningListData;
        //     let data = []
        //     for (var i = 0; i < datas.length; i++) {
        //         let col = []
        //         col.push(datas[i].name)
        //         col.push(datas[i].warning)
        //         data.push(col);
        //     }
        //     self.warningConfig.data = data;

        //     setTimeout(()=>{
        //         self.$refs.waringList.calcData();

        //     },1000)

        // },
        timeFn() {
            setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
                this.setMainBodyStyle()
            }, 500)
        }
    }
}
import '../../assets/scss/index.scss'
</script>

<style lang="scss" scoped>
@import '../../assets/scss/style.scss';
.big-view-main .host-body .title .title-text {
    font-size: 0.425rem;
    width: 100%;
}
.big-title-text-span {
    font-size: 0.325rem;
}
.label-text-font {
    font-size: 0.205rem !important;
    color: #68b0c1;
}
.bottom-data {
    .item-box {
        float: right;
        position: relative;
        width: 33%;
        color: #d3d6dd;
        padding: 0.2rem 0;
        // 金币
        .coin {
            position: absolute;
            left: 0.1rem;
            top: 0.2125rem;
            font-size: 0.25rem;
            color: #ffc107;
        }
        .colorYellow {
            color: yellowgreen;
        }
    }
}
</style>
<style lang="scss">
.big-view-main {
    @media screen and (min-width: 2000px) {
        .dv-scroll-ranking-board .ranking-info .rank {
            width: 80px;
        }
    }
    .dv-border-box-13,
    .dv-border-box-12 {
        height: 95%;
    }
    .dv-border-box-13 .dv-border-svg-container {
        height: 110%;
    }
    .dv-border-box-12 .dv-border-svg-container {
        height: 110%;
    }
    .dv-scroll-ranking-board .ranking-column .inside-column {
        background-color: #42799e;
        /*background-color: #182c9e;*/
    }
    .dv-scroll-ranking-board .ranking-column {
        // border-bottom: 2px solid rgba(13, 94, 169, 0.5);
        border-bottom-color: #162851;

        /*border-bottom: 2px solid rgba(24, 44, 251581, 0.5);*/
    }
    text.dv-border-box-11-title {
        fill: #68b0c1;
    }
    .dv-scroll-board {
        color: rgba(255, 255, 255, 0.75);
    }
    .dv-scroll-board .header .header-item {
        color: #46a3e7;
    }
    .dv-scroll-ranking-board .ranking-info .rank {
        color: #46a3e7;
    }
    .ranking-info {
        color: rgba(255, 255, 255, 0.75);
    }
    .ranking-value {
        color: #b7e4fc;
    }
}
.host-body {
    /*height: 15.42rem;*/
    height: 100%;
}
.dv-decoration-6 svg {
    transform-origin: center top !important;
}
.bottom-body {
    /*margin-top: 50px;*/
    height: 12.5rem;
    .box-title {
        padding: 0.2rem 0.2rem;
        display: block;
    }
    .title-text {
        display: block;
    }
    .col-3-lg {
        height: 30%;
    }
    .margin-top-min {
        /*margin-top: .25rem;*/
    }
    .bottom-left {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .bottom-center {
        width: 50%;
        height: 100%;
        .map-body {
            height: 8.25rem;
            position: relative;
            .map-top-line {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
            }
        }
        .map-bottom {
            /*background: #ff3a3a;*/
            /*height: 4.3rem;*/
            padding: 0 0.4rem;
            /*.netflow-title{*/
            /*    display: block;*/
            /*    text-align: center;*/
            /*}*/
        }
    }

    .bottom-right {
        width: 25%;
        /*background: #ffffff;*/
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}
</style>
