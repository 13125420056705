import Conf from '../conf';
const pathSymbols = {
    agent0:
        `path://M148 395.652c0 100.755 39.2 195.913 112 263.083 16.8 16.792 16.8 44.78 0 61.572-16.8 16.793-44.8 16.793-61.6 0C108.8 630.747 64 518.797 64 395.652c0-123.145 44.8-235.095 128.8-319.058 16.8-16.792 44.8-16.792 61.6 0 16.8 16.793 16.8 44.78 0 61.573-67.2 67.17-106.4 156.73-106.4 257.485z m235.2 195.913c-16.8 22.39-44.8 16.792-56 0-50.4-55.975-78.4-117.548-78.4-190.315 0-72.768 22.4-139.938 78.4-184.718 16.8-16.793 39.2-16.793 56 0 16.8 16.792 16.8 44.78 5.6 55.975-39.2 33.585-56 78.365-56 128.743 0 50.377 16.8 95.157 50.4 128.742 16.8 22.39 16.8 44.78 0 61.573z m442.4-514.97C915.2 160.556 960 272.506 960 395.651c0 117.548-50.4 235.095-134.4 324.655-16.8 16.793-44.8 16.793-61.6 0-16.8-16.792-16.8-44.78 0-61.572 67.2-72.768 106.4-162.328 106.4-263.083 0-95.157-39.2-190.315-106.4-257.485-16.8-16.793-16.8-44.78 0-61.573 16.8-16.792 44.8-16.792 61.6 0zM702.4 216.531c50.4 50.378 78.4 111.95 84 190.315 0 72.768-28 134.34-78.4 190.315-16.8 11.195-44.8 16.793-61.6 0-16.8-16.792-16.8-44.78 0-61.572 33.6-33.585 50.4-78.365 50.4-128.743-5.6-50.377-22.4-95.157-56-128.742-16.8-16.793-16.8-44.78 0-61.573s44.8-16.793 61.6 0zM540 569.175c16.8 0 28 11.195 28 27.987l112 335.85C680 949.805 668.8 961 652 961h-5.6l-112-100.755c-11.2-16.793-33.6-16.793-44.8 0L377.6 961H372c-11.2 0-28-11.195-28-27.988l112-335.85c0-16.792 16.8-27.987 28-27.987h56z m-106.4-156.73c0-43.28 35.1-78.365 78.4-78.365 43.3 0 78.4 35.085 78.4 78.365 0 43.28-35.1 78.365-78.4 78.365-43.3 0-78.4-35.086-78.4-78.365z`,
    agent1:
        "path://M507.4 521.7m-77.6 0a77.6 77.6 0 1 0 155.2 0 77.6 77.6 0 1 0-155.2 0Z M232.8 812.6c-77-72.9-125.3-176.5-125.3-290.9 0-112.3 46.3-213.7 120.8-286.4l-55.9-59.8C81.8 263.1 25.5 385.8 25.5 521.7c0 138 58 262.5 151 350.3l56.3-59.4zM835.3 168.6L776.7 226c80.3 73.1 130.6 178.5 130.6 295.7 0 117.2-50.9 223.5-131.2 296.7l59.1 56.5c94.8-88 154.1-213.7 154.1-353.2s-59.3-265.1-154-353.1z M273.5 522c0-64.4 26-122.7 68.2-165l-56.3-60.2c-58.2 57.3-94.3 137.1-94.3 225.2 0 90.3 37.8 171.7 98.5 229.3l56.3-59.9c-44.6-42.5-72.4-102.8-72.4-169.4zM823.6 522c0-94.3-41.3-179-106.8-236.9l-58.5 57.4c51 42.9 83 107.7 83 179.6 0 71.9-36.3 135.4-81 178.1l56.4 58.9c65.5-58 106.9-142.7 106.9-237.1z M201.5 204.4m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z M314 326.4m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z M686.4 312.3m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z M206.5 844m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z M317.8 721.3m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z M690.4 727.9m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z M804.4 847.9m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z M806 197.3m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z",
};
export default {
    name: 'pictorialBar',
    mixins: [Conf],
    props: {
        info: Array,
        data: Array,
        type: String,
        name: String,
        yAxisName: {
            type: String,
            default: '连接次数/次'
        },
    },
    data() {
        return {
            symbol: pathSymbols.agent0,
            timer: null
        }
    },
    mounted() {
        let optionIndex = 0;
        this.timer = setInterval( () => {
            optionIndex = (optionIndex + 1) % 2;
            this.symbol = pathSymbols[`agent${optionIndex}`]
            this.option = this.optionHandler();
            this.chartIns.setOption(this.option);
        }, 2500);
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        toHumanUnit(val){
            if(val < 1024){
                return val + "B";
            }
            if(val >= 1024 && val < 1024 * 1024){
                return Math.round(val / 1024) + "KB";
            }
            if(val >= 1024 * 1024 && val < 1024 * 1024 * 1024){
                return Math.round(val / 1024 / 1024) + "MB";
            }
            if(val >= 1024 * 1024 * 1024 && val < 1024 * 1024 * 1024 * 1024){
                return Math.round(val / 1024 / 1024 / 1024) + "GB";
            }
            if(val >= 1024 * 1024 * 1024 * 1024){
                return Math.round(val / 1024 / 1024 / 1024 / 1024) + "TB";
            }
            return "-";
        },
        seriesHandler() {
            let self = this;
            return {
                name: '',
                type: 'pictorialBar',
                label: {
                    show: true,
                    position: 'right',
                    offset: [10, 0],
                    fontSize: this.data.length > 5 ? 12: 16,
                    formatter: function (params) {
                        return self.toHumanUnit(params.value);
                    },
                },
                symbolRepeat: true,
                symbolSize: this.data.length > 5 ? ['70%', '50%'] : ['50%', '35%'],
                barCategoryGap: '40%',
                symbol: this.symbol,
                universalTransition: {
                    enabled: true,
                    delay: function (idx, total) {
                      return (idx / total) * 1000;
                    }
                },
                data: this.data
            };
        },
        gridHandler() {
            let res = {
                containLabel: true,
                left: 20,
                top: 20,
                height: 290,
            };

            return res;
        },
        yAxisHandler() {
            return {
                data: this.yAxis,
                inverse: true,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    margin: 30,
                    fontSize: 12
                },
                axisPointer: {
                    label: {
                        show: true,
                        margin: 30
                    }
                }
            }
        },
        // animationDurationUpdateHandler() {
        //     return 500
        // },
        xAxisHandler() {
            return {
                splitLine: { show: false },
                axisLabel: { show: false },
                axisTick: { show: false },
                axisLine: { show: false }
            }
        },
        tooltipHandler() {
            return {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            };
        },
        // dataZoomHandler() {
        //     return [
        //         {
        //             type: 'inside'
        //         },
        //         {
        //             type: 'slider',
        //             textStyle: {
        //                 color: '#999999'
        //             }
        //         }
        //     ]
        // }

    }
};