<template>
    <div id="chartId" :style="{ width: width, height: height }" class="chart">
    </div>
</template>

<script>
    import china from '../../../assets/json/china'
    export default {
        name: "MyChart",
        props: {
            // chartId: {
            //     type: String,
            //     required: true
            // },
            width: {
                type: String,
                default: "100%"
            },
            height: {
                type: String,
                default: "100%"
            },
            // chartOptions: {
            //     type: Object,
            //     required: true
            // },
            confimDisabled: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initChart("chartId", china);
            })
        },
        methods: {
            initChart(id, data) {
                let _that = this;
                let myChart = document.getElementById(id)
                this.chartLine = this.$echarts.init(myChart);
                this.chartLine.setOption(data)
                window.addEventListener('resize', function () {
                    _that.chartLine.resize()
                })
                if (this.confimDisabled) {
                    let that = this
                    let chart = this.$echarts.init(document.getElementById(id))
                    chart.on('legendselectchanged', function (obj) {
                        that.$emit("exportClick", obj);
                    })
                }
            },
        },
        watch: {
            data: {
                handler(newValue, old) {
                    this.initData("chartId", newValue, old)
                },
                deep: true
            }
        }
    };
</script>
<style scoped>
    .chart {
        display: inline-block;
    }
</style>
