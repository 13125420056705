import Conf from '../conf';
export default {
    name: 'deviceGauge',
    mixins: [Conf],
    props: {
        name: {
            type: [String],
        },
        max: {
            type: [Number],
        },
        isPercentage: {
            type: [Boolean],
            default: true
        }
    },
    methods: {
        seriesHandler() {
            
            let seriesLeft = {
                name: 'left',
                type: 'gauge',
                startAngle: -30,
                endAngle: -315,
                radius: '75%',
                center: ['21%', '55%'],
                min: 0,
                max: 10,
                precision: 0,
                progress: {
                    show: true,
                    overLap: true,
                    width: 10,
                    roundCap: false,
                    clip: true,
                },
                detail: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    roundCap: false,
                    lineStyle: {
                        width: 6,
                        color: [
                            [1, '#03a9f4']
                        ],
                    }
                },
                axisTick: {
                    show: true,
                    splitNumber: 5,
                    length: 6,
                    distance: 10,
                    lineStyle: {
                        color: '#fff',
                        width: 1,
                    }
                },
                splitLine: {
                    distance: -10,
                    length: 10,
                    lineStyle: {
                        color: '#fff',
                        width: 2
                    }
                },
                axisLabel: {
                    color: 'auto',
                    distance: 8,
                    fontSize: 12,
                    formatter: (val) => {
                        return Math.round(+val)
                    }
                },
                pointer: {
                    width: 4,
                    length: '40%',
                    itemStyle: {
                        color: 'auto'
                    }
                },
                anchor: {
                    show: true
                },
                title: {
                    show: false,
                    offsetCenter: [0, '30%'],
                    color: '#464646',
                    fontSize: 12
                },
                data: this.data
            }
            let series = {
                name: 'middle',
                type: 'gauge',
                radius: '100%',
                // min: this.isPercentage ? this.max : 0,
                // max: this.isPercentage ? 0 : this.max,
                min: 0,
                max: 10,
                center: ['50%', '50%'],
                // splitNumber: this.max > 9 ? 10 : ( this.max || 1),
                precision: 0,
                progress: {
                    show: true,
                    overLap: true,
                    width: 10,
                    roundCap: false,
                    clip: true,
                },
                detail: {
                    show: false,
                    valueAnimation: true,
                    // formatter: '{value}%',
                    formatter: (value) => {
                        if(this.isPercentage) {
                            return value+'%'
                        } else {
                            return value
                        }
                    },
                    offsetCenter:[0, '60%'],
                    fontSize: 20,
                    lineHeight: 30,
                    
                },
                axisLine: {
                    show: true,
                    roundCap: false,
                    lineStyle: {
                        width: 10,
                        color: [
                            
                            [1, '#6CB5FF']
                        ],
                    }
                },
                axisTick: {
                    show: true,
                    splitNumber: 5,
                    length: 6,
                    distance: 10,
                    lineStyle: {
                        color: '#fff',
                        with: 1,
                    }
                },
                splitLine: {
                    distance: -10,
                    length: 10,
                    lineStyle: {
                        color: '#fff',
                        width: 3
                    }
                },
                axisLabel: {
                    color: 'auto',
                    distance: 8,
                    fontSize: 12,
                    formatter: (val) => {
                        return Math.round(+val)
                    }
                },
                pointer: {
                    width: 6,
                    itemStyle: {
                        color: 'auto'
                    }
                },
                anchor: {
                    show: true
                },
                title: {
                    show: false,
                    offsetCenter: [0, '30%'],
                    color: '#464646',
                    fontSize: 12
                },
                data: this.data
            }
            let seriesRight = {
                name: 'right',
                type: 'gauge',
                startAngle: 135,
                endAngle: -150,
                splitNumber: 8,
                radius: '75%',
                center: ['79%', '55%'],
                min: 0,
                max: 10,
                // splitNumber: this.max > 9 ? 10 : ( this.max || 1),
                precision: 0,
                progress: {
                    show: true,
                    overLap: true,
                    width: 10,
                    roundCap: false,
                    clip: true,
                },
                detail: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    roundCap: false,
                    lineStyle: {
                        width: 6,
                        color: [
                            [1, '#03a9f4']
                           
                        ],
                    }
                },
                axisTick: {
                    show: true,
                    splitNumber: 5,
                    length: 6,
                    distance: 10,
                    lineStyle: {
                        color: '#fff',
                        with: 1,
                    }
                },
                splitLine: {
                    distance: -10,
                    length: 10,
                    lineStyle: {
                        color: '#fff',
                        width: 2
                    }
                },
                axisLabel: {
                    color: 'auto',
                    distance: 8,
                    fontSize: 12,
                    formatter: (val) => {
                        return Math.round(+val)
                    }
                },
                pointer: {
                    width: 4,
                    length: '40%',
                    itemStyle: {
                        color: 'auto'
                    }
                },
                anchor: {
                    show: true
                },
                title: {
                    show: false,
                    offsetCenter: [0, '30%'],
                    color: '#464646',
                    fontSize: 12
                },
                data: this.data
            }
    
            return [seriesLeft, series, seriesRight]
        },
        // tooltipHandler() {
        //     return {
        //         show: true,
        //         // formatter: '{a} <br/>{b} : {c}'
        //         formatter: (data) => {
        //             if (this.isPercentage) {
        //                 // return data.name + '<br/>' + data.seriesName + ' : ' + data.value + '%'
        //                 return data.seriesName + '<br/>' + data.name + ' : ' + data.value + '%'
        //             } else {
        //                 return data.seriesName + '<br/>' + data.name + ' : ' + data.value
        //             }
        //         }
        //     };
        // },
        
    }
};
