<template>
    <el-main class="data-page-view device-list-dialog">
        <el-form label-position="top" class="search-form" label-width="100px">
            <el-row :gutter="35">
                <el-col :span="6">
                    <el-form-item label="设备名称">
                        <el-input
                            v-model="searchForm.deviceName"
                            :size="inputSize"
                            clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="." class="search-button-space">
                        <el-button
                            class="search-button"
                            @click="handleSearch"
                            :size="buttonSize"
                            type="primary"
                            plain>
                            <span class="icon iconfont iconsearch"></span>
                            查询
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-table
            tooltip-effect="light"
            height="300px"
            v-loading="loading"
            class="table-top width-ninety"
            @selection-change="handleSelectionChange"
            stripe
            :data="tableData">
            <el-table-column
                :model="tableData"
                fixed="left"
                type="selection"
                width="55"></el-table-column>
            <el-table-column
                prop="deviceName"
                show-overflow-tooltip
                min-width="130"
                label="设备名称"></el-table-column>
            <el-table-column
                prop="createTime"
                min-width="140"
                show-overflow-tooltip
                label="注册时间">
                <template slot-scope="scope">
                    {{ scope.row.createTime | formatDate }}
                </template>
            </el-table-column>

            <el-table-column
                prop="deviceTypeName"
                width="100"
                show-overflow-tooltip
                label="设备类型"></el-table-column>
            <el-table-column
                prop="deviceSerialNumber"
                width="100"
                show-overflow-tooltip
                label="序列号"></el-table-column>
            <el-table-column
                prop="deviceIp"
                width="100"
                show-overflow-tooltip
                label="IP"></el-table-column>
<!--            <el-table-column-->
<!--                prop="deviceMac"-->
<!--                width="120"-->
<!--                show-overflow-tooltip-->
<!--                label="MAC"></el-table-column>-->
            <el-table-column
                prop="deviceModelNumber"
                width="120"
                show-overflow-tooltip
                label="型号"></el-table-column>

            <el-table-column
                prop="organization"
                width="100"
                show-overflow-tooltip
                label="所属单位">
                <template slot-scope="scope">
                    {{
                        scope.row.organization
                            ? scope.row.organization.name
                            : '-'
                    }}
                </template>
            </el-table-column>
            <!-- <el-table-column
                prop="region"
                width="80"
                show-overflow-tooltip
                label="区域">
                <template slot-scope="scope">
                    {{
                        scope.row.regionFullName
                            ? scope.row.regionFullName
                            : '-'
                    }}
                </template>
            </el-table-column> -->
            <!-- <el-table-column
                show-overflow-tooltip
                prop="address"
                min-width="240"
                label="位置"></el-table-column> -->
        </el-table>
        <el-row class="paging-row width-ninety">
            <el-col :span="12" style="text-align: left">
                <el-button @click="handleDevice" type="primary">
                    批量添加设备
                </el-button>
            </el-col>
            <el-col :span="12" class="">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 15, 20, 30, 40, 50]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-size="pageSize"
                    :current-page="pageNo"
                    :total="total"></el-pagination>
            </el-col>
        </el-row>
    </el-main>
</template>

<script>
import { formatDate } from '../../../../assets/js/date'

export default {
    name: 'DeviceDialog',
    props: {
        id: {
            type: String,
            default: null
        },
        appInfo: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            buttonSize: BUTTONSIZE,
            inputSize: INPUTSIZE,
            tableHeight: window.innerHeight - 480,
            pageNo: 1,
            pageSize: 10,
            tableData: [],
            total: 0,
            formLabelWidth: '150px',
            ids: [],
            loading: false,
            searchForm: {
                deviceName: ''
            }
        }
    },
    created() {
        this.findByPage()
    },
    filters: {
        formatDate(time) {
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
            //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
        },
        formatDateToDate(time) {
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd')
        }
    },
    methods: {
        handleSelectionChange(val) {
            let self = this
            self.ids = []
            for (let i = 0; i < val.length; i++) {
                self.ids.push(val[i].id)
            }
        },

        handleDevice() {
            if (this.ids == null || this.ids.length == 0) {
                this.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            let params = {
                id: this.id,
                ids: this.ids
            }
            // if (
            //     this.appInfo.applicationCryptoDeviceIds &&
            //     this.appInfo.applicationCryptoDeviceIds.length > 0
            // ) {
            //     params = {
            //         ...this.appInfo,
            //         applicationCryptoDeviceIds: [
            //             ...new Set([
            //                 ...this.appInfo.applicationCryptoDeviceIds,
            //                 ...this.ids
            //             ])
            //         ]
            //     }
            // } else {
            //     params = {
            //         ...this.appInfo,
            //         applicationCryptoDeviceIds: this.ids
            //     }
            // }

            this.$http.post({
                url: this.$api.applicationApi.addDeviceToApplications,
                params: params,
                opt: { isLoading: false, showSuccess: true },
                callback: {
                    success: (data) => {
                        this.$emit('closeDialog')
                    }
                }
            })
        },
        handleSearch() {
            this.pageNo = 1
            this.findByPage()
        },
        handleSizeChange(val) {
            this.pageNo = 1
            this.pageSize = val
            this.findByPage()
        },
        handleCurrentChange(val) {
            this.pageNo = val
            this.findByPage()
        },

        findByPage() {
            let self = this
            self.searchForm.pageNo = self.pageNo - 1
            self.searchForm.pageSize = self.pageSize
            self.loading = true
            self.$http.post({
                url: self.$api.deviceApi.queryDeviceByExample,
                params: { ...self.searchForm, belongLocal: 1 },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.loading = false
                        self.tableData = data.result.content
                        self.total = data.result.totalElements
                    },
                    error: (data) => {
                        self.loading = false
                    },
                    fail: (res) => {
                        self.loading = false
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.table-top {
    margin-top: 30px;
}

.search-bt {
    width: 120px;
    margin-top: 51px;
}
.data-page-view.device-list-dialog {
    border-left: none;
}
</style>
