const china = {
    tooltip: {
        padding: 0,
        enterable: true,
        transitionDuration: 0,
        textStyle: {
            color: '#000',
            decoration: 'none',
        },
    },
    geo: {
        map: "china",
        show: true,
        roam: false,
        zoom: 1.3, //当前视角的缩放比例
        aspectScale: 0.86,
        layoutCenter: ["50%", "50%"], //地图位置
        layoutSize: '100%',
        itemStyle: {
            normal: {
                shadowColor: 'rgba(80,225,247,0.6)',
                shadowOffsetX: 1,
                shadowOffsetY: 5,
                opacity: 1,
                shadowBlur: 10
            },
            emphasis: {
                areaColor: 'rgba(0,243,255,1)',
            }
        },
        regions: [{
            name: '南海诸岛',
            itemStyle: {
                areaColor: 'rgb(17,37,76)',

                borderColor: 'rgb(17,37,76)',
                normal: {
                    opacity: 0,
                    label: {
                        show: false,
                        color: "#009cc9",
                    }
                },
            },
            label: {
                show: false,
                color: '#FFFFFF',
                fontSize: 12,
            },
        }],
    },

    series: [
        {
            name: '散点',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            // 显示的点
            // data: this.convertData(data),
            // 气泡大小
            symbolSize: 9,
            symbol: 'circle',
            label: {
                //   显示位置
                normal: {
                    show: false
                },
                emphasis: {
                    show: false
                }
            },
            showEffectOn: 'render',
            itemStyle: {
                normal: {
                    color: {
                        type: 'radial',
                        x: 0.5,
                        y: 0.5,
                        r: 0.5,
                        colorStops: [{
                            offset: 0,
                            color: 'rgba(12,46,80,0.1)'
                        },
                            {

                                offset: 0.8,
                                color: 'rgba(14,245,209,0.2)'
                            }, {
                                offset: 1,
                                color: 'rgba(14,245,209,1)'
                            }
                        ],
                        // 圆点圈的显示
                        global: false // 缺省为 false
                    },
                }
            },

        },

        // 常规地图
        {
            type: 'map',
            map: 'china',
            geoIndex: 1,
            aspectScale: 0.85,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: '100%',
            showLegendSymbol: false, // 存在legend时显示
            selectedMode: "false",
            zoom: 1.3, //当前视角的缩放比例
            // roam: true, //是否开启平游或缩放
            scaleLimit: { //滚轮缩放的极限控制
                min: 1,
                max: 2
            },
            tooltip: {
                show: false,
            },
            label: {
                normal: {
                    show: false
                },
                emphasis: {
                    show: false
                }
            },
            roam: false,
            itemStyle: {
                normal: {
                    areaColor: {
                        type: 'radial',
                        x: 0.5,
                        y: 0.5,
                        r: .8,
                        colorStops: [{
                            offset: .26, color: 'rgba(16,27,63,1)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(21,73,121,1)'// 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    },

                    borderColor: 'rgba(0,243,255,0.4)',
                    borderWidth: 2.5,

                },
                emphasis: {
                    areaColor: 'rgb(16,27,63)',
                    label: {
                        color: "#fff"
                    }
                }
            },
        }
    ]
}

export default china