const strArr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l',
    'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
    'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z','0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
import smHelper from "sm-crypto"
export const sm2SignHelper = {
    buildJsStr:(len)=>{
        let s = "";
        for(let i = 0; i < len; i++){
            let index = Math.floor(Math.random() * (strArr.length));
            s = s + strArr[index];
        }
        return s;
    },
    buildSignParamAndSort:(paramObject)=>{
        let keyArr = [];
        for(let key in paramObject){
            keyArr.push(key);
        }
        keyArr.sort();
        let paramStr = ""
        for(let k = 0; k < keyArr.length; k++){
            paramStr = paramStr + keyArr[k] + "=" + paramObject[keyArr[k]];
            if(k <  keyArr.length -1 ){
                paramStr += "&";
            }
        }
        return paramStr;
    },
    sm3Hex(str){
        let hash = smHelper.sm3(str);
        return hash.toUpperCase();
    }
}