<template>
    <el-main style="min-width:1200px">
        <el-card class="center admin-style-list">
            <div slot="header" class="clearfix" >
                <span class="title-text">角色管理</span>
<!--                <el-button style="float: right; padding: 3px 0" type="text" @click="addGroup">添加用户组</el-button>-->
            </div>
            <el-row class="operation-button">
                <el-col :span="24" class="text-right">
                    <el-button type="primary" :size="buttonSize" @click="addGroup"><span class="icon iconfont iconfile-plus"></span> 添加角色</el-button>
                    <el-button type="primary" :size="buttonSize" @click="editGroup"><span class="icon iconfont iconedit"></span> 编辑</el-button>
                    <el-button type="primary" :size="buttonSize" @click="editGroupRoles"><span class="icon iconfont iconflag"></span> 角色权限</el-button>
                    <el-button type="primary" :size="buttonSize" @click="editGroupUser"><span class="icon iconfont iconuser-group"></span> 角色用户</el-button>
                    <el-button type="success" :size="buttonSize" @click="updateStatusToEnalbed"><span class="icon iconfont iconcheck-circle"></span> 启用</el-button>
                    <el-button type="warning" :size="buttonSize" @click="updateStatusToDisabled"><span class="icon iconfont iconban"></span> 禁用</el-button>
                    <!--                    <el-button type="warning" :size="buttonSize" @click="delModule"><span class="icon iconfont iconban"></span> 禁用</el-button>-->
                    <el-button type="danger" :size="buttonSize" @click="delGroup"><span class="icon iconfont icondelete-fill"></span> 删除</el-button>
                </el-col>
            </el-row>
            <el-table
                    tooltip-effect="light"
                    v-loading="loading"
                    :height="tableHeight"
                    stripe
                    @selection-change="handleSelectionChange"
                    :data="tableData"
                    class="table-top width-ninety">
                <el-table-column
                        fixed="left"
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        label="角色名称"
                        prop="name"
                        show-overflow-tooltip
                        width="300">
                </el-table-column>
                <el-table-column
                        label="状态"
                        width="180">
                    <template  slot-scope="scope">
                        <el-tag :type="scope.row.isEnabled == 1?'success':'danger'">{{scope.row.isEnabled ==1?'启用':'禁用'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        label="类型"
                        width="180">
                    <template  slot-scope="scope">
                        <el-tag :type="scope.row.flag == 0?'danger':'success'">{{scope.row.flag ==0?'内置':'自定义'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="description"
                        show-overflow-tooltip
                        label="描述">
                </el-table-column>
<!--                <el-table-column-->
<!--                        label="操作"-->
<!--                        width="200">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>-->
<!--                        <el-button type="text" size="small" @click="editGroupRoles(scope.row)">授权</el-button>-->
<!--                        <el-button type="text" size="small" @click="editGroupUser(scope.row)">用户列表</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>

            <!--<el-row class="paging-row width-ninety">
                <el-col>
                    <el-pagination
                            background
                            layout="total,prev, pager, next"
                            @current-change="handleCurrentChange"
                            :page-size="10"
                            :total="20">
                    </el-pagination>
                </el-col>
            </el-row>-->
        </el-card>

        <!--用户组编辑-->
        <el-dialog :title="isAdd?'添加角色':'编辑角色'" @close="dialogEditGroupFormClose" :visible.sync="dialogEditGroupFormVisible" :close-on-click-modal="false" width="30%" custom-class="small-dialog-min-width">
            <el-form :model="groupForm" ref="groupForm">
                <el-form-item label="角色名称" prop="name"
                              :label-width="formLabelWidth"
                              :rules="{required: true, message: '角色名称不能为空', trigger: 'blur'}">
                    <el-input v-model="groupForm.name" auto-complete="off" placeholder="如：超级管理员" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="角色描述"  prop="description"
                              :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="3" v-model="groupForm.description" auto-complete="off" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="状态"  prop="isEnabled"
                              :label-width="formLabelWidth"
                              :rules="{required: true, message: '请选择状态', trigger: 'change'}">
                    <el-radio v-model="groupForm.isEnabled" :label="0">禁用</el-radio>
                    <el-radio v-model="groupForm.isEnabled" :label="1">启用</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditGroupFormVisible = false"  class="black-button" :size="buttonSize">取 消</el-button>
                <el-button type="primary" @click="saveGroup('groupForm')" :size="buttonSize">{{groupDialogSaveButton}}</el-button>
            </div>
        </el-dialog>

        <!--用户组授权-->
        <el-dialog :title="currentGroupName+groupRoleDialogTitle"  :visible.sync="dialogEditGroupRoleFormVisible" :close-on-click-modal="false" width="60%" custom-class="mid-dialog-min-width data-page-view">
            <el-row v-for="item in groupRoleForm" :key="item.modelDto.id" class="module-row">
                <el-col :span="24">

                    <el-checkbox @change="handleCheckAllChange(item.modelDto.id)" v-model="item.modelDto.isChecked" :true-label="1" :false-label="0"><b>{{item.modelDto.name}}</b></el-checkbox>
                    <div style="margin: 5px 0;"></div>
                    <el-checkbox v-for="page in item.pageWithRoleDto" @change="handleCheckChildrenChange(item.modelDto.id)" v-model="page.isChecked" :true-label="1" :false-label="0" :key="page.id"><i class="color-grey">{{page.name}}</i></el-checkbox>
                </el-col>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditGroupRoleFormVisible = false" class="black-button" :size="buttonSize">取 消</el-button>
                <el-button type="primary" @click="saveGroupRole()" :size="buttonSize">{{groupRoleDialogSaveButton}}</el-button>
            </div>
        </el-dialog>

        <!--组用户列表管理-->
        <el-dialog :title="currentGroupName+groupUserDialogTitle"  :visible.sync="dialogEditGroupUserFormVisible" :close-on-click-modal="false" width="60%" custom-class="mid-dialog-min-width">
            <!--重新选择用户组-->
            <el-dialog :title="groupUserName+editGroupUserDialogTitle" @close="editGroupFormClose"  :visible.sync="dialogEditUserGroupFormVisible" append-to-body :close-on-click-modal="false" width="30%" custom-class="small-dialog-min-width">
                <el-form :model="editGroupForm" ref="editGroupForm" >
                    <el-form-item label="请选择新角色" prop="id"
                                  :label-width="formLabelWidth"
                                  :rules="{required: true, message: '用户角色不能为空', trigger: 'change'}">
                        <el-select v-model="editGroupForm.id" placeholder="请选择" :size="inputSize">
                            <el-option
                                    v-for="item in tableData"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                    v-if="item.isEnabled==1">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogEditUserGroupFormVisible = false" class="black-button" :size="buttonSize">取 消</el-button>
                    <el-button type="primary" @click="saveNewUserGroup('editGroupForm')" :size="buttonSize">{{groupDialogSaveButton}}</el-button>
                </div>
            </el-dialog>
            <!--<el-button style="float: right; margin:10px 0"  icon="el-icon-plus" type="primary" @click="addChildrenModule">增加子模块</el-button>-->
            <el-table
                    tooltip-effect="light"
                    v-loading="groupUserLoading"
                    :height="300"
                    stripe

                    class="table-top width-ninety"
                    :data="groupUserTableData">
                <el-table-column
                        prop="code"
                        label="编号"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="username"
                        label="用户名">
                </el-table-column>
                <el-table-column
                        prop="realName"
                        label="名字"
                        width="160">
                </el-table-column>
                <el-table-column
                        label="添加时间"
                        width="180">
                    <template slot-scope="scope">
                        {{formatDate(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="上次登录"
                        width="180">
                    <template slot-scope="scope">
                        {{formatDate(scope.row.lastLoginTime)}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作"
                        width="110">
                    <template slot-scope="scope">
                        <el-button type="primary" :disabled="scope.row.flag == 0" :size="buttonSize" @click="editGroupUserRole(scope.row)"><span class="icon iconfont iconedit"></span> 变更角色</el-button>
<!--                        <el-button type="text" size="small" @click="editGroupUserRole(scope.row)">重新分组</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditGroupUserFormVisible = false" :size="buttonSize" class="black-button">关闭</el-button>
            </div>
        </el-dialog>


    </el-main>
</template>

<script>
    //时间格式化
    import {formatDate} from '../../assets/js/date';
    export default {
        name:"UserGroup",
        data(){
            return{
                tableHeight: window.innerHeight  - 293,
                inputSize:INPUTSIZE,
                buttonSize:BUTTONSIZE,
                formLabelWidth: '120px',
                tableData: [],
               /* status: true,*/
                groupDialogTitle:"编辑角色",
                groupDialogSaveButton:'保存',
                dialogEditGroupFormVisible:false,
                isAdd:false,
                groupForm:{},
                dialogEditGroupRoleFormVisible:false,
                groupRoleDialogTitle:'角色-模块授权',
                groupRoleDialogSaveButton:'保存',
                groupRoleForm:[],
                currentGroupId:'',
                currentGroupName:'',
                checkedPage: [],
                isIndeterminate: true,
                checkedModules:[],
                dialogEditGroupUserFormVisible:false,
                groupUserTableData:[],
                groupUserDialogTitle:'角色-用户列表',
                dialogEditUserGroupFormVisible:false,
                groupUserName:'',
                editGroupUserDialogTitle:'-变更角色',
                editGroupUserForm:{},
                selectUserGroup:'',
                editGroupForm:{
                    id:"",
                    name:""
                },
                moduleForms:[],
                ids:[],
                groupUserLoading:false,
                loading:false
            }
        },
        created(){
            this.$emit('message', { isMain: false });
            this.findAllGroups();
        },
        methods: {
            delGroup(){
                if(this.ids == null || this.ids.length == 0){
                    this.$alert("至少需要选择一条数据进行此操作", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                this.$confirm('此操作将永久删除选择的数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass:"black-button",
                    type: 'warning'
                }).then(() => {
                    this.doDelGroup();
                });
            },
            doDelGroup(){
                let self = this;
                self.$http.post({
                    url: self.$api.moduleEditApi.delGroup,
                    params: {ids:self.ids},
                    callback: {
                        success: data => {
                            self.findAllGroups();
                        }
                    }
                })
            },
            // handleSelectable(row,index){
            //     if(row.flag === 0)return false;
            //     return true;
            // },
            handleSelectionChange(val) {
                let self = this;
                self.ids = [];
                for(let i = 0; i < val.length; i++){
                    self.ids.push(val[i].id);
                }
                self.moduleForms = val;
            },
            formatDate(time){
                let date = new Date(time);
                return formatDate(date,'yyyy-MM-dd hh:mm:ss')
            },
            handleCheckAllChange(id) {
                for(var i = 0;i<this.groupRoleForm.length;i++){
                   if(this.groupRoleForm[i].modelDto.id == id){
                       if(this.groupRoleForm[i].modelDto.isChecked == 0){
                           //此模块下的子模块全部不选择
                           let page = this.groupRoleForm[i].pageWithRoleDto;
                           for(var j=0;j<page.length;j++){
                               page[j].isChecked =0;
                           }
                           break;
                       }else{
                           //此模块下的子模块全部选择
                           let page = this.groupRoleForm[i].pageWithRoleDto;
                           for(var j=0;j<page.length;j++){
                               page[j].isChecked =1;
                           }
                           break;
                       }
                   }
                }
            },
            handleCheckChildrenChange(id){
                for(var i = 0;i<this.groupRoleForm.length;i++){
                    if(this.groupRoleForm[i].modelDto.id == id){
                        let page = this.groupRoleForm[i].pageWithRoleDto;
                        let count = 0;
                        for(var j=0;j<page.length;j++){
                            if(page[j].isChecked ==1){
                                count++;
                            }
                        }
                        if(count==0){
                            this.groupRoleForm[i].modelDto.isChecked = 0;
                        }else{
                            this.groupRoleForm[i].modelDto.isChecked = 1;
                        }
                    }
                }
            },
            //翻页
            findAllGroups(){
                var self = this;
                self.loading = true;
                self.$http.post({
                    url: self.$api.groupApi.findAllGroups,
                    opt:{isLoading:false, showSuccess:false},
                    callback: {
                        success: data => {
                            self.loading = false;
                            self.tableData = data.result;
                        },
                        error: data =>{
                            self.loading = false;
                        },
                        fail: res =>{
                            self.loading = false;
                        }

                    }
                })
            },

            addGroup(){
                this.groupForm={};
                this.isAdd = true;
                this.dialogEditGroupFormVisible = true;
            },
            editGroup(){
                if(this.ids == null || this.ids.length != 1){
                    this.$alert("需要且只能选择一条数据进行此操作", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                this.groupForm = this.moduleForms[0];
                this.isAdd = false;
                this.dialogEditGroupFormVisible = true;
            },
            saveGroup(formName){
                var self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        self.doSaveGroup();
                    } else {
                        return false;
                    }
                });
            },
            updateStatusToEnalbed(){
                if(this.ids == null || this.ids.length == 0){
                    this.$alert("至少需要选择一条数据进行此操作", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                this.doEnabled();
            },
            updateStatusToDisabled(){
                if(this.ids == null || this.ids.length == 0){
                    this.$alert("至少需要选择一条数据进行此操作", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                this.$confirm('此操作将禁用选择数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass:"black-button",
                    type: 'warning'
                }).then(() => {
                    this.doDisabled();
                });

            },
            doEnabled(){
                let self = this;
                self.$http.post({
                    url: self.$api.moduleEditApi.enabledGroups,
                    params: {ids:self.ids},
                    callback: {
                        success: data => {
                            self.findAllGroups();
                        }
                    }
                })
            },
            doDisabled(){
                let self = this;
                self.$http.post({
                    url: self.$api.moduleEditApi.disabledGroups,
                    params: {ids:self.ids},
                    callback: {
                        success: data => {
                            self.findAllGroups();
                        }
                    }
                })
            },
            doSaveGroup(){
                let self = this;
                self.$http.post({
                    url: self.$api.groupApi.saveGroup,
                    params: self.groupForm,
                    callback: {
                        success: data => {
                            self.dialogEditGroupFormVisible = false;
                        }
                    }
                })
            },
            dialogEditGroupFormClose(){
                this.$refs["groupForm"].resetFields();
                this.groupForm = {};
                this.findAllGroups();
            },
            editGroupRoles(){
                if(this.ids == null || this.ids.length != 1){
                    this.$alert("需要且只能选择一条数据进行此操作", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                let self = this;
                let row = self.moduleForms[0];
                if(row.flag == 0){
                    self.$alert("系统内置管理员角色不能进行权限设置！", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                self.currentGroupName = row.name;
                self.currentGroupId = row.id;
                self.dialogEditGroupRoleFormVisible = true;
                self.$http.post({
                    url: self.$api.groupApi.findGroupAndRoleList,
                    params: {groupId:self.currentGroupId},
                    opt:{showSuccess:false},
                    callback: {
                        success: data => {
                            self.groupRoleForm = data.result;
                        }
                    }
                })
            },
            saveGroupRole(){
                let self = this;
                //self.groupRoleForm.groupId = self.currentGroupId;
                let listGroupRoleDto = {
                    groupRoleDto:self.groupRoleForm,
                    groupId:self.currentGroupId
                }
                self.$http.post({
                    url: self.$api.groupApi.saveGroupRole,
                    params: listGroupRoleDto,
                    callback: {
                        success: data => {
                            self.findAllGroups();
                            self.dialogEditGroupRoleFormVisible = false;
                        }
                    }
                })

            },
            editGroupUser(){
                if(this.ids == null || this.ids.length != 1){
                    this.$alert("需要且只能选择一条数据进行此操作", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                let self = this;
                let row = self.moduleForms[0];
                self.currentGroupName = row.name;
                self.dialogEditGroupUserFormVisible = true;
                self.currentGroupId = row.id;
                self.findGroupAllUser(row.id);
            },
            findGroupAllUser(id){
                let self = this;
                self.groupUserLoading = true;
                self.$http.post({
                    url: self.$api.groupApi.findGroupUserByGroupId,
                    params:{groupId:id},
                    opt:{isLoading:false, showSuccess:false},
                    callback: {
                        success: data => {
                            self.groupUserLoading = false;
                            self.groupUserTableData = data.result;
                        },
                        error: data =>{
                            self.groupUserLoading = false;
                        },
                        fail: res =>{
                            self.groupUserLoading = false;
                        }

                    }
                })
            },
            editGroupUserRole(row){
                this.groupUserName = row.username;
                this.editGroupForm.id = row.group.id;
                this.editGroupUserForm = row;
                this.dialogEditUserGroupFormVisible = true;
            },
            editGroupFormClose(){
                this.$refs["editGroupForm"].resetFields();
                this.findGroupAllUser(this.currentGroupId);
            },
            saveNewUserGroup(){
                let self = this;
                //let oldId = self.editGroupUserForm.group.id;
                self.$http.post({
                    url: self.$api.groupApi.saveUserGroup,
                    params: {userId:this.editGroupUserForm.id,groupId: this.editGroupForm.id},
                    callback: {
                        success: data => {
                            self.dialogEditUserGroupFormVisible = false;
                        }
                    }
                })

            }
        }
    }
</script>

<style scoped>
    .input-width{
        width: 300px;
    }

    .mar-right{
        margin-right: 35px;
    }

    .long-bt{
        width: 120px;
    }

    .table-top{
        margin-top: 30px;
    }

    .label-text{
        color: #606266;
        line-height: 40px;
        display: inline-block;
        padding: 0 0 10px;
        font-size: 14px;
    }
</style>