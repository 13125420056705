import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import  {sm2SignHelper} from "./assets/js/sm2SignHelper"
import './assets/css/app.scss'
import './assets/css/iconfont.css'
import './assets/css/common.css'
import './assets/css/scrolltype.css'
import smHelper from "sm-crypto"


//ElementUI
import ElementUI from 'element-ui'
//import './assets/css/element-custom-theme.scss'
import './assets/css/index.css'

//v-charts
import VCharts from 'v-charts'

import './assets/js/global.js'
import api from './assets/js/api.js'
// import cookie from './assets/js/cookie.js'
import urlParams from './assets/js/urlParams.js'
//引入echart
import echarts from 'echarts'

// 适配flex
import '@/common/flexible.js';

// 引入全局css


import dataV from '@jiaminghi/data-view';

import Charts from '@/components/charts';

import * as tool from '@/assets/js/tool';

import * as filters from '@/assets/js/filters';

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

//cookies
import VueCookies from 'vue-cookies'

Vue.use(ElementUI,{size:"mini"});
// Vue.use(VueResource);
Vue.use(VCharts);
Vue.use(dataV);
Vue.use(VueClipboard)
Vue.use(VueCookies)
Vue.use(Charts);

//axios
//import axios from "axios";
// import ElTreeSelect from 'el-tree-select';
// Vue.use(ElTreeSelect);

//http request
import http from './assets/js/http'

//http api url
import httpApiUrl from './assets/js/api'

import vueValidate from './assets/js/vue-validate'

//copy clipboard
import VueClipboard from 'vue-clipboard2'

//frontend save file with js
import { saveAs } from 'file-saver';


Vue.prototype.$api = api;
// Vue.prototype.$cookie = cookie;

Vue.prototype.$apiUrl = httpApiUrl;
Vue.prototype.$urlParams = urlParams;
Vue.prototype.$echarts = echarts;
Vue.prototype.$http = http;
Vue.prototype.$validate = vueValidate;
Vue.prototype.$saveAs = saveAs;
Vue.prototype._ = tool;
Vue.prototype.$sm2SignHelper = sm2SignHelper;
Vue.prototype.$smHelper = smHelper;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// 全局注册图标
Vue.component('icon', Icon);

Vue.config.productionTip = false

/*Vue http拦截器*/
// Vue.http.interceptors.push((request, next) => {
//
//   //const loading = {};
//   // const loading = ElementUI.Loading.service({
//   //   lock: true,
//   //   text: 'Loading',
//   //   spinner: 'el-icon-loading',
//   //   background: 'rgba(0, 0, 0, 0.5)'
//   // });
//   // modify request
//   let Token = cookie.getCookie(TOKENNAME)==null?"":cookie.getCookie(TOKENNAME);
//   request.headers.set(POSTAUTHNAME, Token);
//   request.method = 'POST';//在请求之前可以进行一些预处理和配置
//
//   // continue to next interceptor
//
//   next((response) => {//在响应之后传给then之前对response进行修改和逻辑判断。对于token时候已过期的判断，就添加在此处，页面中任何一次http请求都会先调用此处方
//     // loading.close();
//     if (response.body.error == 2 && !ISSHOWERROR) {  //用户未登录
//       ISSHOWERROR = true;
//       cookie.delCookie(TOKENNAME);  //删除cookie
//       ElementUI.MessageBox.alert(response.body.msg, '信息', {
//         confirmButtonText: '确定',
//         showClose: false,
//         type: "error",
//         callback: action => {
//           router.push("/login"); //跳转到登录页面
//           ISSHOWERROR = false;
//         }
//       })
//     }
//     return response;
//
//   })
// })
//
// /**/
// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAdminAuth) {  // 判断该路由是否含有自定义字段需要登录权限
//     if (cookie.checkCookie(TOKENNAME) == 1) {  // 通过查看Cookie是否已有Token
//       //if(to.meta.checkOpenLilun){}else if(to.meta.checkOpenShicao){}else {
//       next();
//       //}
//     }
//     else {
//       next({
//         path: '/login',
//         query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
//       })
//     }
//   }
//   else {
//     next();
//   }
// })
//
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

router.beforeEach((to, from, next) => {
  if (to.meta.requireAdminAuth) {  // 判断该路由是否含有自定义字段需要登录权限
    if (VueCookies.isKey(TOKENNAME)) {  // 通过查看Cookie是否已有Token
      //if(to.meta.checkOpenLilun){}else if(to.meta.checkOpenShicao){}else {
      next();
      //}
    }
    else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  }
  else {
    next();
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

