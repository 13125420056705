import Conf from '../conf';
export default {
    name: 'defaultLine',
    mixins: [Conf],
    props: {
        yAxisName: {
            type: String,
            default: '连接数/个'
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        dataZoom: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        seriesHandler() {
            if (this.data && this.data.length > 0 && this._.isObject(this.data[0])) {
                return this.data.map(item => {
                    return {
                        name: item.name,
                        type: 'line',
                        smooth: true,
                        data: item.data
                    };
                });
            } else {
                return {
                    name: '',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    areaStyle: {},
                    data: this.data
                };
            }
        },
        gridHandler() {
            return {
                x: 30,
                y: 50,
                x2: 40,
                y2: this.dataZoom ? 50 : 30,
                containLabel: true,
                borderColor: 'transparent'
            };
        },
        yAxisHandler() {
            return [{
                type: 'value',
                name: this.yAxisName,
                min: this.min,
                max: this.max,
                axisTick: {
                    show: false
                }
            },
            {
                type: 'value',
                name: this.yAxisName,
                min: this.min,
                max: this.max,
                axisTick: {
                    show: false
                }
            }];
        },
        xAxisHandler() {
            return [{
                type: 'category',
                smooth: true,
                symbol: 'none',
                data: this.xAxis,
                boundaryGap: false,
                axisTick: {
                    show: false
                }
            }];
        },
        tooltipHandler() {
            return {
                trigger: 'axis',
            };
        },
        dataZoomHandler() {
            return this.dataZoom ? [
                {
                    type: 'inside'
                },
                {
                    type: 'slider',
                    textStyle: {
                        color: '#999999'
                    }
                }
            ] : ''
        }
    }
};
