<template>
    <el-main style="min-width:1200px">
        <el-card class="center admin-style-list">
            <div slot="header" class="clearfix">
                <span class="title-text">模块管理</span>
<!--                <el-button style="float: right; padding: 3px 0" type="text" @click="add">增加模块</el-button>-->
            </div>
            <el-row class="operation-button">
                <el-col :span="24" class="text-right">
                    <el-button type="primary" :size="buttonSize" @click="add"><span class="icon iconfont iconfileplus-fill"></span> 添加模块</el-button>
                    <el-button type="primary" :size="buttonSize" @click="editModule"><span class="icon iconfont iconedit"></span> 编辑</el-button>
                    <el-button type="primary" :size="buttonSize" @click="editChildren"><span class="icon iconfont iconcopy"></span> 管理子模块</el-button>
<!--                    <el-button type="warning" :size="buttonSize" @click="delModule"><span class="icon iconfont iconban"></span> 禁用</el-button>-->
                    <el-button type="danger" :size="buttonSize" @click="delModule"><span class="icon iconfont icondelete-fill"></span> 删除</el-button>
                </el-col>
            </el-row>
            <el-table
                    tooltip-effect="light"
                    v-loading="loading"
                    class="table-top width-ninety"
                    :height="tableHeight"
                    stripe
                    @selection-change="handleSelectionChange"
                    :data="tableData">
                <el-table-column
                        fixed="left"
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="columncode"
                        label="编码"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="主模块名称">
                </el-table-column>
                <el-table-column
                        label="类型"
                        width="180">
                    <template  slot-scope="scope">
                        <el-tag :type="scope.row.flag == 0?'danger':'success'">{{scope.row.flag ==0?'内置':'自定义'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="icon"
                        label="图标"
                        width="150">
                    <template slot-scope="scope">
                        <i :class="scope.row.icon"></i>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="index"
                        label="索引"
                        width="150">
                </el-table-column>
<!--                <el-table-column-->
<!--                        label="操作"-->
<!--                        width="200">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button type="text" size="small" @click="editModule(scope.row)">编辑</el-button>-->
<!--                        <el-button type="text" size="small" @click="editChildren(scope.row)">管理子模块</el-button>-->
<!--                        <el-button type="text" size="small" @click="delModule(scope.row.id)">删除</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>
            <!--<el-row class="paging-row width-ninety">
                <el-col>
                    <el-pagination
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            :current-page="pageNo"
                            :total="model.totalElements">
                    </el-pagination>
                </el-col>
            </el-row>-->
        </el-card>

        <!--主模块编辑-->
        <el-dialog :title="isAdd?'添加主模块':'编辑主模块'" @close="moduleDialogClose" :close-on-click-modal="false" width="30%" custom-class="small-dialog-min-width"  :visible.sync="dialogEditModuleFormVisible">
            <el-form :model="moduleForm" ref="moduleForm">
                <el-form-item label="模块编码" prop="columncode"
                              :label-width="formLabelWidth"
                              :rules="{required: true, message: '编码不能为空', trigger: 'blur'}">
                    <el-input v-model="moduleForm.columncode" auto-complete="off" placeholder="如：M0001" :size="inputSize" class="dialog-input-width"></el-input>
                </el-form-item>
                <el-form-item label="模块名称"  prop="name"
                              :label-width="formLabelWidth"
                              :rules="{required: true, message: '模块名称不能为空', trigger: 'blur'}">
                    <el-input v-model="moduleForm.name" auto-complete="off" :size="inputSize" class="dialog-input-width"></el-input>
                </el-form-item>
                <el-form-item label="模块图标"  prop="icon"
                              :label-width="formLabelWidth"
                              :rules="{required: true, message: '模块图标不能为空', trigger: 'blur'}">
                    <el-input v-model="moduleForm.icon" auto-complete="off" :size="inputSize" placeholder="使用iconfont图标，如iconfont iconedit，请参考图标页面" class="dialog-input-width"></el-input>
                </el-form-item>
                <el-form-item label="模块索引" prop="index" :rules="[{required: true, message: '模块索引不能为空', trigger:  ['blur', 'change']},{ validator: numberValidate, trigger: ['blur', 'change'] }]" :label-width="formLabelWidth">
<!--                    <el-input v-model.number="moduleForm.index" auto-complete="off" type="number" placeholder="使用大于0的数字" :size="inputSize" class="dialog-input-width"></el-input>-->
                    <el-input-number v-model="moduleForm.index" controls-position="right" :min="0" :max="1000"></el-input-number> <span class="form-tips">不能重复</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditModuleFormVisible = false" :size="buttonSize" class="black-button">取 消</el-button>
                <el-button type="primary" @click="saveModule('moduleForm')" :size="buttonSize">{{moduleDialogSaveButton}}</el-button>
            </div>
        </el-dialog>

        <!--子模块管理-->
        <el-dialog title="管理子模块"  :visible.sync="dialogEditChildrenModuleFormVisible" :close-on-click-modal="false" width="70%" custom-class="big-dialog-min-width">
            <!--子模块编辑-->
            <el-dialog :title="isAddChildren?'添加子模块':'编辑子模块'"  :visible.sync="dialogEditChildrenModuleAndAPIFormVisible" append-to-body @close="moduleChildrenDialogClose" :close-on-click-modal="false" width="6    0%" custom-class="mid-dialog-min-width data-page-view">
                <el-form :model="childrenModuleAPIForm" ref="childrenModuleAPIForm">
                    <el-form-item label="模块编码" prop="memucode"
                                  :label-width="formLabelWidth"
                                  :rules="{required: true, message: '编码不能为空', trigger: 'blur'}">
                        <el-input v-model="childrenModuleAPIForm.memucode" readonly disabled class="dialog-input-width" auto-complete="off" placeholder="如：1-1，-前面为主模块索引，后面为子模块索引" :size="inputSize"></el-input>
                    </el-form-item>
                    <el-form-item label="模块名称"  prop="name"
                                  :label-width="formLabelWidth"
                                  :rules="{required: true, message: '模块名称不能为空', trigger: 'blur'}">
                        <el-input v-model="childrenModuleAPIForm.name" class="dialog-input-width" auto-complete="off" :size="inputSize"></el-input>
                    </el-form-item>
                    <el-form-item label="模块索引" prop="index" :rules="[{required: true, message: '模块索引不能为空', trigger:  ['blur', 'change']},{ validator: numberValidate, trigger: ['blur', 'change'] }]" :label-width="formLabelWidth">
                        <!--                    <el-input v-model.number="moduleForm.index" auto-complete="off" type="number" placeholder="使用大于0的数字" :size="inputSize" class="dialog-input-width"></el-input>-->
                        <el-input-number @change="childrenIndexChange" v-model="childrenModuleAPIForm.index" controls-position="right" :min="0" :max="1000"></el-input-number> <span class="form-tips">在同一个主模块中不能重复</span>
                    </el-form-item>
                    <el-form-item label="模块链接" prop="url" :rules="{required: true, message: '模块链接不能为空', trigger: 'blur'}" :label-width="formLabelWidth">
                        <el-input v-model="childrenModuleAPIForm.url" class="dialog-input-width" auto-complete="off" placeholder="路由#后面的地址" :size="inputSize"></el-input>
                    </el-form-item>
                    <el-form-item label="模块API" :label-width="formLabelWidth">
                        <el-table
                                stripe
                                height="250"
                                :data="childrenModuleAPIForm.pageAPIs"
                                class="box-table api-dialog-table"
                                style="width: 100%">
                            <el-table-column
                                    prop="name"
                                    label="API名称"
                                    width="200">
                                <template slot-scope="scope">
                                    <el-input placeholder="请输入API名称" v-model="scope.row.name">
                                    </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="api"
                                    label="API链接">
                                <template slot-scope="scope">
                                    <el-input placeholder="请输入API链接" v-model="scope.row.api">
                                    </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="180"
                                    label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                            v-if="scope.$index == 0"
                                            @click.native.prevent="addRow(scope.$index, childrenModuleAPIForm.pageAPIs)"
                                            type="primary"
                                            size="mini">
                                        <span class="icon iconfont iconplus"></span> 增加
                                    </el-button>
                                    <el-button
                                            :disabled="scope.$index == 0"
                                            @click.native.prevent="deleteRow(scope.$index, childrenModuleAPIForm.pageAPIs,scope.row)"
                                            type="danger"
                                            size="mini">
                                        <span class="icon iconfont icondelete-fill"></span> 删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogEditChildrenModuleAndAPIFormVisible = false" class="black-button" :size="buttonSize">取 消</el-button>
                    <el-button type="primary" @click="saveChildrenModule('childrenModuleAPIForm')" :size="buttonSize">{{moduleDialogSaveButton}}</el-button>
                </div>
            </el-dialog>
                <el-button style="float: right; margin:10px 0"  icon="el-icon-plus" type="primary" @click="addChildrenModule" :size="buttonSize">增加子模块</el-button>
                <el-table
                        stripe
                        height="300"
                        v-loading="childrenLoading"
                        class="table-top width-ninety"
                        :data="childrenTableData">
                    <el-table-column
                            prop="memucode"
                            label="编码"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="模块名称"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            label="类型"
                            width="180">
                        <template  slot-scope="scope">
                            <el-tag :type="scope.row.flag == 0?'danger':'success'">{{scope.row.flag ==0?'内置':'自定义'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="url"
                            label="链接">
                    </el-table-column>
                    <el-table-column
                            label="操作"
                            width="180">
                        <template slot-scope="scope">
                            <el-button type="primary" :disabled="scope.row.flag == 0" size="mini" @click="editChildrenModule(scope.row)"><span class="icon iconfont iconedit"></span> 编辑</el-button>
                            <el-button type="danger" :disabled="scope.row.flag == 0" size="mini" @click="delChildrenModule(scope.row.id)"><span class="icon iconfont icondelete-fill"></span> 删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditChildrenModuleFormVisible = false" class="black-button" :size="buttonSize">关闭</el-button>
            </div>
        </el-dialog>



    </el-main>
</template>

<script>
    export default {
        name: 'ModuleEdit',
        data(){
            var validate = (rule, value, callback) => {
                if (value < 1 || value > 1000) {
                    callback(new Error('该值必须是1-1000之间的数字且不能重复'));
                }else{
                    callback();
                }
            };
            return{
                numberValidate:validate,
                tableHeight: window.innerHeight  - 283,
                inputSize:INPUTSIZE,
                buttonSize:BUTTONSIZE,
                formLabelWidth: '120px',
                tableData:[],
                dialogEditModuleFormVisible:false,
                moduleDialogTitle:"编辑模块",
                moduleDialogSaveButton:"保存",
                moduleForm:{
                    columncode:'',
                    name:'',
                    id:'',
                    icon:'',
                    index:0,
                },
                currentModuleId:null,
                dialogEditChildrenModuleFormVisible:false,
                childrenTableData:[],
                dialogEditChildrenModuleAndAPIFormVisible:false,
                childrenModuleAPIForm:{
                    memucode:'',
                    name:'',
                    id:'',
                    url:'',
                    index:0,
                    pageAPIs: [{
                        name:'',
                        api:''
                    }],
                },
                isAdd:false,
                ids:[],
                moduleForms:[],
                loading:false,
                childrenLoading:false,
                isAddChildren:false
            }
        },
        created(){
            this.$emit('message', { isMain: false });
            this.findAllModules();
        },
        methods:{
            // handleSelectable(row,index){
            //     if(row.flag === 0)return false;
            //     return true;
            // },
            handleSelectionChange(val) {
                let self = this;
                self.ids = [];
                for(let i = 0; i < val.length; i++){
                    self.ids.push(val[i].id);
                }
                self.moduleForms = val;
            },
            findAllModules(){
                var self = this;
                self.loading = true;
                self.$http.post({
                    url: self.$api.moduleEditApi.findAllModules,
                    opt:{isLoading:false, showSuccess:false},
                    callback: {
                        success: data => {
                            self.loading = false;
                            self.tableData = data.result;
                        },
                        error: data =>{
                            self.loading = false;
                        },
                        fail: res =>{
                            self.loading = false;
                        }

                    }
                })
            },
            add(){
                this.moduleForm = {
                    columncode:'',
                    name:'',
                    id:'',
                    icon:'',
                    index:0,
                };
                this.dialogEditModuleFormVisible = true;
            },
            moduleDialogClose(){
                this.$refs["moduleForm"].resetFields();
                this.moduleForm = {
                    columncode:'',
                    name:'',
                    id:'',
                    icon:'',
                    index:0,
                };
                this.findAllModules();
            },
            saveModule(formName){
                var self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        self.$http.post({
                            url: self.$api.moduleEditApi.saveModule,
                            params: self.moduleForm,
                            callback: {
                                success: data => {
                                    self.dialogEditModuleFormVisible = false;
                                }
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            editModule(){
                if(this.ids == null || this.ids.length != 1){
                    this.$alert('只能选择一条数据进行此操作！', '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                this.moduleForm = this.moduleForms[0];
                this.dialogEditModuleFormVisible = true;
            },
            delModule(){
                let self = this;
                if(self.ids == null || self.ids.length == 0){
                    self.$alert('需要且只能选择一条数据进行此操作！', '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                self.$confirm('此操作将永久删除选择数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass:'black-button',
                    type: 'warning'
                }).then(() => {
                    self.$http.post({
                        url: self.$api.moduleEditApi.delModule,
                        params: {ids:self.ids},
                        callback: {
                            success: data => {
                                self.findAllModules();
                            }
                        }
                    })

                });
            },
            editChildren(){
                let self = this;
                if(self.ids == null || self.ids.length != 1){
                    self.$alert('需要且只能选择一条数据进行此操作！', '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                self.currentModuleId = self.ids[0];
                self.findChildrenModule(self.currentModuleId);
                self.dialogEditChildrenModuleFormVisible = true;
            },
            findChildrenModule(id){
                let self = this;
                self.childrenLoading = true;
                self.$http.post({
                    url: self.$api.moduleEditApi.findChildrenModules,
                    params:{moduleId:id},
                    opt:{isLoading: false, showSuccess: false},
                    callback: {
                        success: data => {
                            self.childrenLoading = false;
                            self.childrenTableData = data.result;
                        },
                        error: data=>{
                            self.childrenLoading = false;
                        },
                        fail: res=>{
                            self.childrenLoading = false;
                        }
                    }
                })

            },
            addChildrenModule(){
                this.isAddChildren = true;
                let parentIndex = this.moduleForms[0].index;
                this.childrenModuleAPIForm = {
                    memucode: parentIndex+'-0',
                    name:'',
                    id:'',
                    url:'',
                    index:0,
                    pageAPIs: [{
                        name:'',
                        api:''
                    }],
                };
                this.dialogEditChildrenModuleAndAPIFormVisible = true;
            },
            childrenIndexChange(){
                let parentIndex = this.moduleForms[0].index;
                this.childrenModuleAPIForm.memucode = parentIndex+'-'+this.childrenModuleAPIForm.index;
            },
            moduleChildrenDialogClose(){
                this.$refs["childrenModuleAPIForm"].resetFields();
                this.childrenModuleAPIForm = {
                    memucode:'',
                    name:'',
                    id:'',
                    url:'',
                    index:0,
                    pageAPIs: [{
                        name:'',
                        api:''
                    }],
                };
                this.findChildrenModule(this.currentModuleId);
            },
            editChildrenModule(row){
                this.isAddChildren = false;
                this.childrenModuleAPIForm = row;
                this.dialogEditChildrenModuleAndAPIFormVisible = true;
            },
            addRow(index, rows){
                rows.push({
                    name:'',
                    api:''
                });
            },
            deleteRow(index, rows,row){
                let self = this;
                if(!this.isAddChildren && row.id){
                    self.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        cancelButtonClass:'black-button',
                        type: 'warning'
                    }).then(() => {
                        self.$http.post({
                            url: self.$api.moduleEditApi.delPageApi,
                            params: {pageApiId: row.id},
                            callback: {
                                success: data => {
                                    rows.splice(index, 1);
                                }
                            }
                        })
                    })
                }else{
                    rows.splice(index, 1);
                }
            },
            saveChildrenModule(formName){
                var self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        self.$http.post({
                            url: self.$api.moduleEditApi.savePage,
                            params: {page:self.childrenModuleAPIForm,moduleId:self.currentModuleId},
                            callback: {
                                success: data => {
                                    self.dialogEditChildrenModuleAndAPIFormVisible = false;
                                }
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            delChildrenModule(id){
                let self = this;
                self.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass:'black-button',
                    type: 'warning'
                }).then(() => {
                    self.$http.post({
                        url: self.$api.moduleEditApi.delPage,
                        params: {pageId: id},
                        callback: {
                            success: data => {
                                self.findChildrenModule(self.currentModuleId);
                            }
                        }
                    })
                })
            },
        }
    }
</script>

<style scoped>
    .save-bt{
        width: 120px;
        margin-top: 30px;
    }

    .input-width{
        width: 260px;
    }
    .form-tips{
        color: burlywood;
        display: inline-block;
        margin-left: 10px;
    }
</style>