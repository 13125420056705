<template>
    <el-main style="min-width:1200px">

        <el-row class="con" :gutter="20">
            <el-col :span="8">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 告警统计</span>
                        </div>
                    </div>
                    <div class="graph-panel total-num-wrap">
                        <div>
                            <dv-decoration-12 class="scan-decoration-12" :color="['#409eff', '#0792e0']" />
                        </div>
                        <div class="total-num-box" >
                            <div class="total-num-item">
                                <div class="num-val">{{totalInfo.dayData | numberFilter}}</div>
                                <div class="num-title">
                                    当天告警次数
                                </div>
                            </div>
                            <div class="total-num-item">
                                <div class="num-val">{{totalInfo.monthData | numberFilter}}</div>
                                <div class="num-title">
                                    当月告警次数
                                </div>
                            </div>
                            <div class="total-num-item">
                                <div class="num-val">{{totalInfo.yearData | numberFilter}}</div>
                                <div class="num-title">
                                    当年告警次数
                                </div>
                            </div>
                        </div>

                    </div>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 近30日的告警次数</span>
                        </div>
                    </div>

                    <div class="graph-panel network-encrypt-daily-box">
                        <div class="empty-data" v-if="lineData.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-else>暂时没有数据</div>
                        </div>

                        <default-line v-else :data="lineData" :xAxis="dailyXAxis" yAxisName="告警数/次"/>
                    </div>
                </el-card>

            </el-col>

        </el-row>

        <el-row class="con statistic-table" :gutter="20">
            <el-col :span="24">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 告警列表</span>
                        </div>
                        <el-form label-position="left" class="inline-search-form">
                            <el-form-item label="探针机房区域" class="small-input">
                                <el-select v-model="searchForm.engineRoom" clearable placeholder="请选择" @change="engineChange" :disabled="engineDisabled">
                                    <el-option
                                            v-for="item in engines"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="探针网络边界" class="small-input" >
                                <el-select v-model="searchForm.networkBoundary" clearable placeholder="请选择" @change="netBoundaryChange" :disabled="netBoundaryDisabled">
                                    <el-option
                                            v-for="item in netBoundary"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="探针" >
                                <el-select v-model="searchForm.agentId" clearable placeholder="请选择" @change="agentChange" :disabled="agentDisabled">
                                    <el-option
                                            v-for="item in agents"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="告警时间范围" >
                                <el-date-picker v-model="dateRange" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDateRange" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="search">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="graph-panel tls-version-box">

                        <el-table tooltip-effect="light" height="350" class="table-top width-ninety" stripe :data="dataList" v-loading="loading">

                            <el-table-column prop="createTime" show-overflow-tooltip label="时间" width="140">
                                <template slot-scope="scope">
                                    {{scope.row.timestamp | formatDateFilter}}
                                </template>
                            </el-table-column>
                            <!-- <el-table-column prop="timestamp" show-overflow-tooltip label="时间" width="140">
                                <template slot-scope="scope">
                                    {{scope.row.timestamp | formatDateFilter}}
                                </template>
                            </el-table-column> -->

                            <el-table-column prop="srcIp" show-overflow-tooltip label="源 IP/名称" width="150">
                                <template slot-scope="scope">{{scope.row.srcName?scope.row.srcName:scope.row.srcIp+":"+scope.row.srcPort}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="dstIp" show-overflow-tooltip label="目的 IP/名称" width="150">
                                <template slot-scope="scope">
                                    {{scope.row.dstName?scope.row.dstName:scope.row.dstIp+":"+scope.row.dstPort}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="protocol" show-overflow-tooltip label="通信协议" width="100" :formatter="formatterEmpty">
                            </el-table-column>
                            <el-table-column prop="warnLevel" show-overflow-tooltip label="告警级别" width="100" :formatter="formatterWarnLevel">
                                <template slot-scope="scope">
                                    <el-tag effect="dark" :type="formatterWarnLevelTag(scope.row.warnLevel)">{{formatterWarnLevel(scope.row.warnLevel)}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="finding" show-overflow-tooltip label="告警内容" min-width="200" :formatter="formatterEmpty">
                            </el-table-column>
                            <el-table-column prop="name" show-overflow-tooltip label="探针名称" width="160" :formatter="formatterEmpty">
                            </el-table-column>
                            <el-table-column prop="agentIp" show-overflow-tooltip label="探针 IP" width="120" :formatter="formatterEmpty">
                            </el-table-column>
<!--                            <el-table-column prop="agentArea" show-overflow-tooltip label="探针区域位置" width="120" :formatter="formatterEmpty">-->
<!--                            </el-table-column>-->
                            <el-table-column prop="agentOrgName" show-overflow-tooltip label="探针所属单位" width="120" :formatter="formatterEmpty">
                            </el-table-column>
<!--                            <el-table-column prop="agentAddress" show-overflow-tooltip label="探针所在详细地址" width="120">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.agentArea + scope.row.agentAddress}}-->
<!--                                </template>-->
<!--                            </el-table-column>-->
                            <el-table-column label="操作" width="90">
                                <template slot-scope="scope">
                                   <el-button type="text" @click="showDetail(scope.row)">查看详情</el-button>
                                </template>
                            </el-table-column>
                            
                        </el-table>
                        <el-row class="paging-row width-ninety">
                            <el-col :span="24">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 15, 20, 30, 40, 50]" layout="total, sizes, prev, pager, next, jumper" :page-size="pageSize" :current-page="pageNo" :total="total">
                                </el-pagination>
                            </el-col>
                        </el-row>

                        <el-dialog
                                title="告警详情"
                                :close-on-click-modal="false"
                                :visible.sync="dialogShowModelFormVisible"
                                width="40%"
                                custom-class="mid-dialog-min-width">
                            <el-form :model="currentWarning" ref="currentWarning">

                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="告警时间" :label-width="formLabelWidth">
                                            {{ currentWarning.timestamp | formatDateFilter }}
                                        </el-form-item>
                                        <el-form-item label="源 IP" :label-width="formLabelWidth">
                                            {{currentWarning.srcIp}}:{{currentWarning.srcPort}}
                                        </el-form-item>
                                        <el-form-item label="目的IP" :label-width="formLabelWidth">
                                            {{ currentWarning.dstIp }}:{{currentWarning.dstPort}}
                                        </el-form-item>
                                        <el-form-item label="探针IP" :label-width="formLabelWidth">
                                            {{ currentWarning.agentIp}}
                                        </el-form-item>

                                        <!--                        <el-form-item label="状态" :label-width="formLabelWidth">-->
                                        <!--                            <el-tag :type="showForm.state === 0?'success':'danger'">{{showForm.state ===0?'正常':'离线'}}</el-tag>-->
                                        <!--                        </el-form-item>-->
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="告警级别" :label-width="formLabelWidth">
                                            <el-tag effect="dark" :type="formatterWarnLevelTag(currentWarning.warnLevel)">{{formatterWarnLevel(currentWarning.warnLevel)}}</el-tag>
                                        </el-form-item>
                                        <el-form-item label="源名称" :label-width="formLabelWidth">
                                            {{currentWarning.srcName?currentWarning.srcName:"-"}}
                                        </el-form-item>
                                        <el-form-item label="目的名称" :label-width="formLabelWidth">
                                            {{ currentWarning.dstName?currentWarning.dstName:"-" }}
                                        </el-form-item>
                                        <el-form-item label="探针名称" :label-width="formLabelWidth">
                                            {{ currentWarning.name?currentWarning.name:"-"}}
                                        </el-form-item>

                                        <!--                        <el-form-item label="合规检测" :label-width="formLabelWidth">-->
                                        <!--                            <el-tag :type="showForm.status === 1?'success':'danger'">{{showForm.state ===0?'正常':'告警中'}}</el-tag>-->
                                        <!--                        </el-form-item>-->
                                        <!-- <el-form-item label="生产厂家" :label-width="formLabelWidth">
                                            {{ showForm.manufacturer }}
                                        </el-form-item> -->
                                    </el-col>
                                </el-row>
                                <el-form-item label="探针所属单位" :label-width="formLabelWidth">
                                    {{ currentWarning.agentOrgName }}
                                </el-form-item>
                                <el-form-item label="探针详细地址" :label-width="formLabelWidth">
                                    {{currentWarning.agentArea + currentWarning.agentAddress}}
                                </el-form-item>
                                <el-form-item label="通信协议" :label-width="formLabelWidth">
                                    {{ currentWarning.protocol }}
                                </el-form-item>
                                <el-form-item label="告警内容" :label-width="formLabelWidth">
                                    {{ currentWarning.finding }}
                                </el-form-item>
                                <el-form-item label="处置建议" :label-width="formLabelWidth">
                                    {{ currentWarning.recommendation || "暂无" }}
                                </el-form-item>

<!--                                <el-form-item label="详细位置" :label-width="formLabelWidth">-->
<!--                                    {{ showForm.address }}-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="设备特征码" :label-width="formLabelWidth">-->
<!--                                    {{ showForm.featureCode }}-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="功能描述" :label-width="formLabelWidth">-->
<!--                                    {{ showForm.functionDesc ? showForm.functionDesc : '无' }}-->
<!--                                </el-form-item>-->
                                <!-- <el-form-item label="来自下级" :label-width="formLabelWidth">
                                    {{ showForm.belongLocal == 2 ? '是' : '否' }}
                                </el-form-item>
                                <el-form-item label="来源平台" :label-width="formLabelWidth">
                                    {{ showForm.platformName }}
                                </el-form-item>
                                <el-form-item label="来源IP" :label-width="formLabelWidth">
                                    {{ showForm.platformIp }}
                                </el-form-item> -->
                            </el-form>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="dialogShowModelFormVisible = false" class="black-button" :size="buttonSize">
                                    关闭
                                </el-button>
                            </div>
                        </el-dialog>
                    </div>
                </el-card>
            </el-col>

        </el-row>

    </el-main>
</template>
<script>
import {numberFilter} from "../../../assets/js/filters"
import {agentSearchField} from "../../../assets/js/constants"

export default {
    
    data() {
        let now = new Date();
        let year = now.getFullYear();
        let date = now.getDate();
        let month = now.getMonth();
        let last = new Date();
        last.setDate(last.getDate() - 7);
        let lastYear = last.getFullYear();
        let lastDate = last.getDate();
        let lastMonth = last.getMonth();
        let start = new Date(lastYear, lastMonth, lastDate);
        let end = new Date(year, month, date, 23, 59, 59);
        return {
            formLabelWidth: '160px', //增加数据输入框大小
            isLoading: false,
            inputSize: INPUTSIZE, //增加数据输入框大小
            buttonSize: BUTTONSIZE, // 按钮大小
            isInit: true,
            dataInfo: {
                lineData: [],
                warnData: {}
            },
            lineData: [],
            dailyXAxis: [],
            totalInfo: {
                dayData: 0,
                monthData: 0,
                yearData: 0,
            },
            pageNo: 1,
            pageSize: 15,
            total: 0,
            startTimeDate: start,
            endTimeDate: end,
            searchForm: {
                agentId: "",
                startTime: start,
                endTime: end,
                engineRoom: null,
                networkBoundary: null
            },
            dataList: [],
            dateRange: [start, end],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            loading: false,
            warnType: 1,
            dialogShowModelFormVisible:false,
            currentWarning:{},
            agents:[],
            engines:agentSearchField.engines,
            netBoundary:agentSearchField.netBoundary,
            engineDisabled: false,
            netBoundaryDisabled: false,
            agentDisabled: false
        }
    },
    filters:{
        numberFilter(value){
            return numberFilter(value);
        }
    },
    mounted() {
        let val = this.$route
        if(val.name === 'Compliance') {
            this.warnType = 1
        }
        if(val.name === 'Security') {
            this.warnType = 2
        }
        if(val.name === 'Threaten') {
            this.warnType = 3
        }
        this.init()
    },
    watch: {

        $route(val){
            if(val.name === 'Compliance') {
                this.warnType = 1
            }
            if(val.name === 'Security') {
                this.warnType = 2
            }
            if(val.name === 'Threaten') {
                this.warnType = 3
            }
            this.init()
        }
    },
    methods: {
        engineChange(val){
            if(this.searchForm.engineRoom){
                this.searchForm.networkBoundary = null;
                this.netBoundaryDisabled = true;
                this.searchForm.agentId = "";
                this.agentDisabled = true;
            }else{
                this.netBoundaryDisabled = false;
                this.agentDisabled = false;
            }
        },
        netBoundaryChange(val){
            if(this.searchForm.networkBoundary){
                this.searchForm.engineRoom = null;
                this.engineDisabled = true;
                this.searchForm.agentId = "";
                this.agentDisabled = true;
            }else{
                this.engineDisabled = false;
                this.agentDisabled = false;
            }
        },
        agentChange(val){
            if(this.searchForm.agentId){
                this.searchForm.engineRoom = null;
                this.engineDisabled = true;
                this.searchForm.networkBoundary = null;
                this.netBoundaryDisabled = true;
            }else{
                this.engineDisabled = false;
                this.netBoundaryDisabled = false;
            }
        },
        showDetail(row){
            this.currentWarning = row;
            this.dialogShowModelFormVisible = true;
        },
        init() {
            this.dailyXAxis = []
            this.lineData = []
            this.isLoading = true
            this.dataInfo = {
                lineData: [],
                warnData: {}
            }
            this.totalInfo = {
                dayData: 0,
                monthData: 0,
                yearData: 0,
            }
            this.pageNo = 1
            this.pageSize = 15
            this.total = 0
            this.searchForm = {
                agentId: "",
                startTime: this.startTimeDate,
                endTime: this.endTimeDate,
                engineRoom: "",
                networkBoundary: ""
            }
            this.dataList = []
            this.dateRange = [this.startTimeDate, this.endTimeDate]
            this.pickerOptions = {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            }
            this.currentWarning = {}
            this.agents = []
            this.findWarnStatisticsData()
            this.findCipherConformanceNoticeByPage()
            this.findAgents();
        },
        findAgents() {
            this.$http.post({
                url: this.$api.adminApi.findAllAgents,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        this.agents = data.result || []
                    }
                }
            })
        },
        formatterEmpty(row, column, cellValue, index) {
            return cellValue || '--'
        },
        formatterWarnLevel(cellValue) {
            if(cellValue === 1){
                return "低"
            }
            if(cellValue === 2){
                return "中"
            }
            if(cellValue === 3){
                return "高"
            }
            return "未分级"
        },
        formatterWarnLevelTag(cellValue){
            if(cellValue === 1){
                return "info"
            }
            if(cellValue === 2){
                return "warning"
            }
            if(cellValue === 3){
                return "danger"
            }
            return ""
        },
        findWarnStatisticsData() {
            let params = {
                warnType: this.warnType
            }

            this.$http.post({
                url: this.$api.warningApi.findWarnStatisticsData,
                params: params,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        this.dataInfo = data.result;
                        
                        let lineData = data.result.lineData || []
                        lineData.map(item => {
                            let day = this._.formatDate(item.day)
                            this.dailyXAxis.push(day)
                            this.lineData.push(item.number)
                        })

                        this.totalInfo.dayData = this.dataInfo.warnData.dayData
                        this.totalInfo.monthData = this.dataInfo.warnData.monthData
                        this.totalInfo.yearData = this.dataInfo.warnData.yearData
                        this.isLoading = false

                    },
                    error: () => {
                        this.isLoading = false
                    },
                    fail: () => {
                        this.isLoading = false
                    }
                }
            })

        },
        changeDateRange(val) {
            if (val) {
                this.searchForm.startTime = val[0]
                this.searchForm.endTime = val[1]
            } else {
                this.searchForm.startTime = ''
                this.searchForm.endTime = ''
            }
        },
        search(){
            this.findCipherConformanceNoticeByPage()
        },
        handleSizeChange(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.findCipherConformanceNoticeByPage();
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.findCipherConformanceNoticeByPage();
        },
        findCipherConformanceNoticeByPage() {
            let params = {
                pageNo: this.pageNo - 1,
                pageSize: this.pageSize,
                warnType: this.warnType,
                ...this.searchForm
            }
            this.loading = true
            this.$http.post({
                url: this.$api.warningApi.findCipherConformanceNoticeByPage,
                params: params,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        this.dataList = data.result.content || [];
                        this.total = data.result.totalElements
                        this.loading = false
                    },
                    fail: () => {
                        this.loading = false
                    }
                }
            })

        }
    }
}
</script>
<style lang="scss" scoped="scoped">
.con {
    display: flex;
}
.statistic-table {
    margin-top: 20px;
}
.network-encrypt-daily-box {
    height: 300px;
    position: relative;
    .statics-day {
        position: absolute;
        top: -30px;
        right: 0;
    }
}
.tls-version-box {
    height: 350px;
}
.dst-ip-bar-box {
    height: 350px;
}
.header-title {
    display: flex;
    justify-content: space-between;
}
.uri-item {
    padding-bottom: 10px;
    overflow: auto;
    white-space: initial;
    label {
        padding-right: 10px;
    }
}
.total-num-wrap {
    height: 300px;
}
.total-num-box {
    display: flex;
    padding-top: 40px;
    .total-num-item {
        width: 33.333%;
        text-align: center;
        background-color: rgba(5, 112, 177, .2);
        margin: 0 10px;
        .num-val {
            font-weight: bold;
            font-size: 34px;
            margin-bottom: 10px;
            padding-top: 16px;
        }
        .num-title {
            font-size: 14px;
            margin-bottom: 16px;
            color: #b3d8ff;
        }
        .num-decoration-9 {
            width: 150px;
            height: 150px;
            margin: 0 auto;
            font-size: 22px;
            font-weight: 700;
        }
    }
}
.scan-decoration-12 {
    width: 160px;
    height: 160px;
    margin: 0 auto;
}

</style>
<style lang="scss">
    .inline-search-form {
        .small-input{
            width: 150px;
        }
        .el-form-item{
            display: inline-block;
            margin-left: 10px;

        }
    }
    /*.el-input.is-disabled .el-input__inner {*/
    /*    border-color: #0060bb !important;*/
    /*    background: #0052a0 !important;*/
    /*}*/
</style>