<template>
    <el-main style="min-width: 1200px">
        <el-card class="center admin-style-list">
            <div slot="header" class="clearfix">
                <span class="title-text">
                    <i class="icon iconfont icondata"></i>
                    密评应用管理
                </span>
            </div>
            <el-form label-position="top" class="search-form" label-width="100px">
                <el-row :gutter="35">
                    <el-col :span="4">
                        <el-form-item label="应用名称（模糊匹配）">
                            <el-input v-model="searchForm.name" :size="inputSize" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="应用IP（模糊匹配）">
                            <el-input v-model="searchForm.ip" :size="inputSize" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-form-item label="应用端口（精确匹配）">
                            <el-input type="number" :min="0" v-model="searchForm.port" :size="inputSize" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="应用类型">
                            <el-select v-model="searchForm.typeId" clearable placeholder="请选择">
                                <el-option
                                        v-for="item in types"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="所属单位">
                            <el-select
                                    clearable
                                    @clear="searchOrgClear"
                                    v-model="searchForm.orgId"
                                    placeholder="请选择"
                                    popper-class="tree-select"
                                    ref="selectSearchReport"
                                    style="width: 80%">
                                <el-option :value="searchForm.orgId" :label="searchForm.orgName">
                                    <el-tree :data="organization" :props="defaultProps" @node-click="handleSearchNodeClick"></el-tree>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-form-item label="." class="search-button-space">
                            <el-button class="search-button" @click="select" :size="buttonSize" type="primary" plain>
                                <span class="icon iconfont iconsearch"></span>
                                查询
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row class="paging-row width-ninety">
                <el-col :span="8" class="text-left">
                    <el-button type="primary" :size="buttonSize" @click="dialogTypeModelFormVisible = true">
                        <span class="icon iconfont iconfolder-plus"></span>
                        应用类型管理
                    </el-button>
                </el-col>
                <el-col :span="16" class="text-right">
                    <el-button type="primary" :size="buttonSize" @click="add">
                        <span class="icon iconfont iconfileplus-fill"></span>
                        添加应用
                    </el-button>
                    <el-button type="primary" :size="buttonSize" @click="edit">
                        <span class="icon iconfont iconedit"></span>
                        编辑
                    </el-button>
                    <el-button type="primary" size="mini" @click="importData" ref="data"><span class="icon iconfont iconupload"></span> 导入</el-button>
                    <!-- <el-button type="primary" :size="buttonSize" @click="applyForReview">
                        <span class="icon iconfont iconterminal"></span>
                        申请
                    </el-button> -->
                    <!-- <el-button type="warning" :size="buttonSize" @click="verify">
                        <span class="icon iconfont iconshenpiliuchengguanli"></span>
                        审核
                    </el-button> -->
                    <!-- <el-button type="success" :size="buttonSize" @click="applicationReport">
                        <span class="icon iconfont iconcloudupload"></span>
                        上报
                    </el-button> -->
                    <!-- <el-button type="primary" :size="buttonSize" @click="reviewRollback">
                        <span class="icon iconfont iconundo"></span>
                        重置
                    </el-button> -->
                    <el-button type="danger" :size="buttonSize" @click="handleDelete">
                        <span class="icon iconfont icondelete-fill"></span>
                        删除
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                tooltip-effect="light"
                :height="tableHeight"
                v-loading="loading"
                class="table-top width-ninety"
                @selection-change="handleSelectionChange"
                stripe
                :data="tableData">
                <el-table-column :model="tableData" fixed="left" type="selection" width="55"></el-table-column>
                <el-table-column
                    prop="name"
                    show-overflow-tooltip
                    min-width="130"
                    :formatter="_.formatEmpty"
                    label="应用名称"></el-table-column>
                <!-- <el-table-column prop="createTime" min-width="140" show-overflow-tooltip label="新增时间">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column> -->

                <el-table-column
                    prop="applicationsTypeName"
                    min-width="100"
                    show-overflow-tooltip
                    :formatter="_.formatEmpty"
                    label="应用类型"></el-table-column>
                <el-table-column
                    prop="ip"
                    min-width="120"
                    show-overflow-tooltip
                    :formatter="_.formatEmpty"
                    label="IP"></el-table-column>
                <el-table-column
                    prop="port"
                    min-width="100"
                    show-overflow-tooltip
                    :formatter="_.formatEmpty"
                    label="端口"></el-table-column>
                <el-table-column prop="createTime" min-width="140" show-overflow-tooltip label="创建时间">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orgName"
                    min-width="100"
                    show-overflow-tooltip
                    :formatter="_.formatEmpty"
                    label="所属单位"></el-table-column>
                <el-table-column
                    prop="level"
                    width="80"
                    show-overflow-tooltip
                    :formatter="_.formatEmpty"
                    label="密评级别">
                    <template slot-scope="scope">
                        {{ levels[scope.row.level].label }}
                    </template>
                </el-table-column>

                <!-- <el-table-column prop="runTime" min-width="140" label="投入时间">
                    <template slot-scope="scope">
                        {{ scope.row.runTime | formatDate }}
                    </template>
                </el-table-column> -->

                <!-- <el-table-column
                    :formatter="_.formatEmpty"
                    prop="evaluatedStates"
                    width="80"
                    show-overflow-tooltip
                    label="密评状态">
                    <template slot-scope="scope">
                        {{ stateOptions[scope.row.evaluatedStates].label }}
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                    :formatter="_.formatEmpty"
                    prop="evaluatedOrgName"
                    width="120"
                    show-overflow-tooltip
                    label="评估机构"></el-table-column> -->
                <!-- <el-table-column prop="evaluatedTime" min-width="140" label="评估时间">
                    <template slot-scope="scope">
                        {{ scope.row.evaluatedTime | formatDate }}
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                    :formatter="_.formatEmpty"
                    prop="auditStates"
                    width="80"
                    show-overflow-tooltip
                    label="审核状态">
                    <template slot-scope="scope">
                        <el-tag :type="evaluatedOptions[scope.row.auditStates].tag">
                            {{ evaluatedOptions[scope.row.auditStates].label }}
                        </el-tag>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column prop="auditStates" width="80" show-overflow-tooltip label="是否上报">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isReport == 1 ? '' : 'info'">
                            {{ scope.row.isReport == 1 ? '是' : '否' }}
                        </el-tag>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column prop="isSynchronous" width="80" show-overflow-tooltip label="是否同步">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isSynchronous == 1 ? '' : 'info'">
                            {{ scope.row.isSynchronous == 1 ? '是' : '否' }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="isSynchronous" width="80" show-overflow-tooltip label="来自下级">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.platformId ? '' : 'info'">
                            {{ scope.row.platformId ? '是' : '否' }}
                        </el-tag>
                    </template>
                </el-table-column> -->

                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="setDevice(scope.row)" size="mini">
                            <span class="icon iconfont iconbox"></span>
                            配置设备
                        </el-button>
                        <el-button type="primary" @click="read(scope.row)" size="mini">
                            <span class="icon iconfont iconeye"></span>
                            查看
                        </el-button>
<!--                        <el-button type="primary" @click="handleLog(scope.row)" size="mini">-->
<!--                            <span class="icon iconfont iconlayer-group"></span>-->
<!--                            日志-->
<!--                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <el-row class="paging-row width-ninety">
                <el-col :span="24">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 15, 20, 30, 40, 50]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-size="pageSize"
                        :current-page="pageNo"
                        :total="total"></el-pagination>
                </el-col>
            </el-row>
        </el-card>
        <!--应用信息编辑-->
        <el-dialog
            :title="isAdd ? '添加应用信息' : '编辑应用信息'"
            :close-on-click-modal="false"
            :visible.sync="dialogEditmodelFormVisible"
            @close="closeDialog"
            width="40%"
            custom-class="mid-dialog-min-width app-dialog">
            <el-form :model="modelForm" ref="modelForm">
                <el-form-item
                    label="应用名称"
                    prop="name"
                    :label-width="formLabelWidth"
                    :rules="{
                        required: true,
                        message: '应用名称不能为空',
                        trigger: 'blur'
                    }">
                    <el-input
                        v-model="modelForm.name"
                        auto-complete="off"
                        :size="inputSize"
                        class="dialog-input-width"></el-input>
                </el-form-item>
                <el-form-item
                    label="密评级别"
                    prop="level"
                    :label-width="formLabelWidth"
                    :rules="{
                        required: true,
                        message: '请选择密评级别',
                        trigger: 'blur'
                    }">
                    <el-select v-model="modelForm.level" placeholder="请选择" style="width: 80%">
                        <el-option
                            v-for="item in levels"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="IP"
                    prop="ip"
                    :label-width="formLabelWidth"
                    :rules="{
                        required: true,
                        message: '请输入 IP',
                        trigger: 'blur'
                    }">
                    <el-input class="dialog-input-width" v-model="modelForm.ip" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item
                    label="端口"
                    prop="port"
                    :label-width="formLabelWidth"
                    :rules="{
                        required: true,
                        message: '请输入端口',
                        trigger: 'change'
                    }">
                    <el-input-number
                        style="width: 80%"
                        v-model="modelForm.port"
                        controls-position="right"
                        :min="1"
                        :max="65535"></el-input-number>
                </el-form-item>
                <el-form-item
                    label="应用类型"
                    prop="applicationsTypeId"
                    :label-width="formLabelWidth"
                    :rules="{
                        required: true,
                        message: '应用类型不能为空',
                        trigger: 'blur'
                    }">
                    <el-select v-model="modelForm.applicationsTypeId" placeholder="请选择" style="width: 80%">
                        <el-option
                            v-for="item in types"
                            :key="item.typeId"
                            :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="所属单位"
                    prop="orgId"
                    :label-width="formLabelWidth"
                    :rules="{ required: true, message: '请选择设备所属单位', trigger: 'blur' }">
                    <el-select
                        v-model="modelForm.orgId"
                        placeholder="请选择"
                        popper-class="tree-select"
                        ref="selectReport"
                        style="width: 80%">
                        <el-option :value="modelForm.orgId" :label="modelForm.orgName">
                            <el-tree :data="organization" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="简介" prop="description" :label-width="formLabelWidth">
                    <el-input
                        type="textarea"
                        class="dialog-input-width"
                        rows="6"
                        v-model="modelForm.description"
                        :size="inputSize"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel" class="black-button" :size="buttonSize">取 消</el-button>
                <el-button type="primary" @click="save('modelForm', 0)" :size="buttonSize">保存</el-button>
                <el-button type="success" v-if="isAdd" :size="buttonSize" @click="save('modelForm', 1)">
                    保存并继续
                </el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="查看应用信息"
            :close-on-click-modal="false"
            :visible.sync="dialogShowmodelFormVisible"
            width="80%"
            @close="showAppClose"
            custom-class="big-dialog-min-width">
            <el-form>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="应用名称" :label-width="formLabelWidth">
                        {{ showForm.name }}
                    </el-form-item>
                    <el-form-item label="IP" :label-width="formLabelWidth">
                        {{ showForm.ip == 0 ? '-' : showForm.ip }}
                    </el-form-item>
                    <el-form-item label="应用类型" :label-width="formLabelWidth">
                        {{ showForm.applicationsTypeName }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="密评级别" :label-width="formLabelWidth">
                        {{ levels[showForm.level] && levels[showForm.level].label }}
                    </el-form-item>
                    <el-form-item label="端口" :label-width="formLabelWidth">
                        {{ showForm.port == 0 ? '-' : showForm.port }}
                    </el-form-item>
                    <el-form-item label="所属单位" :label-width="formLabelWidth">
                        {{ showForm.orgName }}
                    </el-form-item>
                </el-col>

                    <el-form-item label="简介" prop="description" :label-width="formLabelWidth">
                        {{ showForm.description || '无' }}
                    </el-form-item>

            </el-row>
            </el-form>
                <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane label="密码合规告警" name="1"></el-tab-pane>
                    <el-tab-pane label="密码安全告警" name="2"></el-tab-pane>
                    <el-tab-pane label="网络威胁告警" name="3"></el-tab-pane>
                </el-tabs>
                <compliance :application-id="applicationId" :warn-type="warnType"></compliance>
<!--                <compliance :application-id="applicationId" :warn-type="1"></compliance>-->
<!--                <compliance :application-id="applicationId" :warn-type="1"></compliance>-->
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogShowmodelFormVisible = false" class="black-button" :size="buttonSize">
                    关闭
                </el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="应用类型管理"
            :close-on-click-modal="false"
            @close="typeDialogClose"
            :visible.sync="dialogTypeModelFormVisible"
            width="80%"
            custom-class="big-dialog-min-width">
            <device-type-dialog></device-type-dialog>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTypeModelFormVisible = false" class="black-button" :size="buttonSize">
                    关闭
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="配置设备"
            :close-on-click-modal="false"
            @close="deviceDialogClose"
            :visible.sync="deviceDialogVisible"
            width="50%"
            custom-class="mid-dialog-min-width">
            <device-dialog :id="id" v-if="deviceDialogVisible"></device-dialog>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deviceDialogVisible = false" class="black-button" :size="buttonSize">关闭</el-button>
            </div>
        </el-dialog>

        <el-dialog
            @close="closeVerifytDialog"
            title="审核"
            :visible.sync="dialogVisible"
            width="30%"
            custom-class="small-dialog-min-width">
            <el-form :model="auditForm" ref="auditForm">
                <el-form-item
                    label="审核状态"
                    prop="auditStates"
                    :rules="[
                        {
                            required: true,
                            message: '请选择审核状态',
                            trigger: 'blur'
                        }
                    ]">
                    <el-radio-group v-model="auditForm.auditStates">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="-1">不通过</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" class="black-button">取 消</el-button>
                <el-button type="primary" @click="handleVerify">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="日志"
            :close-on-click-modal="false"
            :visible.sync="logDialogVisible"
            width="80%"
            custom-class="big-dialog-min-width">
            <log-dialog :id="id" v-if="logDialogVisible"></log-dialog>
            <div slot="footer" class="dialog-footer">
                <el-button @click="logDialogVisible = false" class="black-button" :size="buttonSize">关闭</el-button>
            </div>
        </el-dialog>

        <el-dialog title="导入应用信息" @close="closeImportDialog" :close-on-click-modal="false" :visible.sync="dialogImportVisible" width="20%" custom-class="small-dialog-min-width">
            <el-form :model="importForm" ref="importForm">
                <el-form-item label="模板下载" prop="templateFile"
                              :label-width="formLabelWidth">
                    <a :href="fileServer+'upload/apptemplate.xlsx'" target="_blank">点击下载</a>
                </el-form-item>
                <el-form-item label="导入文件" prop="upFile"
                              :label-width="formLabelWidth"
                              :rules="{required: true, message: '导入文件不能为空', trigger: 'blur'}">
                    <el-upload
                            ref="importUpload"
                            :action="importUrl"
                            :file-list="importFileList"
                            :on-success="handleImportSuccess"
                            :on-error="handleImportError"
                            :headers="headers"
                            :on-remove="handleImportRemove"
                            :on-change="handleImportChange"
                            :before-upload="handleImportBefore"
                            accept=".xls,.xlsx"
                            :limit="1"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传.xls，.xlsx文件，且不超过5M</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogImportVisible = false" class="black-button" :size="buttonSize">取 消</el-button>
                <el-button type="primary" @click="importModel('importForm')" :size="buttonSize">导 入</el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
import { formatDate } from '../../../assets/js/date'
import DeviceDialog from './component/deviceDialog'
import DeviceTypeDialog from './component/devicetypedialog'
import LogDialog from './component/logDialog'
import Compliance from './warn/compliance'
export default {
    name: 'DevicesManage',
    components: { DeviceDialog, DeviceTypeDialog, LogDialog,Compliance },
    data() {
        return {
            fileServer: HOSTCONFIG.FILESERVER,
            tableHeight: window.innerHeight - 430,
            tableData: [],
            allTableData: [],
            modelForm: {
                // id: '',
                // name: '',
                // description: '',
                // level: 0,
                // runTime: '',
                // evaluatedStates: '',
                // evaluatedOrgName: '',
                // evaluatedTime: '',
                // applicationsTypeId: '',
                // applicationArithmeticIds: '',
                // applicationCryptoDeviceIds: ''
                name: '',
                description: '',
                level: '',
                ip: '',
                port: '',
                modifyTime: '',
                applicationsTypeId: '',
                applicationCryptoDeviceIds: [],
                orgId: ''
            },
            searchForm: {
                name: '',
                ip: '',
                port: null,
                typeId: '',
                orgName: '',
                orgId: '',
                pageNo: 1,
                pageSize: 15
            },
            total: 0,
            pageNo: 1,
            pageSize: 15,
            loading: false,
            types: [],
            //查找
            inputSize: INPUTSIZE, //增加数据输入框大小
            buttonSize: BUTTONSIZE, // 按钮大小
            formLabelWidth: '140px', //增加数据输入框大小
            dialogEditmodelFormVisible: false, //用户对话框显示隐藏
            dialogTypeModelFormVisible: false,
            isAdd: false,
            isEditRegion: true,
            ids: [], //删除数据
            dialogReadmodelFormVisible: false,
            selectModels: [],
            dialogTypeApiModelFormVisible: false,
            dialogShowmodelFormVisible: false,
            showForm: {
                evaluatedStates: null,
                level: null
            },
            importFileList: [],
            headers: {},
            importForm: {
                upFile: ''
            },
            importUrl: HOSTCONFIG.RESTHOST + this.$api.adminApi.importApplicationFromExcel,
            organization: [],
            defaultProps: {
                label: 'label',
                children: 'children'
            },
            dialogImportVisible: false,
            resetUpload: false,
            regionProps: {
                lazy: true,
                lazyLoad: this.lazyLoad
            },
            levels: [
                { value: 0, label: '暂未定级' },
                { value: 1, label: '第一级' },
                { value: 2, label: '第二级' },
                { value: 3, label: '第三级' },
                { value: 4, label: '第四级' }
            ],
            stateOptions: [
                { value: 0, label: '未评估' },
                { value: 1, label: '评估中' },
                { value: 2, label: '已评估' }
            ],
            evaluatedOptions: {
                0: { value: 0, label: '未审核', tag: 'info' },
                1: { value: 1, label: '审核中', tag: '' },
                2: { value: 2, label: '已通过', tag: 'success' },
                '-1': { value: 0, label: '未通过', tag: 'danger' }
            },
            allArithmetic: [],
            props: {
                multiple: true,
                value: 'id',
                children: 'list',
                label: 'name',
                emitPath: false
            },
            auditForm: {
                auditStates: null
            },
            dialogVisible: false,
            logDialogVisible: false,
            id: null,
            deviceDialogVisible: false,
            applicationId: null,
            activeName: "1",
            warnType: null
        }
    },
    created() {},
    computed: {},
    mounted: function () {
        this.findByPage()
        this.findAllTypes()
        this.findAllArithmetic()
        this.getHeaders()
        this.findAllOrganization()
    },
    filters: {
        formatDate(date) {
            if (!date) return '-'
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
        }
    },
    methods: {
        editRegion() {
            this.isEditRegion = true
            this.modelForm.regionId = ''
        },
        regionChange(value) {
            if (value && value.length > 0) {
                this.modelForm.regionId = value[value.length - 1]
            } else {
                this.modelForm.regionId = 0
            }
        },
        lazyLoad(node, resolve) {
            const { level } = node
            const { value } = node
            let self = this
            let parentId = value ? value : 0
            self.$http.post({
                url: self.$api.adminApi.findRegion,
                params: { parentId: parentId },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        const nodes = Array.from(data.result).map((item) => ({
                            value: item.value,
                            label: item.label,
                            leaf: level >= 2
                        }))
                        resolve(nodes)
                    }
                }
            })
        },
        handleNodeClick(data) {
            this.modelForm.orgId = data.id
            this.modelForm.orgName = data.label
            this.$refs.selectReport.blur()
        },
        handleSearchNodeClick(data) {
            this.searchForm.orgId = data.id
            this.searchForm.orgName = data.label
            this.$refs.selectSearchReport.blur()
        },
        searchOrgClear(){
            this.searchForm.orgId = null
            this.searchForm.orgName = null
        },
        findAllOrganization() {
            let self = this
            self.$http.post({
                url: self.$api.adminApi.findAllOrganization,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.organization = data.result
                    }
                }
            })
        },
        getHeaders() {
            let Token = this.$cookies.get(TOKENNAME) == null ? '' : this.$cookies.get(TOKENNAME)
            this.headers = {
                'x-authorization': Token
            }
        },
        closeImportDialog() {
            this.$refs['importForm'].resetFields()
            this.importFileList = []
            this.findByPage()
        },
        handleImportBefore(file) {
            //const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isLt2M) {
                this.$message.error('导入文件大小不能超过 10MB!')
                this.$refs['importForm'].resetFields()
                this.importFileList = []
                if (this.loadingInstance) {
                    this.loadingInstance.close()
                    this.loadingInstance = null
                }
            }
            return isLt2M
        },
        handleImportSuccess(response, file, fileList) {
            let self = this
            if (self.loadingInstance) {
                self.loadingInstance.close()
                self.loadingInstance = null
            }
            if (response.error == 0) {
                self.$alert('导入成功', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'success',
                    callback: (action) => {
                        self.dialogImportVisible = false
                    }
                })
            } else {
                self.$alert(response.msg, '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                self.$refs['importForm'].resetFields()
                self.importFileList = []
                self.resetUpload = true
            }
        },
        handleImportError(err, file, fileList) {
            if (this.loadingInstance) {
                this.loadingInstance.close()
                this.loadingInstance = null
            }
            this.$alert('导入文件失败：' + err, '提示', {
                confirmButtonText: '确定',
                showClose: false,
                type: 'error'
            })
            return
        },
        handleImportRemove(file, fileList) {
            if (fileList.length == 0) {
                this.importForm.upFile = null
            }
        },
        handleImportChange(file, fileList) {
            if (fileList.length == 0) {
                this.importForm.upFile = null
            } else {
                this.importForm.upFile = file
            }
            if (this.resetUpload) {
                this.importForm.upFile = null
                this.resetUpload = false
            }
        },
        importModel(formName) {
            let self = this
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.loadingInstance = self.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.5)'
                    })
                    self.$refs.importUpload.submit()
                } else {
                    return false
                }
            })
        },
        importData() {
            this.dialogImportVisible = true
        },
        doEnabled() {
            let self = this
            if (self.ids.length <= 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                self.$http.post({
                    url: self.$api.deviceApi.updateDeviceToEnabled,
                    params: { ids: self.ids },
                    callback: {
                        success: (data) => {
                            self.findByPage()
                        }
                    }
                })
            }
        },
        doDisabled() {
            let self = this
            if (self.ids.length <= 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                self.$confirm('此操作将禁用该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'black-button',
                    type: 'warning'
                })
                    .then(() => {
                        self.$http.post({
                            url: self.$api.deviceApi.updateDeviceToDisabled,
                            params: { ids: self.ids },
                            callback: {
                                success: (data) => {
                                    self.findByPage()
                                }
                            }
                        })
                    })
                    .catch(() => {})
            }
        },
        findAllArithmetic() {
            let self = this
            self.$http.post({
                url: self.$api.applicationApi.findAllArithmetic,
                // params: { ids: self.ids },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.allArithmetic = data.result
                    }
                }
            })
        },
        findAllTypes() {
            let self = this
            self.$http.post({
                url: self.$api.applicationApi.getAllApplicationsType,
                // params: { ids: self.ids },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.types = data.result
                    }
                }
            })
        },
        typeDialogClose() {
            this.findAllTypes()
        },
        handleSizeChange(val) {
            this.pageNo = 1
            this.pageSize = val
            this.findByPage()
        },
        handleCurrentChange(val) {
            this.pageNo = val
            this.findByPage()
        },

        select() {
            this.pageNo = 1
            this.findByPage()
        },
        findByPage() {
            let self = this
            self.searchForm.pageNo = self.pageNo - 1
            self.searchForm.pageSize = self.pageSize
            self.searchForm.port = self.searchForm.port === "" ? null : Number.parseInt(self.searchForm.port)
            self.loading = true
            self.$http.post({
                url: self.$api.applicationApi.adminFindApplicationsByPage,
                params: self.searchForm,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.loading = false
                        self.tableData = data.result.content
                        //console.log(self.tableData);
                        self.total = data.result.totalElements
                    },
                    error: (data) => {
                        self.loading = false
                    },
                    fail: (res) => {
                        self.loading = false
                    }
                }
            })
        },
        //编辑
        edit() {
            let self = this
            if (self.selectModels.length !== 1) {
                self.$alert('需要且只能选择一条数据进行此操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                this.modelForm = self.selectModels[0]
                this.isAdd = false
                this.isEditRegion = false
                this.dialogEditmodelFormVisible = true
            }
        },
        read(row) {
            //查看
            this.showForm = row
            this.activeName = "1";
            this.warnType = 1;
            this.applicationId = row.id;
            this.dialogShowmodelFormVisible = true
        },
        showAppClose(){
            this.warnType = null;
        },
        handleClick(tab){
          this.warnType = Number.parseInt(tab.name);
        },
        //编辑列表
        save: function (formName, val) {
            let self = this
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.doSaveModel(val)
                } else {
                    return false
                }
            })
        },
        clearForm() {
            this.modelForm = {
                id: '',
                deviceName: '',
                deviceTypeId: '',
                deviceSerialNumber: '',
                functionDesc: '',
                deviceMac: '',
                deviceIp: '',
                deviceModelNumber: '',
                featureCode: '',
                manufacturer: '',
                address: '',
                regionId: 0,
                regionFullName: '',
                organizationId: '',
                organizationName: ''
            }
            this.$refs['modelForm'].resetFields()
        },
        doSaveModel(val) {
            let self = this
            // self.modelForm.region = null
            self.$http.post({
                url: self.$api.applicationApi.saveApplicationEvaluate,
                params: self.modelForm,
                callback: {
                    success: (data) => {
                        if (val === 0) {
                            self.findByPage()
                            self.dialogEditmodelFormVisible = false
                        } else {
                            self.clearForm()
                        }
                    }
                }
            })
        },
        // 删
        del() {
            let self = this
            if (self.ids == null || self.ids.length == 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            self.$confirm('此操作将永久删除您选择的数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    let ids = self.ids
                    self.$http.post({
                        url: self.$api.deviceApi.deleteDevices,
                        params: { ids: ids },
                        callback: {
                            success: (data) => {
                                self.findByPage()
                            }
                        }
                    })
                })
                .catch(() => {})
        },
        // 增
        add() {
            this.modelForm = {
                id: '',
                deviceName: '',
                deviceTypeId: '',
                deviceSerialNumber: '',
                functionDesc: '',
                deviceMac: '',
                deviceIp: '',
                deviceModelNumber: '',
                featureCode: '',
                manufacturer: '',
                address: '',
                regionId: 0,
                organizationId: '',
                organizationName: '',
                regionFullName: ''
            }
            this.isAdd = true
            this.dialogEditmodelFormVisible = true
        },
        // 查
        select: function () {
            this.pageNo = 1
            this.findByPage()
        },
        //取消按钮
        cancel() {
            this.dialogEditmodelFormVisible = false
        },
        //提供删除
        handleSelectionChange(val) {
            let self = this
            self.selectModels = val
            self.ids = []
            for (let i = 0; i < val.length; i++) {
                self.ids.push(val[i].id)
            }
        },
        closeDialog() {
            this.$refs['modelForm'].resetFields()
            this.findByPage()
        },
        applyForReview() {
            if (this.ids.length <= 0) {
                this.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else if (this.ids.length > 1) {
                this.$alert('只能选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                this.$confirm('确定申请审核?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'black-button',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http.post({
                            url: this.$api.applicationApi.applyForReview,
                            params: { id: this.ids[0] },
                            callback: {
                                success: (data) => {
                                    this.findByPage()
                                }
                            }
                        })
                    })
                    .catch(() => {})
            }
        },
        verify() {
            if (this.ids.length <= 0) {
                this.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else if (this.ids.length > 1) {
                this.$alert('只能选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                this.dialogVisible = true
            }
        },
        closeVerifytDialog() {
            this.auditForm.auditStates = null
            this.$refs.auditForm.resetFields()
            this.$refs.auditForm.clearValidate()
        },
        handleVerify() {
            this.$refs.auditForm.validate((valid) => {
                if (valid) {
                    this.$http.post({
                        url: this.$api.applicationApi.verify,
                        params: {
                            id: this.ids[0],
                            auditStates: this.auditForm.auditStates
                        },
                        callback: {
                            success: (data) => {
                                this.dialogVisible = false
                                this.findByPage()
                            }
                        }
                    })
                } else {
                    return false
                }
            })
        },
        applicationReport() {
            let self = this
            if (self.ids.length <= 0) {
                self.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                this.$confirm('确定进行上报?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'black-button',
                    type: 'warning'
                })
                    .then(() => {
                        self.$http.post({
                            url: self.$api.applicationApi.applicationReport,
                            params: { ids: self.ids },
                            callback: {
                                success: (data) => {
                                    self.findByPage()
                                }
                            }
                        })
                    })
                    .catch(() => {})
            }
        },
        handleLog(row) {
            this.id = row.id
            this.logDialogVisible = true
        },
        deviceDialogClose() {
            this.findByPage()
        },
        setDevice(row) {
            this.id = row.id
            this.deviceDialogVisible = true
        },
        reviewRollback() {
            if (this.ids.length <= 0) {
                this.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else if (this.ids.length > 1) {
                this.$alert('只能选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                this.$confirm('确定重置状态?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'black-button',
                    type: 'warning'
                })
                    .then(() => {
                        let ids = this.ids
                        this.$http.post({
                            url: this.$api.applicationApi.reviewRollback,
                            params: { id: ids[0] },
                            callback: {
                                success: (data) => {
                                    this.findByPage()
                                }
                            }
                        })
                    })
                    .catch(() => {})
            }
        },
        handleDelete() {
            if (this.ids.length <= 0) {
                this.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                this.$confirm('此操作将永久删除您选择的数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'black-button',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http.post({
                            url: this.$api.applicationApi.adminDelApplications,
                            params: { ids: this.ids },
                            callback: {
                                success: (data) => {
                                    this.findByPage()
                                }
                            }
                        })
                    })
                    .catch(() => {})
            }
        }
    }
}
</script>

<style scoped>
.input-width {
    width: 300px;
}
.mar-right {
    margin-right: 35px;
}
.long-bt {
    width: 120px;
}
.mar-top {
    margin-top: 30px;
}

.label-text {
    color: #606266;
    line-height: 40px;
    display: inline-block;
    padding: 0 0 10px;
    font-size: 14px;
}
aa {
    background: #2dcb73;
}
a {
    color: #fff;
}
</style>
<style lang="scss">
.app-dialog {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }
}
</style>
