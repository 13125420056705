<template>
  <el-main style="min-width: 1200px">
    <el-card shadow="never" class="no-x-scroll">
      <div slot="header" class="clearfix">
        <el-row class="operation-button">
          <el-col>
            <span class="card-header-title">网络信息</span>
          </el-col>
        </el-row>
      </div>
      <el-table
              tooltip-effect="dark"
              class="table-top width-ninety"
              :highlight-current-row = "true"
              :stripe="true"
              :height="tableHeight"
              :data="nicDatas">
        <el-table-column
                prop="name"
                width="100"
                label="状态">
          <template slot-scope="scope">
            <el-tooltip effect="light" class="item" :content="scope.row.status === 0?'断开':'正常'" placement="top">
              <i class="iconfont big-icon iconWIFI color-grey" v-if="scope.row.status === 0"></i>
              <i class="iconfont big-icon iconWIFI color-green" v-if="scope.row.status === 1"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
                prop="name"
                width="150"
                label="网口名称">
          <template slot-scope="scope">
            {{scope.row.isManage === 1?'管理口':scope.row.name}}
          </template>
        </el-table-column>
        <el-table-column
                prop="isDhcp"
                min-width="180"
                label="DHCP客户端">
          <template slot-scope="scope">
            <el-tag effect="dark" :type="scope.row.isDhcp === 1?'success':''">{{scope.row.isDhcp === 1?'开启':'关闭'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
                prop="mac"
                min-width="180"
                label="MAC">
        </el-table-column>
        <el-table-column
                prop="address"
                min-width="200"
                label="IP地址">
          <template slot-scope="scope">
            {{scope.row.addresses?scope.row.addresses[0]?scope.row.addresses[0]:'-':'-'}}
          </template>
        </el-table-column>
        <el-table-column
                prop="gateway4"
                min-width="180"
                label="网关">
          <template slot-scope="scope">
            {{scope.row.gateway4?scope.row.gateway4:'-'}}
          </template>
        </el-table-column>
        <!--                        <el-table-column-->
        <!--                                prop="nameservers"-->
        <!--                                width="300"-->
        <!--                                label="DNS">-->
        <!--                            <template slot-scope="scope">-->
        <!--                               {{scope.row.nameservers?scope.row.nameservers.toString():'-'}}-->
        <!--                            </template>-->
        <!--                        </el-table-column>-->
        <!--                        <el-table-column-->
        <!--                                prop="status"-->
        <!--                                min-width="80"-->
        <!--                                label="状态">-->
        <!--                            <template slot-scope="scope">-->
        <!--                                <el-tag :type="scope.row.status === 1?'success':'info'">{{scope.row.status === 1?'正常':'断开'}}</el-tag>-->
        <!--                            </template>-->
        <!--                        </el-table-column>-->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button :disabled="scope.row.isManage == 1" type="text" @click="modifyNic(scope.row)">
              <span class="icon iconfont icon-edit"></span>
              修改
            </el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <!--修改网卡信息-->
<!--    <el-dialog-->
<!--            title="修改网卡信息"-->
<!--            :close-on-click-modal="false"-->
<!--            :visible.sync="dialogEditNicFormVisible"-->
<!--            width="20%"-->
<!--            @close="dialogEditNicFormClose"-->
<!--            custom-class="small-dialog-min-width"-->
<!--    >-->
<!--      <el-form :model="nic" ref="nic" :label-width="formLabelWidth">-->
<!--        <el-form-item-->
<!--                label="网卡名称"-->
<!--                prop="name"-->
<!--        >-->
<!--         {{nic.name}}-->
<!--        </el-form-item>-->
<!--        <el-form-item-->
<!--                label="IP地址"-->
<!--                prop="ip"-->
<!--                :rules="{-->
<!--            required: true,-->
<!--            message: 'ip地址不能为空',-->
<!--            trigger: 'blur',-->
<!--          }"-->
<!--        >-->
<!--          <el-input-->
<!--                  placeholder="示例：192.168.0.2/24"-->
<!--                  v-model="nic.ip"-->
<!--                  auto-complete="off"-->
<!--                  class="dialog-input-width"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item-->
<!--                label="网关地址"-->
<!--                prop="gateway"-->
<!--                :label-width="formLabelWidth"-->
<!--        >-->
<!--          <el-input-->
<!--                  placeholder="示例：192.168.0.1"-->
<!--                  v-model="nic.gateway"-->
<!--                  auto-complete="off"-->
<!--                  class="dialog-input-width"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="dialogEditNicFormVisible=false" class="black-button"-->
<!--        >取 消</el-button-->
<!--        >-->
<!--        <el-button type="primary" @click="saveNic('nic')">保存</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->


    <!--修改网卡信息-->
    <el-dialog
            title="修改网卡信息"
            :close-on-click-modal="false"
            :visible.sync="dialogEditNicFormVisible"
            width="20%"
            @close="dialogEditNicFormClose"
            custom-class="small-dialog-min-width"
    >
      <el-form :model="nic" ref="nic" :label-width="formLabelWidth">
        <el-form-item
                label="网卡名称"
                prop="name"
        >
          {{nic.name}}
        </el-form-item>
        <el-form-item
                label="DHCP客户端"
                prop="isDhcp"
                :rules="{
            required: true,
            message: '请选择是否开启DHCP客户端',
            trigger: ['blur','change'],
          }"
        >

          <el-radio-group v-model="nic.isDhcp" >
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
                v-if="nic.isDhcp === 0"
                label="IP地址"
                prop="ip"
                :rules="{
            required: nic.isDhcp === 0,
            message: 'ip地址不能为空',
            trigger: 'blur',
          }"
        >
          <el-input
                  placeholder="示例：192.168.0.2/24"
                  v-model="nic.ip"
                  auto-complete="off"
                  class="dialog-input-width"
          ></el-input>
        </el-form-item>
        <el-form-item
                v-if="nic.isDhcp === 0"
                label="网关地址"
                prop="gateway"
                :label-width="formLabelWidth"
        >
          <el-input
                  placeholder="示例：192.168.0.1"
                  v-model="nic.gateway"
                  auto-complete="off"
                  class="dialog-input-width"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditNicFormVisible=false" class="black-button"
        >取 消</el-button
        >
        <el-button type="primary" @click="saveNic('nic')">保存</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
//时间格式化
import { formatDate } from "../../../assets/js/date";
import { isTel } from "../../../assets/js/validate";
export default {
  name: "Network",
  data() {
    var validateMobile = (rule, value, callback) => {
      if (value) {
        if (!isTel(value)) {
          return callback(new Error("电话格式错误"));
        }
      }
      callback();
    };
    return {
      nicList:[],
      inputSize: INPUTSIZE,
      buttonSize: BUTTONSIZE,
      formLabelWidth: "120px",
      dialogEditNicFormVisible:false,
      modelForm: {
        companyName: "",
        address: "",
        key: "",
        tel: "",
        license: "",
        isRegister: false,
        status: 1,
        isTrail: 0
      },
      nic:{
        name:"",
        ip:"",
        gateway:"",
        isDhcp:0,
      },
      validateMobile: validateMobile,
      disabled: false,
      isRegister: false,
      nicDatas:[],
      tableHeight: (window.innerHeight -240) + "px",
    };
  },
  created() {
    // this.$emit("message", { isMain: false });
    this.getNicInfo();
  },
  filters: {
    formatDate(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
      //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
    },
  },
  methods: {
    modifyNic(row){
      this.nic.ip = row.addresses?row.addresses[0]:'';
      this.nic.gateway = row.gateway4;
      this.nic.name = row.name;
      this.nic.isDhcp = row.isDhcp;
      this.dialogEditNicFormVisible = true;
    },
    saveNic(formName){
      let self = this;
      self.$refs[formName].validate((valid) => {
        if (valid) {
          this.doSaveNic();
        } else {
          return false;
        }
      });
    },
    doSaveNic(){
      let self = this;
      self.$http.post({
        url: self.$apiUrl.adminApi.saveNicInfo,
        params: self.nic,
        callback: {
          success: (data) => {
            self.dialogEditNicFormVisible = false;
          },
        },
      });
    },
    getNicInfo() {
      let self = this;
      self.$http.post({
        url: self.$apiUrl.adminApi.getNicInfo,
        opt: { isLoading: true, showError: true, showSuccess: false },
        callback: {
          success: (data) => {
            self.nicDatas = data.result;
            self.disabled = true;
          },
        },
      });
    },
    // changeNic(nic){
    //   this.nic.name = nic.name;
    //   this.nic.ip = nic.addresses[0];
    //   this.nic.gateway = nic.gateway4;
    //   this.dialogEditNicFormVisible = true;
    // },
    dialogEditNicFormClose(){
      this.$refs['nic'].resetFields();
      this.nic = {
        name:"",
        ip:"",
        gateway:"",
        isDhcp:0,
      }
      this.getNicInfo();
    }
  },
};
</script>

<style scoped>
.prepare-form {
  margin-top: 20px;
  max-width: 800px;
}
/*.prepare-form .el-form-item--mini.el-form-item {*/
/*  margin-bottom: 34px;*/
/*}*/
.el-date-editor.el-input {
  width: 100%;
}
.el-select.el-select--mini {
  width: 100%;
}
.textSize {
  /* font-weight: 600; */
}
.nic-icon-grey i, .nic-icon-green i{
  font-size: 80px;
}
.color-nic-grey{
  color: #cccccc;
}
.nic-icon-grey{
  border-radius: 50%;
  border: 5px solid #cccccc;
  height: 120px;
  width: 120px;
  text-align: center;
  line-height: 120px;
  padding: 5px 0 0 5px;
}
.nic-icon-green{
  border-radius: 50%;
  border: 5px solid #2b8e61;
  height: 120px;
  width: 120px;
  text-align: center;
  line-height: 120px;
  padding: 5px 0 0 5px;
}
  .color-green{
    color: #2b8e61;
  }
  .nics{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .nics div{
    display: inline-block;
  }
  .nic-name{
    padding:10px;
    color: #333;
    font-size: 18px;
  }
.nic-ip{
  padding:0 0 20px 0;
  color: #999;
  font-size: 14px;
}
  .nic-button-icon{
    font-size: 12px;
  }

</style>
<style>
  .el-tooltip__popper.is-dark{
    color: #1c8af4;
  }
</style>