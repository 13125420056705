<template>
    <el-header class="header" style="height:50px;min-width: 1400px">
        <el-row class="header-row">
            <el-col :span="5" class="float-left">
                <img v-if="!isOem" src="./../assets/images/login-logo-hd.png" height="45" class="logo-image"/>
                <span class="app-title" v-if="isOem">{{env.VUE_APP_TITLE}}</span>
            </el-col>
            <el-col :span="10" class="float-right text-right">
                <span class="nav-space">欢迎您，{{groupName}}：{{username}} [{{name}}]</span> &nbsp;|
                <span @click="goIndex" class="nav nav-space pointer">返回首页</span> &nbsp;|
                <span @click="dialogUpdatePasswordFormVisible = true" class="nav nav-space pointer">修改密码</span> &nbsp;|
                <span @click="dialogUpdateuserNsmeVisible = true" class="nav nav-space pointer">用户信息</span> &nbsp;|
                <span class="pointer nav-space nav" @click="logout">【退出】</span>
            </el-col>
        </el-row>

        <!--修改密码-->
        <el-dialog title="修改密码" :visible.sync="dialogUpdatePasswordFormVisible" width="500px" :close-on-click-modal="false" @close="resetForm('passForm')">
            <el-form :model="passForm" ref="passForm">
                <el-form-item label="原密码：" prop="oldPassword"
                              :rules="[{required: true, message: '请输入原密码', trigger: 'blur'}]"
                              :label-width="formLabelWidth">
                    <el-input v-model="passForm.oldPassword" class="input-width" type="password" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="新密码：" prop="password"
                              :rules="[{required: true, message: '请输入新密码', trigger: 'blur'},{ min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }]"
                              :label-width="formLabelWidth">
                    <el-input v-model="passForm.password" class="input-width" type="password" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="确认密码：" prop="cPassword"
                              :rules="[{required: true, message: '请再次输入新密码', trigger: 'blur'},checkCpas]"
                              :label-width="formLabelWidth">
                    <el-input v-model="passForm.cPassword" class="input-width" type="password" :size="inputSize"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="black-button" @click="dialogUpdatePasswordFormVisible=false" :size="buttonSize">取 消</el-button>
                <el-button type="primary" :size="buttonSize" :loading="passLoading"  @click="goUpdatePass('passForm')">保存</el-button>
            </div>
        </el-dialog>
        <!--修改用户-->
        <el-dialog title="修改用户" :visible.sync="dialogUpdateuserNsmeVisible" width="500px" @close="userFormDialogClose" :close-on-click-modal="false">
            <el-form :model="userForm" ref="userForm">
                <el-form-item label="用户名：" prop="username" :label-width="formLabelWidth">
                    {{userForm.username}}
                </el-form-item>
                <el-form-item label="真实姓名：" prop="realname"
                              :rules="[{required: true, message: '请输入真实姓名', trigger: 'blur'},
                              {validator:realnameValidator, trigger: 'blur'}]"
                              :label-width="formLabelWidth">
                    <el-input v-model="userForm.realname" class="input-width" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="邮箱地址：" prop="email"
                              :rules="[{required: true, message: '请输入您的常用邮箱', trigger: 'blur'},
                              {validator:emailValidator, trigger: 'blur'}]"
                              :label-width="formLabelWidth">
                    <el-input v-model="userForm.email" class="input-width" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="电话号码：" prop="tel"
                              :rules="[{required: true, message: '请输入电话号码', trigger: 'blur'},
                              {validator:mobileValidator, trigger: 'blur'}]"
                              :label-width="formLabelWidth">
                    <el-input v-model="userForm.tel" class="input-width" :size="inputSize"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="black-button" @click="dialogUpdateuserNsmeVisible=false" :size="buttonSize">取 消</el-button>
                <el-button type="primary" :size="buttonSize" :loading="infoLoading" @click="modifyUserInfo('userForm')">保存</el-button>
            </div>
        </el-dialog>
<!--        <audio :src="mp3Host" style="display: none" id="orderAudio"></audio>-->

    </el-header>
</template>

<script>
    import ElHeader from "element-ui/packages/header/src/main.vue";
    import ElRow from "element-ui/packages/row/src/row";
    import Bus from '../assets/js/eventBus';
    import {Message} from "element-ui";
    export default {
        components: {
            ElRow,
            ElHeader},
        name: 'Header',
        data () {
            let validatePass2 = (rule, value, callback) => {
                if (value !== this.passForm.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            let validatePass3 = (rule, value, callback) => {
                if (value !== this.userForm.realname) {
                    callback(new Error('两次输入用户名不一致!'));
                } else {
                    callback();
                }
            };
            // 验证邮箱的校验规则
            let checkEmail = (rule, value, callback) => {
                // 验证邮箱的正则表达式
                const regEmail =  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

                if (regEmail.test(value)) {
                    // 合法的邮箱
                    return callback()
                }
                // 不合法的邮箱
                callback(new Error('请输入正确的邮箱地址'))
            };
            //判断中文
            let checkRealName = (rule, value, callback) => {
                if(!value || value ==null || value == ""){
                    return callback(new Error('真实姓名不能为空'))
                }
                var reg = /^[\u4E00-\u9FA5a-zA-Z.·]+$/;
                if(!reg.test(value)){
                    return callback(new Error('只允许使用汉字、字母或者"."作为真实姓名'))
                }
                if(value.length < 2 || value.length > 30){
                    return callback(new Error('真实姓名长度为2-30个字符'))
                }else{
                    return callback()
                }
            };
            // 验证手机号的校验规则
            let checkMobile = (rule, value, callback) => {
                // 验证手机号的正则表达式
                const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;

                if (regMobile.test(value)) {
                    // 合法的手机号
                    return callback()
                }
                // 不合法
                callback(new Error('请输入正确的手机号码'))
            };
            return {
                env: process.env,
                isOem: IS_OEM,
                //mp3Host:MusicHost+"upload/xdd.mp3",
                mobileValidator:checkMobile,
                emailValidator:checkEmail,
                realnameValidator:checkRealName,
                formLabelWidth:'140px',
                inputSize:INPUTSIZE,
                buttonSize:BUTTONSIZE,
                name:'',
                dialogUpdatePasswordFormVisible:false,dialogUpdateuserNsmeVisible:false,
                passForm:{
                    oldPassword:"",
                    password:"",
                    cPassword:""
                },
                userForm:{},
                checkCpas:{ validator: validatePass2, trigger: 'blur' },
                checkCuess:{ validator: validatePass3, trigger: 'blur' },
                websocket:null,
                orderNum:0,
                isHaveNewOrder:false,
                audio:null,
                showBadge:false,
                type:'',
                hasGotInfo:false,
                isSendType:false,
                typename:'',
                username:"",
                leftMenuReady:false,
                groupName:'',
                passLoading:false,
                infoLoading:false,
                path:HOSTCONFIG.WSSERVER + "statics/tunnel",
                socket:"",
                isEndSocket:false,
                heartBeat:null,
                isNotLogin:false,
                token:null,
                showConnectError:true,
                reconnectCount:0,
                clickNotify:null,
                useUkey: USE_UKEY
            }
        },
        created(){
            this.getSytemAdminName();
        },
        mounted(){
            // this.checkUkeyIsReady();
            //this.onOrderWebSocket();
            //this.getAudio();
            let self = this;
            Bus.$on("leftPageReadyEvent",function(msg){
                self.leftMenuReady = true;
                self.sendTypeMsg(self.type);
            });
            this.token = this.$cookies.get(TOKENNAME)==null?"":this.$cookies.get(TOKENNAME);

            if(ISOPENNOTIFY){
                this.init();
            }
        },
        destroyed () {
            // 销毁监听
            if(this.heartBeat){
                clearInterval(this.heartBeat);
            }
            this.isEndSocket = true
            this.close()
        },
        methods: {
            checkUkeyIsReady() {
                let self = this;
                // console.log("abc", this.$cookies.get("USE_UKEY"))
                if(!self.$cookies.get("USE_UKEY")){
                    setTimeout(()=>{
                        self.checkUkeyIsReady();
                    }, 1000)
                    return;
                }
                const token = self.$cookies.get(TOKENNAME);//这里取token之前，你肯定需要先拿到token,存一下
                self.$http.uKeyPost({
                    url: self.$apiUrl.uKeyApi.action,
                    params: {
                        OP: 1
                    },
                    opt: { showError: false, isLoading: false, showSuccess: false },
                    callback: {
                        success: (data) => {
                            // console.log("ukey ready")
                            setTimeout(()=>{
                                self.checkUkeyIsReady();
                            }, 1000)
                        },
                        error: (data) => {
                            if(token.length > 0){
                                self.$cookies.remove(TOKENNAME)
                                self.$cookies.remove("USE_UKEY")
                                self.$router.push("/login"); //跳转到登录页面
                            }
                            // console.log("ukey not ready")
                            setTimeout(()=>{
                                self.checkUkeyIsReady();
                            }, 1000)
                        },
                        fail: (res) => {
                            if(token.length > 0){
                                self.$cookies.remove(TOKENNAME)
                                self.$cookies.remove("USE_UKEY")
                                self.$router.push("/login"); //跳转到登录页面
                            }
                            // console.log("ukey not ready")
                            setTimeout(()=>{
                                self.checkUkeyIsReady();
                            }, 1000)
                        }
                    }
                })
            },
            init: function () {
                if(typeof(WebSocket) === "undefined"){
                    this.$message({
                        showClose: true,
                        message: '浏览器不支持即时通讯功能，无法获取即时信息',
                        type: 'error'
                    });
                }else{
                    // 实例化socket
                    this.socket = new WebSocket(this.path+"?"+this.token)
                    // 监听socket连接
                    this.socket.onopen = this.open
                    // 监听socket错误信息
                    this.socket.onerror = this.error
                    // 监听socket消息
                    this.socket.onmessage = this.getMessage
                    // 关闭
                    this.socket.onclose = this.close
                }
            },
            open: function () {
                let self = this;
                this.$message({
                    showClose: true,
                    message: '即时通讯发生已连接，可接受服务端即时消息',
                    type: 'success'
                });
                this.showConnectError = true
                this.reconnectCount = 0;
                this.heartBeat = setInterval(function(){self.send({code:0,msg:"hello"})},10000);
            },
            error: function () {
                if(this.isEndSocket) return;
                if(!this.showConnectError) {
                    this.$message({
                        showClose: true,
                        message: '即时通讯发生了错误，请等待尝试重新连接',
                        type: 'warning'
                    });
                }
                if(this.heartBeat){
                    clearInterval(this.heartBeat);
                }
                this.socket = null;
                //this.showConnectError = true
                //this.close();
            },
            handleSocketError(data){
                if(data.error == 2){
                    this.isNotLogin = true;
                }
                this.$message({
                    showClose: true,
                    message: '即时通讯发生了错误：'+data.msg,
                    type: 'warning'
                });
            },
            handleSocketSuccess(data){
                let result = data.result;
                if(result.code  == 0){
                    return;
                }
                if(result.code == 1){
                    this.$notify({
                        title: data.msg,
                        message: result.data,
                        type: 'warning',
                        duration:0,
                        position: 'bottom-right'
                    });
                }
            },
            getMessage: function (msg) {
                let data = JSON.parse(msg.data);
                if(data.error != 0){
                    this.handleSocketError(data);
                }else{
                    this.handleSocketSuccess(data);
                }

            },
            send: function (msg) {
                this.socket.send(JSON.stringify(msg))
            },
            close: function () {
                if(this.isEndSocket) return;
                if(this.isNotLogin) {
                    if(this.heartBeat){
                        clearInterval(this.heartBeat);
                    }
                    return;
                }
                if(this.showConnectError){
                    this.$message({
                        showClose: true,
                        message: '即时通讯发生了错误，请等待尝试重新连接',
                        type: 'warning'
                    });
                }
                this.showConnectError = false;
                if(this.heartBeat){
                    clearInterval(this.heartBeat);
                }
                this.socket = null;
                let self = this;
                if(this.reconnectCount < 5){
                    setTimeout(function(){
                        self.init();
                        self.reconnectCount ++;
                    },5000*self.reconnectCount)
                }else {
                    this.clickNotify=this.$notify({
                        title: '提示',
                        message: "多次尝试无法连接服务器即时通讯，点击消息重新尝试，点击闭关不再尝试",
                        type: 'warning',
                        duration:0,
                        position: 'bottom-right',
                        onClick:function () {
                            self.init()
                            if(self.clickNotify){
                                self.clickNotify.close();
                            }
                            self.reconnectCount = 0;
                        }
                    });
                }


            },
            userFormDialogClose(){
                this.$refs["userForm"].resetFields();
                this.getSytemAdminName();
            },
            // getAudio(){
            //     this.orderAudio = document.getElementById('orderAudio');
            // },
//            goOrderList(){
//                this.showBadge = false;
//                this.orderNum = 0;
//                this.$router.push("/order/offlineisPay");
//            },
            goIndex(){
                let data = {
                  parentNode:"0",
                  title: '首页',
                  name: '0-1',
                  routePath:'/statistics'
                };
                this.sendMsg(data);
            },
          sendMsg:function(data){
            Bus.$emit("changedNavTabsEvent",data);
            Bus.$emit("changedLeftMenuEvent",data);
          },
            sendTypeMsg:function(data){
                if(!this.isSendType && this.hasGotInfo && this.leftMenuReady){
                    Bus.$emit("notifyAdminTypeEvent",data);
                    this.isSendType = true;
                }
            },
            resetForm(formName){
                this.$refs[formName].resetFields();
            },
            goUpdatePass(formName){
                let self = this;
                self.passLoading = true;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(self.useUkey){
                            self.savePasswordWithUkey();
                        }else {
                            self.savePassword();
                        }

                    } else {
                        self.passLoading = false;
                        return false;
                    }
                });
            },
            modifyUserInfo(userForm){
                let self = this;
                self.infoLoading = true;
                self.$refs[userForm].validate((valid) => {
                    if (valid) {
                        self.doModifyUserInfo();
                    } else {
                        self.infoLoading = false;
                        return false;
                    }
                });
            },
            doModifyUserInfo(){
                var self = this;
                self.$http.post({
                    url: self.$api.systemApi.saveAdminUserInfo,
                    params: self.userForm,
                    callback: {
                        success: data => {
                            self.dialogUpdateuserNsmeVisible = false;
                            self.getSytemAdminName();
                        }
                    }
                })

            },
            savePassword(){
                var self = this;
                self.$http.post({
                    url: self.$api.systemApi.saveUserPassword,
                    params: self.passForm,
                    callback: {
                        success: data => {
                            self.$alert('修改成功，请重新登录。', '提示', {
                                confirmButtonText: '确定',
                                showClose: false,
                                type: "success",
                                callback: action => {
                                    self.$cookies.remove(TOKENNAME);
                                    self.$router.push("/");
                                }
                            });
                        }
                    }
                })
            },
            savePasswordWithUkey(){
                var self = this;
                self.$http.uKeyPost({
                    url: self.$apiUrl.uKeyApi.action,
                    params: {
                        OP: 4,
                        PIN: self.passForm.oldPassword,
                        NPIN: self.passForm.password
                    },
                    opt: {showSuccess:false},
                    callback: {
                        success: data => {
                            self.$alert('修改成功，请重新登录。', '提示', {
                                confirmButtonText: '确定',
                                showClose: false,
                                type: "success",
                                callback: action => {
                                    self.$cookies.remove(TOKENNAME);
                                    self.$cookies.remove(UKEY_TOKENNAME);
                                    self.$router.push("/");
                                }
                            });
                        },
                        error: data=>{
                            if(data.result.LOCKNUM >= 8){
                                self.$message.error("由于密码错误次数过多，已被锁定");
                            }else{
                                self.$message.error(data.msg);
                            }
                        },
                        fail: res =>{
                            self.$message.error(res.message);
                        }
                    }
                })
            },
            //用户身份
            getSytemAdminName(){
                var self = this;

                if(!self.isSendType){
                    self.sendTypeMsg(self.type);
                }
                self.$http.post({
                    url: self.$api.systemApi.getUserInfo,
                    opt: {showSuccess: false},
                    callback: {
                        success: data => {
                            self.name = data.result.realname !=null && data.result.realname !==""?data.result.realname:data.result.username;
                            self.groupName = data.result.groupName;
                            self.type = data.result.type;
                            self.username=data.result.username;
                            self.userForm = {
                                username:self.username,
                                email:data.result.email,
                                realname:data.result.realname,
                                tel:data.result.tel
                            }
                            self.hasGotInfo = true;
                            if(!self.isSendType){
                                self.sendTypeMsg(self.type);
                            }
                        }
                    }
                })
            },

            //退出
            logout(){
                var self = this;
                this.$confirm('你确定要退出吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass:'black-button',
                    type: 'warning'
                }).then(() => {
                    self.$http.post({
                        url: self.$api.actionApi.userLogout,
                        opt: {showSuccess: false},
                        callback: {
                            success: data => {
                                self.$cookies.remove(TOKENNAME);
                                self.$cookies.remove(UKEY_TOKENNAME);
                                self.$router.push("/");
                            }
                        }
                    })
                }).catch(() => {

                });
            },
            /*onOrderWebSocket:function(){
                //var websocket = null;
                let isWindowRefresh = false;
                let self = this;
                var Token = "";
                //判断当前浏览器是否支持WebSocket
                if('WebSocket' in window){
                    Token = self.$cookie.getCookie(TOKENNAME)==null?"":self.$cookie.getCookie(TOKENNAME);
                    self.websocket = new WebSocket(this.$api.webSocketApi.orderSocketTunnel+"?"+Token);
                }else{
                    self.$alert('您的浏览器不支持实时通讯，无法获取实时提醒，您可以更换现代浏览器再访问！', '信息', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                //连接发生错误的回调方法
                self.websocket.onerror = function(e){
                    //setMessageInnerHTML("error");
                    console.log(e)
                    elf.$confirm('实时通讯已断开，是否重新连接？', '提示', {
                        confirmButtonText: '重新连接',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        console.log("reconnect")
                        reconnect();
                    }).catch(() => {
                    });
                };

                //连接成功建立的回调方法
                self.websocket.onopen = function(event){
                    console.log("open websocket")
                }

                //接收到消息的回调方法
                self.websocket.onmessage = function(event){
                    handleRecievedMessage(event.data);
                }

                //连接关闭的回调方法
                self.websocket.onclose = function(){
                    //setMessageInnerHTML("close");
                    console.log("closed websocket");
                    if(isWindowRefresh) return;
                    self.$confirm('实时通讯已断开，是否重新连接？', '提示', {
                        confirmButtonText: '重新连接',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        console.log("reconnect")
                        reconnect();
                    }).catch(() => {
                    });
                }

                // 重连
                function reconnect() {
                    setTimeout(function () {     //没连接上会一直重连，设置延迟避免请求过多
                        createWebSocket(self.$api.webSocketApi.orderSocketTunnel+"?"+Token);
                    }, 2000);
                }

                // 实例websocket
                function createWebSocket(url) {
                    try {
                        if ('WebSocket' in window) {
                            self.websocket = new WebSocket(url);
                        } else if ('MozWebSocket' in window) {
                            self.websocket = new MozWebSocket(url);
                        } else {
                            self.$alert('您的浏览器不支持实时通讯，无法获取实时提醒，您可以更换现代浏览器再访问！', '信息', {
                                confirmButtonText: '确定',
                                showClose: false,
                                type: "warning"
                            });
                        }
                        initEventHandle();
                    } catch (e) {
                        reconnect(url);
                    }
                }

                // 初始化事件函数
                function initEventHandle() {
                    self.websocket.onclose = function () {
                        if(isWindowRefresh) return;
                        self.$confirm('实时通讯已断开，是否重新连接？', '提示', {
                            confirmButtonText: '重新连接',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            console.log("reconnect")
                            reconnect();
                        }).catch(() => {
                        });
                    };
                    self.websocket.onerror = function (err) {
                        self.$confirm('实时通讯已断开，是否重新连接？', '提示', {
                            confirmButtonText: '重新连接',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            console.log("reconnect")
                            reconnect();
                        }).catch(() => {
                        });
                    };
                    //连接成功建立的回调方法
                    self.websocket.onopen = function(event){
                        console.log("open websocket")
                    }

                    //接收到消息的回调方法
                    self.websocket.onmessage = function(event){
                        handleRecievedMessage(event.data);
                    }
                }

                //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
                window.onbeforeunload = function(){
                    isWindowRefresh = true;
                    self.websocket.close();
                }

                //关闭连接
                function closeWebSocket(){
                    self.websocket.close();
                }

                //发送消息
                function send(){
                    var message = "我来了";
                    self.websocket.send(message);
                }

                function handleRecievedMessage(data){
                    data = JSON.parse(data)
                    console.log(data)
                    if(data.error == 0){
                        if(data.result == null){
                            self.$alert(data.msg, '信息', {
                                confirmButtonText: '确定',
                                showClose: false,
                                type: "info"
                            });
                        }else{
                            let result = data.result;
                            if(result.flag == 1){
                                //有新订单了
                                //self.orderNum += result.orderNum;
                                //self.isHaveNewOrder = true;
                                //self.showBadge = true;
                                self.orderAudio.play();
                                for(var i = 0; i < result.orderCode.length; i++) {
                                    let t = i*500;
                                    let code = result.orderCode[i];
                                    setTimeout(function () {
                                        showNotify(code);
                                    },t);
                                }
                            }
                        }
                    }else if(data.error = 2){
                        self.$alert('您没有登录，请登录后再连接实时通讯', '信息', {
                            confirmButtonText: '确定',
                            showClose: false,
                            type: "warning"
                        });
                    }else{
                        self.$alert(data.msg, '警告', {
                            confirmButtonText: '确定',
                            showClose: false,
                            type: "warning"
                        });
                    }
                }
                function showNotify(data) {
                    self.$notify({
                        title: '新订单',
                        message: '订单编号：' + data + '，请及时处理',
                        type: 'info',
                        duration: 0,
                        position: "bottom-right"
                    });
                }
            }*/

        }
    }
</script>

<style scoped>
    .header-row{
        height: 50px;
        margin: 0 auto;
        line-height: 50px;
    }

    .nav-space{
        margin-left: 15px;
        margin-right: 5px;
    }

    .nav:hover{
        color:#eee;
    }
    .app-title{
        display: inline-block;
        height: 40px;
        background-image: url(../assets/images/oem_logo_write.png);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        font-size: 20px;
        line-height: 40px;
        color: #ffffff;
        padding-left: 50px;
        margin-top: 5px;
    }
</style>
