<template>
    <el-main>
        <el-row class="paging-row width-ninety">
            <el-col :span="24" class="text-right">
                <el-button
                    type="primary"
                    :size="buttonSize"
                    @click="handleCreate">
                    <span class="icon iconfont iconfileplus-fill"></span>
                    添加设备
                </el-button>
                <el-button
                    type="danger"
                    :size="buttonSize"
                    @click="handleDelete">
                    <span class="icon iconfont icondelete-fill"></span>
                    删除
                </el-button>
            </el-col>
        </el-row>
        <el-table
            tooltip-effect="light"
            height="300px"
            v-loading="loading"
            class="table-top width-ninety"
            @selection-change="handleSelectionChange"
            stripe
            :data="tableData">
            <el-table-column
                :model="tableData"
                fixed="left"
                type="selection"
                width="55"></el-table-column>
            <el-table-column
                prop="deviceName"
                show-overflow-tooltip
                min-width="130"
                label="设备名称"></el-table-column>
            <!-- <el-table-column
                prop="createTime"
                min-width="140"
                show-overflow-tooltip
                label="注册时间">
                <template slot-scope="scope">
                    {{ scope.row.createTime | formatDate }}
                </template>
            </el-table-column> -->

            <el-table-column
                prop="deviceTypeName"
                width="100"
                show-overflow-tooltip
                label="设备类型"></el-table-column>
            <el-table-column
                prop="deviceSerialNumber"
                min-width="100"
                show-overflow-tooltip
                label="序列号"></el-table-column>
            <el-table-column
                prop="deviceIp"
                width="100"
                show-overflow-tooltip
                label="IP"></el-table-column>
<!--            <el-table-column-->
<!--                prop="deviceMac"-->
<!--                width="120"-->
<!--                show-overflow-tooltip-->
<!--                label="MAC"></el-table-column>-->
            <el-table-column
                prop="deviceModelNumber"
                width="120"
                show-overflow-tooltip
                label="型号"></el-table-column>
        </el-table>
        <el-dialog
            append-to-body
            @close="closeDialog"
            title="添加设备"
            :visible.sync="dialogVisible"
            width="70%"
            custom-class="big-dialog-min-width">
            <device-list-dialog
                v-if="dialogVisible"
                @closeDialog="handleClose"
                :id="id"
                :appInfo="dataInfo"></device-list-dialog>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" class="black-button">
                    关 闭
                </el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import { formatDate } from '../../../../assets/js/date'
import DeviceListDialog from './deviceListDialog'

export default {
    name: 'DeviceDialog',
    components: { DeviceListDialog },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            buttonSize: BUTTONSIZE,
            inputSize: INPUTSIZE,
            modelDialogSaveButton: '保存',
            tableData: [],
            ids: [],
            loading: false,
            dialogVisible: false,
            dataInfo: {}
        }
    },
    created() {
        this.adminfindApplicationsById()
    },
    filters: {
        formatDate(time) {
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
        },
        formatDateToDate(time) {
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd')
        }
    },
    methods: {
        handleSelectionChange(val) {
            let self = this
            self.ids = []
            for (let i = 0; i < val.length; i++) {
                self.ids.push(val[i].applicationCryptoDeviceId)
            }
        },
        adminfindApplicationsById() {
            this.$http.post({
                url: this.$api.applicationApi.adminfindApplicationsById,
                params: { id: this.id },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        this.loading = false
                        this.dataInfo = data.result
                        this.tableData =
                            data.result.applicationCryptoDeviceWebDtos
                    },
                    error: (data) => {
                        this.loading = false
                    },
                    fail: (res) => {
                        this.loading = false
                    }
                }
            })
        },
        handleCreate() {
            this.dialogVisible = true
        },
        handleDelete() {
            if (this.ids == null || this.ids.length == 0) {
                this.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    // let target = []
                    // this.tableData.map((i) => {
                    //     if (!this.ids.includes(i.cryptoDeviceId)) {
                    //         target.push(i.cryptoDeviceId)
                    //     }
                    // })
                    let params = {
                        id: this.id,
                        ids: this.ids
                    }

                    this.$http.post({
                        url: this.$api.applicationApi.delDeviceFromApplications,
                        params: params,
                        opt: {isLoading: false, showSuccess: true},
                        callback: {
                            success: (data) => {
                                this.adminfindApplicationsById()
                            }
                        }
                    })
                })
                .catch(() => {})
        },
        handleClose() {
            this.dialogVisible = false
            this.adminfindApplicationsById()
        },
        closeDialog() {
            this.adminfindApplicationsById()

        }
    }
}
</script>

<style scoped>
.table-top {
    margin-top: 30px;
}

.search-bt {
    width: 120px;
    margin-top: 51px;
}
</style>
