import echarts from 'echarts';

import {getType} from '@/assets/js/tool';
import {
    COLOR,
    SETTINGS
} from './base';
import THEME from '@/assets/json/theme.json'

export default {
    render(h) {
        return h('div', {
            class: [this.chartCls, 'default-chart'],
            style: this.canvasStyle,
            ref: 'canvas'
        });
    },
    props: {
        width: Number,
        height: Number,
        data: {type: [Object, Array], default() {return [];}},
        xAxis: [Object, Array],
        yAxis: [Object, Array],
        color: {type: Array, default() {
            return COLOR;
        }},
        markPoint: {type: Object},
        backgroundColor: [Object, String],
        legend: [Object, Array],
        name: [String]
    },
    computed: {
        canvasStyle() {
            return {
                width: this.width ? this.width + 'px' : '100%',
                height: this.height ? this.width + 'px' : '100%'
            };
        }
    },
    methods: {
        async refresh() {
            this.beforeRefresh && this.beforeRefresh();
            this.option = this.optionHandler();
            let hasData = getType(this.data) === '[object Object]'
                ? Object.getOwnPropertyNames(this.data) : this.data;
            hasData.length !== 0 && this.chartIns.setOption(this.option);
            this.resizeLitener();
            this.afterRefresh && this.afterRefresh();
        },
        addWatchToProps() {
            Object.keys(this.$props).forEach(prop => {
                let opts = {};
                if (getType(prop) === '[object Object]' || getType(prop) === '[object Array]') {
                    opts.deep = true;
                }
                this.$watch(prop, () => {
                    this.refresh();
                }, opts);
            });
        },
        optionHandler() {
            let options = {};
            SETTINGS.forEach(item => {
                this[item + 'Handler'] && (options[item] = this[item + 'Handler']());
            });
            return options;
        },
        // colorHandler() {
        //     return this.color ? this.color : COLOR;
        // },
        backgroundColorHandler() {
            return this.backgroundColor || 'transparent';
        },
        resizeLitener() {
            (this.chartIns && this.chartIns.resize) && this.chartIns.resize();
        },
        axisHandler() {}
    },
    created() {
        this.chartIns = null;
        this.addWatchToProps();
    },
    mounted() {
        this.chartIns = echarts.init(this.$refs.canvas, THEME);
        this.refresh();
        this.initEvent && this.initEvent(this.chartIns);
        window.addEventListener('resize', this.resizeLitener);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeLitener);
        this.chartIns.dispose();
        this.chartIns = null;
    }
};
