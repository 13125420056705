<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2 label-text-font">密码设备数量</span>
          <dv-decoration-3  style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <CentreLeft1Chart ref="dataPieDisplay" :pieDatas="pieDatas" :appTotal="appTotal"/>
      </div>
<!--       4个主要的数据-->
      <div class="bottom-data">
        <div class="item-box" v-for="(item,index) in numberData" :key="index">
          <div class="d-flex">
<!--            <span class="coin">￥</span>-->
            <dv-digital-flop :config="item.number" style="height:.425rem;" />
          </div>
          <div class="text label-text-font" style="text-align: center;">
            {{item.text}}
            <span class="colorYellow">(台)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentreLeft1Chart from "@/components/echart/centerLeft/centreLeft1Chart";
export default {
  data() {
    return {
      config: {
        lineWidth: 30,
        activeRadius: "80%",
        radius: "75%",
        activeTimeGap: 2000,
      },
      numberData: [],
      timer:null,
        appTotal: 0,
      pieDatas:{},
        cryptoData:null,
        isLoadingLeft:true
    }
  },
  components: {
    CentreLeft1Chart
  },
  mounted() {
    //this.changeTiming();
    this.findCryptoNumber();
  },
  destroyed() {
    if(this.timer){
      clearInterval(this.timer);
    }
  },
  methods: {
    findCryptoNumber(){
      let self = this;
      self.doFindData();
      self.changeTiming();
    },
    doFindData() {
        let self = this;
        let param = {
            type: VIEW_TYPE
        }
        self.$http.post({
            url: self.$api.bigScreenApi.findCryptoNumber,
            opt: {showSuccess: false, isLoading: false},
            params: VIEW_TYPE,
            callback: {
                success: data => {
                    self.cryptoData = data.result;
                    self.$refs.dataPieDisplay.draw();
                    self.makeData()
                }
            }
        })

    },
    changeTiming() {
      let self = this;
      self.timer = setInterval(() => {
        self.doFindData();
      }, 3000);
    },
      makeData(){
          let self = this;
          let data = self.cryptoData;
          self.appTotal = 0;
          self.numberData = []
          let legendData = []
          let seriesData = []
          for(var i = 0; i < data.length; i++){
              self.numberData.push(
                  {
                      number: {
                          number: [data[i].number],
                          toFixed: 0,
                          content: "{nt}",
                          style: {fontSize: 18}
                      },
                      text: data[i].name
                  }
              );
              legendData.push(data[i].name);
              seriesData.push({
                  value: data[i].number,
                  name: data[i].name
              })
              self.appTotal += data[i].number;
          }
          self.pieDatas =  {
              legendData: legendData,
              seriesData: seriesData
          }
          self.$refs.dataPieDisplay.draw();
          self.isLoadingLeft = false;
      }
  }
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 5.0525rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>