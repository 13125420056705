<template>
    <el-aside class="el-menu-vertical-demo" style="width: initial">

        <!--<el-menu-->
        <!--class="full-height"-->
        <!--:default-openeds="openNodes"-->
        <!--:default-active="activeIndex"-->
        <!--:unique-opened="true"-->
        <!--background-color="#333744"-->
        <!--text-color="#fff"-->
        <!--active-text-color="#409EFF"-->
        <!--@select="handleSelect">-->
        <el-menu
                :collapse="isCollapse"
                class="full-height"
                :default-openeds="openNodes"
                :default-active="activeIndex"
                :unique-opened="true"
                background-color="#00152a"
                text-color="#FFF"
                active-text-color="#6CB5FF"
                @select="handleSelect">
            <el-submenu v-for="(menu,index) in menuData" :key="index" :index="menu.name">
                <template slot="title">
                    <i :class="menu.icon"></i>
                    <span class="menu-title">{{menu.title}}</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item v-for="(item,index) in menu.children" :key="index" :index="item.name">{{item.title}}</el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </el-aside>
</template>

<script>
    import ElAside from "element-ui/packages/aside/src/main.vue";
    import Bus from '../assets/js/eventBus';
    import leftMenus from '../assets/js/leftMenus'
    export default {
        name: 'LeftSide',
        components: {ElAside},
        props:["isCollapse"],
        data(){
            return{
                // buttonLeft:"right:0",
                // isCollapse:true,
                isCheckRole:false,
                menuData : [],
                menuAdminData : leftMenus.menuData,
                roleUrls : [],
                menuIcons : MENUICONS,
                menuPath: [],
                menuAdminPath: leftMenus.menu,
                openNodes:["1"],
                activeIndex:"1-1"
            }
        },
        created(){
            this.getSystemMenuList();
        },
        mounted(){
            let self = this;
            // console.log(self)
            Bus.$on("changedNavTabsEvent",function(msg){
                self.doChangedNavTabs(msg);
            });
            Bus.$on("notifyAdminTypeEvent",function(msg){
                //self.getAdminType(msg);
            });
            this.sendReadyMsg("ready");
        },
        methods: {
            getSystemMenuList(){
                let self = this;
                if(!ISUSEROLES){
                    self.menuData = self.menuAdminData;
                    self.menuPath = self.menuAdminPath;
                    self.makeMenu();

                    return;
                }
                self.$http.post({
                    url: self.$api.systemApi.getSystemMenuList,
                    opt:{showSuccess : false, isLoading:false},
                    callback: {
                        success: data => {
                            self.menuData = data.result;
                            self.openNodes=["1"]
                            self.sendRefreshMsg();
                        }
                    }
                })
            },
            getAdminType(data){
                // let self = this;
                // switch (data) {
                //     case 0:
                //         //管理员
                //         self.menuData = self.menuAdminData;
                //         self.menuPath = self.menuAdminPath;
                //         break;
                //     case 1:
                //         //操作员
                //         self.menuData = self.menuDMVData;
                //         self.menuPath = self.menuDMVPath;
                //         break;
                //     case 2:
                //         //审计员
                //         self.menuData = self.menuTaxData;
                //         self.menuPath = self.menuTaxPath;
                //         break;
                //     default:
                //         break;
                // }
                //self.makeMenu();
            },
            makeMenu(){
                let self = this;
                for(let i = 0; i < self.menuData.length; i++){
                    self.menuData[i].children=[];
                    for(let j = 0; j < self.menuPath.length; j++){
                        if(self.menuData[i].node == self.menuPath[j].parentNode){
                            self.menuData[i].children.push(self.menuPath[j]);
                        }
                    }
                }
                self.openNodes=["1"]
                // console.log(self.menuData)
                this.sendRefreshMsg(this.menuData);
            },
            handleSelect(key, keyPath){
                let self = this;
                //console.log(key,keyPath)
                for(var i = 0; i< self.menuData.length; i++){
                    for(var j = 0; j < self.menuData[i].children.length; j++){
                        let path = self.menuData[i].children[j];
                        if(path.name == key){
                            self.sendMsg(path);
                            if(self.$route.path != path.routePath) {
                                self.goPage(path.routePath);
                            }
                            break;
                        }
                    }
                }
                return;
            },
            sendMsg:function(data){
                Bus.$emit("changedLeftMenuEvent",data);
            },
            doChangedNavTabs:function(data){
                let self = this;
                if(self.$route.path == data.routePath){
                    return;
                }
                self.openNodes =[];
                self.openNodes.push(data.parentNode);
                self.activeIndex = data.name;
                self.goPage(data.routePath);
            },
            sendRefreshMsg:function(data){
                let self = this;
                if(!data){
                    data = self.menuData;
                }
                //LEFTMENU = data;
                let routePath = self.$route.path;
                let currentMenu = {};
                let isHave = false;
                for(var i = 0; i< data.length; i++){
                    for(var j = 0; j < data[i].children.length; j ++){
                        if(data[i].children[j].routePath == routePath){
                            currentMenu = data[i].children[j];
                            isHave = true;
                            break;
                        }
                    }

                }
                self.openNodes =[];
                self.openNodes.push(currentMenu.parentNode);
                self.activeIndex = currentMenu.name;
                if(isHave){
                    self.$nextTick(()=>{
                        Bus.$emit("pageRefreshEvent",currentMenu);
                    })
                }
            },
            sendReadyMsg(data){
                Bus.$emit("leftPageReadyEvent",data);
            },
            goPage(url){
                this.$router.push(url);
            }
        }
    }
</script>
<style>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        /*min-width: 200px;*/
        min-height: 400px;
        position: relative;
    }
    .el-menu:not(.el-menu--collapse){
        width: 200px;
    }
</style>