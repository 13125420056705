const state = {
    useName: "sessionModules"
};
const mutations = {

};
const actions = {

};
const getters = {

};

// 不要忘记把state, mutations等暴露出去。
export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}