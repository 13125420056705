// 类型判断函数
export const getType = v => Object.prototype.toString.call(v);

export const isObject = v => getType(v) === '[object Object]';

// 简单的返回 [n, m]之间的整数
export const getRandom = (n, m) =>{
    let num = Math.floor(Math.random() * (m - n + 1) + n)
    return num
}

export const formatDate = (date, fmt = 'yyyy-MM-dd') => {
    if(!date) {
        return '--'
    }
    date = new Date(date);
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
        }
    }
    return fmt
}
function padLeftZero (str) {
    return ('00' + str).substr(str.length)
}

export const fileSizeFilter = (size) => {
    if (!Number.isFinite(size) || size === 0 || size === '') {
        return '-'
    }
    if (size < 1024) { //1KB
        return size + 'B'; 
    } else if (size < 1* 1024 * 1024) { // 1M
        return (size / 1024).toFixed(2) + 'KB'; 
    } else if (size < 1024 * 1024 * 1024) { // 1G
        return (size / (1024 * 1024)).toFixed(2) + 'M'; 
    } else if (size < 1024 * 1024 * 1024 * 1024) {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + 'G'; 
    } else {
        return (size / (1024 * 1024 * 1024 * 1024)).toFixed(2) + 'T'; 
    }
}

export const formatEmpty = (row, column, cellValue) => {
    return cellValue ? cellValue : '-'
}