<template>
    <el-main style="min-width:1200px">
        <el-card class="center admin-style-list"  >
            <div slot="header" class="clearfix" >
                <span class="title-text"><i class="icon iconfont icondata"></i> 组织架构</span>
            </div>
<!--            <el-container>-->
<!--                <el-aside width="340px" class="org-left-items">-->
<!--&lt;!&ndash;                    <div class="left-panel">&ndash;&gt;-->
<!--                        <div class="org-button text-right">-->
<!--                            <el-button type="primary"  size="mini" @click="add" ref="data"><span class="icon iconfont iconplus"></span> 添加</el-button>-->
<!--                            <el-button type="primary" size="mini" @click="importOrg" ref="data"><span class="icon iconfont iconupload"></span> 导入</el-button>-->
<!--                            <el-button type="primary" size="mini" @click="edit" ref="data"><span class="icon iconfont iconedit"></span> 编辑</el-button>-->
<!--                            <el-button type="danger" size="mini" @click="del" ref="data"><span class="icon iconfont icondelete-fill"></span> 删除</el-button>-->
<!--                        </div>-->
<!--                        <div class="org-list" :style="'height:'+(tableHeight-8)+'px' ">-->
<!--                            <el-tree :data="listdata"-->
<!--                                     ref = "tree"-->
<!--                                     node-key="id"-->
<!--                                     default-expand-all-->
<!--                                     :expand-on-click-node="false"-->
<!--                                     highlight-current-->
<!--                                     @node-click = "handleNodeClick">-->
<!--                        <span class="custom-tree-node" slot-scope="{ node, data }">-->
<!--                        <span>{{ data.name }}</span>-->
<!--                    </span>-->
<!--                            </el-tree>-->
<!--                        </div>-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                </el-aside>-->
<!--                <el-main>-->
                    <el-row class="paging-row width-ninety">
                        <el-col :span="8" class="text-left">
<!--                            <el-button type="primary" :size="buttonSize" @click="dialogTypeModelFormVisible = true"><span class="icon iconfont iconfolder-plus"></span> 设备类型管理</el-button>-->
                        </el-col>
                        <el-col :span="16" class="text-right" >
                            <el-button type="primary"  size="mini" @click="add" ref="data"><span class="icon iconfont iconplus"></span> 添加</el-button>
                            <el-button type="primary" size="mini" @click="importOrg" ref="data"><span class="icon iconfont iconupload"></span> 导入</el-button>
                            <el-button type="primary" size="mini" @click="edit" ref="data"><span class="icon iconfont iconedit"></span> 编辑</el-button>
                            <el-button type="danger" size="mini" @click="del" ref="data"><span class="icon iconfont icondelete-fill"></span> 删除</el-button>
                        </el-col>
                    </el-row>
                    <el-table
                            tooltip-effect="light"
                            :height="tableHeight"
                            v-loading="loading"
                            class="table-top width-ninety"
                            stripe
                            :data="listdata"
                            row-key="id"
                            default-expand-all
                            ref="multipleTable"
                            @selection-change="handleNodeClick"
                            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                        <el-table-column
                                type="selection"
                                width="55">
                        </el-table-column>
                        <el-table-column
                                prop="name"
                                label="名称"
                                sortable
                                min-width="180">
                        </el-table-column>
                        <el-table-column
                                width="280"
                                prop="address"
                                label="操作">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="addRow(scope.row)" size="mini"><span class="icon iconfont iconplus "></span> 添加</el-button>
                                <el-button type="primary" @click="editRow(scope.row)" size="mini"><span class="icon iconfont iconedit "></span> 编辑</el-button>
                                <el-button type="danger" @click="delRow(scope.row)" size="mini"><span class="icon iconfont icondelete-fill "></span> 删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
<!--                </el-main>-->
<!--            </el-container>-->
<!--            <div class="paging-row width-ninety">-->
<!--                -->
<!--                <div class="right-panel">-->
<!--                    -->
<!--                </div>-->
<!--            </div>-->

            <el-dialog :title="userDialogTitle" @close="closeDialog" :close-on-click-modal="false" :visible.sync="dialogEditModelFormVisible" width="20%" custom-class="small-dialog-min-width">
                <el-form :model="modelForm" ref="modelForm">
                    <el-form-item v-if="!isAdd" label="组织ID" prop="id"
                                  :label-width="formLabelWidth">
                        {{modelForm.id}}
                    </el-form-item>
                    <el-form-item label="组织名称" prop="name"
                                  :label-width="formLabelWidth"
                                  :rules="{required: true, message: '组织名称不能为空', trigger: 'blur'}">
                        <el-input v-model="modelForm.name" class="dialog-input-width" auto-complete="off" placeholder="请输入组织名称" :size="inputSize"></el-input>
                    </el-form-item>
                    <el-form-item v-if="isAdd" label="父组织" :label-width="formLabelWidth">
                        {{currentOrg.name?currentOrg.name:"无"}}
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel" class="black-button" :size="buttonSize">取 消</el-button>
                    <el-button plain type="primary" @click="saveModel('modelForm')" :size="buttonSize" >保存</el-button>
                </div>
            </el-dialog>
            <el-dialog title="导入组织结构" @close="closeImportDialog" :close-on-click-modal="false" :visible.sync="dialogImportVisible" width="20%" custom-class="small-dialog-min-width">
                <el-form :model="importForm" ref="importForm">
                    <el-form-item label="模板下载" prop="templateFile"
                                  :label-width="formLabelWidth">
                        <a :href="fileServer+'upload/orgtemplate.xlsx'" target="_blank">点击下载</a>
                    </el-form-item>
                    <el-form-item label="导入文件" prop="upFile"
                                  :label-width="formLabelWidth"
                                  :rules="{required: true, message: '导入文件不能为空', trigger: 'blur'}">
                        <el-upload
                                ref="importUpload"
                                :action="importUrl"
                                :file-list="importFileList"
                                :on-success="handleImportSuccess"
                                :on-error="handleImportError"
                                :headers="headers"
                                :on-remove="handleImportRemove"
                                :on-change="handleImportChange"
                                :before-upload="handleImportBefore"
                                accept=".xls,.xlsx"
                                :limit="1"
                                :auto-upload="false">
                            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传.xls，.xlsx文件，且不超过5M</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogImportVisible = false" class="black-button" :size="buttonSize">取 消</el-button>
                    <el-button type="primary" @click="importModel('importForm')" :size="buttonSize">导入</el-button>
                </div>
            </el-dialog>

        </el-card>
    </el-main>
</template>

<script>
    //import assx from "./component/assx"
    import {formatDate} from '../../../assets/js/date'
    export default {
        name:"Organization",
        data(){
            return {
                tableHeight: window.innerHeight  - 347,
                fileServer: HOSTCONFIG.FILESERVER,
                listdata: [],
                tableData:[],
                id :"",
                modifyTime:null,
                model:{},
                pageNo : 1,   //1页
                pageSize :   10, //10条数据
                dialogEditModelFormVisible:false,
                dialogVisible: false,
                dialogmodifyModelFormVisible:false,
                userDialogTitle:"编辑组织架构节点",
                formLabelWidth: '120px',
                inputSize:INPUTSIZE,
                buttonSize:BUTTONSIZE,
                searchBoxWidth:4,
                modelForm:{
                    name:"",
                    parentId:"",
                    level:1
                },
                node:false,
                isAdd:"",
                loading: false,
                dialogImportVisible: false,
                currentOrgId:"",
                currentOrg:{},
                total:0,
                importFileList:[],
                headers:{},
                importForm:{
                    upFile:""
                },
                importUrl:HOSTCONFIG.RESTHOST + this.$api.adminApi.importOrganizationInfo,
                parentId:"",
                resetUpload:false
            }
        },
        components:{
        },
        filters:{
            formatDate(time){
                let date = new Date(time);
                return formatDate(date,'yyyy-MM-dd hh:mm:ss');
                //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
            },
            verifyFilter:function(value){
                if(value == 0){
                    return "管理员";
                }else if(value == 1){
                    return "操作员";
                }else{
                    return "审计员";
                }
            }
        },
        mounted:function(){
            this.findAllOrganization(1);
            this.getHeaders();
        },
        methods: {
            getHeaders(){
                let Token = this.$cookies.get(TOKENNAME)==null?"":this.$cookies.get(TOKENNAME);
                this.headers = {
                    'x-authorization': Token
                }
            },
            closeImportDialog(){
                this.$refs['importForm'].resetFields();
                this.importFileList=[];
                this.findAllOrganization(1);
            },
            handleImportBefore(file) {
                //const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 5;
                if (!isLt2M) {
                    this.$message.error('导入文件大小不能超过 5MB!');
                    this.$refs['importForm'].resetFields();
                    this.importFileList=[];
                    if(this.loadingInstance){
                        this.loadingInstance.close();
                        this.loadingInstance = null;
                    }
                }
                return isLt2M;
            },
            handleImportSuccess(response, file, fileList){
                let self = this;
                if(self.loadingInstance){
                    self.loadingInstance.close();
                    self.loadingInstance = null;
                }
                if(response.error == 0){
                    self.$alert("导入成功", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "success",
                        callback: action => {
                            self.dialogImportVisible = false;
                        }
                    });
                }else{
                    self.$alert(response.msg, '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    self.$refs['importForm'].resetFields();
                    self.importFileList=[];
                    self.resetUpload = true;
                }
            },
            handleImportError(err, file, fileList){
                if(this.loadingInstance){
                    this.loadingInstance.close();
                    this.loadingInstance = null;
                }
                this.$alert("导入文件失败："+err, '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: "error"
                });
                return;
            },
            handleImportRemove(file, fileList){
                if(fileList.length == 0){
                    this.importForm.upFile = null;
                }
            },
            handleImportChange(file, fileList){
                if(fileList.length == 0){
                    this.importForm.upFile = null;
                }else{
                    this.importForm.upFile = file;
                }
                if(this.resetUpload){
                    this.importForm.upFile = null;
                    this.resetUpload = false;
                }
            },
            importModel(formName){
                let self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        self.loadingInstance = self.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.5)'
                        });
                        self.$refs.importUpload.submit();
                    } else {
                        return false;
                    }
                });
            },
            importOrg(){
                this.dialogImportVisible = true;
            },
            //树列表
            findAllOrganization(val){
                let self = this;
                self.isLoading = true;
                self.$http.post({
                    url: self.$api.adminApi.findAllOrganization,
                    opt: {isLoading: false, showSuccess: false},
                    callback: {
                        success: data => {
                            self.isLoading = false;
                            self.listdata = data.result
                            // if (val) {
                            //     self.currentOrgId = self.listdata[0].id;
                            //     // self.findAdminListByOrganizationForPage();
                            // }
                        },
                        error: data=>{
                            self.isLoading = false;
                        },
                        fail: res=>{
                            self.isLoading = false;
                        }

                    }
                });
            },
            handleNodeClick(val){

                let self = this;
                if(val.length > 1){
                    self.$alert("只允许选择条数据进行操作","提示", {
                        confirmButtonText: '确定',
                        showCancelButton:false,
                        type: 'warning'
                    }).then(() => {
                        this.$refs.multipleTable.clearSelection();
                    }).catch(() => {});
                }else {
                    self.currentOrg = val[0];
                    self.currentOrgId = self.currentOrg.id;
                    self.parentId = self.currentOrg.id;
                }
                // self.selectModels = val[0];
                // self.ids = [];
                // for(let i = 0; i < val.length; i++){
                //     self.ids.push(val[i].id);
                // }
                // var self = this;

            },
            //添加修改数据
            doSaveModel(val){
                let self = this;
                self.$http.post({
                    url: self.$api.adminApi.saveOrUpdateOrganization,
                    params:self.modelForm,
                    callback: {
                        success: data => {
                            if(val == 0){
                                this.findAllOrganization(1)
                                self.dialogEditModelFormVisible = false;
                            }else{;
                                self.$refs['modelForm'].resetFields();
                                self.modelForm = {
                                    parentId:"",
                                    name:""
                                };
                            }
                        }
                    }
                })

            },

            //添加
            add() {
                this.modelForm.parentId = this.parentId;
                if(this.parentId== null || this.parentId == ""){
                    this.$confirm('当前将加入一个根节点组织, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        cancelButtonClass:'black-button',
                        type: 'warning'
                    }).then(() => {
                        this.dialogEditModelFormVisible=true
                        this.isAdd = true;
                        this.userDialogTitle = "添加组织结构节点"
                        this.modelForm.level = 1;
                    }).catch(() => {
                    });
                }else{
                    this.dialogEditModelFormVisible=true
                    this.isAdd = true;
                    this.modelForm.level = this.currentOrg.level;
                    this.userDialogTitle = "添加组织结构节点"
                }
            },
            saveModel(formName){
                let self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        self.doSaveModel(0);
                    } else {
                        return false;
                    }
                });
            },
            //编辑
            edit(){
                if (this.currentOrg.id == null || this.currentOrg.id == "") {
                    this.$alert("请选择一条数据进行此操作", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                this.modelForm = this.currentOrg;
                this.isAdd = false;
                this.dialogEditModelFormVisible=true
                this.userDialogTitle = "编辑组织结构节点"
            },
            editRow(row){
                this.currentOrg = row;
                this.currentOrgId = this.currentOrg.id;
                this.parentId = this.currentOrg.id;
                this.edit();
            },
            delRow(row){
                this.currentOrg = row;
                this.currentOrgId = this.currentOrg.id;
                this.parentId = this.currentOrg.id;
                this.del();
            },
            addRow(row){
                this.currentOrg = row;
                this.currentOrgId = this.currentOrg.id;
                this.parentId = this.currentOrg.id;
                this.add();
            },
            //删除
            del() {
                let self = this;
                let id = self.parentId;

                if (id == null || id == "") {
                    self.$alert("请选择一条数据进行操作", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    return;
                }
                self.$confirm('此操作将永久删除您选择的数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'black-button',
                    type: 'warning'
                }).then(() => {
                    self.$http.post({
                        url: self.$api.adminApi.deleteOrganization,
                        params: {id:id},
                        callback: {
                            success: data => {
                                self.currentOrg = {};
                                self.currentOrgId = "";
                                self.parentId = "";
                                self.findAllOrganization(1);
                            }
                        }
                    })
                }).catch(() => {   //取消操作
                    self.currentOrg = {};
                    self.currentOrgId = "";
                    self.parentId = "";
                });
            },
            //取消按钮
            cancel(){
                this.dialogEditModelFormVisible=false
            },
            closeDialog(){
                this.$refs['modelForm'].resetFields();
                this.modelForm = {
                    parentId:"",
                    name:""
                }
                this.currentOrgId = "";
                this.currentOrg = {};
                this.parentId = "";
                this.findAllOrganization(1);
            }
        }
    }
</script>

<style scoped>
    .paging-row{
        width: 100%;
        display: flex;
        flex-direction: row;

    }
    .org-list{
        overflow: scroll;
        margin-top: 10px;
    }
    .custom-tree-node{
        display: flex;
        justify-content: space-around;
    }
    .tablelist{
        width: 80%;
    }
    a{
        color: #fff;
    }
    .left-panel{
        width: 340px;
        margin-right: 15px;
        display: inline-block;
    }
    .org-left-items{
        padding-right: 15px;
    }
    .org-button{
        margin-top:10px;
    }
</style>
``