<template>
    <div>
        <el-form label-position="top" class="search-form" label-width="100px">
            <el-row :gutter="35">
                <el-col :span="7">
                    <el-form-item label="CNNVD编码">
                        <el-input v-model="cnnvdId" class="input-width" :size="inputSize" clearable></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="7">
                    <el-form-item label="描述">
                        <el-input v-model="description" class="input-width" :size="inputSize" clearable></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="7">
                    <el-form-item label="." class="search-button-space">
                        <el-button class="search-button" @click="select" :size="buttonSize" type="primary" plain><span class="icon iconfont iconsearch"></span> 查询</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-row class="operation-button">
            <el-col :span="24" class="text-right" >
                <el-button type="primary" :size="buttonSize" @click="importCveData"><span class="icon iconfont iconupload"></span> 导入漏洞</el-button>
                <!--                <el-button type="success" :size="buttonSize" @click="editBtn"><span class="icon iconfont iconedit "></span> 编辑</el-button>-->
                <!--                <el-button type="danger"  :size="buttonSize" @click="del"><span class="icon iconfont icondelete-fill"></span><span> 删除</span></el-button>-->
            </el-col>
        </el-row>

        <el-table
                tooltip-effect="light"
                :data="tableData"
                :height="tableHeight"
                v-loading="loading"
                stripe
                class="table-top body-table">
            <el-table-column
                    show-overflow-tooltip
                    width="200"
                    prop="cnnvdId"
                    label="CNNVD编码">
            </el-table-column>
            <el-table-column
                    prop="rank"
                    width="100"
                    label="漏洞评分">
            </el-table-column>
            <el-table-column
                    prop="description"
                    label="漏洞描述">
            </el-table-column>
            <el-table-column
                    prop="publishDate"
                    width="130"
                    label="发布时间">
            </el-table-column>
            <el-table-column
                    prop="victims"
                    width="130"
                    label="影响范围">
            </el-table-column>
            <!--                <el-table-column-->
            <!--                        prop="sex"-->
            <!--                        width="100"-->
            <!--                        label="性别">-->
            <!--                    <template slot-scope="scope">-->
            <!--                        {{checkSex(scope.row.sex)}}-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <el-table-column
                    width="100"
                    fixed="right"
                    label="操作">
                <template slot-scope="scope">
                    <!-- <el-button type="text" @click="edit(scope.row)">编辑</el-button>-->
                    <el-button size="mini" type="primary" @click="showCveData(scope.row)"><span class="icon iconfont iconeye"></span> 查看</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-row class="paging-row width-ninety">
            <el-col :span="24" class="float-right">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 15, 20, 30, 40, 50]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-size="pageSize"
                        :current-page="pageNo"
                        :total="total">
                </el-pagination>
            </el-col>
        </el-row>


        <!--查看用户申报-->
        <el-dialog title="漏洞详情" :close-on-click-modal="false" :visible.sync="dialogCveDataVisible" width="60%" custom-class="big-dialog-min-width">

            <el-form label-position="left" class="dialog-form" label-width="100px">
                <el-form-item label="CVVND编码">
                    {{dataForm.cnnvdId}}
                </el-form-item>
                <el-form-item label="漏洞评分">
                    {{dataForm.rank}}
                </el-form-item>
                <el-form-item label="发布日期">
                    {{dataForm.publishDate}}
                </el-form-item>
                <el-form-item label="参考网站">
                    <pre>{{dataForm.references|toText}}</pre>
                </el-form-item>
                <el-form-item label="受影响实体">
                    {{dataForm.victims}}
                </el-form-item>
                <el-form-item label="漏洞描述">
                    {{dataForm.description}}
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogCveDataVisible=false" class="black-button" :size="buttonSize">关 闭</el-button>
            </div>
        </el-dialog>

        <!--漏洞导入-->
        <el-dialog title="导入漏洞信息" @close="closeImportDialog" :close-on-click-modal="false" :visible.sync="dialogImportVisible" width="20%" custom-class="small-dialog-min-width">
            <el-form :model="importForm" ref="importForm">
                <!--                <el-form-item label="模板下载" prop="templateFile"-->
                <!--                              :label-width="formLabelWidth">-->
                <!--                    <a :href="fileServer+'upload/cartemplate.xlsx'" target="_blank">点击下载</a>-->
                <!--                </el-form-item>-->
                <el-form-item label="导入文件" prop="upFile"
                              :label-width="formLabelWidth"
                              :rules="{required: true, message: '导入文件不能为空', trigger: 'blur'}">
                    <el-upload
                            ref="importUpload"
                            :action="importCveUrl"
                            :file-list="importFileList"
                            :on-success="handleImportSuccess"
                            :on-error="handleImportError"
                            :headers="headers"
                            :on-remove="handleImportRemove"
                            :on-change="handleImportChange"
                            :before-upload="handleImportBefore"
                            accept=".json"
                            :limit="1"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传.json文件，且不超过50M</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogImportVisible = false" class="black-button" :size="buttonSize">取 消</el-button>
                <el-button type="primary" @click="importModel('importForm')" :size="buttonSize">导入</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {formatDate} from '../../../../assets/js/date'

    export default {
        name: 'CnnvdData',
        data() {
            return {
                tableHeight: window.innerHeight  - 380,
                buttonSize: BUTTONSIZE,
                inputSize: INPUTSIZE,
                cnnvdId: '',
                description: '',
                pageNo: 1,
                pageSize: 15,
                tableData: [],
                total: 0,
                formLabelWidth: "150px",
                dialogCveDataVisible: false,
                ids:[],
                loading:false,
                dataForm:{},
                dialogImportVisible:false,
                loadingInstance:null,
                fileServer:HOSTCONFIG.FILESERVER,
                importForm:{
                    templateFile:'',
                    upFile:''
                },
                importFileList:[],
                importCveUrl: this.$api.adminApi.importCnnvdDataInfo,
                headers:{},
                resetUpload:false
            }
        },
        mounted() {
            this.findAll();
            this.getHeaders();
        },
        filters: {
            formatDate(time) {
                let date = new Date(time);
                return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
                //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
            },
            toText(text){
                if(!text || text === ""){
                    return "";
                }
                var json = JSON.parse(text);
                var string = "";
                for(var i = 0; i < json.length; i++){
                    if(i == 0){
                        string += json[i];
                    }else{
                        string += "\n\r" + json[i];
                    }
                }
                return string;
            }
        },
        methods: {
            getHeaders(){
                let Token = this.$cookies.get(TOKENNAME)==null?"":this.$cookies.get(TOKENNAME);
                this.headers = {
                    'x-authorization': Token
                }
            },
            closeImportDialog(){
                this.$refs['importForm'].resetFields();
                this.importFileList=[];
                this.findAll();
            },
            handleImportBefore(file) {
                //const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 50;
                if (!isLt2M) {
                    this.$message.error('导入文件大小不能超过 50MB!');
                    this.$refs['importForm'].resetFields();
                    this.importFileList=[];
                    if(this.loadingInstance){
                        this.loadingInstance.close();
                        this.loadingInstance = null;
                    }
                }
                return isLt2M;
            },
            handleImportSuccess(response, file, fileList){
                let self = this;
                if(self.loadingInstance){
                    self.loadingInstance.close();
                    self.loadingInstance = null;
                }
                if(response.error == 0){
                    self.$alert("导入成功", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "success",
                        callback: action => {
                            self.dialogImportVisible = false;
                        }
                    });
                }else{
                    self.$alert(response.msg, '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    self.$refs['importForm'].resetFields();
                    self.importFileList=[];
                    self.resetUpload = true;
                }
            },
            handleImportError(err, file, fileList){
                if(this.loadingInstance){
                    this.loadingInstance.close();
                    this.loadingInstance = null;
                }
                this.$alert("导入文件失败："+err, '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: "error"
                });
                return;
            },
            handleImportRemove(file, fileList){
                if(fileList.length == 0){
                    this.importForm.upFile = null;
                }
            },
            handleImportChange(file, fileList){
                if(fileList.length == 0){
                    this.importForm.upFile = null;
                }else{
                    this.importForm.upFile = file;
                }
                if(this.resetUpload){
                    this.importForm.upFile = null;
                    this.resetUpload = false;
                }
            },
            importModel(formName){
                let self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        self.loadingInstance = self.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.5)'
                        });
                        self.$refs.importUpload.submit();
                    } else {
                        return false;
                    }
                });
            },
            showCveData(row){
                this.dataForm = row;
                this.dialogCveDataVisible = true;
            },
            importCveData(){
                this.dialogImportVisible = true;
            },
            handleSelectionChange(val){
                let self = this;
                self.ids = [];
                for(let i = 0; i < val.length; i++){
                    self.ids.push(val[i].id);
                }
            },

            handleSizeChange(val) {
                this.pageNo = 1;
                this.pageSize = val;
                this.findAll();
            },
            handleCurrentChange(val) {
                this.pageNo = val;
                this.findAll();
            },

            select() {
                this.pageNo = 1;
                this.findAll();
            },

            findAll() {
                let self = this;
                self.loading = true;
                let data = {
                    pageNo: self.pageNo -1,
                    pageSize: self.pageSize,
                    cnnvdId: self.cnnvdId,
                    description: self.description
                };
                self.$http.post({
                    url: self.$api.adminApi.findCnnvdByPage,
                    params:data,
                    opt:{isLoading:false, showSuccess:false},
                    callback: {
                        success: data => {
                            self.loading = false;
                            self.tableData = data.result.content;
                            self.total = data.result.totalElements;
                        },
                        error: data =>{
                            self.loading = false;
                        },
                        fail: res =>{
                            self.loading = false;
                        }

                    }
                })

            }
        }
    }
</script>

<style scoped>

    .search-bt {
        width: 120px;
        margin-top: 51px;
    }
    a{
        color: #FFF;
    }
</style>