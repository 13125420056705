<template>
    <el-main style="min-width: 1200px">
        <div>
            <el-card class="center admin-style-list">
                <div slot="header" class="clearfix">
                    <span class="title-text">
                        <i class="icon iconfont icondata"></i>
                        密码设备自动发现
                    </span>
                </div>
                <el-row>
                    <el-col :span="24" class="scan">
                        <div class="box box-background">
                            <div class="xline"></div>
                        </div>
                        <div class="scanning-box">
                            <div class="scanning-item">
                                <device-line
                                    class="device-chart"
                                    name="123"
                                    :data="deviceChartData"
                                    :xAxis="deviceChartXAxis" />
                            </div>
                            <div class="scanning-item scanning-gauge-box">
                                <device-gauge :data="deviceGaugeChartData" :isPercentage="false" :max="100" />
                            </div>
                            <div class="scanning-item">
                                <device-line
                                    class="device-chart"
                                    name="123"
                                    :data="deviceChartData"
                                    :xAxis="deviceChartXAxis" />
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </div>

        <el-row :gutter="20" class="bottom-card">
            <el-col :span="12" class="news-list">
                <el-card>
                    <div slot="header" class="clearfix">
                        <span class="title-text">
                            <i class="icon iconfont icondata"></i>
                            最新发现
                        </span>
                    </div>

                    <el-row :gutter="35">
                        <el-form label-position="top" class="search-form" label-width="100px">
                            <el-col :span="7">
                                <el-form-item label="设备IP（精确匹配）">
                                    <el-input v-model="searchForm.deviceIp" :size="inputSize" clearable></el-input>
                                </el-form-item>
                            </el-col>

<!--                            <el-col :span="7">-->
<!--                                <el-form-item label="MAC地址">-->
<!--                                    <el-input v-model="searchForm.deviceMac" :size="inputSize" clearable></el-input>-->
<!--                                </el-form-item>-->
<!--                            </el-col>-->
                            <el-col :span="5">
                                <el-form-item label="." class="search-button-space">
                                    <el-button
                                        class="search-button"
                                        @click="select"
                                        :size="buttonSize"
                                        type="primary"
                                        plain>
                                        <span class="icon iconfont iconsearch"></span>
                                        查询
                                    </el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5" class="text-right search-button-space">
                                <el-form-item label=".">
                                    <el-button type="success" :size="buttonSize" @click="register">
                                        <span class="icon iconfont iconfileplus-fill"></span>
                                        <span>注册设备</span>
                                    </el-button>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                    <el-table
                        tooltip-effect="light"
                        :height="tableHeight"
                        v-loading="loading"
                        class="table-top width-ninety"
                        @selection-change="handleSelectionChange"
                        stripe
                        :data="tableData">
                        <el-table-column :model="tableData" fixed="left" type="selection" width="55"></el-table-column>
                        <el-table-column
                            prop="typeName"
                            min-width="100"
                            show-overflow-tooltip
                            label="设备类型"></el-table-column>
                        <el-table-column prop="createTime" width="120" show-overflow-tooltip label="发现时间">
                            <template slot-scope="scope">
                                {{ scope.row.createTime | formatDate }}
                            </template>
                        </el-table-column>

                        <el-table-column prop="deviceIp" width="100" show-overflow-tooltip label="IP"></el-table-column>
<!--                        <el-table-column-->
<!--                            prop="deviceMac"-->
<!--                            width="120"-->
<!--                            show-overflow-tooltip-->
<!--                            label="MAC"></el-table-column>-->

                        <!--                        <el-table-column-->
                        <!--                                prop="state"-->
                        <!--                                label="状态"-->
                        <!--                                width="80">-->
                        <!--                            <template  slot-scope="scope">-->
                        <!--                                <el-tag :type="scope.row.state === 1?'danger':'success'">{{scope.row.state ===1?'离线':'在线'}}</el-tag>-->
                        <!--                            </template>-->
                        <!--                        </el-table-column>-->
                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="read(scope.row)" size="mini">
                                    <span class="icon iconfont iconeye"></span>
                                    查看
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row class="paging-row width-ninety">
                        <el-col :span="24">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-sizes="[10, 15, 20, 30, 40, 50]"
                                layout="total, sizes, prev, pager, next, jumper"
                                :page-size="pageSize"
                                :current-page="pageNo"
                                :total="total"></el-pagination>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12" class="history-list">
                <el-card>
                    <div slot="header" class="clearfix">
                        <span class="title-text">
                            <i class="icon iconfont icondata"></i>
                            历史发现
                        </span>
                    </div>
                    <el-row :gutter="35">
                        <el-form label-position="top" class="search-form" label-width="100px">
                            <el-col :span="7">
                                <el-form-item label="设备IP（精确匹配）">
                                    <el-input v-model="searchForm.deviceIp" :size="inputSize" clearable></el-input>
                                </el-form-item>
                            </el-col>

<!--                            <el-col :span="7">-->
<!--                                <el-form-item label="MAC地址">-->
<!--                                    <el-input v-model="searchForm.deviceMac" :size="inputSize" clearable></el-input>-->
<!--                                </el-form-item>-->
<!--                            </el-col>-->
                            <el-col :span="5">
                                <el-form-item label="." class="search-button-space">
                                    <el-button
                                        class="search-button"
                                        @click="select"
                                        :size="buttonSize"
                                        type="primary"
                                        plain>
                                        <span class="icon iconfont iconsearch"></span>
                                        查询
                                    </el-button>
                                </el-form-item>
                            </el-col>
                            <!--                            <el-col :span="5" class="text-right search-button-space" >-->
                            <!--                                <el-form-item label=".">-->
                            <!--                                    <el-button type="success"  :size="buttonSize" @click="register"><span class="icon iconfont iconfileplus-fill"></span><span> 注册设备</span></el-button>-->
                            <!--                                </el-form-item>-->
                            <!--                            </el-col>-->
                        </el-form>
                    </el-row>
                    <el-table
                        tooltip-effect="light"
                        :height="tableHeight"
                        v-loading="loading"
                        class="table-top width-ninety"
                        stripe
                        :data="tableDataHistory">
                        <el-table-column
                            prop="typeName"
                            min-width="100"
                            show-overflow-tooltip
                            label="设备类型名称"></el-table-column>
                        <el-table-column prop="createTime" width="120" show-overflow-tooltip label="发现时间">
                            <template slot-scope="scope">
                                {{ scope.row.createTime | formatDate }}
                            </template>
                        </el-table-column>

                        <el-table-column prop="deviceIp" width="100" show-overflow-tooltip label="IP"></el-table-column>
<!--                        <el-table-column-->
<!--                            prop="deviceMac"-->
<!--                            width="120"-->
<!--                            show-overflow-tooltip-->
<!--                            label="MAC"></el-table-column>-->

                        <!--                        <el-table-column-->
                        <!--                                prop="state"-->
                        <!--                                label="状态"-->
                        <!--                                width="80">-->
                        <!--                            <template  slot-scope="scope">-->
                        <!--                                <el-tag :type="scope.row.state === 1?'danger':'success'">{{scope.row.state ===1?'离线':'在线'}}</el-tag>-->
                        <!--                            </template>-->
                        <!--                        </el-table-column>-->
                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="read(scope.row)" size="mini">
                                    <span class="icon iconfont iconeye"></span>
                                    查看
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row class="paging-row width-ninety">
                        <el-col :span="24">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-sizes="[10, 15, 20, 30, 40, 50]"
                                layout="total, sizes, prev, pager, next, jumper"
                                :page-size="pageSize"
                                :current-page="pageNo"
                                :total="totalHistory"></el-pagination>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!--设备信息编辑-->
        <el-dialog
            title="注册设备信息"
            :close-on-click-modal="false"
            :visible.sync="dialogEditmodelFormVisible"
            @close="closeDialog"
            width="40%"
            custom-class="mid-dialog-min-width">
            <el-form :model="modelForm" ref="modelForm">
                <el-form-item
                    label="设备名称"
                    prop="deviceName"
                    :label-width="formLabelWidth"
                    :rules="{ required: true, message: '设备名称不能为空', trigger: 'blur' }">
                    <el-input
                        v-model="modelForm.deviceName"
                        auto-complete="off"
                        :size="inputSize"
                        class="dialog-input-width"></el-input>
                </el-form-item>

                <el-row class="dialog-row">
                    <el-col :span="12">
                        <el-form-item
                            label="设备类型"
                            prop="deviceTypeId"
                            :label-width="formLabelWidth"
                            :rules="{ required: true, message: '设备类型不能为空', trigger: 'blur' }">
                            <el-select v-model="modelForm.deviceTypeId" placeholder="请选择">
                                <el-option
                                    v-for="item in types"
                                    :key="item.typeId"
                                    :label="item.typeName"
                                    :value="item.typeId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="设备IP"
                            prop="deviceIp"
                            :label-width="formLabelWidth"
                            :rules="{ required: true, message: '设备IP不能为空', trigger: 'blur' }">
                            <el-input v-model="modelForm.deviceIp" auto-complete="off" :size="inputSize"></el-input>
                        </el-form-item>
                        <el-form-item
                            label="设备端口"
                            prop="devicePort"
                            :label-width="formLabelWidth">
                            <el-input-number v-model="modelForm.devicePort" controls-position="right" :min="1" :max="65535" style="width: 100%"></el-input-number>
                        </el-form-item>

                    </el-col>

                    <el-col :span="12">
                        <el-form-item
                            label="所属单位"
                            prop="organizationId"
                            :label-width="formLabelWidth"
                            :rules="{ required: true, message: '请选择设备所属单位', trigger: 'blur' }">
                            <el-select
                                v-model="modelForm.organizationId"
                                placeholder="请选择"
                                popper-class="tree-select"
                                ref="selectReport">
                                <el-option :value="modelForm.organizationId" :label="modelForm.organizationName">
                                    <el-tree
                                        :data="organization"
                                        :props="defaultProps"
                                        @node-click="handleNodeClick"></el-tree>
                                </el-option>
                            </el-select>
                        </el-form-item>
<!--                        <el-form-item-->
<!--                            label="MAC"-->
<!--                            prop="deviceMac"-->
<!--                            :label-width="formLabelWidth">-->
                            <!--                            <el-input v-if="isAdd" v-model="modelForm.deviceMac" :size="inputSize" placeholder="FF-FF-FF-FF-FF-FF"></el-input>-->
<!--                            <span>{{ modelForm.deviceMac }}</span>-->
<!--                        </el-form-item>-->
                        <el-form-item label="序列号" prop="deviceSerialNumber" :label-width="formLabelWidth">
                            <el-input
                                v-model="modelForm.deviceSerialNumber"
                                auto-complete="off"
                                :size="inputSize"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="设备型号" prop="deviceModelNumber" :label-width="formLabelWidth">
                    <el-input class="dialog-input-width" v-model="modelForm.deviceModelNumber" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="生产厂家" prop="manufacturer" :label-width="formLabelWidth">
                    <el-input class="dialog-input-width" v-model="modelForm.manufacturer" :size="inputSize"></el-input>
                </el-form-item>
                <el-form-item label="所在区域" prop="regionId" :label-width="formLabelWidth">
                    <el-cascader
                        v-if="isEditRegion || !modelForm.regionFullName"
                        popper-class="dark-theme-popper"
                        @change="regionChange"
                        clearable
                        :props="regionProps"></el-cascader>
                    <span v-if="!isEditRegion && modelForm.regionFullName">{{ modelForm.regionFullName }}</span>
                    <el-button v-if="!isEditRegion && modelForm.regionFullName" type="text" @click="editRegion">
                        <i class="icon iconfont iconedit small-icon"></i>
                    </el-button>
                </el-form-item>
                <el-form-item label="详细位置" prop="address" :label-width="formLabelWidth">
                    <el-input class="dialog-input-width" v-model="modelForm.address" :size="inputSize"></el-input>
                </el-form-item>

                <!--                <el-form-item label="设备特征码" prop="featureCode" :label-width="formLabelWidth">-->
                <!--                    <el-input class="dialog-input-width" v-model="modelForm.featureCode"  :size="inputSize"></el-input>-->
                <!--                </el-form-item>-->

                <el-form-item label="功能描述" prop="functionDesc" :label-width="formLabelWidth">
                    <el-input
                        type="textarea"
                        class="dialog-input-width"
                        rows="6"
                        v-model="modelForm.functionDesc"
                        :size="inputSize"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel" class="black-button" :size="buttonSize">取 消</el-button>
                <el-button type="primary" @click="save('modelForm', 0)" :size="buttonSize">注 册</el-button>
                <!--                <el-button type="success" v-if="isAdd" :size="buttonSize" @click="save('modelForm',1)">保存并继续</el-button>-->
            </div>
        </el-dialog>
        <el-dialog
            title="查看设备信息"
            :close-on-click-modal="false"
            :visible.sync="dialogShowmodelFormVisible"
            width="40%"
            custom-class="mid-dialog-min-width">
            <el-form :model="showForm" ref="showForm">
                <el-form-item label="设备名称" :label-width="formLabelWidth">
                    {{ showForm.deviceName }}
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="设备类型" :label-width="formLabelWidth">
                            {{ showForm.deviceTypeName }}
                        </el-form-item>
                        <el-form-item label="设备IP" :label-width="formLabelWidth">
                            {{ showForm.deviceIp }}
                        </el-form-item>
                        <el-form-item label="设备端口" :label-width="formLabelWidth">
                            {{ showForm.devicePort == 0 ? '-' : showForm.devicePort }}
                        </el-form-item>

                        <!--                        <el-form-item label="状态" :label-width="formLabelWidth">-->
                        <!--                            <el-tag :type="showForm.state === 0?'success':'danger'">{{showForm.state ===0?'正常':'离线'}}</el-tag>-->
                        <!--                        </el-form-item>-->
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属单位" :label-width="formLabelWidth">
                            {{ showForm.organizationName }}
                        </el-form-item>
<!--                        <el-form-item label="MAC" :label-width="formLabelWidth">-->
<!--                            {{ showForm.deviceMac }}-->
<!--                        </el-form-item>-->
                        <el-form-item label="序列号" :label-width="formLabelWidth">
                            {{ showForm.deviceSerialNumber }}
                        </el-form-item>
                        <!--                        <el-form-item label="合规检测" :label-width="formLabelWidth">-->
                        <!--                            <el-tag :type="showForm.state === 1?'success':'danger'">{{showForm.state ===1?'正常':'告警中'}}</el-tag>-->
                        <!--                        </el-form-item>-->
                        <!-- <el-form-item label="生产厂家" :label-width="formLabelWidth">
                            {{ showForm.manufacturer }}
                        </el-form-item> -->
                    </el-col>
                </el-row>
                <el-form-item label="设备型号" :label-width="formLabelWidth">
                    {{ showForm.deviceModelNumber }}
                </el-form-item>
                <el-form-item label="生产厂家" :label-width="formLabelWidth">
                    {{ showForm.manufacturer }}
                </el-form-item>
                <el-form-item label="所在区域" :label-width="formLabelWidth">
                    {{ showForm.regionFullName }}
                </el-form-item>
                <el-form-item label="详细位置" :label-width="formLabelWidth">
                    {{ showForm.address }}
                </el-form-item>
                <el-form-item label="设备特征码" :label-width="formLabelWidth">
                    {{ showForm.featureCode }}
                </el-form-item>
                <el-form-item label="功能描述" :label-width="formLabelWidth">
                    {{ showForm.functionDesc ? showForm.functionDesc : '无' }}
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogShowmodelFormVisible = false" class="black-button" :size="buttonSize">
                    关闭
                </el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="设备类型管理"
            :close-on-click-modal="false"
            @close="typeDialogClose"
            :visible.sync="dialogTypeModelFormVisible"
            width="80%"
            custom-class="big-dialog-min-width">
            <device-type-dialog></device-type-dialog>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTypeModelFormVisible = false" class="black-button" :size="buttonSize">
                    关闭
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="导入设备数据"
            @close="closeImportDialog"
            :close-on-click-modal="false"
            :visible.sync="dialogImportVisible"
            width="20%"
            custom-class="small-dialog-min-width">
            <el-form :model="importForm" ref="importForm">
                <el-form-item label="模板下载" prop="templateFile" :label-width="formLabelWidth">
                    <a :href="fileServer + 'upload/devicetemplate.xlsx'" target="_blank">点击下载</a>
                </el-form-item>
                <el-form-item
                    label="导入文件"
                    prop="upFile"
                    :label-width="formLabelWidth"
                    :rules="{ required: true, message: '导入文件不能为空', trigger: 'blur' }">
                    <el-upload
                        ref="importUpload"
                        :action="importUrl"
                        :file-list="importFileList"
                        :on-success="handleImportSuccess"
                        :on-error="handleImportError"
                        :headers="headers"
                        :on-remove="handleImportRemove"
                        :on-change="handleImportChange"
                        :before-upload="handleImportBefore"
                        accept=".xls,.xlsx"
                        :limit="1"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传.xls，.xlsx文件，且不超过10M</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogImportVisible = false" class="black-button" :size="buttonSize">
                    取 消
                </el-button>
                <el-button type="primary" @click="importModel('importForm')" :size="buttonSize">导入</el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
import { formatDate } from '../../../assets/js/date'
export default {
    name: 'DevicesDiscovery',
    components: {},
    data() {
        return {
            fileServer: HOSTCONFIG.FILESERVER,
            tableHeight: window.innerHeight - 630,
            tableData: [],
            tableDataHistory: [],
            allTableData: [],
            modelForm: {
                id: '',
                deviceName: '',
                deviceTypeId: '',
                deviceSerialNumber: '',
                functionDesc: '',
                deviceMac: '',
                deviceIp: '',
                devicePort: null,
                deviceModelNumber: '',
                featureCode: '',
                manufacturer: '',
                address: '',
                regionId: 0,
                organizationId: '',
                regionFullName: '',
                organizationName: '',
                autoDeviceId: ''
            },
            searchForm: {
                deviceName: '',
                deviceIp: '',
                deviceSerialNumber: '',
                typeId: '',
                pageNo: 1,
                pageSize: 15
            },
            total: 0,
            pageNo: 1,
            pageSize: 15,
            loading: false,
            types: [],
            //查找
            inputSize: INPUTSIZE, //增加数据输入框大小
            buttonSize: BUTTONSIZE, // 按钮大小
            formLabelWidth: '160px', //增加数据输入框大小
            dialogEditmodelFormVisible: false, //用户对话框显示隐藏
            dialogTypeModelFormVisible: false,
            isAdd: false,
            isEditRegion: true,
            ids: [], //删除数据
            dialogReadmodelFormVisible: false,
            selectModels: [],
            dialogTypeApiModelFormVisible: false,
            dialogShowmodelFormVisible: false,
            showForm: {},
            importFileList: [],
            headers: {},
            importForm: {
                upFile: ''
            },
            importUrl: this.$api.adminApi.importDeviceWithExcel,
            organization: [],
            defaultProps: {
                label: 'label',
                children: 'children'
            },
            dialogImportVisible: false,
            resetUpload: false,
            regionProps: {
                lazy: true,
                lazyLoad: this.lazyLoad
            },
            currentDevice: {},
            totalHistory: 0,
            deviceChartData: [],
            deviceChartXAxis: [],
            deviceGaugeChartData: [
                {
                    value: 0
                }
            ]
        }
    },
    created() {},
    computed: {},
    mounted: function () {
        this.findByPage()
        this.findHistoryByPage()
        this.findAllTypes()
        this.getHeaders()
        this.findAllOrganization()
        this.initFakeData()
    },
    filters: {
        formatDate(date) {
            if (!date) return '-'
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
        }
    },
    methods: {
        initFakeData() {
            for (let i = 0; i < 100; i++) {
                let num = this._.getRandom(1000, 1800)
                this.deviceChartData.push(num)
                this.deviceChartXAxis.push('item')
                if (i === 99) {
                    this.handleFakeData()
                }
            }
        },
        handleFakeData() {
            setInterval(() => {
                this.deviceChartData.shift()
                this.deviceChartXAxis.shift()

                let num = this._.getRandom(1000, 1860)
                this.deviceChartData.push(num)
                this.deviceChartXAxis.push('item')

                this.deviceGaugeChartData = [
                    {
                        value: this.deviceGaugeChartData[0].value === 0 ? 10 : 0
                    }
                ]
            }, 1000)
        },

        register() {
            let self = this
            if (self.ids.length !== 1) {
                self.$message.error('必须且只能选择一条数据进行该操作')
                return
            }
            self.currentDevice = self.selectModels[0]
            self.modelForm.deviceIp = self.currentDevice.deviceIp
            if( self.currentDevice.devicePort == 0) {
                self.modelForm.devicePort = undefined
            } else {
                self.modelForm.devicePort = self.currentDevice.devicePort
            }
            // self.modelForm.deviceMac = self.currentDevice.deviceMac
            self.modelForm.deviceName = self.currentDevice.typeName
            self.modelForm.autoDeviceId = self.currentDevice.id
            // self.modelForm.deviceTypeName = self.currentDevice.deviceName;

            self.dialogEditmodelFormVisible = true
        },
        editRegion() {
            this.isEditRegion = true
            this.modelForm.regionId = ''
        },
        regionChange(value) {
            console.log(value)
            if (value && value.length > 0) {
                this.modelForm.regionId = value[value.length - 1]
            } else {
                this.modelForm.regionId = 0
            }
        },
        lazyLoad(node, resolve) {
            const { level } = node
            const { value } = node
            let self = this
            let parentId = value ? value : 0
            self.$http.post({
                url: self.$api.adminApi.findRegion,
                params: { parentId: parentId },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        const nodes = Array.from(data.result).map((item) => ({
                            value: item.value,
                            label: item.label,
                            leaf: level >= 2
                        }))
                        resolve(nodes)
                    }
                }
            })
        },
        handleNodeClick(data) {
            this.modelForm.organizationId = data.id
            this.modelForm.organizationName = data.label
            this.$refs.selectReport.blur()
            console.log(this.modelForm)
        },
        findAllOrganization() {
            let self = this
            self.$http.post({
                url: self.$api.adminApi.findAllOrganization,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.organization = data.result
                    }
                }
            })
        },
        getHeaders() {
            let Token = this.$cookies.get(TOKENNAME) == null ? '' : this.$cookies.get(TOKENNAME)
            this.headers = {
                'x-authorization': Token
            }
        },
        closeImportDialog() {
            this.$refs['importForm'].resetFields()
            this.importFileList = []
            this.findByPage()
            this.findHistoryByPage()
        },
        handleImportBefore(file) {
            //const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isLt2M) {
                this.$message.error('导入文件大小不能超过 10MB!')
                this.$refs['importForm'].resetFields()
                this.importFileList = []
                if (this.loadingInstance) {
                    this.loadingInstance.close()
                    this.loadingInstance = null
                }
            }
            return isLt2M
        },
        handleImportSuccess(response, file, fileList) {
            let self = this
            if (self.loadingInstance) {
                self.loadingInstance.close()
                self.loadingInstance = null
            }
            if (response.error == 0) {
                self.$alert('导入成功', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'success',
                    callback: (action) => {
                        self.dialogImportVisible = false
                    }
                })
            } else {
                self.$alert(response.msg, '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                self.$refs['importForm'].resetFields()
                self.importFileList = []
                self.resetUpload = true
            }
        },
        handleImportError(err, file, fileList) {
            if (this.loadingInstance) {
                this.loadingInstance.close()
                this.loadingInstance = null
            }
            this.$alert('导入文件失败：' + err, '提示', {
                confirmButtonText: '确定',
                showClose: false,
                type: 'error'
            })
            return
        },
        handleImportRemove(file, fileList) {
            if (fileList.length == 0) {
                this.importForm.upFile = null
            }
        },
        handleImportChange(file, fileList) {
            if (fileList.length == 0) {
                this.importForm.upFile = null
            } else {
                this.importForm.upFile = file
            }
            if (this.resetUpload) {
                this.importForm.upFile = null
                this.resetUpload = false
            }
        },
        importModel(formName) {
            let self = this
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.loadingInstance = self.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.5)'
                    })
                    self.$refs.importUpload.submit()
                } else {
                    return false
                }
            })
        },
        importData() {
            this.dialogImportVisible = true
        },
        findAllTypes() {
            let self = this
            self.$http.post({
                url: self.$api.deviceApi.queryAllDeviceType,
                params: { ids: self.ids },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.types = data.result
                    }
                }
            })
        },
        typeDialogClose() {
            this.findAllTypes()
        },
        handleSizeChange(val) {
            this.pageNo = 1
            this.pageSize = val
            this.findByPage()
        },
        handleCurrentChange(val) {
            this.pageNo = val
            this.findByPage()
        },

        select() {
            this.pageNo = 1
            this.findByPage()
        },
        findByPage() {
            let self = this
            self.searchForm.pageNo = self.pageNo - 1
            self.searchForm.pageSize = self.pageSize
            self.loading = true
            self.$http.post({
                url: self.$api.deviceApi.automaticLastestList,
                params: self.searchForm,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.loading = false
                        self.tableData = data.result.content
                        //console.log(self.tableData);
                        self.total = data.result.totalElements
                    },
                    error: (data) => {
                        self.loading = false
                    },
                    fail: (res) => {
                        self.loading = false
                    }
                }
            })
        },
        findHistoryByPage() {
            let self = this
            self.searchForm.pageNo = self.pageNo - 1
            self.searchForm.pageSize = self.pageSize
            self.loading = true
            self.$http.post({
                url: self.$api.deviceApi.automaticHistoryList,
                params: self.searchForm,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.loading = false
                        self.tableDataHistory = data.result.content
                        //console.log(self.tableData);
                        self.totalHistory = data.result.totalElements
                    },
                    error: (data) => {
                        self.loading = false
                    },
                    fail: (res) => {
                        self.loading = false
                    }
                }
            })
        },
        //编辑
        edit() {
            let self = this
            if (self.selectModels.length !== 1) {
                self.$alert('需要且只能选择一条数据进行此操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
            } else {
                this.modelForm = self.selectModels[0]
                this.isAdd = false
                this.isEditRegion = false
                this.dialogEditmodelFormVisible = true
            }
        },
        read(row) {
            //查看
            this.showForm = row
            this.dialogShowmodelFormVisible = true
        },
        //编辑列表
        save: function (formName, val) {
            let self = this
            self.$refs[formName].validate((valid) => {
                if (valid) {
                    self.doSaveModel(val)
                } else {
                    return false
                }
            })
        },
        clearForm() {
            this.modelForm = {
                id: '',
                deviceName: '',
                deviceTypeId: '',
                deviceSerialNumber: '',
                functionDesc: '',
                deviceMac: '',
                deviceIp: '',
                deviceModelNumber: '',
                featureCode: '',
                manufacturer: '',
                address: '',
                regionId: 0,
                regionFullName: '',
                organizationId: '',
                organizationName: '',
                autoDeviceId: ''
            }
            this.$refs['modelForm'].resetFields()
        },
        doSaveModel(val) {
            let self = this
            // self.modelForm.region = null
            self.$http.post({
                url: self.$api.deviceApi.autoDeviceToCryptoDevice,
                params: self.modelForm,
                callback: {
                    success: (data) => {
                        if (val === 0) {
                            self.findByPage()
                            self.dialogEditmodelFormVisible = false
                        } else {
                            self.clearForm()
                        }
                    }
                }
            })
        },
        // 查
        select: function () {
            this.pageNo = 1
            this.findByPage()
        },
        //取消按钮
        cancel() {
            this.dialogEditmodelFormVisible = false
        },
        //提供删除
        handleSelectionChange(val) {
            let self = this
            self.selectModels = val
            self.ids = []
            for (let i = 0; i < val.length; i++) {
                self.ids.push(val[i].id)
            }
        },
        closeDialog() {
            this.$refs['modelForm'].resetFields()
            this.findByPage()
        }
    }
}
</script>

<style lang="scss" scoped>
.input-width {
    width: 300px;
}
.mar-right {
    margin-right: 35px;
}
.long-bt {
    width: 120px;
}
.mar-top {
    margin-top: 30px;
}

.label-text {
    color: #606266;
    line-height: 40px;
    display: inline-block;
    padding: 0 0 10px;
    font-size: 14px;
}
aa {
    background: #2dcb73;
}
a {
    color: #fff;
}
.bottom-card {
    margin-top: 20px;
}
.scan {
    position: relative;
    .box {
        height: 150px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .xline {
        height: 100%;
        width: 10%;
        background: linear-gradient(90deg, rgba(0, 255, 51, 0) 43%, #03a9f4 211%);
        border-right: 2px solid #03a9f4;
        transform: translateX(-100%);
        animation: radar-beam 6.2s infinite;
        animation-timing-function: cubic-bezier(0.3, 0, 0.43, 0.7);
        animation-delay: 0.8s;
    }
}
@keyframes radar-beam {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(1000%);
    }
}
/*.history-list{*/
/*    background: #125112;*/
/*}*/
/*.news-list{*/
/*    background: #212533;*/
/*}*/
.device-chart {
    height: 150px !important;
    opacity: 0.4;
}
.scanning-box {
    display: flex;
    .scanning-item {
        width: 35%;
    }
    .scanning-gauge-box {
        width: 30%;
    }
}
</style>
<style>
.tree-select .el-select-dropdown__item {
    padding: 0;
}
</style>
