<template>
    <el-dialog center title="X.509 数字证书" style="margin-top: -10vh;" :close-on-click-modal="false" :visible="dialogVisible" @close="closeDialog" width="50%" custom-class="big-dialog-min-width">
        <el-form label-position="right" class="dialog-form" label-width="140px" v-if="dialogVisible">
            <h3 class="con">使用者</h3>
            <el-form-item label="通用名称">
                {{certDetail.subject.common_name}}
            </el-form-item>
            <el-form-item label="组织" v-if="certDetail.subject.organization_name">
                {{certDetail.subject.organization_name}}
            </el-form-item>
            <el-form-item label="国家" v-if="certDetail.subject.country_name">
                {{certDetail.subject.country_name}}
            </el-form-item>
            <el-form-item v-if="certDetail.subject_alt_names && certDetail.subject_alt_names.length>0" label="备用名称">
                {{certDetail.subject_alt_names_str}}
            </el-form-item>
            <h3 class="con">签发者</h3>
            <el-form-item label="通用名称" v-if="certDetail.issuer.common_name">
                {{certDetail.issuer.common_name}}
            </el-form-item>
            <el-form-item label="组织" v-if="certDetail.issuer.organization_name">
                {{certDetail.issuer.organization_name}}
            </el-form-item>
            <el-form-item label="国家" v-if="certDetail.issuer.country_name">
                {{certDetail.issuer.country_name}}
            </el-form-item>
            <h3 class="con">基本信息</h3>
            <el-form-item label="SHA1指纹">
                {{certDetail.fingerprint_sha1}}
            </el-form-item>
            <el-form-item label="SHA256指纹">
                {{certDetail.fingerprint_sha256}}
            </el-form-item>
            <el-form-item label="序列号">
                {{certDetail.serial_number}}
            </el-form-item>
            <el-form-item label="生效时间">
                {{certDetail.not_valid_before_str | formatDateFilter}}
            </el-form-item>
            <el-form-item label="失效时间">
                {{certDetail.not_valid_after_str | formatDateFilter}}
            </el-form-item>
            <el-form-item label="公钥算法">
                {{certDetail.pub_alg}}
            </el-form-item>
            <el-form-item label="公钥">
                <pre class="plc">{{certDetail.public_key_str}}</pre>
            </el-form-item>
            <el-form-item label="签名算法">
                {{certDetail.signature_algorithm}}
            </el-form-item>
            <el-form-item label="签名">
                <pre class="plc">{{certDetail.signature_str}}</pre>
            </el-form-item>
            <h3 class="con">扩展信息</h3>
            <el-form-item label="扩展版本">
                {{certDetail.x509_version}}
            </el-form-item>
            <el-form-item label="基本限定">
                {{certDetail.extensions.basicConstraints}}
            </el-form-item>
            <el-form-item label="密钥用途" v-if="certDetail.extensions.keyUsage">
                {{certDetail.extensions.keyUsage}}
            </el-form-item>
            <el-form-item label="扩展密钥用途" v-if="certDetail.extensions.extendedKeyUsage">
                {{certDetail.extensions.extendedKeyUsage}}
            </el-form-item>
            <el-form-item label="使用者KeyID" v-if="certDetail.extensions.subjectKeyIdentifier">
                {{certDetail.extensions.subjectKeyIdentifier}}
            </el-form-item>
            <el-form-item label="颁发机构信息访问" v-if="certDetail.extensions.authorityInfoAccess">
                {{certDetail.extensions.authorityInfoAccess}}
            </el-form-item>
            <el-form-item label="CRL分发点" v-if="certDetail.extensions.crlDistributionPoints">
                {{certDetail.extensions.crlDistributionPoints}}
            </el-form-item>
            <el-form-item label="SCT时间戳" v-if="certDetail.extensions.ct_precert_scts">
                <pre class="plc">{{certDetail.extensions.ct_precert_scts}}</pre>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible=false" class="black-button">关 闭</el-button>
            <el-button type="primary" @click="downloadCert(certDetail)"><span class="icon iconfont iconarrow-to-bottom"></span> 下载</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        value: Boolean,
        certDetail: {
            type: Object,
            default: {
                subject: {
                    common_name: ''
                },
                issuer: {
                    common_name: ''
                },
                extensions: {}
            }
        }
    },
    data() {
        return {
            dialogVisible: false,
            // certDetail: {
            //     subject: {
            //         common_name: ''
            //     },
            //     issuer: {
            //         common_name: ''
            //     },
            //     extensions: {}
            // },
        }
    },
    watch: {
        value(val) {
            this.dialogVisible = val;
        }
    },
    mounted() {
    },
    methods: {
        closeDialog(e) {
            
            this.dialogVisible = false;
            this.$emit('input', false);
        },
        downloadCert(row) {
            let bold = new Blob([row.pem_string], { type: "text/plain;charset=utf-8" });
            let fileName = `${row.fingerprint_sha1}.pem`
            this.$saveAs(bold, fileName);
        }
    }
}
</script>
<style scoped>
.con {
    border-bottom: 3px solid #023d79;
    font-size: 19px;
}
.main {
    border-top: 1px solid #ccc;
    width: 95%;
    margin: auto;
    height: 30px;
    line-height: 30px;
    display: flex;
}
.public {
    border-top: 1px solid #ddd;
    width: 95%;
    margin: auto;
    line-height: 30px;
}
.plc {
    line-height: 16px;
    margin: 0;
    padding: 10px 0;
}
.public[data-v-a7cc0052] {
    line-height: 10px;
}
paging-row {
    padding-top: 0px;
    margin-top: 0px;
}
pre {
    margin: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.long-bt {
    width: 120px;
}
.label-text {
    color: #606266;
    line-height: 40px;
    display: inline-block;
    padding: 0 0 10px;
    font-size: 14px;
}
.label-text {
    color: #606266;
    line-height: 40px;
    display: inline-block;
    padding: 0 0 10px;
    font-size: 14px;
}
a {
    color: gainsboro;
}
.cert-tips {
    color: red;
}
</style>
