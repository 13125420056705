<template>
    <div class="certificate-list">
        <el-form label-position="top" class="search-form" label-width="100px">
            <el-row :gutter="15">

                <el-col :span="5">
                    <el-form-item label="使用者">
                        <el-input v-model="searchForm.subject" clearable></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="5">
                    <el-form-item label="颁发者">
                        <el-input v-model="searchForm.issuer" clearable></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="5">
                    <el-form-item label="公钥类型">
                        <el-select v-model="searchForm.pubkey" style="width: 100%" clearable placeholder="请选择">
                            <el-option v-for="item in types" :key="item.value" :label="item.typeName" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="5">
                    <el-form-item label="." class="search-button-space">
                        <el-button class="search-button" @click="handleSearch" type="primary" plain><span class="icon iconfont iconsearch"></span> 查询</el-button>
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>

        <el-table tooltip-effect="light" class="table-top width-ninety" v-loading="loading" height="280" stripe :data="dataList">
            <el-table-column fixed="left" type="selection" width="55">
            </el-table-column>
            <el-table-column prop="subject" label="使用者 " min-width="80">
                <template slot-scope="scope">
                    {{scope.row.subject.common_name}}
                </template>
            </el-table-column>
            <el-table-column prop="issuer" label="签发者 " show-overflow-tooltip min-width="120">
                <template slot-scope="scope">
                    {{scope.row.issuer.common_name}}
                </template>
            </el-table-column>
            <el-table-column prop="pub_alg" label="公钥算法" show-overflow-tooltip min-width="120">
            </el-table-column>
            <el-table-column min-width="180" label="有效期">
                <template slot-scope="scope">
                    <span :style="checkCertStatusIsExp(scope.row.not_valid_after_str)?'color:red':''">{{scope.row.not_valid_before_str|formatDateFilter('yyyy-MM-dd')}} - {{scope.row.not_valid_after_str|formatDateFilter('yyyy-MM-dd')}}</span>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="fingerprint_sha1" label="SHA1指纹" min-width="160">
            </el-table-column>
            <el-table-column fixed="right" width="60" label="操作">
                <template slot-scope="scope">
                    <el-tooltip effect="light" content="查看" placement="top">
                        <el-button type="text" @click="handleDetail(scope.row)"><span class="icon iconfont iconeye "></span></el-button>
                    </el-tooltip>
                    <el-tooltip effect="light" content="下载" placement="top">
                        <el-button type="text" @click="downloadCert(scope.row)"><span class="icon iconfont iconarrow-to-bottom"></span></el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-row class="paging-row width-ninety">
            <el-col :span="24">
                <el-pagination layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 15, 20, 30, 40, 50]" :page-size="pageSize" :current-page="pageNo" :total="total">
                </el-pagination>
            </el-col>
        </el-row>
        <certificate-dialog v-model="dialogCertDetailVisible" :certDetail="certDetail" />
    </div>
</template>
<script>
import certificateDialog from './certificateDialog.vue'
export default {
    components: {
        certificateDialog
    },
    data() {
        return {
            searchForm: {
                subject: null,
                issuer: null,
                pubkey: null,
                isValid: 1// 0不显示；1显示
            },
            dataList: [],
            loading: false,
            pageNo: 1,
            pageSize: 15,
            total: 0,
            types: [{
                typeName: 'RSA',
                value: 'RSA'
            }, {
                typeName: 'EC:SM2',
                value: 'EC:SM2'
            }, {
                typeName: 'ECC',
                value: 'ECC'
            }],
            nowTime: null,
            dialogCertDetailVisible: false,
            certDetail: {}

        }
    },
    mounted() {
        this.findCertByPage()
    },
    methods: {
        checkCertStatusIsExp(time) {
            if (!this.nowTime) return;
            let now = this.nowTime;
            let cert = time;
            if (cert > now) {
                return false;
            } else {
                return true;
            }
        },
        handleSizeChange(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.findCertByPage();
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.findCertByPage();
        },
        handleSearch() {
            this.pageNo = 1;
            this.findCertByPage()
        },
        findCertByPage() {
            let params = {
                pageNo: this.pageNo - 1,
                pageSize: this.pageSize,
                query: {
                    ...this.searchForm
                }
                
            }
            this.loading = true
            this.$http.post({
                url: this.$api.encryptionApi.findCertByPage,
                params: params,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        this.dataList = data.result.content || [];
                        this.total = data.result.totalElements
                        this.nowTime = data.time;
                        this.loading = false
                    }
                }
            })

        },
        handleDetail(row) {
            this.certDetail = row
            this.dialogCertDetailVisible = true
        },

        downloadCert(row) {
            let bold = new Blob([row.pem_string], { type: "text/plain;charset=utf-8" });
            let fileName = `${row.fingerprint_sha1}.pem`
            this.$saveAs(bold, fileName);
        },
    }

}
</script>
<style lang="scss" scoped>
.certificate-list {
    padding-top: 10px;
}
</style>