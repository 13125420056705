<template>
  <el-tabs v-model="editableTabsValue" type="card" @tab-remove="removeTab" @tab-click="tabClick" class="nav-tabs-top">
    <el-tab-pane
      :key="item.name"
      v-for="(item, index) in editableTabs"
      :label="item.title"
      :name="item.name"
      :closable="item.name != '0-1'"
    >
      {{item.content}}
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import Bus from '../assets/js/eventBus';
    export default {
      name: 'NavTabs',
      data() {
        return {
          editableTabsValue: '0-1',
          editableTabs: [{
            parentNode:"0",
            title: '首页',
            name: '0-1',
            routePath:'/statistics'
          }],
          tabIndex: 1
        }
      },
      created(){
        let self = this;

      },
      mounted(){
        let self = this;
        Bus.$on("pageRefreshEvent",function(msg){
          self.doRefreshMenu(msg);
          // console.log(msg)

        })
        Bus.$on("changedLeftMenuEvent",function(msg){
          self.doChangedMenu(msg);
        })
      },
      methods: {
        addTabs(menu){
          this.editableTabs.push(menu);
          this.editableTabsValue = menu.name;
        },
        currentTabs(menu){
          this.editableTabsValue = menu.name;
        },
        tabClick(tabs,event){
          let tabName = tabs.name;
          let self = this;
          let routePath = self.$route.path;
          for(var i = 0; i< self.editableTabs.length;i++){
            if(self.editableTabs[i].name == tabName){
              if(routePath != self.editableTabs[i].routePath){
                self.sendMsg(self.editableTabs[i]);
              }
              break;
            }
          }
        },
        removeTab(targetName) {
          let tabs = this.editableTabs;
          let self = this;
          if(tabs.length <= 1){
            self.$alert("当前界面不允许关闭了", '提示', {
              confirmButtonText: '确定',
              showClose: false,
              type: "warning"
            });
            return;
          }
          let activeName = this.editableTabsValue;
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.name;
                  self.sendMsg(nextTab);
                }
              }
            });
          }
          this.editableTabsValue = activeName;
          this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        },
//        handleTabsEdit(targetName, action) {
//          let self = this;
//          if (action === 'add') {
//
//          }
//          if (action === 'remove') {
//            let tabs = this.editableTabs;
//            if(tabs.length <= 1){
//              self.$alert("当前界面不允许关闭了", '提示', {
//                confirmButtonText: '确定',
//                showClose: false,
//                type: "warning"
//              });
//              return;
//            }
//            let activeName = this.editableTabsValue;
//            if (activeName === targetName) {
//              tabs.forEach((tab, index) => {
//                if (tab.name === targetName) {
//                  let nextTab = tabs[index + 1] || tabs[index - 1];
//                  if (nextTab) {
//                    activeName = nextTab.name;
//                  }
//                }
//              });
//            }
//
//            this.editableTabsValue = activeName;
//            this.editableTabs = tabs.filter(tab => tab.name !== targetName);
//          }
//        },
        sendMsg:function(data){
          Bus.$emit("changedNavTabsEvent",data);
        },
        doChangedMenu:function(data){
          let self = this;
          let isHave = false;
          for(var i = 0 ; i< self.editableTabs.length; i++){
            if(self.editableTabs[i].name == data.name){
              isHave = true;
              break;
            }
          }
          if(isHave){
            self.currentTabs(data);
          }else{
            self.addTabs(data);
          }
          //console.log(data);
        },
        doRefreshMenu(data){
          let self = this;
          // console.log(data)
          self.addTabs(data);
          //console.log(routePath);
        }
      }
    }
</script>
