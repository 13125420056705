//export var HOSTCONFIG.RESTHOST= "http://192.168.3.141:8080/csasystem/";//服务器地址，如果有项目名称请带项目名称
//export var FileHost= "http://192.168.3.141:8080/";//文件服务器地址，如果有项目名称请带项目名称

export default {
    HostApi: {
        fileHost: HOSTCONFIG.FILESERVER,
        timeHost: HOSTCONFIG.WSSERVER
    },
    uKeyApi:{
        action:"http://127.0.0.1:7093/"
    },
    netFlowApi: {
        getData: "admin/getNetFlowData",
        getProtocolData: "admin/getProtocolCryptoData",
        getProtocolCryptoStatisticData: "admin/getProtocolCryptoStatisticData"
    },
    actionApi: {
        userLogin: "action/adminlogin",
        userLogout: "action/adminlogout",
        simDunLogin: `admin/simDunApi/simDunLogin`, // sim盾登录
        checkSimDunLogin: `admin/simDunApi/checkSimDunLogin`, // SIM盾登录进度查询
    },
    systemApi: {
        getUserInfo: "system/getAdminInfo",
        saveUserPassword: "system/saveAdminPassword",
        saveAdminRealName: "system/saveAdminRealName",
        findStaticsData: "system/findStaticsData",//获取首页统计数据
        getSystemMenuList: "system/getSystemMenuList",//获取菜单
        saveAdminUserInfo: "system/saveAdminUserInfo", //修改用户基本信息
        getAgentInfoForOneHour: "system/getAgentInfoForOneHour", //获取所有探针1小时内的系统数据
        findWeekStatsProtocol: "system/findWeekStatsProtocol", //一周内网络通信协议连接图
        getAgentNetFlowWithNow: "system/getAgentNetFlowWithNow", //获取所有探针当前流量大小
        findBackendNumberStatics: "system/findBackendNumberStatics", //后台首页数字统计
    },
    deviceApi: {
        //设备管理模块
        queryDeviceByExample: "admin/findCryptoDeviceByPage",//分页查询设备信息
        saveOrUpdateDevice: "admin/saveOrUpdateDevice",//新增或者修改设备信息
        updateDeviceToEnabled: "admin/updateDeviceToEnabled",//批量启用设备信息
        updateDeviceToDisabled: "admin/updateDeviceToDisable",//批量禁用设备信息
        queryDeviceTypeByExample: "admin/queryDeviceTypeByExample",//分页查询设备类型
        deleteDevices: "admin/deleteDevices",//删除设备
        queryAllDeviceType: "admin/queryAllDeviceType",//设备类型全部列表
        saveOrUpdateDeviceType: "admin/saveOrUpdateDeviceType",//新增或者修改设备类型
        delDeviceType: "admin/delDeviceType",//删除设备类型
        updateDeviceTypeToDisabled: "admin/updateDeviceTypeToDisabled", //批量禁用设备类型
        updateDeviceTypeToEnabled: "admin/updateDeviceTypeToEnabled", //批量启用设备类型
        replaceChildNodeDeviceData: "admin/replaceChildNodeDeviceData", //同步
        reportDeviceByIds: "admin/reportDeviceByIds", //上报
        
        //设备发现模块
        automaticLastestList: "admin/automaticLastestList", //最新发现设备
        automaticHistoryList: "admin/automaticHistoryList", //历史发现设备
        autoDeviceToCryptoDevice: "admin/autoDeviceToCryptoDevice", //设备注册成正式设备
        //数据统计
        deviceStatistics: "admin/deviceStatistics", //密码设备数据统计
        getConnectInfoList: "admin/getConnectInfoList", //密码设备通讯数据列表
        
    },
    applicationApi: {
        //密评应用
        adminFindApplicationsByPage: "admin/adminFindApplicationsByPage", //获取申报应用分页
        saveApplicationEvaluate: "admin/saveApplicationEvaluate", //新增或编辑密评应用信息
        saveApplicationsType: "admin/saveApplicationsType", //新增应用分类
        delApplicationsTypeByIds: "admin/delApplicationsTypeByIds", //批量删除应用分类
        getAllApplicationsType: "admin/getAllApplicationsType", //获取所有应用分类
        findAllArithmetic: "admin/findAllArithmetic", //获取所有密码算法
        applicationReport: "admin/applicationReport", //应用上报
        applyForReview: "admin/applyForReview", //申请
        verify: "admin/verify", //审核,
        findApplicationTypeByPage: "admin/findApplicationTypeByPage", //获取应用分类分页信息
        findApplicationsLogByPageAndId: "admin/findApplicationsLogByPageAndId", //获取日志分页信息
        adminfindApplicationsById: "admin/adminfindApplicationsById", //根据id获取信息
        reviewRollback: "admin/reviewRollback", //不通过审核回滚编辑状态
        adminDelApplications: "admin/adminDelApplications", //删除应用信息
        addDeviceToApplications: "admin/addDeviceToApplications", //添加应用信息与设备的关联
        delDeviceFromApplications: "admin/delDeviceFromApplications", //删除应用信息与设备的关联
    },
    encryptionApi: {
        networkEncryptMonitor: "admin/networkEncryptMonitor", // 网络层加密监控
        transportEncryptMonitor: "admin/transportEncryptMonitor", // 传输层加密监控
        plainProtocolMonitor: "admin/plainProtocolMonitor", // 明文协议监控
        findCertByPage: "admin/findCertByPage", // 数字证书分页列表
        certProtocolMonitor: "admin/certProtocolMonitor", // 数字证书检测
        
    },
    warningApi: {
        findWarnStatisticsData: "admin/findWarnStatisticsData",//威胁告警页面统计数据统一接口
        findCipherConformanceNoticeByPage: "admin/findCipherConformanceNoticeByPage",//威胁告警分页数据统一接口
    },
    InformationApi:{//系统管理 -- 系统信息
        addAndUpdateSystemInfo:  'admin/addAndUpdateSystemInfo',
        findSystemInfo:  'admin/findSystemInfo',
        saveNicInfo: "admin/saveNicInfo"
    },
    licenseApi:{
        getLicenseInfo: "license/getLicenseInfo",
        active: "license/active"
    },
    adminApi: {


        findAdminListByPage: "admin/findAdminListByPage", //用户管理分页
        saveOrUpdateSystemAdmin: "admin/saveOrUpdateSystemAdmin", //保存用户
        updateSystemAdminToEnabled: "admin/updateSystemAdminToEnabled", //批量启用用户
        updateSystemAdminToDisabled: "admin/updateSystemAdminToDisabled", //批量禁止用户
        delAdminUsers: "admin/delAdminUsers", //批量删除用户
        // SIM 盾
        openSimDun: `admin/simDunApi/openSimDun`, //开启SIM盾登录功能
        checkOpenSimDun: `admin/simDunApi/checkOpenSimDun`, //开启SIM盾登录进度查询
        updateSimDunPhone: `admin/simDunApi/updateSimDunPhone`, //更换用户的sim盾登录手机号
        checkUpdateSimPhone: `admin/simDunApi/checkUpdateSimPhone`, //更换sim盾登录手机号进度查询
        closeSimDun: `admin/simDunApi/closeSimDun`, //关闭用户的SIM盾登录功能
        checkCloseSimDun: `admin/simDunApi/checkCloseSimDun`, //关闭sim盾登录执行进度查询
        resetSimSignPwd: `admin/simDunApi/resetSimSignPwd`, //重置或设置用户SIM盾口令，0重置，1设置
        updateSimSignPwd: `admin/simDunApi/updateSimSignPwd`, //修改用户SIM盾口令
        checkSimPwd: `admin/simDunApi/checkSimPwd`, //查询sim口令重置、设置、修改执行进度
        resetSimDun: `admin/simDunApi/resetSimDun`, //重置用户的SIM盾
        //日志列表
        getAdminLoginLogDto: "admin/getAdminLoginLogDto", //日志列表

        //组织架构
        findAllOrganization: "admin/findAllOrganization", //组织架构列表
        saveOrUpdateOrganization: "admin/saveOrUpdateOrganization", //保存组织架构列表
        deleteOrganization: "admin/deleteOrganization", //删除组织架构列表
        // findAdminListByOrganizationForPage: "admin/findAdminListByOrganizationForPage",  //节点用户列表
        importOrganizationInfo: "admin/importOrganizationInfo",  //导入组织结构

        sendActiveCodeById: "admin/sendActiveCodeById",//重新发送激活邮件

        // 数字证书管理
        findCertByPage: "admin/findCertByPage",//数字证书分页列表
        buildCertDownloadUrl: "admin/buildCertDownloadUrl",//获取数字证书下载地址
        findBySha1: "admin/findBySha1",//获取单个数字证书详情
        buildNewCert: "admin/buildNewCert",//生成证书
        getCertInfoFromFile: "admin/getCertInfoFromFile",//解析数字证书
        findAppOrDeviceBySha1Page: "admin/findAppOrDeviceBySha1Page",//使用当前证书的应用或设备分页列表
        saveAppOrDeviceForCertByIds: "admin/saveAppOrDeviceForCertByIds",//为当前证书关联设备或应用信息
        delAppOrDeviceForCertByIds: "admin/delAppOrDeviceForCertByIds",//删除当前证书的应用或设备关联
        findCveByPage: "admin/findCveByPage",//查询CVE漏洞分页
        findCnnvdByPage: "admin/findCnnvdByPage",//查询CNNVD漏洞分页
        importCveDataInfo: "admin/importCveFromJsonFile",//导入CVE漏洞文件
        importCnnvdDataInfo: "admin/importCnnvdFromJsonFile",//导入CNNVD漏洞文件
        findDeviceOrApplicationNotInCertBySha1Page: "admin/findDeviceOrApplicationNotInCertBySha1Page",//查询未被关联当前证书的设备或者应用


        findDeviceWarnByPage: "admin/findDeviceWarnByPage",//设备异常告警信息分页
        handleDeviceWarnLog: "admin/handleDeviceWarnLog",//设备异常处理
        findAppWarnByPage: "admin/findAppWarnByPage",//应用异常告警信息分页
        handleAppWarnLog: "admin/handleAppWarnLog",//应用异常处理
        findDeviceVulWarnByPage: "admin/findDeviceVulWarnByPage",
        findAppVulWarnByPage: "admin/findAppVulWarnByPage",

        getRandomResultByPage: "admin/getRandomResultByPage",//随机数检测文件分页
        sendFileAndStartRandomCheck: "admin/sendFileAndStartRandomCheck",//随机数检测上传文件
        getRandomResultWithFileId: "admin/getRandomResultWithFileId",//单个文件检测详情
        stopRandomTest: "admin/stopRandomTest",//强制结束检测

        // findApplicationLogsByPage: "admin/findApplicationLogsByPage",//应用日志分页查询

        getAlgInfoFromCryptoFile: "admin/getAlgInfoFromCryptoFile",//上传文件确认加密算法
        getCryptoDeviceApiStats: "admin/getCryptoDeviceApiStats",//获取设备API接口的数据信息统计



        // saveOrUpdateDeviceApi: "admin/saveOrUpdateDeviceApi",//新增API
        // queryDeviceApiByExample: "admin/queryDeviceApiByExample",//分页查询设备API
        // delDeviceApi: "admin/delDeviceApi",//删除API
        // updateDeviceApiToEnabled: "admin/updateDeviceApiToEnabled",//批量启用设备API
        // updateDeviceApiToDisabled: "admin/updateDeviceApiToDisabled",//批量禁用设备API

        // saveOrUpdateApplication: "admin/saveOrUpdateApplication",//密码应用新增和修改
        // queryApplicationByExample: "admin/queryApplicationByExample",//分页查询密码应用
        // updateApplicationToEnabled: "admin/updateApplicationToEnabled",//批量启用应用
        // delApplications: "admin/delApplications",//批量删除密码应用
        // saveOrUpdateApplicationType: "admin/saveOrUpdateApplicationType",//新增或者修改密码应用类型
        // queryAPPlicationTypeByExample: "admin/queryAPPlicationTypeByExample",//分页查询应用类型
        // updateAPPlicationTypeToEnabled: "admin/updateAPPlicationTypeToEnabled",//批量启用应用类型
        // updateAPPlicationTypeToDisable: "admin/updateAPPlicationTypeToDisabled",//批量禁用应用类型
        // queryAllApplicationType: "admin/queryAllApplicationType",//查询所有的应用类型
        // delAPPlicationType: "admin/delAPPlicationType",//删除应用类型
        //findAllEnabledGroup : 'admin/findAllEnabledGroup',//查询所有可用的角色组

        //add new
        delCerts: "admin/delCerts",//删除证书
        importApplicationFromExcel: "admin/importApplicationFromExcel",//导入应用
        importDeviceWithExcel: "admin/importDeviceWithExcel",//导入设备

        findRegion: "admin/findRegion",//查询区域

        // 探针管理
        findAgentByPage: "admin/findAgentByPage", //探针管理列表分页
        findAllAgents: "admin/findAllAgents", //查询所有探针
        saveAgent: "admin/saveAgent", //探针信息更新
        delAgentByIds: "admin/delAgentByIds", //删除探针信息

        // 委外用户
        saveExternalUser: "admin/saveExternalUser", //新增外部用户信息
        findExternalUserByPage: "admin/findExternalUserByPage", //获取外部用户分页
        delExternalUserByIds: "admin/delExternalUserByIds", //删除外部用户

        //网络管理
        saveNicInfo: "admin/saveNicInfo", //保存网口
        findSystemInfo: "admin/findSystemInfo", //系统信息
        getNicInfo: "admin/getNicInfo", //查询网口信息
    },
    groupApi: {    //系统管理 -- 用户分组
        saveGroup: 'admin/saveGroup',
        findAllGroups: 'admin/findAllGroup',
        findGroupAndRoleList: "admin/findGroupAndRoleList",
        saveGroupRole: 'admin/saveGroupRole',
        findGroupUserByGroupId: 'admin/findGroupUserByGroupId',
        saveUserGroup: 'admin/saveUserGroup'
    },
    moduleEditApi: {//系统管理 -- 系统模块及API
        findAllModules: 'admin/findAllModules',
        findChildrenModules: 'admin/findChildrenModules',
        saveModule: 'admin/saveModule',
        savePage: 'admin/savePage',
        delModule: 'admin/delModule',
        delPage: 'admin/delPage',
        delPageApi: 'admin/delPageApi',
        enabledGroups: 'admin/enabledGroups',
        disabledGroups: 'admin/disabledGroups',
        delGroup: "admin/delGroups"
    },
    bigScreenApi: {
        findCryptoNumber: 'public/findCryptoNumber', //新大屏共用 密码应用分类统计数量
        findCurrentFlowData: 'public/findCurrentFlowData',
        findStaticsAllData: 'public/findStaticsAllData',
        findTopCryptoFlow: 'public/findTopCryptoFlow',
        findCryptoFlowPer: 'public/findCryptoFlowPer',
        findWarningDevice: 'public/findWarningDevice',
        findCryptoBarData: 'public/findCryptoBarData',
        findCryptoAlgData: 'public/findCryptoAlgData',
    },
    //山东联通大屏
    sdBigScreenApi: {
        findCryptoNumber: 'public/findCryptoNumber', //密码应用分类统计数量
        findCurrentFlowData: 'public/findCurrentFlowData', // 密码流量
        findHeGuiWarn: 'public/findHeGuiWarn',//合规告警列表
        findSecWarn: 'public/findSecWarn',//安全告警列表
        findHeGuiAgentStatistics: 'public/findHeGuiAgentStatistics',//合规探针统计
        findSecAgentStatistics: 'public/findSecAgentStatistics',//安全探针统计
        findAllAgentData: 'public/findAllAgentData',//探针流量数据
        // findCryptoBarData: 'public/findCryptoBarData',
        // findCryptoAlgData: 'public/findCryptoAlgData',
    },
    uKeyActionApi: {
        ukeylogin: 'ukey/action/ukeylogin',
        ukeyparam: 'ukey/action/ukeyparam',
        useUkeyParams: 'ukey/action/useUkeyParams',
        addAdminWithUkey: 'admin/addAdminWithUkey',
        changeUkey: 'admin/changeUkey',
        // modifyUkeyPassword: 'admin/modifyUkeyPassword'
    }
}
