import Conf from '../conf';
export default {
    name: 'defaultPie',
    mixins: [Conf],
    props: {
        radius: {
            type: String,
            default: '55%'
        },
        legendTextWidth: {
            type: [Number, String],
            default: 'auto'
        }
    },
    methods: {
        seriesHandler() {
            let series = {
                name: this.name,
                type: 'pie',
                radius: this.radius,
                center: ['40%', '50%'],
                avoidLabelOverlap: true,
                minAngle: 5,
                hoverAnimation: true,
                data: this.data
            };
            return [series];
        },

        legendHandler() {
            return {
                type: 'scroll',
                orient: 'vertical',
                right: 0,
                top: 20,
                bottom: 20,
                // width: 100,
                data: this.data,
                formatter: '{a|{name}}', // 按照文档的说法 `rich` 参数只有指定了 `formatter` 后才有效
                textStyle: {
                    rich: {
                        a: {
                            width: this.legendTextWidth,
                            // overflow: 'hidden',
                            // textOverflow: 'ellipsis',
                            // whitespace: 'nowrap'
                        }
                    }
                },
                // textStyle: {
                //     width: 120,
                //     overflow: 'none',
                //     display: 'block'
                //     // fontSize: 10
                
                // },
                
            }
        },
        tooltipHandler(){
            return {
                trigger: 'item',
                // formatter: '{a} <br/>{b} : {c} ({d}%)',
                formatter(params) {
                    return `<div>
                            ${params.data.deviceMac || params.seriesName}<br/>
                            ${params.name}：${params.value}(${params.percent}%)
                        </div>`
                }
            };
        }
    },
    mounted() {
    }
};
