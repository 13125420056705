<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2 label-text-font" >实时告警信息</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board ref="waringList" :config="config" style="width:3.375rem;height:4.88rem" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["设备IP", "告警等级"],
        data: [],
        rowNum: 12, //表格行数
        headerHeight: 40,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: false,
        columnWidth: [320,180],
        align: ["left","center"]
      },
      cryptoData:null
    };
  },
  components: {},
  mounted() {
    //this.changeTiming();
    this.findCryptoNumber();
  },
  destroyed() {
    if(this.timer){
      clearInterval(this.timer);
    }
  },
  methods: {
    findCryptoNumber() {
      let self = this;
      self.doFindData();
      self.changeTiming();
    },
    doFindData() {
      let self = this;
      let param = {
        type: VIEW_TYPE
      }
      self.$http.post({
        url: self.$api.bigScreenApi.findWarningDevice,
        opt: {showSuccess: false, isLoading: false, showError: false},
        params: VIEW_TYPE,
        callback: {
          success: data => {
            self.cryptoData = data.result;
            self.makeData()
          }
        }
      })
    },
    changeTiming() {
      let self = this;
      self.timer = setInterval(() => {
        self.doFindData();
      }, 600000);
    },
    makeData() {
      let self = this;
      let datas = self.cryptoData;
      let data = []
      for (var i = 0; i < datas.length; i++) {
        let col = []
        col.push(datas[i].name)
        col.push(datas[i].warning)
        data.push(col);
      }
      self.config.data = data;
      self.$refs.waringList.calcData();
    }
  }
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>