/****   http.js   ****/
// 导入封装好的axios实例
import request from './request'

import ukeyRequest from './ukeyRequest'

// import router from "../../router";

import { Loading, Message } from 'element-ui';
// import {default} from "vuex/dist/vuex";

const http ={
    /**
     * methods: get请求
     * @param options:{url,params,opt,callback}
     */
    get(options){
        if(!options) {
            console.error("no options, the request cannot finish.")
            return;
        }
        let url = options.url;
        let params = options.params;
        let callback = options.callback;
        let opt = {
            isLoading:true,
            showError:true,
            showSuccess:true,
            isHandleCustom:false
        }
        if(options.opt){
            Object.assign(opt, options.opt);
        }
        if(!url){
            console.error("no url, the request cannot finish.")
            return;
        }
        const config = {
            method: 'get',
            url:url
        }
        if(params) config.params = params
        if(opt.isHandleCustom){
            return request(config)
        }else{
            if(opt.isLoading){
                const loading = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                request(config).then(res=>{
                    loading.close();
                    if(res.data.error !== 0){
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                    loading.close();
                })
            }else{
                request(config).then(res=>{
                    if(res.data.error !== 0){
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                })
            }

        }

    },
    /**
     * post请求
     * @param options:{url,params,opt,callback}
     */
    post(options){
        if(!options) {
            console.error("no options, the request cannot finish.")
            return;
        }
        let url = options.url;
        let params = options.params;
        let callback = options.callback;
        let opt = {
            isLoading:true,
            showError:true,
            showSuccess:true,
            isHandleCustom:false
        }
        if(options.opt){
            Object.assign(opt, options.opt);
        }
        if(!url){
            console.error("no url, the request cannot finish.")
            return;
        }
        const config = {
            method: 'post',
            url:url
        }
        if(params !== undefined) config.data = params
        if(opt.isHandleCustom){
            return request(config)
        }else{
            if(opt.isLoading){
                const loading = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                request(config).then(res=>{
                    loading.close();
                    if(res.data.error !== 0){
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                    loading.close();
                })
            }else{
                request(config).then(res=>{
                    if(res.data.error !== 0){
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                })
            }

        }
    },
    /**
     * ukey post请求
     * @param options:{url,params,opt,callback}
     */
    uKeyPost(options){
        function errorToMsg(e) {
            switch (e) {
                case 1:
                    return "您的UKEY未就绪";
                case 2:
                    return "与UKEY通讯时数据格式不正确";
                case 3:
                    return "UKEY认证过期，请重新认证";
                case 4:
                    return "UKEY内部错误，临时文件失效";
                case 5:
                    return "签名数据长度不正确";
                case 7:
                    return "UKEY通讯时认证失败";
                case 8:
                    return "与UKEY通讯失败，不支持当前操作";
                case 9:
                    return "与UKEY通讯时认证数据不正确";
                case 167772192:
                    return "无法与UKEY进行通讯，请检测UKEY是否被其它软件管控";
                case 167772199:
                    return "UKEY PIN码错误";
                case 167772196:
                    return "UKEY PIN码错误";
                default:
                    return "UKEY内部错误，错误代码：[CODE:" + e + "]"
            }
        }
        if(!options) {
            console.error("no options, the request cannot finish.")
            return;
        }
        let url = options.url;
        let params = options.params;
        let callback = options.callback;
        let opt = {
            isLoading:true,
            showError:true,
            showSuccess:true,
            isHandleCustom:false
        }
        if(options.opt){
            Object.assign(opt, options.opt);
        }
        if(!url){
            console.error("no url, the request cannot finish.")
            return;
        }
        const config = {
            method: 'post',
            url:url
        }
        if(params) config.data = params
        if(opt.isHandleCustom){
            return request(config)
        }else{
            if(opt.isLoading){
                const loading = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                ukeyRequest(config).then(res=>{
                    loading.close();
                    if(res.data.error !== 0){
                        res.data.msg = errorToMsg(res.data.error);
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                    loading.close();
                })
            }else{
                ukeyRequest(config).then(res=>{
                    if(res.data.error !== 0){
                        res.data.msg = errorToMsg(res.data.error);
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                })
            }

        }
    },
    /**
     * put请求
     * @param options:{url,params,opt,callback}
     */
    put(options){
        if(!options) {
            console.error("no options, the request cannot finish.")
            return;
        }
        let url = options.url;
        let params = options.params;
        let callback = options.callback;
        let opt = {
            isLoading:true,
            showError:true,
            showSuccess:true,
            isHandleCustom:false
        }
        if(options.opt){
            Object.assign(opt, options.opt);
        }
        if(!url){
            console.error("no url, the request cannot finish.")
            return;
        }
        const config = {
            method: 'put',
            url:url
        }
        if(params) config.params = params
        if(opt.isHandleCustom){
            return request(config)
        }else{
            if(opt.isLoading){
                const loading = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                request(config).then(res=>{
                    loading.close();
                    if(res.data.error !== 0){
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                    loading.close();
                })
            }else{
                request(config).then(res=>{
                    if(res.data.error != 0){
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                })
            }

        }
    },
    /**
     * delete请求
     * @param options:{url,params,opt,callback}
     */
    delete(options){
        if(!options) {
            console.error("no options, the request cannot finish.")
            return;
        }
        let url = options.url;
        let params = options.params;
        let callback = options.callback;
        let opt = {
            isLoading:true,
            showError:true,
            showSuccess:true,
            isHandleCustom:false
        }
        if(options.opt){
            Object.assign(opt, options.opt);
        }
        if(!url){
            console.error("no url, the request cannot finish.")
            return;
        }
        const config = {
            method: 'delete',
            url:url
        }
        if(params) {
            config.params = params
        }
        if(opt.isHandleCustom){
            return request(config)
        }else{
            if(opt.isLoading){
                const loading = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                request(config).then(res=>{
                    loading.close();
                    if(res.data.error !== 0){
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                    loading.close();
                })
            }else{
                request(config).then(res=>{
                    if(res.data.error !== 0){
                        if(callback.error){
                            callback.error(res.data);
                        }
                        if(opt.showError){
                            Message.error(res.data.msg);
                        }
                    }else{
                        if(callback.success){
                            callback.success(res.data);
                        }
                        if(opt.showSuccess){
                            Message.success(res.data.msg);
                        }
                    }
                }).catch(res=>{
                    if(callback.fail){
                        callback.fail(res)
                    }
                })
            }

        }
    }
}
//导出
export default http
