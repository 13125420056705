import defaultBar from './bar';
import pictorialBar from './bar/pictorialBar';
import deviceGauge from './gauge/device';
import deviceLine from './line/device';
import defaultLine from './line';
import defaultPie from './pie/index';

const components = {
    defaultBar,
    pictorialBar,
    deviceGauge,
    deviceLine,
    defaultLine,
    defaultPie,
};

const install = (Vue, opts = {}) => {
    Object.keys(components).forEach(key => {
        Vue.component(components[key].name, components[key]);
    });
};

export default {
    name: 'echarts',
    install,
    ...components
};