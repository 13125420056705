<template>
    <el-main style="min-width: 1200px">
        <el-card class="center admin-style-list">
            <div slot="header" class="clearfix">
                <span class="title-text">探针管理</span>
            </div>
            <el-row class="operation-button">
                <el-col :span="24" class="text-right">
                    <el-button type="primary" :size="buttonSize" @click="addAgent">
                        <span class="icon iconfont iconplus"></span>
                        添加
                    </el-button>
                    <el-button type="primary" :size="buttonSize" @click="handleEdit">
                        <span class="icon iconfont iconedit"></span>
                        编辑
                    </el-button>
                    <el-button type="danger" :size="buttonSize" @click="handleDel">
                        <span class="icon iconfont icondelete-fill"></span>
                        删除
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                    ref="multipleTable"
                tooltip-effect="light"
                v-loading="loading"
                class="table-top width-ninety"
                :height="tableHeight"
                stripe
                @selection-change="handleSelectionChange"
                :data="tableData">
                <el-table-column fixed="left" type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="探针名称" min-width="180" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ip" label="探针 IP" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sn" label="探针 ID" width="165" show-overflow-tooltip></el-table-column>
<!--                <el-table-column label="接入时间" width="150" show-overflow-tooltip>-->
<!--                    <template slot-scope="scope">-->
<!--                        {{ scope.row.createTime | formatDateFilter }}-->
<!--                    </template>-->
<!--                </el-table-column>-->

<!--                <el-table-column prop="tx" width="100" label="当前上行流量">-->
<!--                    <template slot-scope="scope">-->
<!--                        {{ scope.row.tx | fileSizeFilter}}-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="rx" width="120" label="当前下行流量">
                    <template slot-scope="scope">
                        {{ scope.row.rx | fileSizeFilter}}
                    </template>
                </el-table-column>
<!--                <el-table-column prop="regionName" width="100" label="区域名称" show-overflow-tooltip></el-table-column>-->
<!--                <el-table-column prop="address" min-width="120" label="具体位置" show-overflow-tooltip></el-table-column>-->
                <el-table-column prop="organizationName" width="160" label="所属组织" show-overflow-tooltip></el-table-column>
<!--                <el-table-column prop="clientVersion" width="80" label="版本"></el-table-column>-->
                <el-table-column label="最后一次通讯时间" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.lastConnectTime | formatDateFilter }}
                    </template>
                </el-table-column>
<!--                <el-table-column prop="memUsed" min-width="120" label="内存使用率"></el-table-column>-->
<!--                <el-table-column prop="cpuUsed" min-width="120" label="CPU使用率"></el-table-column>-->
                <el-table-column label="状态" width="80">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.state == 1 ? 'danger' : 'success'">
                            {{ scope.row.state == 1 ? '离线' : '在线' }}
                        </el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <el-row class="paging-row width-ninety">
                <el-col :span="24">
                    <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 15, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        :current-page="pageNo"
                        :total="total"></el-pagination>
                </el-col>
            </el-row>
        </el-card>

        <!--编辑-->
        <el-dialog
            :title="isAdd ? '添加探针信息' : '编辑探针信息'"
            @close="closeDialog"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            width="40%"
            custom-class="mid-dialog-min-width">
            <el-form label-width="120px" :model="dataForm" ref="dataForm">
                <el-form-item
                    label="探针名称："
                    prop="name"
                    :rules="[
                        {
                            required: true,
                            message: '请输入探针名称',
                            trigger: 'blur'
                        }
                    ]">
                    <el-input v-model="dataForm.name" class="dialog-input-width" maxlength="50"></el-input>
                </el-form-item>
                <el-form-item
                    label="探针IP："
                    prop="ip"
                    v-if="isAdd"
                    :rules="[
                        {
                            required: isAdd,
                            message: '请输入探针IP地址',
                            trigger: 'blur'
                        }
                    ]">
                    <el-input v-model="dataForm.ip" class="dialog-input-width" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item
                    label="探针ID："
                    prop="sn"
                    v-if="isAdd"
                    :rules="[
                        {
                            required: isAdd,
                            message: '请输入探针ID',
                            trigger: 'blur'
                        }
                    ]">
                    <el-input v-model="dataForm.sn" class="dialog-input-width"></el-input>
                </el-form-item>
                <el-form-item
                    label="探针公钥X："
                    prop="clientPublicKeyX"
                    :rules="[
                        {
                            required: true,
                            message: '请输入探针公钥X',
                            trigger: 'blur'
                        }
                    ]">
                    <el-input v-model="dataForm.clientPublicKeyX" class="dialog-input-width" maxlength="64"></el-input>
                </el-form-item>
                <el-form-item
                    label="探针公钥Y："
                    prop="clientPublicKeyY"
                    :rules="[
                        {
                            required: true,
                            message: '请输入探针公钥Y',
                            trigger: 'blur'
                        }
                    ]">
                    <el-input v-model="dataForm.clientPublicKeyY" class="dialog-input-width" maxlength="64"></el-input>
                </el-form-item>

                <el-form-item
                    label="区域"
                    prop="regionId"
                    :rules="[
                        {
                            required: true,
                            message: '请选择探针所在区域',
                            trigger: 'blur'
                        }
                    ]">
                    <el-cascader
                        popper-class="dark-theme-popper"
                        @change="regionChange"
                        clearable
                        :props="regionProps"></el-cascader>
                </el-form-item>

                <el-form-item label="详细地址：" prop="address">
                    <el-input v-model="dataForm.address" class="dialog-input-width"></el-input>
                </el-form-item>

                <el-form-item
                    label="所属单位"
                    prop="organizationId"
                    :rules="[
                        {
                            required: true,
                            message: '请选择探针所属单位',
                            trigger: 'blur'
                        }
                    ]">
                    <el-select
                        class="dialog-row-select"
                        v-model="dataForm.organizationId"
                        placeholder="请选择"
                        popper-class="tree-select"
                        ref="selectReport">
                        <el-option :value="dataForm.organizationId" :label="dataForm.organizationName">
                            <el-tree :data="organization" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="机房区域"
                        prop="engineRoom">
                    <el-select
                            class="dialog-row-select"
                            v-model="dataForm.engineRoom"
                            placeholder="请选择">
                        <el-option
                                v-for="item in engines"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                                :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="网络边界"
                        prop="engineRoom">
                    <el-select
                            class="dialog-row-select"
                            v-model="dataForm.networkBoundary"
                            placeholder="请选择">
                        <el-option
                                v-for="item in netBoundary"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                                :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="所属组织：" prop="organizationId">
                    <el-input v-model="dataForm.organizationId" class="dialog-input-width"></el-input>
                </el-form-item> -->
                <el-form-item label="探针描述：" prop="remark">
                    <el-input
                        type="textarea"
                        :rows="6"
                        v-model="dataForm.description"
                        class="dialog-input-width"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog" class="black-button">关 闭</el-button>
                <el-button type="primary" @click="handleSubmit('dataForm')">确 定</el-button>
            </div>
        </el-dialog>
    </el-main>
</template>
<script>
import {agentEditField} from "../../../assets/js/constants"
export default {
    data() {
        return {
            engines:agentEditField.engines,
            netBoundary:agentEditField.netBoundary,
            tableHeight: window.innerHeight - 330,
            inputSize: INPUTSIZE, //输入框大小
            buttonSize: BUTTONSIZE, //按钮大小
            pageNo: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            loading: false,
            currentSelectIds: [],
            currentSelect: null,
            buttonStatus: {
                disableSingle: true,
                disableMultiple: true
            },
            // dataForm: {},
            dialogVisible: false,
            dataForm: {
                name: '',
                regionId: '',
                address: '',
                organizationId: '',
                description: '',
                sn: '',
                clientPublicKeyX: '',
                clientPublicKeyY: '',
                ip: '',
                engineRoom:null,
                networkBoundary:null

            },
            organization: [],
            defaultProps: {
                label: 'label',
                children: 'children'
            },
            regionProps: {
                lazy: true,
                lazyLoad: this.lazyLoad
            },
            isAdd: false,
            startAutoRefresh:false,
            latestSelect: []
        }
    },
    mounted() {
        this.findByPage()
        this.findAllOrganization()
        setTimeout(()=>{
            this.startAutoRefresh = true;
            this.autoRefresh()
        },3000)
    },
    methods: {
        lazyLoad(node, resolve) {
            const { level } = node
            const { value } = node
            let self = this
            let parentId = value ? value : 0
            self.$http.post({
                url: self.$api.adminApi.findRegion,
                params: { parentId: parentId },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        const nodes = Array.from(data.result).map((item) => ({
                            value: item.value,
                            label: item.label,
                            leaf: level >= 2
                        }))
                        resolve(nodes)
                    }
                }
            })
        },
        regionChange(value) {
            if (value && value.length > 0) {
                this.dataForm.regionId = value[value.length - 1]
            } else {
                this.dataForm.regionId = 0
            }
        },
        handleNodeClick(data) {
            this.dataForm.organizationId = data.id
            this.dataForm.organizationName = data.label
            this.$refs.selectReport.blur()
        },
        findAllOrganization() {
            let self = this
            self.$http.post({
                url: self.$api.adminApi.findAllOrganization,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        self.organization = data.result
                    }
                }
            })
        },
        closeDialog() {
            this.dialogVisible = false

            this.$refs.dataForm && this.$refs.dataForm.resetFields()
            this.dataForm = {
                name: '',
                regionId: '',
                address: '',
                organizationId: '',
                description: '',
                sn: '',
                clientPublicKeyX: '',
                clientPublicKeyY: '',
                ip: '',
                id: '',
                engineRoom:null,
                networkBoundary:null
            }
            this.findByPage()
        },
        handleSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    if (this.isAdd) {
                        this.dataForm.id = ''
                    }
                    this.$http.post({
                        url: this.$apiUrl.adminApi.saveAgent,
                        params: this.dataForm,
                        opt: { showSuccess: true },
                        callback: {
                            success: (data) => {
                                this.dialogVisible = false
                                this.closeDialog()
                            }
                        }
                    })
                } else {
                    return false
                }
            })
        },
        handleSelectionChange(val) {
            this.currentSelectIds = []
            for (let i = 0; i < val.length; i++) {
                this.currentSelectIds.push(val[i].id)
            }
            this.currentSelect = val
        },
        deepClone(src){
            let _obj = JSON.stringify(src);
            return JSON.parse(_obj)
        },
        selectLatestSelect() {
            this.$nextTick(() => {
                if (!this.latestSelect || this.latestSelect.length === 0) {
                    return;
                }
                this.latestSelect.forEach(row => {
                    // console.log("row", row)
                    for (let i = 0; i < this.tableData.length; i++) {
                        if (row.id === this.tableData[i].id) {
                            this.$refs["multipleTable"].toggleRowSelection(this.tableData[i], true);
                        }
                    }
                });
            })
        },
        addAgent() {
            this.isAdd = true
            this.dialogVisible = true
        },
        handleEdit() {
            if (this.currentSelectIds == null || this.currentSelectIds.length != 1) {
                this.$alert('需要且只能选择一条数据进行此操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            this.dataForm = this.currentSelect[0]
            this.isAdd = false
            this.dialogVisible = true
        },
        handleDel() {
            if (this.currentSelectIds == null || this.currentSelectIds.length == 0) {
                this.$alert('请至少选择一条数据进行操作', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: 'warning'
                })
                return
            }
            this.$confirm('此操作将永久删除您选择的数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                cancelButtonClass: 'black-button',
                type: 'warning'
            })
                .then(() => {
                    this.$http.post({
                        url: this.$api.adminApi.delAgentByIds,
                        params: { ids: this.currentSelectIds },
                        callback: {
                            success: (data) => {
                                this.findByPage()
                            }
                        }
                    })
                })
                .catch(() => {})
        },

        handleSizeChange(val) {
            this.pageNo = 1
            this.pageSize = val
            this.findByPage()
        },
        handleCurrentChange(val) {
            this.pageNo = val
            this.findByPage()
        },
        select() {
            this.pageNo = 1
            this.findByPage()
        },
        autoRefresh() {
            // this.loading = true
            let self = this;
            let params = {
                pageNo: this.pageNo - 1,
                pageSize: this.pageSize
            }
            this.$http.post({
                url: this.$api.adminApi.findAgentByPage,
                params: params,
                opt: { isLoading: false, showSuccess: false, showError:false },
                callback: {
                    success: (data) => {
                        // this.loading = false
                        this.latestSelect = this.deepClone(this.currentSelect)
                        this.tableData = data.result.content || []
                        this.total = data.result.totalElements
                        this.selectLatestSelect();
                        if(self.startAutoRefresh){
                            setTimeout(()=>{
                                self.autoRefresh();
                            },3000)
                        }
                    },
                    error: (data)=>{
                        if(self.startAutoRefresh){
                            setTimeout(()=>{
                                self.autoRefresh();
                            },3000)
                        }
                    },
                    fail: (res)=>{
                        if(self.startAutoRefresh){
                            setTimeout(()=>{
                                self.autoRefresh();
                            },3000)
                        }
                    }
                }
            })
        },
        findByPage() {
            this.loading = true
            let params = {
                pageNo: this.pageNo - 1,
                pageSize: this.pageSize
            }
            this.$http.post({
                url: this.$api.adminApi.findAgentByPage,
                params: params,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        this.loading = false
                        this.tableData = data.result.content || []
                        this.total = data.result.totalElements
                    },
                    error: (res) => {
                        this.loading = false
                    },
                    fail: (res) => {
                        this.loading = false
                    }
                }
            })
        }
    },
    destroyed() {
        this.startAutoRefresh = false;
    }
}
</script>
