<template>
  <div>
    <div id="centreRight2Chart1" style="width:3.25rem; height: 2.60rem;">123</div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";

export default {
  data() {
    return {
      chart: null
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreRight2Chart1"));
      //  ----------------------------------------------------------------
      let dataBJ = [
        [94, 69, 114, 2.08, 73, 39, 22],
        [99, 73, 110, 2.43, 76, 48, 23],
        [31, 12, 30, 0.5, 32, 16, 24],
        [42, 27, 43, 1, 53, 22, 25],
        [154, 117, 157, 3.05, 92, 58, 26],
        [234, 185, 230, 4.09, 123, 69, 27],
        [160, 120, 186, 2.77, 91, 50, 28]
      ];

      let dataGZ = [
        [84, 94, 140, 2.238, 68, 18, 22],
        [93, 77, 104, 1.165, 53, 7, 23],
        [99, 130, 227, 3.97, 55, 15, 24],
        [146, 84, 139, 1.094, 40, 17, 25],
        [113, 108, 137, 1.481, 48, 15, 26],
        [81, 48, 62, 1.619, 26, 3, 27],
        [56, 48, 68, 1.336, 37, 9, 28]
      ];

      let dataSH = [
        [91, 45, 125, 0.82, 34, 23, 1],
        [65, 27, 78, 0.86, 45, 29, 2],
        [83, 60, 84, 1.09, 73, 27, 3],
        [109, 81, 121, 1.28, 68, 51, 4],
        [106, 77, 114, 1.07, 55, 51, 5],
        [109, 81, 121, 1.28, 68, 51, 6],
        [106, 77, 114, 1.07, 55, 51, 7]
      ];

      let lineStyle = {
        normal: {
          width: 1,
          opacity: 0.5
        }
      };

      let option = {
        radar: {
          indicator: [
            { name: "SM3", max: 300 },
            { name: "SM2", max: 250 },
            { name: "SM4", max: 300 },
            { name: "SM9", max: 5 },
            { name: "ZUC", max: 200 },
            { name: "SM1", max: 100 }
          ],
          shape: "circle",
          splitNumber: 5,
          name: {
            textStyle: {
              color: "rgb(238, 197, 102)"
            }
          },
          splitLine: {
            lineStyle: {
              color: [
                "rgba(238, 197, 102, 0.1)",
                "rgba(238, 197, 102, 0.2)",
                "rgba(238, 197, 102, 0.4)",
                "rgba(238, 197, 102, 0.6)",
                "rgba(238, 197, 102, 0.8)",
                "rgba(238, 197, 102, 1)"
              ].reverse()
            }
          },
          splitArea: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "rgba(238, 197, 102, 0.5)"
            }
          }
        },
        series: [
          {
            name: "北京",
            type: "radar",
            lineStyle: lineStyle,
            data: dataBJ,
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#F9713C"
              }
            },
            areaStyle: {
              normal: {
                opacity: 0.1
              }
            }
          },
          {
            name: "上海",
            type: "radar",
            lineStyle: lineStyle,
            data: dataSH,
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#B3E4A1"
              }
            },
            areaStyle: {
              normal: {
                opacity: 0.05
              }
            }
          },
          {
            name: "广州",
            type: "radar",
            lineStyle: lineStyle,
            data: dataGZ,
            symbol: "none",
            itemStyle: {
              normal: {
                color: "rgb(238, 197, 102)"
              }
            },
            areaStyle: {
              normal: {
                opacity: 0.05
              }
            }
          }
        ]
      };
      this.chart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
#centreRight2Chart1 {
  margin: 0 auto;
}
</style>