import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminMain from './../components/main.vue'
import AdminLogin from './../views/admin/login.vue'
import Statistics from './../views/admin/statistics.vue'  //首页

import DeviceManage from '../views/children/device/manage'//设备管理
import DeviceDiscovery from '../views/children/device/discovery' //自动感知
import DeviceStatistics from '../views/children/device/statistics' //自动感知

import ApplicationManage from '../views/children/application/manage'//设备管理

import Ipsec from '../views/children/protocol/ipsec' //网络层协议监控
import Http from '../views/children/protocol/http' //明文协议监控
import Tls from '../views/children/protocol/tls'  //传输层加密监控
// import RandomDigitTest from '../views/children/protocol/randomdigittest' //随机数质量测试
// import CsaDisplay from '../views/children/protocol/csadisplay.vue'  //密码态势感知
import Certificate from '../views/children/protocol/certificate'  //数据证书监控

import Compliance from '../views/children/warning/compliance'  //密码合规告警
// import Security from '../views/children/warning/security'  //密码安全告警
// import Threaten from '../views/children/warning/threaten'  //网络威胁告警


import VulnerabilityData from '../views/children/vulnerabilitywarning/vulnerabilitydata'    //密码漏洞库
import AbnormalMonitor from '../views/children/vulnerabilitywarning/abnormalmonitor'  //设备异常检测
import WarningData from '../views/children/vulnerabilitywarning/warningdata'  //密码漏洞通知

import AdminLogs from '../views/children/system/adminlogs'  //角色
import AdminUsers from '../views/children/system/adminusers'  //用户
import Sim from '../views/children/system/sim'  //SIM盾
import Outsourcing from '../views/children/system/outsourcing'  //委外用户
import Organization from '../views/children/system/organization'  //组织

import AddGroup from '../views/roles/addgroup'//新增角色组
import ModuleEdit from '../views/roles/moduleedit'//编辑系统基础模块和API
import UserGroup from '../views/roles/usergroup'

import Agent from '../views/children/system/agent'  //探针
import Network from "../views/children/system/network";


import BigScreen from './../views/bigscreen/index'

import MapBigScreen from './../views/mapscreen/index'
import License from "../views/children/system/license";

import SdBigView from "../views/sdbigview/index"
import SdUnicomBigview from "../views/sdUnicomBigview/index"

Vue.use(VueRouter)

const routes = [
    {//用户登录
        path: '/',
        name: 'AdminLogin',
        alias: '/login',
        component: AdminLogin
    },
    // {//
    //   path: '/device/register',
    //   name: 'register',
    //   alias: '/register',
    //   component: register
    // },
    {//大屏展示
        path: '/dview',
        name: 'BigScreen',
        component: BigScreen
    },
    {
        path: '/cview',
        name: 'SdBigView',
        component: SdBigView
    },
    {
        path: '/bview',
        name: 'SdUnicomBigview',
        component: SdUnicomBigview
    },
    {//地图展示
        path: '/mview',
        name: 'MapBigScreen',
        component: MapBigScreen
    },
    {//登录后的主界面框架
        path: '/admin',
        name: 'AdminMain',
        component: AdminMain,
        meta: {
            requireAdminAuth: true,  // 添加该自定义字段，表示进入这个路由是需要登录的
        },
        children: [
            {
                path: '/statistics',//首页
                alias: '/',
                name: 'Statistics',
                component: Statistics,
                meta: {
                    requireAdminAuth: true,  // 添加该自定义字段，表示进入这个路由是需要登录的
                },
            },

            {
                path: '/device/manage', //设备管理
                name: 'DeviceManage',
                component: DeviceManage,
                meta: {
                    requireAdminAuth: true  
                },
            },
            {
                path: '/device/discovery', //自动感知
                name: 'DeviceDiscovery',
                component: DeviceDiscovery,
                meta: {
                    requireAdminAuth: true  
                },
            },
            {
                path: '/device/statistics', //数据统计
                name: 'DeviceStatistics',
                component: DeviceStatistics,
                meta: {
                    requireAdminAuth: true
                },
            },
            {
                path: '/application/manage', //应用管理
                name: 'ApplicationManage',
                component: ApplicationManage,
                meta: {
                    requireAdminAuth: true  
                },
            },
            {
                path: '/protocol/certificate',//数据证书监控
                name: 'Certificate',
                component: Certificate,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/protocol/ipsec',//网络层加密监控
                name: 'Ipsec',
                component: Ipsec,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/protocol/http',//明文协议监控
                name: 'Http',
                component: Http,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/protocol/tls',//传输层加密监控
                name: 'Tls',
                component: Tls,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/warning/compliance',//密码合规告警
                name: 'Compliance',
                component: Compliance,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/warning/security',//密码安全告警
                name: 'Security',
                component: Compliance,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/warning/threaten',//网络威胁告警
                name: 'Threaten',
                component: Compliance,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            // 密码警告威胁
            {
                path: '/vulnerabilitywarning/vulnerabilitydata',//密码漏洞库
                name: 'VulnerabilityData',
                component: VulnerabilityData,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/vulnerabilitywarning/abnormalmonitor',//设备异常检测
                name: 'AbnormalMonitor',
                component: AbnormalMonitor,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/vulnerabilitywarning/warningdata',//密码漏洞通知
                name: 'WarningData',
                component: WarningData,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            //系统管理
            {
                path: '/system/adminlogs',//用户操作日志
                name: 'AdminLogs',
                component: AdminLogs,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/system/agent',//探针
                name: 'Agent',
                component: Agent,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/system/outsourcing',//委外用户
                name: 'Outsourcing',
                component: Outsourcing,
                meta: {
                    requireAdminAuth: true,  
                },
            },

            {
                path: '/system/adminusers',//用户管理
                name: 'AdminUsers',
                component: AdminUsers,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/system/sim',//SIM盾管理
                name: 'Sim',
                component: Sim,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/system/organization',//组织结构管理
                name: 'Organization',
                component: Organization,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/roles/module',//系统模块管理
                name: 'ModuleEdit',
                component: ModuleEdit,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/roles/group',//用户角色管理
                name: 'UserGroup',
                component: UserGroup,
                meta: {
                    requireAdminAuth: true,  
                },
            },
            {
                path: '/system/network',//网络信息
                name: 'Network',
                component: Network,
                meta: {
                    requireAdminAuth: true,
                },
            },
            {
                path: '/system/license',//网络信息
                name: 'License',
                component: License,
                meta: {
                    requireAdminAuth: true,
                },
            }
        ]
    }
]

const router = new VueRouter({
    routes
})
export default router
