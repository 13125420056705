<template>
  <div>
    <div ref="zsCharts" style="width:85%;height:2.25rem; margin: 0 auto"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import allData from '../../../assets/json/bigview.json'
export default {
  props:["data", "refreshTime"],
  data() {
    return {
      chart: null,
      cryptoBarData:null,
      timer:null,
    };
  },
  mixins: [echartMixins],
  mounted() {
    // this.findData();
    // this.changeTiming();
    this.makeData()
  },
  watch:{
    refreshTime(newVal, oldVal){
      this.makeData()
      // console.log("abc", this.data)
    }
  },
  destroyed() {
    // if(this.timer){
    //   clearInterval(this.timer);
    // }
  },
  methods: {
    // changeTiming() {
    //   let self = this;
    //   self.timer = setInterval(() => {
    //     self.findData();
    //   }, 60*60*24*1000);
    // },
    // findData(){
    //   let self = this;
    //   self.cryptoBarData = allData.zsData;
    //   self.makeData();
    // },
    makeData() {
      let width = document.body.clientWidth;
      // 基于准备好的dom，初始化echarts实例
      // this.chart = this.$echarts.init(document.getElementById("zsCharts"));
      this.chart = this.$echarts.init(this.$refs["zsCharts"]);
      //  ----------------------------------------------------------------
      let category = this.data.x;
      let lineData = this.data.y;
      let barData = this.data.y;
      // for(var i = 0; i < this.cryptoBarData.length; i++){
      //   category.push(this.cryptoBarData[i].date);
      //   lineData.push(this.cryptoBarData[i].lineData);
      //   barData.push(this.cryptoBarData[i].barData);
      // }
      // let rateData = [];
      // for (let i = 0; i < 33; i++) {
      //   let rate = barData[i] / lineData[i];
      //   rateData[i] = rate.toFixed(2);
      // }
      let option = {
        title: {
          text: "",
          x: "center",
          y: 0,
          textStyle: {
            color: "#B4B4B4",
            fontSize:  width > 2000 ?24 * 1.5 : 16,
            fontWeight: "normal"
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#3d6c75"
            }
          }
        },
        legend: {
          // data: ["证书态势"],
          textStyle: {
            color: "#B4B4B4",
            fontSize: width > 2000 ? 24 * 1.5 : 14,
          },
          top: "0%"
        },
        grid: {
          x: "18%",
          width: "78%",
          y: "4%"
        },
        xAxis: {
          data: category,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisLabel: {
            interval: 0,
            rotate: "15",
            fontSize:  width > 2000 ?18 * 1.5 : 12
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },

            axisLabel: {
              fontSize:  width > 2000 ?16 * 1.5 : 12,
              formatter: "{value} "
            }
          },
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisLabel: {
              fontSize:  width > 2000 ?16 * 1.5 : 12,
              formatter: "{value} "
            }
          }
        ],
        series: [
          // {
          //   name: "加密率",
          //   type: "line",
          //   smooth: true,
          //   showAllSymbol: true,
          //   symbol: "emptyCircle",
          //   symbolSize: 8,
          //   yAxisIndex: 1,
          //   itemStyle: {
          //     normal: {
          //       color: "#F02FC2"
          //     }
          //   },
          //   data: rateData
          // },

          {
            // name: "告警统计",
            type: "bar",
            barWidth: 10,
            label: {
              show: true,
              position: 'inside',
              formatter: function (params) {
                let value = params.value;
                if(value > 99999999){
                  // let t = Math.round(value / 10000000)
                  return  "9999万+"
                }
                // if(value > 9999999){
                //   let t = Math.round(value / 10000000)
                //   return t + "千万"
                // }
                // if(value > 999999){
                //   let t = Math.round(value / 1000000)
                //   return t + "百万"
                // }
                if(value > 9999){
                  let t = Math.round(value / 10000)
                  return t + "万"
                }
                return value;
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#73d2d3" },
                  { offset: 1, color: "#73d2d3" }
                ])
              }
            },
            data: barData
          },

          // {
          //   name: "连接总量",
          //   type: "bar",
          //   barGap: "-100%",
          //   barWidth: 10,
          //   itemStyle: {
          //     normal: {
          //       barBorderRadius: 5,
          //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //         { offset: 0, color: "rgba(156,107,211,0.8)" },
          //         { offset: 0.2, color: "rgba(156,107,211,0.5)" },
          //         { offset: 1, color: "rgba(156,107,211,0.2)" }
          //       ])
          //     }
          //   },
          //   z: -12,

            // data: lineData
          // }
        ]
      };
      this.chart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>