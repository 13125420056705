import Conf from '../conf';
export default {
    name: 'defaultBar',
    mixins: [Conf],
    props: {
        info: Array,
        data: Array,
        type: String,
        name: String,
        yAxisName: {
            type: String,
            default: '连接次数/次'
        }
    },
    methods: {
        seriesHandler() {
            return {
                name: '目的IP地址',
                type: 'bar',
                barWidth: '60%',
                barMaxWidth: 60,
                data: this.data
            };
        },
        gridHandler() {
            let res = {
                x: 40,
                // y: 20,
                x2: 40,
                // y2: 30,
                containLabel: true,
                borderColor: 'transparent'
            };
            return res;
        },
        yAxisHandler() {
            return [{
                name: this.yAxisName,
                minInterval: 1,
                min: 0,
                type: 'value'
            },
            {
                name: this.yAxisName,
                minInterval: 1,
                min: 0,
                type: 'value'
            }];
        },
        xAxisHandler() {
            return [{
                type: 'category',
                data: this.xAxis,
                axisTick: {
                    alignWithLabel: true
                },
                // axisLabel: { rotate: 50, interval: 0 }
            }];
        },
        tooltipHandler() {
            return {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            };
        },
        dataZoomHandler() {
            return [
                {
                    type: 'inside'
                },
                {
                    type: 'slider',
                    textStyle: {
                        color: '#999999'
                    }
                }
            ]
        }

    }
};