<template>
    <el-main style="min-width:1200px">
        <el-card class="center admin-style-list">
            <div slot="header" class="clearfix" >
                <span class="title-text">操作日志</span>
            </div>
            <el-form label-position="top" class="search-form" label-width="100px">
                <el-row :gutter="15">
                    <el-col :span="6">
                        <el-form-item label="系统用户名">
                            <el-input v-model="listQuery.username" :size="inputSize" clearable></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="10">
                        <el-form-item label="时间范围">
                            <el-date-picker
                                    v-model="dateRange"
                                    type="datetimerange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="5">
                        <el-form-item label="." class="search-button-space">
                            <el-button class="search-button" @click="select" :size="buttonSize" type="primary" plain><span class="icon iconfont iconsearch"></span> 查询</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>


            <el-table
                    tooltip-effect="light"
                    v-loading="loading"
                    :height="tableHeight"
                    class="table-top width-ninety"
                    stripe
                    :data="tableData">
                <el-table-column
                        prop="name"
                        width="140"
                        label="操作用户名">
                </el-table-column>
                <el-table-column
                        prop="ip"
                        width="120"
                        label="操作IP">
                </el-table-column>
                <el-table-column
                        prop="action"
                        label="操作行为"
                        width="100">
                </el-table-column>
                <el-table-column
                    prop="log"
                    show-overflow-tooltip
                    label="详细内容">
            </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="日志时间"
                        width="150">
                    <template slot-scope="scope">
                    {{scope.row.createTime|formatDate}}
                </template>
                </el-table-column>

            </el-table>
            <el-row class="paging-row width-ninety">
                <el-col :span="24">
                    <el-pagination
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-sizes="[10, 15, 20, 30, 40, 50]"
                            :page-size="pageSize"
                            :current-page="pageNo"
                            :total="model.totalElements">
                    </el-pagination>
                </el-col>
            </el-row>
        </el-card>

    </el-main>
</template>

<script>
    import {formatDate} from '../../../assets/js/date'
    export default {
        name:"AdminLogs",
        data(){
            return{
                tableHeight: window.innerHeight  - 382,
                dateRange:[],
                pageNo:1, //商品显示的页码
                pageSize:15,//每页显示的数据的条数，默认是10条
                listQuery:{//查询和分页时使用
                    username:null,
                    startTime:null,
                    endTime:null,
                },
                inputSize:INPUTSIZE, //输入框大小
                buttonSize:BUTTONSIZE, //按钮大小
                searchBoxWidth:4,
                formLabelWidth: '120px',
                tableData: [],
                model:{},
                loading: false,
                userForm:{},

                allGroup: [{
                    id:0,
                    name:"管理员"
                },{
                    id:1,
                    name:"操作员"
                },{
                    id:2,
                    name:"审计员"
                }],

            }
        },
        created(){
            this.findByPage();
            // this.getCurrentAaminFlag()
        },
        filters:{
            formatDate(time){
                let date = new Date(time);
                return formatDate(date,'yyyy-MM-dd hh:mm:ss');
                //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
            },
        },
        methods: {

            handleSelectable(row,index){
                if(row.flag === 0)return false;
                return true;
            },
            handleSizeChange(val) {
                this.pageNo = 1;
                this.pageSize = val;
                this.findByPage();
            },
            handleCurrentChange(val) {
                this.pageNo = val;
                this.findByPage();
            },
            select(){
                this.pageNo = 1;
                this.findByPage();
            },
            //得到后台用户
            findByPage(){
                var self = this;
                self.loading = true
                let startDate = "";
                let endDate = "";
                if(self.dateRange != null && self.dateRange.length == 2){
                    startDate = new Date(self.dateRange[0]);
                    endDate = new Date(self.dateRange[1]);
                }
                self.listQuery.startTime = startDate;
                self.listQuery.endTime = endDate;
                self.listQuery.pageNo = self.pageNo - 1;
                self.listQuery.pageSize = self.pageSize;

                self.$http.post({
                    url: self.$api.adminApi.getAdminLoginLogDto,
                    params:self.listQuery,
                    opt:{isLoading:false, showSuccess:false},
                    callback: {
                        success: data => {
                            self.loading = false;
                            self.tableData = data.result.content;
                            //console.log(self.tableData);
                            self.total = data.result.totalElements;
                        },
                        error: data =>{
                            self.loading = false;
                        },
                        fail: res =>{
                            self.loading = false;
                        }

                    }
                })
            },
            // 查
            select:function(){
                this.pageNo = 1;
                //重新获取数据，显示数据
                this.findByPage();
            },

            closeDialog(){
                this.groupId = null;
                this.$refs['userForm'].resetFields();
                this.findByPage();
            }
        }

    }
</script>

<style scoped>
    .icon{
        font-size: 13px;
    }
    .input-width{
        width: 300px;
    }

    .mar-right{
        margin-right: 35px;
    }

    .long-bt {
        width: 120px;
    }

    .mar-top{
        margin-top: 30px;
    }

    .label-text{
        color: #606266;
        line-height: 40px;
        display: inline-block;
        padding: 0 0 10px;
        font-size: 14px;
    }

    .label-text{
        color: #606266;
        line-height: 40px;
        display: inline-block;
        padding: 0 0 10px;
        font-size: 14px;
    }


</style>
