<template>
    <el-main style="min-width:1200px">
        <el-card shadow="never" class="main-card-height">
            <div slot="header" class="clearfix">
                <el-row class="operation-button">
                    <el-col :span="20">
                        <span class="card-header-title">授权信息</span>
                    </el-col>
                    <el-col :span="4">
                        <div class="text-right">
                            <el-button @click="activeSoft" type="primary" v-if="!isRegister && isActive === 0">软件授权</el-button>
                            <el-button @click="activeSoft" type="primary" v-if="isRegister">更换授权</el-button>
                        </div>

                    </el-col>
                </el-row>
            </div>
            <el-form
                    class="prepare-form"
                    label-width="240px"
                    :model="modelForm"
                    ref="modelForm"
            >
                <el-form-item
                        label="系统名称："
                        prop="companyName"
                >
                    <span class="textSize">{{env.VUE_APP_TITLE}}</span>
                </el-form-item>
                <el-form-item
                        label="软件版本："
                        prop="companyName"
                >
                    <span class="textSize">{{env.VUE_APP_VERSION}}</span>
                </el-form-item>

                <el-form-item
                        label="生产厂家："
                        prop="companyName"
                >
                    <span class="textSize">{{ factory }}</span>
                </el-form-item>

<!--                <el-form-item-->
<!--                        label="序列号："-->
<!--                        prop="sn"-->
<!--                >-->
<!--                    <span class="textSize">{{ modelForm.sn }}</span>-->

<!--                </el-form-item>-->
                <el-form-item
                        label="授权使用单位："
                        prop="endTime"
                >
                    <span class="textSize">{{ modelForm.companyName }}</span>
                    <!--          <el-tag effect="dark" type="success" v-if="modelForm.status !== -1">永久</el-tag>-->
                </el-form-item>
                <el-form-item
                  label="授权许可号："
                  prop="sn"
                >
                  <span class="textSize">{{ modelForm.sn }}</span>
                </el-form-item>
                <el-form-item
                        label="授权时间："
                        prop="registerTime"
                >
                    <span class="textSize">{{ modelForm.registerTime | formatDate}}</span>
                </el-form-item>
                <el-form-item
                        label="授权状态："
                >
                    <!--          <el-tag effect="dark" :type="modelForm.isRegister ? 'success':'danger'">{{-->
                    <!--            modelForm.isRegister ? "已注册" : "未注册"-->
                    <!--          }}</el-tag>-->
<!--                    <el-tag effect="dark" type="success">正式授权</el-tag>-->
                    <el-tag effect="dark" :type="modelForm.isRegister ? ((modelForm.isRegister === 1 && modelForm.isTrail === 0) ? 'success':'warning' ):'danger'">{{
                        modelForm.isRegister ?((modelForm.isRegister === 1 && modelForm.isTrail === 0) ? "正式授权" : "试用授权"): "未注册"
                        }}</el-tag>
                </el-form-item>

                <el-form-item
                        label="产品质保到期时间："
                        prop="endTime"
                >
<!--                    <span class="textSize">{{ modelForm.endTime}}</span>-->
                    <span class="textSize" v-if="modelForm.status !== -1">{{ modelForm.endTime|formatDate }}</span>
                    <el-tag effect="dark" type="warning" v-if="modelForm.status === -1">已过期</el-tag>
                </el-form-item>
<!--                <el-form-item-->
<!--                        label="产品使用到期时间："-->
<!--                        prop="endTime"-->
<!--                >-->
<!--                    <span class="textSize">永久</span>-->
<!--                    &lt;!&ndash;          <el-tag effect="dark" type="success" v-if="modelForm.status !== -1">永久</el-tag>&ndash;&gt;-->
<!--                </el-form-item>-->
            </el-form>
        </el-card>

        <!--上传证书解析-->
        <el-dialog title="软件授权" @close="closeImportDialog" :close-on-click-modal="false" :visible.sync="dialogImportVisible" width="20%" custom-class="small-dialog-min-width">
            <el-form :model="importForm" ref="importForm">
                <el-form-item label="授权许可号"
                              :label-width="formLabelWidth">
                    {{softSn}}
                </el-form-item>
                <el-form-item label="选择授权文件" prop="upFile"
                              :label-width="formLabelWidth"
                              :rules="{required: true, message: '授权文件不能为空', trigger: 'blur'}">
                    <el-upload
                            ref="importUpload"
                            :action="importFileUrl"
                            :file-list="importFileList"
                            :on-success="handleImportSuccess"
                            :on-error="handleImportError"
                            :on-remove="handleImportRemove"
                            :on-change="handleImportChange"
                            :before-upload="handleImportBefore"
                            :limit="1"
                            accept=".key"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip">文件大小不能超过1M，文件类型：.key</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogImportVisible = false" class="black-button" >取消</el-button>
                <el-button type="primary" @click="importModel('importForm')" >授权</el-button>
            </div>
        </el-dialog>
    </el-main>
    
</template>

<script>
    import { formatDate } from "../../../assets/js/date";
    export default {
        name: "",
        data() {
        //可写validate方法
            return {
                factory: "深圳市纽创信安科技开发有限公司",
                modelForm: {
                    // companyName: "深圳市纽创信安科技开发有限公司",
                    // endTime: "2025-11-20",
                    // registerTime: "2022-11-21",
                    // sn:"",
                    // license:"51F1-0600-6F01-BC50",

                    companyName: "",
                    address: "",
                    key: "",
                    tel: "",
                    license: "",
                    isRegister: false,
                    status: 1,
                    isTrail: 0
                },
                env: process.env,
                dialogImportVisible:false,
                importFileList:[],
                importFileUrl:HOSTCONFIG.RESTHOST + this.$apiUrl.licenseApi.active,
                formLabelWidth: '120px',
                loadingInstance:null,
                isActive: null,
                softSn: "",
                isTrail: 0,
                isRegister: 0,
                importForm:{
                    upFile:''
                }
            }
        },
        filters: {
            formatDate(time) {
                return formatDate(time, "yyyy-MM-dd");
                //此处formatDate是一个函数，将其封装在common/js/date.js里面，便于全局使用
            },
        },
        mounted() {
            // this.$refs[this.activeName].findByPage()
            this.findSystemInformation();
        },
        beforeDestroy() {
        },
        watch: {},
        methods: {
            findLicenseInfo() {
                let self = this;
                self.$http.post({
                    url: self.$apiUrl.licenseApi.getLicenseInfo,
                    opt: {isLoading: false, showError: true, showSuccess: false},
                    callback: {
                        success: (data) => {
                            self.isActive = data.result.isActive;
                            self.softSn = data.result.sn;
                            self.isTrail = data.result.isTrail;
                            self.isRegister = data.result.isRegister;
                        },
                        error: (data) => {
                            self.$alert(data.msg,"错误",{
                                confirmButtonText: '确定',
                                type: 'error',
                                callback: action => {}
                            });
                        }
                    }
                })
            },
            closeImportDialog(){
                this.$refs['importForm'].resetFields();
                this.importFileList=[];
                this.findSystemInformation();
            },
            handleImportBefore(file) {
                const isLt2M = file.size / 1024 / 1024 < 1;
                if (!isLt2M) {
                    this.$message.error('上传文件大小不能超过 1MB!');
                    this.$refs['importForm'].resetFields();
                    this.importFileList=[];
                    if(this.loadingInstance){
                        this.loadingInstance.close();
                        this.loadingInstance = null;
                    }
                }
                return isLt2M;
            },
            handleImportSuccess(response, file, fileList){
                let self = this;
                if(self.loadingInstance){
                    self.loadingInstance.close();
                    self.loadingInstance = null;
                }
                if(response.error == 0){
                    //self.getUpCertDetail(response.result.)
                    let r = "试用授权";
                    if(response.result.register && response.result.isTrail === 0){
                        r = "正式授权";
                    }
                    self.$alert("授权成功。["+response.result.company+"]取得本软件的["+r+"]", '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "success",
                        callback: action => {
                            self.dialogImportVisible = false;
                        }
                    });
                }else{
                    self.$alert(response.msg, '提示', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: "warning"
                    });
                    self.$refs['importForm'].resetFields();
                    // this.importForm={
                    //     templateFile:'',
                    //     upFile:''
                    // }
                    self.importFileList=[];
                    this.resetUpload = true;
                }
            },
            handleImportError(err, file, fileList){
                if(this.loadingInstance){
                    this.loadingInstance.close();
                    this.loadingInstance = null;
                }
                this.$alert("上传文件失败："+err, '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    type: "error"
                });
                return;
            },
            handleImportRemove(file, fileList){
                if(fileList.length == 0){
                    this.importForm.upFile = null;
                }
            },
            handleImportChange(file, fileList){
                if(fileList.length == 0){
                    this.importForm.upFile = null;
                }else{
                    this.importForm.upFile = file;
                }
                if(this.resetUpload){
                    this.importForm.upFile = null;
                    this.resetUpload = false;
                }
            },
            importModel(formName){
                let self = this;
                self.$refs[formName].validate((valid) => {
                    if (valid) {
                        self.loadingInstance = self.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(200, 200, 200, 0.4)'
                        });
                        self.$refs.importUpload.submit();
                    } else {
                        return false;
                    }
                });
            },
            activeSoft(){
                this.dialogImportVisible = true;
            },
            handleClick(){
                this.$refs[this.activeName].findByPage()
            },
            findSystemInformation() {
                let self = this;
                self.$http.post({
                    url: self.$apiUrl.InformationApi.findSystemInfo,
                    opt: { isLoading: true, showError: true, showSuccess: false },
                    callback: {
                        success: (data) => {
                            self.modelForm = data.result.systemInfo;
                            // self.nicDatas = data.result.ip;
                            self.disabled = true;
                            let reTiem = formatDate(data.result.registerTime, "yyyy-MM-dd");
                            self.modelForm.reTiem = reTiem;
                            if (data.result.systemInfo.isRegister === 1) {
                                self.isRegister = true;
                            }
                            self.findLicenseInfo();
                        },
                    },
                });
            },
        }
    }
</script>

<style scoped>

</style>