<template>
    <div ref="flow" class="flow-line"></div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        name: "centerFlowChar",
        props:["flowData"],
        data(){
            return{
                width: document.body.clientWidth,
                chart: null
            }
        },
        mounted() {
            // console.log(this.flowData)
            //this.draw();
        },
        methods:{
            draw(){
               let option = {
                   tooltip: {
                       trigger: 'axis',
                       position: function (pt) {
                           return [pt[0], '10%'];
                       }
                   },
                   title: {
                       show:false
                   },
                   toolbox: false,
                   grid:{
                       top:"20px",
                       bottom:"30px",
                       left:"50px",
                       right:"20px"
                   },
                   xAxis: {
                       type: 'category',
                       boundaryGap: false,
                       data: this.flowData.date,
                       axisLine:{
                           lineStyle:{
                               color:"#888888"
                           }
                       },
                       axisLabel:{
                           fontSize: this.width > 2000 ?12 * 1.5 : 12,
                       },
                       splitLine:false,
                       min:0
                   },
                   yAxis: {
                       type: 'value',
                       min: 0,
                       axisLine:{
                           show:true,
                           lineStyle:{
                               color:"#888888"
                           }
                       },
                       axisLabel:{
                           fontSize: this.width > 2000 ?12 * 1.5 : 12,
                       },
                       splitLine: {
                           lineStyle:{
                               color: "#666666"
                           }
                       }
                   },
                    series: [
                        {
                            name: '即时流量',
                            type: 'line',
                            symbol: 'none',
                            smooth: true,
                            itemStyle: {
                                color: '#37a2da'
                            },
                            areaStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#37a2da'
                                }, {
                                    offset: 1,
                                    color: '#37a2da'
                                }])
                            },
                            data: this.flowData.data
                        }
                    ]
                };
                this.chart = this.$echarts.init(this.$refs.flow);
                this.chart.setOption(option);
            }
        }
    }
</script>

<style scoped>
    .flow-line{
        height: 2.85rem;
    }
</style>