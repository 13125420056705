<template>
    <div id="china-map" style="width: 100%;height: 12rem"></div>
</template>

<script>
    import 'echarts/map/js/china.js'
    export default {
        name: "chinamap",
        data(){
            return{
                option: {
                    tooltip:{//鼠标移上去时显示悬浮框
                        backgroundColor:'transparent',//悬浮框最外层设置了默认padding;5,背景色灰色，如果要自定义，设置padding:0;或者背景色透明。
                        padding:0,
                        trigger:'item'//设置该属性之后，会与series中data数据对应，如上图。注意data中对象的键名为name。如果单纯的展示数据可用此属性，不与formatter同用。
                        // formatter:(params)=>{
                        //let obj = {img:require('../../assets/images/weather/湿度.png')}
                        //     return `<div class="test">${params.name}<img src=${obj.img}><div>`//模板字符串渲染悬浮框，注意图片的引入方式（require）,否则无法显示。
                        // },
                    },
                    series: [
                        {
                            name: "国内天气",
                            type: "map",
                            mapType: "china",
                            roam: false,
                            zoom:1.2,//默认地图在容器中显示zoom:1,可根据需求放大缩小地图
                            itemStyle:{
                                areaColor:'#001e3b',//地图区域背景颜色
                                borderColor:'#0268CE'//地图边界颜色
                            },
                            emphasis:{//高亮时的设置
                                itemStyle:{
                                    areaColor:'#01376d',//高亮时地图区域背景颜色
                                },
                                label:{//文字颜色，样式在此处
                                    color:'rgb(230, 102, 18)',
                                    fontSize:'15'
                                }
                            },
                            label: {
                                normal: {
                                    show: true,
                                    color:"#fff"
                                },


                            },

                            data: [
                                { name: "北京", value: Math.round(Math.random() * 1000) },
                                { name: "天津", value: Math.round(Math.random() * 1000) },
                                { name: "上海", value: Math.round(Math.random() * 1000) },
                                { name: "重庆", value: Math.round(Math.random() * 1000) },
                                { name: "河北", value: Math.round(Math.random() * 1000) },
                                { name: "河南", value: Math.round(Math.random() * 1000) },
                                { name: "云南", value: Math.round(Math.random() * 1000) },
                                { name: "辽宁", value: Math.round(Math.random() * 1000) },
                                { name: "黑龙江", value: Math.round(Math.random() * 1000) },
                                { name: "湖南", value: Math.round(Math.random() * 1000) },
                                { name: "安徽", value: Math.round(Math.random() * 1000) },
                                { name: "山东", value: Math.round(Math.random() * 1000) },
                                { name: "新疆", value: Math.round(Math.random() * 1000) },
                                { name: "江苏", value: Math.round(Math.random() * 1000) },
                                { name: "浙江", value: Math.round(Math.random() * 1000) },
                                { name: "江西", value: Math.round(Math.random() * 1000) },
                                { name: "湖北", value: Math.round(Math.random() * 1000) },
                                { name: "广西", value: Math.round(Math.random() * 1000) },
                                { name: "甘肃", value: Math.round(Math.random() * 1000) },
                                { name: "山西", value: Math.round(Math.random() * 1000) },
                                { name: "内蒙古", value: Math.round(Math.random() * 1000) },
                                { name: "陕西", value: Math.round(Math.random() * 1000) },
                                { name: "吉林", value: Math.round(Math.random() * 1000) },
                                { name: "福建", value: Math.round(Math.random() * 1000) },
                                { name: "贵州", value: Math.round(Math.random() * 1000) },
                                { name: "广东", value: Math.round(Math.random() * 1000) },
                                { name: "青海", value: Math.round(Math.random() * 1000) },
                                { name: "西藏", value: Math.round(Math.random() * 1000) },
                                { name: "四川", value: Math.round(Math.random() * 1000) },
                                { name: "宁夏", value: Math.round(Math.random() * 1000) },
                                { name: "海南", value: Math.round(Math.random() * 1000) },
                                { name: "台湾", value: Math.round(Math.random() * 1000) },
                                { name: "香港", value: Math.round(Math.random() * 1000) },
                                { name: "澳门", value: Math.round(Math.random() * 1000) }
                            ]
                        }
                    ]
                }
            }
        },
        mounted() {
            this.drawMap()
        },
        methods:{
            drawMap() {
                var chinaChart = this.$echarts.init(
                    document.getElementById("china-map")
                );
                chinaChart.setOption(this.option);
            }
        }
    }
</script>

<style scoped>

</style>