const bigViewData = {
    mapConfig: {
        // centerPoint:  [0.71, 0.48],
        // centerPoint:  [0.49, 0.53],
        centerPoint:  [0.73, 0.57],
        points: [
            {
                name: '山东',
                position: [0.71, 0.48]
            }, {
                name: '海南',
                position: [0.59, 0.90]
            }, {
                name: '台湾',
                position: [0.75, 0.79]
            },
            {
                name: '北京',
                position: [0.68, 0.39]
            },
            {
                name: '山西',
                position: [0.62, 0.45]
            },
            {
                name: '河北',
                position: [0.66, 0.42]
            },
            {
                name: '河南',
                position: [0.64, 0.55]
            },
            {
                name: '湖北',
                position: [0.62, 0.62]
            },
            {
                name: '湖南',
                position: [0.62, 0.69]
            },
            {
                name: '江苏',
                position:[0.73, 0.57]
            },
            {
                name: '上海',
                position:  [0.76, 0.62]
            },
            {
                name: '安徽',
                position: [0.69, 0.59]
            },
            {
                name: '江西',
                position: [0.67, 0.69]
            },
            {
                name: '浙江',
                position: [0.74, 0.66]
            },
            {
                name: '福建',
                position: [0.71, 0.74]
            },
            {
                name: '广东',
                position: [0.64, 0.80]
            },
            {
                name: '广西',
                position: [0.57, 0.80]
            },
            {
                name: '云南',
                position: [0.47, 0.78]
            },
            {
                name: '重庆',
                position: [0.55, 0.64]
            },
            {
                name: '四川',
                position:  [0.49, 0.62]
            },
            {
                name: '天津',
                position:  [0.70, 0.41]
            },
            {
                name: '吉林',
                position: [0.78, 0.36]
            },
            {
                name: '辽宁',
                position: [0.83, 0.31]
            },
            {
                name: '黑龙江',
                position: [0.86, 0.22]
            },
            {
                name: '内蒙古',
                position:  [0.60, 0.35]
            },
            {
                name: '宁夏',
                position:  [0.52, 0.46]
            },
            {
                name: '青海',
                position: [0.38, 0.49]
            },
            {
                name: '新疆',
                position: [0.24, 0.38]
            },
            {
                name: '西藏',
                position: [0.30, 0.63]
            },
            {
                name: '贵州',
                position: [0.54, 0.71]
            },
            {
                name: '甘肃',
                position: [0.49, 0.53]
            },
            {
                name: '陕西',
                position: [0.49, 0.64]
            }
        ]
        // bgImgSrc: '/img/flylineChart/map.jpg'
    },
    shandongMapConfig: {
        centerPoint:  [0.33, 0.44],
        points: [
            {
                name: '泰安',
                position: [0.33, 0.56]
            },
            {
                name: '莱芜',
                position: [0.39, 0.53]
            },
            {
                name: '淄博',
                position: [0.42, 0.45]
            },
            {
                name: '滨州',
                position: [0.42, 0.30]
            },
            {
                name: '德州',
                position: [0.27, 0.31]
            },
            {
                name: '聊城',
                position: [0.19, 0.49]
            },
            {
                name: '菏泽',
                position: [0.18, 0.73]
            },
            {
                name: '济宁',
                position: [0.28, 0.69]
            },
            {
                name: '枣庄',
                position: [0.36, 0.79]
            },
            {
                name: '临沂',
                position: [0.45, 0.74]
            },
            {
                name: '日照',
                position: [0.55, 0.68]
            },
            {
                name: '潍坊',
                position: [0.53, 0.45]
            },
            {
                name: '东营',
                position: [0.49, 0.26]
            },
            {
                name: '青岛',
                position: [0.66, 0.51]
            },
            {
                name: '烟台',
                position: [0.75, 0.30]
            },
            {
                name: '威海',
                position: [0.85, 0.35]
            },
        ]
    },
    cryptoProtocol: {
        data: [
            {
                name: 'TLS',
                value: 167
            },
            {
                name: 'HTTPS',
                value: 123
            },
            {
                name: '漯河',
                value: 98
            },
            {
                name: '郑州',
                value: 75
            },
            {
                name: '西峡',
                value: 66
            },
        ],
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '单位'
    },
    heGuiWarningListConfig: {
        config: {
            header: ["时间", "资产", "协议", "告警信息"],
            data: [],
            rowNum: 5, //表格行数
            headerHeight: document.body.clientWidth > 2000 ? 60 : 30,
            headerBGC: "#0f1325", //表头
            oddRowBGC: "#0f1325", //奇数行
            evenRowBGC: "#171c33", //偶数行
            index: false,
            columnWidth: [320,290,160,300],
            // align: ["left","center"]
        },
        cryptoData:null
    },
    secWarningListConfig: {
        config: {
            header: ["时间", "资产", "协议", "告警信息"],
            data: [],
            rowNum: 5, //表格行数
            headerHeight: document.body.clientWidth > 2000 ? 60 : 30,
            headerBGC: "#0f1325", //表头
            oddRowBGC: "#0f1325", //奇数行
            evenRowBGC: "#171c33", //偶数行
            index: false,
            columnWidth: [320,290,160,300],
            // align: ["left","center"]
        },
        cryptoData:null
    }
}


export default bigViewData;