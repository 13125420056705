<template>
    <el-main style="min-width:1200px">
        <el-row :gutter="20" type="flex" class="top-total-box">
            <template v-for="(item, index) in totalData"> 
                <el-col :span="4" :key="index" v-if="item.index != 1 && item.index != 2">
                    <el-card>
                        <el-row type="flex" align="middle">
                            <el-col class="icon-col">
                                <i :class="['icon', 'iconfont', icons[index]]" :style="{color: colors[index]}"></i>
                            </el-col>
                            <el-col class="txt-col">
                                <strong class="num">{{item.number}}</strong>
                                <span>{{item.name}}</span>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </template>
        </el-row>
        <el-row class="con statistic-table" :gutter="20">
            <el-col :span="12">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 探针当前流量</span>
                        </div>
                    </div>

                    <div class="graph-panel agent-line-box">
                        <div class="empty-data" v-if="flowBarData.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-else>暂时没有数据</div>
                        </div>
                        <pictorial-bar :data="flowBarData" :yAxis="flowYAxis" />
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
<!--                <el-card class="center">-->
<!--                    <div class="header-title">-->
<!--                        <div class="d-flex pt-2 pl-2">-->
<!--                            <span style="color:#5cd9e8">-->
<!--                                <icon name="chart-area"></icon>-->
<!--                            </span>-->
<!--                            <span class="fs-xl text mx-2"> 1小时内探针吞吐量</span>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="graph-panel agent-line-box">-->
<!--                        <div class="empty-data" v-if="totalLineData.length == 0">-->
<!--                            <span class="empty-rotate">-->
<!--                                <i class="empty-data-icon iconfont iconsync-alt"></i>-->
<!--                            </span>-->
<!--                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>-->
<!--                            <div class="empty-data-text" v-else>暂时没有数据</div>-->
<!--                        </div>-->
<!--                        <default-line :data="totalLineData" :xAxis="lineXAxis" :dataZoom="true" yAxisName="流量大小/字节" />-->
<!--                    </div>-->
<!--                </el-card>-->
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 一周内网络通信协议连接图</span>
                        </div>
                    </div>

                    <div class="graph-panel agent-line-box">
                        <div class="empty-data" v-if="cryptoNetFlowData.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="weekloading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-else>暂时没有数据</div>
                        </div>
                        <default-line :data="cryptoNetFlowData" :xAxis="cryptoNetFlowXAxis" :dataZoom="true" yAxisName="连接次数/次" />
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row class="con statistic-table" :gutter="20">
            <el-col :span="12">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 1小时内探针内存使用率</span>
                        </div>
                    </div>

                    <div class="graph-panel agent-line-box">
                        <div class="empty-data" v-if="memoryLineData.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="memLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-else>暂时没有数据</div>
                        </div>
                        <default-line :data="memoryLineData" :xAxis="lineXAxis" :min="0" :max="100" yAxisName="使用率/%" />
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 1小时内探针 CPU 使用率</span>
                        </div>
                    </div>

                    <div class="graph-panel agent-line-box">
                        <div class="empty-data" v-if="cpuLineData.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="cpuLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-else>暂时没有数据</div>
                        </div>
                        <default-line :data="cpuLineData" :xAxis="lineXAxis"  :min="0" :max="100" yAxisName="使用率/%" />
                    </div>
                </el-card>
            </el-col>
        </el-row>

    </el-main>
</template>
<script>
export default {
    data() {
        return {
            isLoading: false,
            weekloading: false,
            cpuLoading: false,
            memLoading: false,
            memoryLineData: [],
            lineXAxis: [],
            cpuLineData: [],
            totalLineData: [],
            flowBarData: [],
            flowXAxis: [],
            flowYAxis: [],
            totalData: [],
            cryptoNetFlowData: [],
            cryptoNetFlowXAxis:[],
            icons: ['iconbox', 'iconinbox-in', 'iconlayer-group', 'iconlocation-fill', 'iconlocation', 'iconlightbulb', 'iconlightbulb', 'iconlightbulb'],
            // colors: ['#5743be', '#407809', '#1a55af', '#dba42f', '#f95e1c']
            colors: ['#82bbf5', '#82bbf5', '#82bbf5', '#dba42f', '#dba42f', '#f95e1c', '#f95e1c', '#f95e1c'],
            startAutoRefresh: false
        }
    },
    mounted() {
        this.getAgentInfoForOneHour()
        this.getAgentNetFlowWithNow()
        this.findBackendNumberStatics()
        this.findWeekStatsProtocol()
        setTimeout(()=>{
            this.startAutoRefresh = true;
            this.autoFreshAgentInfoForOneHour();
            this.autoAgentNetFlowWithNow();
            this.autoBackendNumberStatics();
        },3000)
    },
    destroyed() {
        this.startAutoRefresh = false;
    },
    methods: {
        autoFreshAgentInfoForOneHour(){
            let self = this;
            this.$http.post({
                url: this.$api.systemApi.getAgentInfoForOneHour,
                // params: {},
                opt: { isLoading: false, showSuccess: false, showError: false },
                callback: {
                    success: data => {
                        let dataTemp = data.result
                        this.memoryLineData = []
                        this.cpuLineData = []
                        this.totalLineData = []
                        this.lineXAxis = []
                        dataTemp.map((i, index) => {
                            this.memoryLineData.push({
                                name: i.name?i.name:i.ip,
                                ip: i.ip,
                                data: []
                            })
                            this.cpuLineData.push({
                                name: i.name?i.name:i.ip,
                                ip: i.ip,
                                data: []
                            })
                            this.totalLineData.push({
                                name: i.name?i.name:i.ip,
                                ip: i.ip,
                                data: []
                            })
                            if (Array.isArray(i.datas) && i.datas.length > 0) {
                                i.datas.map(j => {
                                    if (index === 0) {
                                        this.lineXAxis.push(this._.formatDate(j.createTime, 'hh:mm:ss'))
                                    }

                                    this.memoryLineData[index].data.push(Number(j.memUsed * 100))
                                    this.cpuLineData[index].data.push(Number(j.cpuUsed * 100))
                                    this.totalLineData[index].data.push(Number(j.tx) + Number(j.rx))
                                })
                            }
                        })
                        if(self.startAutoRefresh) {
                            setTimeout(() => {
                                self.autoFreshAgentInfoForOneHour()
                            }, 3000)
                        }
                    },
                    error: (data)=>{
                        if(self.startAutoRefresh) {
                            setTimeout(() => {
                                self.autoFreshAgentInfoForOneHour()
                            }, 3000)
                        }
                    },
                    fail: (res)=>{
                        if(self.startAutoRefresh) {
                            setTimeout(() => {
                                self.autoFreshAgentInfoForOneHour()
                            }, 3000)
                        }
                    }
                }
            })
        },
        autoAgentNetFlowWithNow(){
            let self = this;
            this.$http.post({
                url: this.$api.systemApi.getAgentNetFlowWithNow,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        let dataTemp = data.result
                        this.flowYAxis = []
                        this.flowBarData = []
                        dataTemp.map(i => {
                            this.flowYAxis.push(i.name + (i.state===0?"":"(离线)"))
                            this.flowBarData.push(i.total?i.total:"0")
                        })
                        if(self.startAutoRefresh) {
                            setTimeout(() => {
                                self.autoAgentNetFlowWithNow()
                            }, 3000)
                        }
                    },
                    error: (data)=>{
                        if(self.startAutoRefresh) {
                            setTimeout(() => {
                                self.autoAgentNetFlowWithNow()
                            }, 3000)
                        }
                    },
                    fail: (res)=>{
                        if(self.startAutoRefresh) {
                            setTimeout(() => {
                                self.autoAgentNetFlowWithNow()
                            }, 3000)
                        }
                    }
                }
            })
        },
        autoBackendNumberStatics(){
            let self = this;
            this.$http.post({
                url: this.$api.systemApi.findBackendNumberStatics,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        this.totalData = data.result
                        // let dataTemp = data.result
                        if(self.startAutoRefresh){
                            setTimeout(()=>{
                                self.autoBackendNumberStatics()
                            },3000)
                        }
                    },
                    error: (data)=>{
                        if(self.startAutoRefresh){
                            setTimeout(()=>{
                                self.autoBackendNumberStatics()
                            },3000)
                        }
                    },
                    fail: (res)=>{
                        if(self.startAutoRefresh){
                            setTimeout(()=>{
                                self.autoBackendNumberStatics()
                            },3000)
                        }
                    }
                }
            })
        },
        getAgentInfoForOneHour() {
            this.cpuLoading = true
            this.memLoading = true
            this.$http.post({
                url: this.$api.systemApi.getAgentInfoForOneHour,
                // params: {},
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        let dataTemp = data.result
                        dataTemp.map((i, index) => {
                            this.memoryLineData.push({
                                name: i.name?i.name:i.ip,
                                ip: i.ip,
                                data: []
                            })
                            this.cpuLineData.push({
                                name: i.name?i.name:i.ip,
                                ip: i.ip,
                                data: []
                            })
                            this.totalLineData.push({
                                name: i.name?i.name:i.ip,
                                ip: i.ip,
                                data: []
                            })
                            if (Array.isArray(i.datas) && i.datas.length > 0) {
                                i.datas.map(j => {
                                    if (index === 0) {
                                        this.lineXAxis.push(this._.formatDate(j.createTime, 'hh:mm:ss'))
                                    }

                                    this.memoryLineData[index].data.push(Number(j.memUsed * 100))
                                    this.cpuLineData[index].data.push(Number(j.cpuUsed * 100))
                                    this.totalLineData[index].data.push(Number(j.tx) + Number(j.rx))
                                })
                            }
                        })
                        this.cpuLoading = false
                        this.memLoading = false
                    }
                }
            })

        },
        getAgentNetFlowWithNow() {
            this.$http.post({
                url: this.$api.systemApi.getAgentNetFlowWithNow,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        let dataTemp = data.result
                        dataTemp.map(i => {
                            this.flowYAxis.push(i.name + (i.state===0?"":"(离线)"))
                            this.flowBarData.push(i.total?i.total:"0")
                        })
                    }
                }
            })
        },
        findBackendNumberStatics() {
            this.$http.post({
                url: this.$api.systemApi.findBackendNumberStatics,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        this.totalData = data.result
                        // let dataTemp = data.result

                    }
                }
            })
        },
        reverse(data){
            //'tls', 'http', 'smtp', 'ssh', 'imap', 'dns', 'ldap', 'ftp', 'telnet'
            let tls = data.tls.reverse();
            let http = data.http.reverse();
            let smtp = data.smtp.reverse();
            let ssh = data.ssh.reverse();
            let imap = data.imap.reverse();
            let dns = data.dns.reverse();
            let ldap = data.ldap.reverse();
            let ftp = data.ftp.reverse();
            let telnet = data.telnet.reverse();
            let date = data.date.reverse();
            let r = {
                tls: tls,
                http: http,
                smtp: smtp,
                ssh: ssh,
                imap: imap,
                dns: dns,
                ldap: ldap,
                ftp: ftp,
                telnet: telnet,
                date: date,

            }
            return r;
        },
        findWeekStatsProtocol() {
            this.weekloading = true
            this.$http.post({
                url: this.$api.systemApi.findWeekStatsProtocol,
                // params: {},
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        let dataTemp = data.result
                        let res = this.reverse(dataTemp);
                        this.cryptoNetFlowData = [
                            {
                                name: "tls",
                                data: res.tls
                            },
                            {
                                name: "http",
                                data: res.http
                            },
                            {
                                name: "smtp",
                                data: res.smtp
                            },
                            {
                                name: "ssh",
                                data: res.ssh
                            },
                            {
                                name: "imap",
                                data: res.imap
                            },
                            {
                                name: "dns",
                                data: res.dns
                            },
                            {
                                name: "idap",
                                data: res.idap
                            },
                            {
                                name: "ftp",
                                data: res.ftp
                            },
                            {
                                name: "telnet",
                                data: res.telnet
                            }
                        ];
                        this.cryptoNetFlowXAxis = res.date;
                        //datatemp结构：[{name:"", datas:[date:"", value:""]}]
                        // dataTemp.map((i, index) => {
                        //     this.cryptoNetFlowData.push({
                        //         name: i.name,
                        //         ip: i.ip,
                        //         data: []
                        //     })
                        //     // this.cpuLineData.push({
                        //     //     name: i.name?i.name:i.ip,
                        //     //     ip: i.ip,
                        //     //     data: []
                        //     // })
                        //     // this.totalLineData.push({
                        //     //     name: i.name?i.name:i.ip,
                        //     //     ip: i.ip,
                        //     //     data: []
                        //     // })
                        //
                        //     if (Array.isArray(i.datas) && i.datas.length > 0) {
                        //         i.datas.map(j => {
                        //             if (index === 0) {
                        //                 this.cryptoNetFlowXAxis.push(this._.formatDate(j.date, 'hh:mm:ss'))
                        //             }
                        //
                        //             this.memoryLineData[index].data.push(Number(j.memUsed * 100))
                        //             this.cpuLineData[index].data.push(Number(j.cpuUsed * 100))
                        //             this.totalLineData[index].data.push(Number(j.tx) + Number(j.rx))
                        //         })
                        //     }
                        // })
                        this.weekloading = false
                    },error: (data)=>{
                        this.weekloading = false
                    },fail:(res)=>{
                        this.weekloading = false
                    }
                }
            })

        },
    }
}
</script>
<style lang="scss" scoped="scoped">
.con {
    display: flex;
}
.statistic-table {
    margin-top: 20px;
}
.graph-panel {
    position: relative;
}
.statics-day {
    position: absolute;
    top: -30px;
    right: 0;
}
.agent-line-box {
    height: 300px;
}
.top-total-box {
    .icon-col {
        width: 50px;
        .icon {
            font-size: 24px;
        }
    }
    .txt-col {
        text-align: center;
        .num {
            text-align: center;
            display: block;
            font-size: 24px;
        }
    }
}
@media screen and (max-width: 1667px) {
    .top-total-box {
        .icon-col {
            // width: 0;
            display: none;
        }
        .txt-col {
            font-size: 12px;
            // margin: 0 -10px;
        }
    }
}
</style>
<style lang="scss">
.top-total-box {
    .el-card__body {
        padding-left: 10px;
        padding-right: 10px;
    }
}
</style>