<template>
  <div>
    <div id="hgChart" style="width:100%;height:2.75rem;"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import allData from '../../../assets/json/bigview.json'
export default {
  data() {
    return {
      chart: null,
      staticsData:null,
      timer:null
    };
  },
  mounted() {
    this.findCryptoAlgData();
    this.charTimg();
  },
  destroyed() {
    if(this.timer){
      clearInterval(this.timer);
    }
  },
  mixins: [echartMixins],
  methods: {
    charTimg() {
      this.timer = setInterval(() => {
        this.findCryptoAlgData();
      }, 1000*60*60*24);
    },
    findCryptoAlgData() {
      let self = this;
      self.staticsData = allData.hgData;
      self.draw();
    },
    draw() {
      let width = document.body.clientWidth;
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("hgChart"));
      //  ----------------------------------------------------------------
      // 数据
      let weekCategory = this.staticsData.weekCategory;


      let maxData = this.staticsData.weekMaxData[0];
      let weekMaxData =  this.staticsData.weekMaxData;
      let weekLineData = this.staticsData.weekLineData;



      // 颜色设置
      let color = {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)"
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)"
            }
          ]
        }
      };
      let option = {
        // title: {
        //   text: "",
        //   textStyle: {
        //     color: "#D3D6DD",
        //     fontSize: 24,
        //     fontWeight: "normal"
        //   },
        //   subtext: year + "/" + weekCategory[6],
        //   subtextStyle: {
        //     color: "#fff",
        //     fontSize: 16
        //   },
        // },

        tooltip: {
          trigger: "item"
        },
        grid: {
          left: 90,
          right: 80,
          bottom: 55,
          top: 20
        },
        xAxis: {
          type: "category",
          position: "bottom",
          axisLine: true,
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: width > 2000 ?20 * 1.5 : 14,
          },
          data: weekCategory
        },
        // 下方Y轴
        yAxis: {
          name: "次数",
          nameLocation: "end",
          nameGap: 24,
          nameTextStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize:  width > 2000 ?20 * 1.5 : 14,
          },
          max: maxData,
          splitNumber: 4,

          axisLine: {
            lineStyle: {
              opacity: 0
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.1
            }
          },
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: width > 2000 ?20 * 1.5 : 14,
          }
        },
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#fff"
              }
            },
            lineStyle: {
              normal: {
                color: color.linearBtoG,
                width: 3
              }
            },
            areaStyle: {
              normal: {
                color: color.areaBtoG
              }
            },
            data: weekLineData,
            lineSmooth: true,
            markLine: {
              silent: true,
              data: [
                {
                  type: "average",
                  name: "平均值"
                }
              ],
              precision: 0,
              label: {
                normal: {
                  formatter: "{c}",
                  fontSize:  width > 2000 ?16 * 1.5 : 16
                }
              },
              lineStyle: {
                normal: {
                  color: "rgba(248,211,81,.7)"
                }
              }
            },
            tooltip: {
              position: "top",
              formatter: "{c} 次",
              backgroundColor: "rgba(28,152,232,.2)",
              padding: 6
            }
          },
          {
            name: "占位背景",
            type: "bar",
            itemStyle: {
              normal: {
                show: true,
                color: "#000",
                opacity: 0
              }
            },
            silent: true,
            barWidth: "50%",
            data: weekMaxData,
            animation: false
          }
        ]
      };
      this.chart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>