<template>
    <div id="chartsBox" ref="myEchart"></div>
</template>
<script>
import 'echarts/map/js/province/shandong.js'

export default {
    mounted() {
        this.initChinaMap()
    },
    methods: {
        initChinaMap() {
            let option = {
               
                geo: {
                    map: '山东',
                    roam: false,
                },
                series: [
                    {
                        type: 'map',
                        map: '山东',
                        itemStyle: {
                            normal: {
                                areaColor: {
                                    type: 'radial',
                                    x: 0.5,
                                    y: 0.5,
                                    r: 0.8,
                                    colorStops: [
                                        {
                                            offset: 0.26,
                                            color: 'rgba(12, 37, 57,1)' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(28, 59, 93,1)' // 100% 处的颜色
                                        }
                                    ]
                                },
                                borderColor: 'rgba(124, 232, 252, 0.8)',
                                borderWidth: 1.5
                            },
                            emphasis: {
                                areaColor: 'rgb(16,27,63)',
                                label: {
                                    color: '#fff'
                                }
                            }
                        }
                    }
                ]
            }
            let myEchart = this.$echarts.init(this.$refs.myEchart)
            myEchart.setOption(option)
        }
    }
}
</script>
<style lang="scss" scoped>
#chartsBox {
    width: 100%;
    height: 100%;
    display: inline-block;
}
</style>
