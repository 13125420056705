import Conf from '../conf';
export default {
    name: 'deviceLine',
    mixins: [Conf],
    methods: {
        seriesHandler() {
            return {
                name: 'device Chart',
                type: 'line',
                smooth: true,
                symbol: 'none',
                areaStyle: {},
                // containLabel: true,
                // areaStyle: {
                //     color: {
                //         type: 'linear',
                //         x: 0,
                //         y: 0,
                //         x1: 0,
                //         y1: 1,
                //         colorStops: [
                //             {
                //                 offset: 0, color: 'rgba(107,168,255,0.10)'
                //             },
                //             {
                //                 offset: 1, color: 'rgba(57,111,255,0.20)'
                //             }]
                //     }
                // },
                data: this.data
            };
        },
        gridHandler() {
            return {
                show: false,
                x: 0,
                y: 0,
                x2: 0,
                y2: 0,
                borderColor: 'transparent'
            };
        },
        yAxisHandler() {
            return [{
                type: 'value',
                show: false,
                splitNumber: 4,
                offset: 30,
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                // splitLine: {
                //     show: true,
                //     lineStyle: {
                //         color: '#eee'
                //     }
                // },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#666',
                    fontSize: 12,
                    formatter(value) {
                        if (value >= 10000) {
                            value = value / 10000 + '万';
                        }
                        return value;
                    }
                }
            }];
        },
        xAxisHandler() {
            return [{
                type: 'category',
                show: false,
                smooth: true,
                symbol: 'none',
                areaStyle: {},
                data: this.xAxis,
                // boundaryGap: false,
                splitLine: {
                    show: false
                }
                // axisTick: {
                //     show: false
                // },
                // axisLine: {
                //     show: false
                // },
                // axisLabel: {
                //     color: '#666',
                //     fontSize: 12
                // }
            }];
        }
    }
};
