export default  function validate(vue, formName) {
    return new Promise(function (resolve, reject) {
        vue.$refs[formName].validate((valid) => {
            // console.log(valid)
            if (valid) {
                resolve(valid);
            }
            // else {
            //     reject(valid);
            // }
        });
    });
}