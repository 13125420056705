<template>
    <el-main style="min-width:1200px">
<!--        <el-card class="center admin-style-list">-->
<!--            <div slot="header" class="clearfix">-->
<!--                <span class="title-text">密码漏洞库</span>-->
<!--            </div>-->
            <el-tabs v-model="activeName" class="body-tab" type="border-card" @tab-click="handleTabClick" >
                <el-tab-pane label="CVE密码漏洞库" name="first">
                    <cve-data v-if="isCve"></cve-data>
                </el-tab-pane>
                <el-tab-pane label="CNNVD密码漏洞库" name="second">
                    <cnnvd-data v-if="!isCve"></cnnvd-data>
                </el-tab-pane>
            </el-tabs>
<!--        </el-card>-->
    </el-main>
</template>

<script>
    import CveData from './components/cvedata'
    import CnnvdData from './components/cnnvddata'
    export default {
        name: 'VulnerabilityData',
        components: {CveData,CnnvdData},
        data() {
            return {
                activeName:"first",
                isCve:true
            }
        },
        methods:{
            handleTabClick(tab, event) {
                if(tab.name == "first"){
                    this.isCve = true;
                }else{
                    this.isCve = false;
                }
                //console.log(tab);
            }
        }
    }
</script>
