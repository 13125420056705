<template>
  <div id="centreRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2">密码算法使用分析</span>
      </div>
      <div class="d-flex jc-center body-box" style=" margin-top: 0;">
        <dv-capsule-chart :config="config" style="width: 100%;height:2rem" />
        <!-- ---------------------------------------- -->
        <centreRight2Chart1 />
      </div>
    </div>
  </div>
</template>

<script>
import centreRight2Chart1 from "@/components/echart/centerRight/centerRight2Chart";

export default {
  data() {
    return {
      config: {
        data: [
          {
            name: "AES",
            value: 100
          },
          {
            name: "SM4",
            value: 67
          },
          {
            name: "SHA256",
            value: 89
          },
          {
            name: "ECC",
            value: 78
          },
          {
            name: "SM2",
            value: 68
          }
        ]
      }
    };
  },
  components: { centreRight2Chart1 },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">

</style>