<template>
    <el-main style="min-width:1200px">
        <!--        <el-card class="center admin-style-list">-->
        <!--            <div slot="header" class="clearfix">-->
        <!--                <span class="title-text">密码漏洞库</span>-->
        <!--            </div>-->
        <el-tabs v-model="activeName" type="border-card" class="body-tab" @tab-click="handleTabClick" >
            <el-tab-pane label="设备异常告警信息" name="first">
                <device-warn-page v-if="isDevice"></device-warn-page>
            </el-tab-pane>
            <el-tab-pane label="应用异常告警信息" name="second">
                <app-warn-page v-if="!isDevice"></app-warn-page>
            </el-tab-pane>
        </el-tabs>
        <!--        </el-card>-->
    </el-main>
</template>

    <script>
        import DeviceWarnPage from './components/devicewarnpage'
        import AppWarnPage from './components/appwarnpage'
        export default {
            name: 'AbnormalMonitor',
            components: {DeviceWarnPage,AppWarnPage},
            data() {
                return {
                    activeName:"first",
                    isDevice:true
                }
            },
            methods:{
                handleTabClick(tab, event) {
                    if(tab.name == "first"){
                        this.isDevice = true;
                    }else{
                        this.isDevice = false;
                    }
                    //console.log(tab);
                }
            }
        }
    </script>