<template>
  <el-footer>{{appConfig.copyRight}}</el-footer>
</template>

<script>
    export default {
        name: 'Footer',
      data(){
          return{
            appConfig: APPCONFIG
          }
      }
    }
</script>
