export default {
    menuData: [{
        node: "1",
        name: "1",
        title: "密码设备",
        icon: "iconfont iconshenpi",
        children: []
    },
    {
        node: "5",
        name: "5",
        title: "密评应用",
        icon: "iconfont iconapplication",
        children: []
    },
    {
        node: "2",
        name: "2",
        title: "加密业务",
        icon: "iconfont iconwj-zl",
        children: []
    },
    {
        node: "3",
        name: "3",
        title: "异常威胁",
        icon: "iconfont iconexclamation-circle ",
        children: []
    },
    {
        node: "4",
        name: "4",
        title: "系统管理",
        icon: "iconfont iconguanli",
        children: []
    }],
    menu: [{
        parentNode: "1",
        name: "1-1",
        icon: "iconfont iconguanli",
        routePath: "/device/manage",
        title: "设备管理"
    },
    {
        parentNode: "1",
        name: "1-2",
        routePath: "/device/discovery",
        title: "自动感知"
    },
    {
        parentNode: "1",
        name: "1-3",
        routePath: "/device/statistics",
        title: "数据统计"
    },
    {
        parentNode: "5",
        name: "5-1",
        routePath: "/application/manage",
        title: "应用管理"
    },
    {
        parentNode: "2",
        name: "2-1",
        routePath: "/protocol/ipsec",
        title: "网络层加密监控"
    },
    {
        parentNode: "2",
        name: "2-2",
        routePath: "/protocol/tls",
        title: "传输层加密监控"
    },
    {
        parentNode: "2",
        name: "2-3",
        routePath: "/protocol/http",
        title: "明文协议监控"
    },
        {
        parentNode:"2",
        name:"2-4",
        routePath:"/protocol/certificate",
        title:"数字证书监控"
    },
    {
        parentNode: "3",
        name: "3-1",
        routePath: "/warning/compliance",
        title: "密码合规告警"
    },
    // 密码威胁告警
    {
        parentNode: "3",
        name: "3-2",
        routePath: "/warning/security",
        title: "密码安全告警"
    },
    {
        parentNode: "3",
        name: "3-3",
        routePath: "/warning/threaten",
        title: "网络威胁告警"
    },

    // {
    //     parentNode: "3",
    //     name: "3-4",
    //     routePath: "/vulnerabilitywarning/warningdata",
    //     title: "漏洞攻击及威胁检测"
    // },
    // {
    //     parentNode: "3",
    //     name: "3-5",
    //     routePath: "/protocol/randomdigittest",
    //     title: "加密业务合规检测"
    // },
    {
        parentNode: "4",
        name: "4-5",
        routePath: "/system/adminlogs",
        title: "系统日志"
    },
    {
        parentNode: "4",
        name: "4-6",
        routePath: "/system/agent",
        title: "探针管理"
    },
    // {
    //     parentNode: "4",
    //     name: "4-7",
    //     routePath: "/system/outsourcing",
    //     title: "委外用户"
    // },
    {
        parentNode: "4",
        name: "4-3",
        routePath: "/system/adminusers",
        title: "用户管理"
    },
    // {
    //     parentNode: "4",
    //     name: "4-7",
    //     routePath: "/system/sim",
    //     title: "SIM 管理"
    // },
    {
        parentNode: "4",
        name: "4-1",
        routePath: "/system/organization",
        title: "组织架构"
    },
        {
            parentNode: "4",
            name: "4-2",
            routePath: "/system/license",
            title: "授权信息"
        },
    // {
    //     parentNode: "4",
    //     name: "4-2",
    //     routePath: "/roles/group",
    //     title: "角色管理"
    // },
    //     {
    //         parentNode: "4",
    //         name: "4-8",
    //         routePath: "/system/network",
    //         title: "网络设置"
    //     },
    // {
    //     parentNode: "4",
    //     name: "4-4",
    //     routePath: "/roles/module",
    //     title: "系统模块"
    // }
    ]
}