<template>
    <el-main style="min-width:1200px">
        <el-row>
            <el-col>
                <el-form label-position="left" class="inline-search-form">
                    <el-form-item label="探针机房区域" label-width="100px">
                        <el-select v-model="mainSearchForm.engineRoom" clearable placeholder="请选择" @change="engineChange" :disabled="engineDisabled">
                            <el-option
                                    v-for="item in engines"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="探针网络边界" label-width="100px">
                        <el-select v-model="mainSearchForm.networkBoundary" clearable placeholder="请选择" @change="netBoundaryChange" :disabled="netBoundaryDisabled">
                            <el-option
                                    v-for="item in netBoundary"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="探针" label-width="40px">
                        <el-select v-model="mainSearchForm.agentId" clearable placeholder="请选择" @change="agentChange" :disabled="agentDisabled">
                            <el-option
                                    v-for="item in agents"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="mainSearch">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-row class="con" :gutter="20">
            <el-col :span="8">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 证书数据统计</span>
                        </div>

                        <el-radio-group v-model="staticsDay6" @change="(label) => {changeStaticsDay(label, 6)}">
                            <el-radio-button label="WEEK">周</el-radio-button>
                            <el-radio-button label="MONTH">月</el-radio-button>
<!--                            <el-radio-button label="YEAR">年</el-radio-button>-->
                        </el-radio-group>
                    </div>
                    <div class="graph-panel total-num-wrap">
                        <div class="empty-data" v-if="totalInfo.total == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-else>暂时没有数据</div>
                        </div>
                        <div class="total-num-box" v-else>
                            <div class="total-num-item">
                                <div class="num-title">
                                    总连接数
                                </div>
                                <dv-decoration-9 class="num-decoration-9" :color="['#0792e0', '#0570b1']">
                                    {{totalInfo.total | numberFilter}}
                                </dv-decoration-9>
                            </div>
                            <div class="total-num-item">
                                <div class="num-title">
                                    总证书数量
                                </div>
                                <dv-decoration-9 class="num-decoration-9" :color="['#0792e0', '#0570b1']">
                                    {{totalInfo.total_cert | numberFilter}}
                                </dv-decoration-9>
                            </div>
                        </div>

                    </div>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 证书通信连接</span>
                        </div>

                        <el-radio-group v-model="staticsDay1" @change="(label) => {changeStaticsDay(label, 1)}">
                            <el-radio-button label="WEEK">周</el-radio-button>
                            <el-radio-button label="MONTH">月</el-radio-button>
<!--                            <el-radio-button label="YEAR">年</el-radio-button>-->
                        </el-radio-group>
                    </div>

                    <div class="graph-panel network-encrypt-daily-box">
                        <div class="empty-data" v-if="sessDailyCount.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-else>暂时没有数据</div>
                        </div>
                        <default-line :data="sessDailyCount" :xAxis="dailyXAxis" />
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row class="con statistic-table" :gutter="20">
            <el-col :span="8">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 证书公钥算法统计</span>
                        </div>

                        <el-radio-group v-model="staticsDay2" @change="(label) => {changeStaticsDay(label, 2)}">
                            <el-radio-button label="WEEK">周</el-radio-button>
                            <el-radio-button label="MONTH">月</el-radio-button>
<!--                            <el-radio-button label="YEAR">年</el-radio-button>-->
                        </el-radio-group>
                    </div>

                    <div class="graph-panel tls-version-box">
                        <div class="empty-data" v-if="certPubalg.length == 0">
                            <span class="empty-rotate">
                                <i class="empty-data-icon iconfont iconsync-alt"></i>
                            </span>
                            <div class="empty-data-text" v-if="isLoading">正在加载数据，请稍候...</div>
                            <div class="empty-data-text" v-if="!isLoading">暂时没有数据</div>
                        </div>

                        <default-pie :data="certPubalg" name="证书公钥算法统计" />

                    </div>

                </el-card>

            </el-col>

            <el-col :span="16">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 证书查询</span>
                        </div>
                        <!-- <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDateRange" :picker-options="pickerOptions">
                        </el-date-picker> -->
                    </div>
                    <div class="graph-panel tls-version-box">
                        <certificate-list />
                    </div>
                </el-card>
            </el-col>

        </el-row>
        <el-row class="con statistic-table" :gutter="20">
            <el-col :span="8">
                <el-card class="center">

                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 证书使用次数</span>
                        </div>

                        <el-radio-group v-model="staticsDay3" @change="(label) => {changeStaticsDay(label, 3)}">
                            <el-radio-button label="WEEK">周</el-radio-button>
                            <el-radio-button label="MONTH">月</el-radio-button>
<!--                            <el-radio-button label="YEAR">年</el-radio-button>-->
                        </el-radio-group>
                    </div>

                    <div class="graph-panel tls-version-box">
                        <el-table tooltip-effect="light" height="350" v-loading="loading3" class="table-top width-ninety" stripe :data="certhashCount">
                            <el-table-column prop="subject" show-overflow-tooltip label="证书主体">
                            </el-table-column>
                            <el-table-column prop="count" width="60" label="数量">
                            </el-table-column>
                            <el-table-column width="60" label="证书" align="right">
                                <template slot-scope="scope">
                                    <el-tooltip effect="light" content="查看证书" placement="top">
                                        <el-button type="text" icon="el-icon-collection" class="table-btn" @click="handleCertDetail(scope.row.name)">
                                        </el-button>
                                    </el-tooltip>

                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card class="center">
                    <div class="header-title">
                        <div class="d-flex pt-2 pl-2">
                            <span style="color:#5cd9e8">
                                <icon name="chart-area"></icon>
                            </span>
                            <span class="fs-xl text mx-2"> 证书通信列表</span>
                        </div>
                        <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDateRange" :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                    <div class="graph-panel tls-version-box">

                        <el-table tooltip-effect="light" height="350" class="table-top width-ninety" stripe :data="dataList" v-loading="loading">
                            <el-table-column prop="timestamp" show-overflow-tooltip label="时间">
                                <template slot-scope="scope">
                                    {{scope.row.timestamp | formatDateFilter}}
                                </template>
                            </el-table-column>

                            <el-table-column prop="srcIp" show-overflow-tooltip label="源 IP">
                            </el-table-column>
                            <el-table-column prop="dstIp" show-overflow-tooltip label="目的 IP">
                                <template slot-scope="scope">
                                    {{scope.row.dstIp}}:{{scope.row.dstPort}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="protocol" show-overflow-tooltip label="协议">
                                <template slot-scope="scope">
                                    <span v-for="(item, index) in scope.row.protocol" :key="index">{{index===0 ? '' : ','}} {{item.toUpperCase()}} </span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="totPackets" show-overflow-tooltip label="包数量">
                            </el-table-column>
                            <el-table-column prop="totDataBytes" show-overflow-tooltip label="数据大小">
                                <template slot-scope="scope">
                                    {{scope.row.totDataBytes | fileSizeFilter}}
                                </template>
                            </el-table-column>
                            <el-table-column width="100" label="证书" align="right">
                                <template slot-scope="scope">

                                    <el-tooltip effect="light" content="查看证书" placement="top" v-for="(item, index) in scope.row.CertHash" :key="index" >
                                        <el-button type="text" icon="el-icon-collection" class="table-btn" @click="handleCertDetail(item)">
                                        </el-button>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-row class="paging-row width-ninety">
                            <el-col :span="24">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 15, 20, 30, 40, 50]" layout="total, sizes, prev, pager, next, jumper" :page-size="pageSize" :current-page="pageNo" :total="total">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>

            <certificate-dialog v-model="certDialogVisible" :certDetail="certDetail" />

        </el-row>
    </el-main>
</template>
<script>
import certificateList from './component/certificateList.vue'
import certificateDialog from './component/certificateDialog.vue'
import {numberFilter} from "../../../assets/js/filters";
import {agentSearchField} from "../../../assets/js/constants"

export default {
    components: {
        certificateList, certificateDialog
    },
    data() {
        let d = new Date();
        let times = d.getTime();
        let s = times - (24 * 60 * 60 * 1000);
        let st = new Date(s);
        let year = st.getFullYear();
        let date = st.getDate();
        let mounth = st.getMonth();
        let start = new Date(year, mounth, date);
        let end = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        return {
            isLoading: false,
            isInit: true,
            dataInfo: {
                sess_daily_count: [],
                cert_pubalg: [],
                dstIp_count: [],
                srcIp_count: [],
                certhash_count: [],
                total: null,
                total_cert: null,
            },
            staticsDay: 'WEEK',
            staticsDay1: 'WEEK',
            staticsDay2: 'WEEK',
            staticsDay3: 'WEEK',
            staticsDay4: 'WEEK',
            staticsDay5: 'WEEK',
            staticsDay6: 'WEEK',
            staticsDayType: 1,
            sessDailyCount: [],
            dailyXAxis: [],
            certPubalg: [],
            certhashCount: [],
            totalInfo: {
                total: 0,
                total_cert: 0
            },
            pageNo: 1,
            pageSize: 15,
            total: 0,
            searchForm: {
                startTime: start,
                endTime: end,
            },
            dataList: [],
            dateRange: [start, end],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            loading: false,
            loading3: false,
            certDialogVisible: false,
            certDetail: {},
            agents:[],
            engines:agentSearchField.engines,
            netBoundary:agentSearchField.netBoundary,
            engineDisabled: false,
            netBoundaryDisabled: false,
            agentDisabled: false,
            mainSearchForm: {
                agentId: "",
                engineRoom: null,
                networkBoundary: null
            }
        }
    },
    filters:{
        numberFilter(value){
            return numberFilter(value);
        }
    },
    mounted() {
        this.findAgents();
        this.certProtocolMonitor()
        this.getConnectInfoList()
    },
    methods: {
        findAgents() {
            this.$http.post({
                url: this.$api.adminApi.findAllAgents,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: (data) => {
                        this.agents = data.result || []
                    }
                }
            })
        },
        engineChange(val){
            if(this.mainSearchForm.engineRoom){
                this.mainSearchForm.networkBoundary = null;
                this.netBoundaryDisabled = true;
                this.mainSearchForm.agentId = "";
                this.agentDisabled = true;
            }else{
                this.netBoundaryDisabled = false;
                this.agentDisabled = false;
            }
        },
        netBoundaryChange(val){
            if(this.mainSearchForm.networkBoundary){
                this.mainSearchForm.engineRoom = null;
                this.engineDisabled = true;
                this.mainSearchForm.agentId = "";
                this.agentDisabled = true;
            }else{
                this.engineDisabled = false;
                this.agentDisabled = false;
            }
        },
        agentChange(val){
            if(this.mainSearchForm.agentId){
                this.mainSearchForm.engineRoom = null;
                this.engineDisabled = true;
                this.mainSearchForm.networkBoundary = null;
                this.netBoundaryDisabled = true;
            }else{
                this.engineDisabled = false;
                this.netBoundaryDisabled = false;
            }
        },
        mainSearch(){
            //顶部搜索，需要结合别的搜索框内容
            this.isInit = true
            this.isLoading = true;
            this.loading3 = true;
            this.staticsDay = 'WEEK';
            this.staticsDay1 = 'WEEK';
            this.staticsDay2 = 'WEEK';
            this.staticsDay3 = 'WEEK';
            this.staticsDay4 = 'WEEK';
            this.staticsDay5 = 'WEEK';
            this.staticsDay6 = 'WEEK';
            this.certProtocolMonitor()
            this.getConnectInfoList()
        },
        changeStaticsDay(val, type) {
            this.staticsDay = this['staticsDay' + type]
            this.staticsDayType = type
            this.certProtocolMonitor()
        },
        formatDailyXAxis(day) {
            let dates = []
            let today = new Date().getTime()
            for (let i = day; i >= 0; i--) {
                let beforeToday = today - 1000 * 60 * 60 * 24 * i
                dates.push(this._.formatDate(beforeToday))
            }
            return dates
        },
        changeDateRange(val) {
            if(val) {
                this.searchForm.startTime = val[0]
                this.searchForm.endTime = val[1]
            } else {
                this.searchForm.startTime = ''
                this.searchForm.endTime = ''
            }
            this.getConnectInfoList()
        },
        handleSizeChange(val) {
            this.pageNo = 1;
            this.pageSize = val;
            this.getConnectInfoList();
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.getConnectInfoList();
        },

        certProtocolMonitor() {
            let params = {
                staticsDay: this.staticsDay,
                ...this.mainSearchForm
            }
            if (this.isInit) {
                this.isLoading = true;
            }
            if (this.staticsDayType === 3) {
                this.loading3 = true;
            }
            this.$http.post({
                url: this.$api.encryptionApi.certProtocolMonitor,
                params: params,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {


                        this.dataInfo = data.result;

                        if (this.staticsDayType === 1 || this.isInit) {
                            if (this.staticsDay === 'WEEK') {
                                this.dailyXAxis = this.formatDailyXAxis(6)
                            }
                            if (this.staticsDay === 'MONTH') {
                                this.dailyXAxis = this.formatDailyXAxis(29)
                            }
                            if (this.staticsDay === 'YEAR') {
                                this.dailyXAxis = this.formatDailyXAxis(364)
                            }
                            this.sessDailyCount = this.dataInfo.sess_daily_count
                        }

                        if (this.staticsDayType === 2 || this.isInit) {
                            this.certPubalg = []

                            Object.keys(this.dataInfo.cert_pubalg).forEach(key => {
                                this.certPubalg.push({
                                    name: key,
                                    value: this.dataInfo.cert_pubalg[key]
                                })
                            })
                        }

                        if (this.staticsDayType === 3 || this.isInit) {
                            this.certhashCount = this.dataInfo.certhash_count || []
                        }

                        if (this.staticsDayType === 6 || this.isInit) {
                            this.totalInfo.total = this.dataInfo.total
                            this.totalInfo.total_cert = this.dataInfo.total_cert
                        }

                        this.isInit = false
                        this.isLoading = false;
                        this.loading3 = false

                    }
                }
            })

        },

        getConnectInfoList() {
            let params = {
                pageNo: this.pageNo - 1,
                pageSize: this.pageSize,
                model: 'CertSess',
                ...this.searchForm,
                ...this.mainSearchForm
            }
            this.loading = true
            this.$http.post({
                url: this.$api.deviceApi.getConnectInfoList,
                params: params,
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        this.dataList = data.result.result || [];
                        this.total = data.result.total
                        this.loading = false
                    }
                }
            })

        },
        handleCertDetail(val) {
            this.$http.post({
                url: this.$api.adminApi.findBySha1,
                params: {
                    shaValue: val
                },
                opt: { isLoading: false, showSuccess: false },
                callback: {
                    success: data => {
                        this.certDetail = data.result
                        this.certDialogVisible = true
                    }
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped="scoped">
.con {
    display: flex;
}
.statistic-table {
    margin-top: 20px;
}
.graph-panel {
    position: relative;
}
.statics-day {
    position: absolute;
    top: -30px;
    right: 0;
}
.network-encrypt-daily-box {
    height: 300px;
    position: relative;
}

.tls-version-box {
    height: 350px;
}
.dst-ip-bar-box {
    height: 350px;
}
.header-title {
    display: flex;
    justify-content: space-between;
}

.total-num-wrap {
    height: 300px;
}
.total-num-box {
    display: flex;
    padding-top: 50px;
    .total-num-item {
        width: 50%;
        text-align: center;
        .num-title {
            font-size: 14px;
            margin-bottom: 20px;
            color: #b3d8ff;
        }
        .num-decoration-9 {
            width: 150px;
            height: 150px;
            margin: 0 auto;
            font-size: 22px;
            font-weight: 700;
        }
    }
}
</style>

<style lang="scss">
.table-btn {
    padding: 0px !important;
    .el-icon-collection {
        font-size: 18px;
    }
}
</style>