export const agentEditField = {
    engines: [
        {name: "未指定", value: 0, disabled: true},
        {name: "联通云节点", value: 1},
        {name: "移动云节点", value: 2},
        {name: "浪潮云节点", value: 3},
        {name: "计算中心云节点", value: 4}
    ],

    netBoundary: [
        {name: "未指定", value: 0, disabled: true},
        {name: "互联网", value: 1},
        {name: "政务网", value: 2}
    ]
}

export const agentSearchField = {
    engines: [
        {name: "联通云节点", value: 1},
        {name: "移动云节点", value: 2},
        {name: "浪潮云节点", value: 3},
        {name: "计算中心云节点", value: 4}
    ],

    netBoundary: [
        {name: "互联网", value: 1},
        {name: "政务网", value: 2}
    ]
}